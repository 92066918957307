import { useEffect, useState } from 'react';

import startCase from 'lodash/startCase';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import PopupFooter from 'components/modals/components/PopupFooter';
import PopupHeader from 'components/modals/components/PopupHeader';
import { ROLE_OPTIONS } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';
import { Option } from 'models/form.types';
import { useGetPayTypesQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import {
  useCreateStaffCostMutation,
  useDeleteStaffCostMutation,
  useLazyGetStaffCostsByIdQuery,
  useLazyGetStaffCostsQuery,
  useUpdateStaffCostMutation,
} from 'store/staffCosts/staffCostsSlice';
import { validation } from 'utils/helpers';
import { NUMBER_REGEXP } from 'utils/regExp';

import { EditStaffCostsFormData, EditStaffCostsProps } from './editStaffCosts.types';

const EditStaffCosts: React.FC<EditStaffCostsProps> = ({ id }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, formState, reset } = useForm<EditStaffCostsFormData>({ reValidateMode: 'onChange' });
  const [payTypesOptions, setPayTypesOptions] = useState<Option[]>([]);

  const { data: payTypes } = useGetPayTypesQuery();
  const [getStaffCostData, { data: staffCostData, isLoading }] = useLazyGetStaffCostsByIdQuery();
  const [createStaffCost, { isLoading: loadingCreate }] = useCreateStaffCostMutation();
  const [updateStaffCosts, { isLoading: loadingEdit }] = useUpdateStaffCostMutation();
  const [deleteStaffCost, { isLoading: loadingDelete }] = useDeleteStaffCostMutation();
  const [getStaffCosts] = useLazyGetStaffCostsQuery();

  const isDisabled = isLoading || loadingCreate || loadingEdit || loadingDelete;

  const onSuccess = (message: string) => {
    notifySuccess(message);
    getStaffCosts({ params: queryString.parse(location.search) });
    dispatch(closeModal());
  };

  const onSubmitForm = (formData: EditStaffCostsFormData) => {
    if (formData) {
      const body = {
        staff: formData.staff.value,
        payType: formData.payType.value,
        payAmount: formData.payAmount,
        status: formData.status.value,
      };

      const request = id ? updateStaffCosts({ body, id }) : createStaffCost({ body });
      request.unwrap().then((res) => onSuccess(res.message));
    }
  };

  const onRemove = () => {
    if (id) {
      deleteStaffCost({ id })
        .unwrap()
        .then((res) => onSuccess(res.message));
    }
  };

  useEffect(() => {
    if (payTypes) {
      setPayTypesOptions(payTypes.map((payType) => ({ label: payType, value: payType })));
    }
  }, [payTypes]);

  useEffect(() => {
    if (staffCostData) {
      const { staff, payType, payAmount, status } = staffCostData;
      reset({
        staff: { label: staff, value: staff },
        payType: { label: payType, value: payType },
        payAmount: payAmount.toString(),
        status: { label: startCase(status), value: status },
      });
    }
  }, [reset, staffCostData]);

  useEffect(() => {
    if (id) {
      getStaffCostData({ id });
    }
  }, [getStaffCostData, id]);

  return (
    <div data-testid="staff_cost_popup" className="p-8">
      <PopupHeader title={id ? 'Edit staff costs' : 'New staff costs'} id={id} />
      <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmitForm)}>
        <ControlledSelect
          control={control}
          labelDirection="row"
          options={ROLE_OPTIONS}
          placeholder="Select staff..."
          label="Staff"
          name="staff"
          rules={validation('Staff')}
        />

        <ControlledSelect
          control={control}
          labelDirection="row"
          options={payTypesOptions}
          placeholder="Select pay type..."
          label="Pay type"
          name="payType"
          rules={validation('Pay type')}
        />

        <InputField
          name="payAmount"
          label="Pay amount ($)"
          placeholder="$xxxxxx"
          control={control}
          labelDirection="row"
          type="number"
          errors={formState.errors.payAmount}
          helper={formState.errors.payAmount?.message}
          rules={validation('Pay amount', NUMBER_REGEXP)}
        />

        <ControlledSelect
          control={control}
          labelDirection="row"
          options={STATUS_OPTIONS}
          placeholder="Select status..."
          label="Status"
          name="status"
          rules={validation('Status')}
        />

        <div className="mt-1">
          <PopupFooter onRemove={onRemove} hiddenDeleteButton={!id} disabled={isDisabled} />
        </div>
      </form>
    </div>
  );
};

export default EditStaffCosts;
