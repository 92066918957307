import classNames from 'classnames';
import dayjs from 'dayjs';
import { Rating } from 'react-simple-star-rating';

import { CustomerFeedbackProblemType } from 'enums/customerFeedback';
import { DateFormat } from 'enums/dateFormats';
import { FeedbackProps } from 'store/customerFeedback/customerFeedback.types';

const FeedbackDetails: React.FC<{ feedback?: FeedbackProps }> = ({ feedback }) => {
  const problemText =
    feedback?.problemType === CustomerFeedbackProblemType.MyProvider
      ? 'Problem with my provider'
      : feedback?.problemType === CustomerFeedbackProblemType.UsingApp
        ? 'Problem using the app'
        : '--';

  const reviewComment = feedback?.reviewComment || '--';

  const wrapperClassNames = classNames('flex flex-col gap-3 font-semibold');

  return (
    <div className={wrapperClassNames}>
      <div data-testid="problem_container" className="flex text-sm">
        <p className="w-[120px] text-sm">Problem</p> {problemText}
      </div>
      <div className="flex text-sm">
        <p className="w-[120px] text-sm">Feedback</p>{' '}
        <div data-testid="review_content" className="max-w-[420px] truncate whitespace-normal text-sm">
          {reviewComment}
        </div>
      </div>
      <div data-testid="provider_container" className="flex text-sm">
        <p className="w-[120px] text-sm">Provider</p> {feedback?.providerInfo?.name}
      </div>
      <div className="flex text-sm">
        <p className="w-[120px] text-sm">Stars</p>{' '}
        <Rating
          initialValue={Number(feedback?.rating) || 0}
          readonly={true}
          SVGclassName="inline-block mb-1"
          size={16}
          SVGstorkeWidth="1"
          fillColor="#FFA700"
          SVGstrokeColor="#FFA700"
          emptyColor="transparent"
        />
      </div>
      <div data-testid="date_added_container" className="flex text-sm">
        <p className="w-[120px] text-sm">Date added</p> {dayjs(feedback?.createdAt).format(DateFormat.MMM_D_YYYY)}
      </div>
    </div>
  );
};

export default FeedbackDetails;
