import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MessageType } from 'enums/messages';
import type { RootState } from 'store';

import { SlidingPaneProps } from './slidingInPane.types';

const initialState: SlidingPaneProps = {
  taskId: '',
  isOpen: false,
  isQueue: false,
  isDisabledMarkAsComplete: true,
  alertsState: {
    [MessageType.Medical]: true,
    [MessageType.Support]: true,
    [MessageType.SMS]: true,
    [MessageType.StaffNote]: true,
  },
  isTaskReminder: true,
};

const slidingPaneSlice = createSlice({
  name: 'slidingPane',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true;
      state.isTaskReminder = true;
      state.isQueue = !!action.payload.isQueue;
      if (action.payload.taskId) state.taskId = action.payload.taskId;
    },
    closeAlert(state, action: PayloadAction<{ type: MessageType }>) {
      state.alertsState[action.payload.type] = false;
    },
    closeRemindAlert: (state) => {
      state.isTaskReminder = false;
    },
    disableMarkAsComplete: (state, action) => {
      state.isDisabledMarkAsComplete = action.payload;
    },
    reset: () => initialState,
  },
});

export const selectSlidingInPane = (state: RootState) => state.slidingInPane;

export const { open, reset, disableMarkAsComplete, closeAlert, closeRemindAlert } = slidingPaneSlice.actions;

export default slidingPaneSlice.reducer;
