import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';
import { EventTypes } from 'models/event.types';
import { EventDetailsProps } from 'store/calendar/calendar.types';

/**
 * Generates a string describing the recurrence of an event.
 *
 * @param {EventDetailsProps} recurringEventInfo - An object containing information about the recurring event.
 * @returns {string} A string describing the recurrence of the event, or an empty string if the event is not recurring.
 * If recurringEventInfo is undefined or not an object, the function returns an empty string.
 * If recurringEventInfo.recurring is undefined or not an object, the function returns an empty string.
 * If recurringEventInfo.recurring.daysOfWeek is not an array, the function returns a string describing the recurrence type but not the days of the week.
 * If recurringEventInfo.recurring.until is a valid date, the function includes the end date in the returned string.
 */
const getRepeats = (recurringEventInfo?: EventDetailsProps) => {
  let result = '';
  if (recurringEventInfo?.recurring) {
    const daysOfWeek = recurringEventInfo?.recurring.daysOfWeek.map((day) => {
      return dayjs().set('day', day).format(DateFormat.dddd);
    });

    result = `Repeats ${recurringEventInfo?.recurring.type} on ${daysOfWeek.join(', ')}`;

    if (recurringEventInfo?.recurring.until) {
      result += ` until ${dayjs(recurringEventInfo?.recurring.until).format(DateFormat.MMM_D_YYYY)}`;
    }

    return result;
  }
};

const getDefaultTab = (shiftType: string) => {
  return shiftType.toLowerCase() === 'day off' || shiftType.toLowerCase() === 'break'
    ? EventTypes.TIME_OFF
    : EventTypes.SHIFT;
};

export { getRepeats, getDefaultTab };
