const Skeleton = () => {
  return (
    <div className="flex animate-pulse items-center gap-2" data-testid="timezone-header-skeleton">
      <div className="h-4 w-4 rounded-full bg-slate-200" />
      <div className="h-5 w-[200px] bg-slate-200" />
    </div>
  );
};

export default Skeleton;
