import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { marked } from 'marked';
import { useFormContext } from 'react-hook-form';

import { stripHTML } from 'utils/common/parseHTML';

import { PasteResponseProps } from './pasteResponse.types';

const PasteResponse = ({ content, isDisabled }: PasteResponseProps) => {
  const { setValue, getValues } = useFormContext();

  const isPasted = !!content && content.replace(/\s/g, '') === stripHTML(getValues('message')).replace(/\s/g, '');

  const btnText = isPasted ? 'Pasted' : 'Paste response';

  const btnClasses = classNames(
    'flex items-center text-sm px-4 py-2 gap-1 rounded-lg disabled:bg-white/30 disabled:text-gray-700',
    {
      'text-white/50 cursor-default': isPasted || !content,
      'border border-white/50': isPasted,
      'bg-white font-bold  text-gray-700': !isPasted,
    },
  );

  const handleClick = () => !isPasted && setValue('message', marked(content));

  return (
    <button className={btnClasses} onClick={handleClick} disabled={isDisabled || isPasted}>
      <Common.Icon name={isPasted ? 'check' : 'arrow-alt-up'} className="h-4 w-4" />

      <span>{btnText}</span>
    </button>
  );
};

export default PasteResponse;
