import { Common } from '@thecvlb/design-system';

const NoPhoneNumberPlaceholder = () => {
  return (
    <div className="absolute top-2/4 flex w-full flex-col items-center justify-center">
      <div className="w-fit rounded-full bg-red-100 p-4">
        <Common.Icon className="h-8 w-8 text-red" name="chat-outline" />
      </div>

      <p className="font-bold text-gray-700">Cannot send SMS</p>
      <p className="text-sm text-gray-700 ">Patient does not have a phone number listed.</p>
    </div>
  );
};

export default NoPhoneNumberPlaceholder;
