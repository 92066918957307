import { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useFlag } from '@unleash/proxy-client-react';

import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import SearchForm from 'components/filters/SearchForm';
import TaskCategoriesFilter from 'components/filters/Tasks/TaskCategoriesFilter';
import ConnectedTasksTable from 'components/tables/TaskTable/ConnectedTasksTable';
import { TagTypes } from 'enums/tagTypes';
import { useAppSelector } from 'hooks/redux';
import { filtersByRoles } from 'pages/Tasks/Tasks.settings';
import { useGetTagsListQuery } from 'store/patients/patientsSlice';
import { selectUser } from 'store/user/userSlice';

const selectUserState = createSelector(selectUser, (user) => ({ userRole: user.userType.name }));

const PatientTasks: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { data: tags } = useGetTagsListQuery({ tagTypes: [TagTypes.Both, TagTypes.Manual, TagTypes.System] });
  const tagsOptions = useMemo(() => tags?.data?.map((tag) => ({ label: tag.name, value: tag._id })), [tags?.data]);
  const { userRole } = useAppSelector(selectUserState);
  const isQueueDisabled = useFlag('disable-queue');

  const filterData = useMemo(() => filtersByRoles(userRole, false, isQueueDisabled, true), [userRole, isQueueDisabled]);

  return (
    <div className="flex flex-col gap-4 px-6 py-4">
      <div className="flex items-center justify-between">
        <TaskCategoriesFilter categories={filterData} />
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags options={tagsOptions} />
      <ConnectedTasksTable patientId={patientId} />
    </div>
  );
};

export default PatientTasks;
