import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { useFlag } from '@unleash/proxy-client-react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import HealthMetricTable from 'components/tables/HealthMetric/HealthMetricTable';
import { useAppSelector } from 'hooks/redux';
import { selectBasicTables } from 'store/basicTable/basicTableSlice';
import { SortField } from 'store/patientChart/patientChart.types';
import { useLazyGetHealthMetricsQuery } from 'store/patientChart/patientChartSlice';

import AddButton from './AddButton';
import { getHealthMetricTitle } from './healthMetric.settings';
import { HealthMetricProps } from './healthMetric.types';
import ChartAccordion from '../Accordion';

const HealthMetric: React.FC<HealthMetricProps> = ({
  type,
  value,
  metricSubtitle,
  openMetricModal,
  children,
  isTableOpenByDefault = true,
  isWMDevices = false,
}) => {
  const [showTable, toggle] = useToggle(isTableOpenByDefault);
  const basicTablesParams = useAppSelector(selectBasicTables);
  const [getHealthMetrics, { data: metrics }] = useLazyGetHealthMetricsQuery();
  const { patientId = '' } = useParams<{ patientId: string }>();

  const editingDisabled = useFlag('disable-health-metric-editing');
  const basicTablesParamsLimit = basicTablesParams?.[type]?.limit ?? 50;
  const basicTablesParamsPageNo = basicTablesParams?.[type]?.pageNo?.toString();
  const basicTablesParamsSortField = basicTablesParams?.[type]?.sortField as SortField;
  const basicTablesParamsSortOrder = basicTablesParams?.[type]?.sortOrder;

  useEffect(() => {
    if (value) {
      getHealthMetrics({
        patientId,
        metricType: type,
        limit: basicTablesParamsLimit.toString(),
        pageNo: basicTablesParamsPageNo,
        sortField: basicTablesParamsSortField,
        ...(basicTablesParamsSortOrder && { sortOrder: basicTablesParamsSortOrder }),
      });
    }
  }, [
    value,
    getHealthMetrics,
    patientId,
    type,
    basicTablesParamsLimit,
    basicTablesParamsPageNo,
    basicTablesParamsSortField,
    basicTablesParamsSortOrder,
  ]);

  const metricTitle = getHealthMetricTitle(type);

  return value ? (
    <ChartAccordion
      title={metricTitle}
      subtitle={metricSubtitle}
      handleClick={openMetricModal}
      isEditAllowed={!editingDisabled}
    >
      {children}
      {showTable && !!metrics?.data.length && (
        <div className="mb-4">
          <HealthMetricTable
            metricType={type}
            metrics={metrics?.data}
            totalCount={metrics?.info?.totalCount ?? 0}
            isEditAllowed={!editingDisabled}
            isWMDevices={isWMDevices}
          />
        </div>
      )}
      {!isTableOpenByDefault && (
        <Common.Button color="white" className="mx-auto my-2" onClick={() => toggle()}>
          {showTable ? 'Show less' : 'View more'}
        </Common.Button>
      )}
    </ChartAccordion>
  ) : openMetricModal && !editingDisabled ? (
    <AddButton text={`Add ${metricTitle}`} onClick={openMetricModal} />
  ) : null;
};

export default HealthMetric;
