import { useEffect } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Common } from '@thecvlb/design-system';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch } from 'hooks/redux';
import { removeItem, toggleAvailability, updateItem } from 'store/availability/availabilitySlice';

import { getTimeInputClassName } from './period.settings';
import { PeriodProps } from './period.types';

const Period: React.FC<PeriodProps> = ({ start, end, day, id, appointments, queue }) => {
  const dispatch = useAppDispatch();
  const { formState, register, setError, clearErrors } = useFormContext();

  const startTimeError = formState.isSubmitted && formState.errors[`start_time${id}`];
  const endTimeError = formState.isSubmitted && formState.errors[`end_time${id}`];
  const isError = formState.isSubmitted && !queue && !appointments;

  const startTimeRegister = register(`start_time${id}`, {
    required: {
      value: true,
      message: 'Start time is required',
    },
    onBlur: (e) => dispatch(updateItem({ id, day, type: 'startTime', value: e.target.value })),
    value: start,
  });
  const endTimeRegister = register(`end_time${id}`, {
    required: {
      value: true,
      message: 'End time is required',
    },
    onBlur: (e) => dispatch(updateItem({ id, day, type: 'endTime', value: e.target.value })),
    value: end,
  });

  useEffect(() => {
    if (isError) {
      setError(`appointmentTypeError-${id}`, { type: 'custom', message: 'Appointment type is required' });
    } else {
      clearErrors(`appointmentTypeError-${id}`);
    }
  }, [id, isError, setError, clearErrors]);

  return (
    <div className="flex items-center gap-4" data-testid={`period-${id}`}>
      <div className="self-start">
        <input
          data-testid="start_time"
          type="time"
          className={getTimeInputClassName(startTimeError)}
          defaultValue={start}
          {...startTimeRegister}
        />
        <p className="text-xs text-red">{!!startTimeError && startTimeError?.message?.toString()}</p>
      </div>
      <span className="relative mt-2.5 self-start text-sm">to</span>
      <div className="self-start">
        <input
          data-testid="end_time"
          type="time"
          className={getTimeInputClassName(endTimeError)}
          defaultValue={end}
          {...endTimeRegister}
        />
        <p className="text-xs text-red">{!!startTimeError && startTimeError?.message?.toString()}</p>
      </div>
      <div className="relative">
        <div className="flex items-center gap-4">
          <Common.Checkbox
            checked={queue}
            key="queue"
            color="blue"
            name={`queue-${id}`}
            onChange={(e) => dispatch(toggleAvailability({ day, id, value: e.target.checked, type: 'queue' }))}
          >
            Queue
          </Common.Checkbox>
          <Common.Checkbox
            checked={appointments}
            key="appointments"
            color="blue"
            name={`appointments-${id}`}
            onChange={(e) => dispatch(toggleAvailability({ day, id, value: e.target.checked, type: 'appointments' }))}
          >
            Appointments
          </Common.Checkbox>
        </div>
        {isError && (
          <ErrorMessage
            name={`appointmentTypeError-${id}`}
            errors={formState.errors}
            render={({ message }) => <p className="absolute text-sm text-red-500">{message}</p>}
          />
        )}
      </div>

      <button
        data-testid="trash_btn"
        type="button"
        className="mt-2.5 self-start"
        onClick={() => dispatch(removeItem({ id, day }))}
      >
        <Common.Icon name="trash" />
      </button>
    </div>
  );
};

export default Period;
