import { useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import { FormProvider, useForm } from 'react-hook-form';

import Alert from 'components/common/Alert';
import Loader from 'components/common/Loader';
import PrescribeCompoundResult from 'components/modals/PrescribeCompoundResult';
import { PlanRecommendationStatus } from 'enums/planRecommendation';
import { Tags } from 'enums/tagTypes';
import { TaskTags } from 'enums/taskCategories';
import { DosagePreferences } from 'enums/taskDetailsStatus';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { getDisplayName } from 'store/patients/patients.settings';
import { selectPatient } from 'store/patients/patientsSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { useSubmitPrescriptionsMutation } from 'store/weightManagement/weightManagementSlice';

import type { PrescribeCompoundFormData, PrescribeCompoundProps } from './prescribeCompound.types';
import PrescribeCompoundForm from './PrescribeCompoundForm';

const selectPersonalInfo = createSelector([selectTask, selectPatient], (task, patient) => ({
  tasksPersonalInfo: task.taskDetails.personalInfo,
  taskDetails: task.taskDetails,
  isTripleTherapyPatient: patient.isTripleTherapyPatient,
}));

const PrescribeCompound: React.FC<PrescribeCompoundProps> = ({ patientId, patientInfo, defaultPrescription }) => {
  const { tasksPersonalInfo, taskDetails, isTripleTherapyPatient } = useAppSelector(selectPersonalInfo);
  const [submitPrescription, { isLoading }] = useSubmitPrescriptionsMutation();
  const [sendSupplyKit, setSendSupplyKit] = useState(false);

  const dispatch = useAppDispatch();
  const methods = useForm<PrescribeCompoundFormData>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const personalInfo = patientInfo || tasksPersonalInfo;
  const isTripleTherapy = taskDetails?.tags?.includes(TaskTags.TripleTherapy) || isTripleTherapyPatient;

  const notIncreaseDosage =
    taskDetails?.weightManagement &&
    'acceptDoseIncrease' in taskDetails?.weightManagement &&
    !taskDetails?.weightManagement.acceptDoseIncrease;
  const showNotIncreaseTripleAlert =
    isTripleTherapy && taskDetails?.weightManagement?.dosagePreference === DosagePreferences.NO_INCREASE;
  const showDecreaseTripleAlert =
    isTripleTherapy && taskDetails?.weightManagement?.dosagePreference === DosagePreferences.DECREASE;

  const patientName = getDisplayName(personalInfo);
  const isMaintenance =
    taskDetails?.patientInfo.tags?.includes(Tags.Maintenance) &&
    patientInfo?.planRecommendations?.[0]?.status === PlanRecommendationStatus.ACCEPTED;
  const handleSendPrescription = async (data: PrescribeCompoundFormData) => {
    const body = {
      icd10Codes: data.icd10Codes?.map((icd10Code) => icd10Code?.value),
      medicationId: data.medication?.data._id || '',
      sigId: data.sig?.data._id || '',
      pharmacyId: data.pharmacy?.data._id || '',
      needSupplyKit: !sendSupplyKit ? false : !!data.needSupplyKit,
    };

    const requestDetails = !!data.qty?.data && {
      patientId,
      parentTaskId: taskDetails._id,
      paymentAmount: data.qty?.data?.totalPrice,
      prescription: data.medication?.data?.name || '',
    };

    if (patientId && requestDetails)
      await submitPrescription({ patientId, body })
        .unwrap()
        .then(
          (prescribeResult) =>
            prescribeResult?.data &&
            dispatch(
              openModal({
                size: 'sm',
                hideClose: true,
                modalContent: (
                  <PrescribeCompoundResult prescribeResult={prescribeResult.data} requestDetails={requestDetails} />
                ),
              }),
            ),
        );
  };

  const onSubmit = (data: PrescribeCompoundFormData) => {
    if (data) {
      const pharmacyValue = data.pharmacy?.data;
      const isStateIncluded = !!personalInfo.state && pharmacyValue?.states?.includes(personalInfo.state);
      if (pharmacyValue && !isStateIncluded) {
        methods.setError('pharmacy', {
          type: 'custom',
          message: `Patient is located in a state that ${pharmacyValue?.name} does not cover.`,
        });
      } else {
        handleSendPrescription(data);
      }
    }
  };

  return (
    <>
      <div data-testid="create_prescription_popup" className="p-6">
        {isLoading && <Loader isVisible />}
        <h2 className="mb-2 text-xl font-bold text-gray-700">Create prescription</h2>
        <p className="mb-6 text-lg font-medium text-gray-700">{patientName}</p>

        {(notIncreaseDosage || showNotIncreaseTripleAlert) && (
          <Alert
            type="warning"
            containerClasses="mb-6 bg-yellow-100 shadow-none"
            children={
              <p className="ml-1 text-base font-medium">
                Patient has indicated they do not want to increase their dosage.
              </p>
            }
          />
        )}

        {showDecreaseTripleAlert && (
          <Alert
            type="error"
            containerClasses="mb-6 bg-red-100 shadow-none"
            children={
              <p className="ml-1 text-base font-medium">Patient has indicated they want to decrease their dosage.</p>
            }
          />
        )}
        {isMaintenance && (
          <Alert
            type="warning"
            containerClasses="mb-6 bg-yellow-100 shadow-none"
            children={<p className="ml-1 text-base font-medium">Patient has switched to a maintenance plan.</p>}
          />
        )}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {personalInfo && (
              <PrescribeCompoundForm
                patientState={personalInfo?.state}
                defaultPrescription={defaultPrescription}
                isMaintenance={isMaintenance}
                patientId={personalInfo?._id}
                setSendSupplyKit={setSendSupplyKit}
              />
            )}
            <div className="mt-6 grid w-full grid-cols-2 gap-x-2">
              <Common.Button
                color="white-alt"
                className="flex w-full justify-center"
                onClick={() => dispatch(closeModal())}
                size="sm"
                type="button"
              >
                Cancel
              </Common.Button>
              <Common.Button color="blue" className="flex w-full justify-center" size="sm">
                Send to chart
              </Common.Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default PrescribeCompound;
