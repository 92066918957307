import { Common } from '@thecvlb/design-system/lib/src';
import { useLocation } from 'react-router-dom';

import BulkEditContent from 'components/modals/BulkEditContent';
import { getHideClose, getModalSize } from 'components/modals/BulkEditContent/bulkEdit.settings';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectBulkEdit } from 'store/bulkEdit/bulkEditSlice';
import { openModal } from 'store/modal/modalSlice';

const BulkEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { bulkEdits } = useAppSelector(selectBulkEdit);
  const handleClick = () => {
    dispatch(
      openModal({
        modalContent: <BulkEditContent />,
        size: getModalSize(location.pathname),
        hideClose: getHideClose(location.pathname),
      }),
    );
  };
  return bulkEdits.length ? (
    <Common.Button dataTestId="bulk_edit_btn" onClick={handleClick} color="white" style="pill" size="sm">
      <Common.Icon name="pencil" />
      {`Edit (${bulkEdits.length}) selected`}
    </Common.Button>
  ) : null;
};

export default BulkEdit;
