import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { ProfileImageProps } from '@thecvlb/design-system/lib/src/common';
import classNames from 'classnames';
import dayjs from 'dayjs';

import EventIcon from 'components/appointments/ManageAvailability/components/EventIcon';
import { DateFormat } from 'enums/dateFormats';
import { EventTypes } from 'models/event.types';
import { getEventColor, getEventsFiltersTitle, getEventTitle, getExclusionsTitleList } from 'utils/calendar';

import { TimeSlotTypes } from './timeSlot.types';

const TimeSlot: React.FC<TimeSlotTypes> = ({ timeSlot, user }) => {
  const isDayOff = timeSlot.type === EventTypes.BREAK || timeSlot.type === EventTypes.TIME_OFF;
  const title = getEventTitle(timeSlot.type, timeSlot.shiftTypes ?? []);
  const filtersTitle = getEventsFiltersTitle(timeSlot.shiftTypes ?? []);
  const exclusionsTitleList = getExclusionsTitleList(timeSlot.shiftTypes ?? []);
  const colorOption = getEventColor(timeSlot.type, title);
  const startTime = dayjs(timeSlot.startTime, DateFormat.HH_mm).format(DateFormat.h_mm_a);
  const endTime = dayjs(timeSlot.endTime, DateFormat.HH_mm).format(DateFormat.h_mm_a);
  const wrapperTooltipClassnames = classNames(
    'z-20 min-w-max h-6 px-2 py-1 shadow top-0 border-t border-gray-100 absolute bg-gray-100 bg-opacity-90 text-xs leading-tight transition transition-all duration-300 transform scale-0 group-hover:scale-100 group-hover:-translate-x-2 -translate-y-2/3 rounded',
  );

  const timeSlotClassnames = classNames(
    `flex items-center absolute inset-y-0 my-auto h-fit max-h-[28px] truncate whitespace-nowrap rounded border-0 border-l-4 py-1 pl-2 text-left ${colorOption.border} ${colorOption.background}`,
    {
      'diagonal-stripes': isDayOff,
    },
  );

  return (
    <div className="group cursor-pointer">
      <div
        data-testid="time_slot"
        className={timeSlotClassnames}
        style={{
          left: timeSlot.left,
          right: timeSlot.right,
        }}
      >
        <Common.ProfileImage
          role={user.userType.shortCode as ProfileImageProps['role']}
          src={user.profileImage}
          size="sm"
          className="mr-1"
        />
        <EventIcon title={title} />
        <p className="ml-1 flex items-center text-xs">
          {title} {filtersTitle && `• ${filtersTitle}`}
        </p>
        {exclusionsTitleList &&
          exclusionsTitleList.length > 0 &&
          exclusionsTitleList.map((exclusionTitle, index) => (
            <p key={index} className="ml-1 text-xs">
              {` • `}
              <i>Excluding</i>
              {` ${exclusionTitle}`}
            </p>
          ))}
      </div>

      <span data-testid="time_slot_value" className={wrapperTooltipClassnames} style={{ right: timeSlot.right }}>
        {title && <b>{`${title}: `}</b>}
        {`${startTime} - ${endTime}`}
      </span>
    </div>
  );
};

export default TimeSlot;
