import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';
import { DateFormat } from 'enums/dateFormats';

export function getTimeDiff({
  startDate,
  endDate,
  unit,
}: {
  startDate?: string;
  endDate?: string;
  unit: dayjs.QUnitType | dayjs.OpUnitType;
}) {
  let result = null;
  const start = startDate ? dayjs(startDate) : null;
  const end = endDate ? dayjs(endDate) : null;

  if (start && end) {
    if (start.isValid() && end.isValid()) {
      result = end.diff(start, unit);
    } else {
      result = null;
    }
  }

  return result;
}

dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(relativeTime);

const timeZone = dayjs.tz.guess();

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a min',
    mm: '%d min',
    h: 'an hr',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export const dateTimeFormatter = (date: string | number, customCalendarFormat?: { [key: string]: string }) => {
  if (date)
    return dayjs
      .utc(date)
      .tz(timeZone)
      .calendar(
        null,
        customCalendarFormat || {
          sameDay: `h:mma z`,
          nextDay: '[Tomorrow •] h:mma z',
          nextWeek: 'MMM D • h:mma z',
          lastDay: '[Yesterday •] h:mma z',
          lastWeek: 'MMM D • h:mma z',
          sameElse: `MMM D • h:mma z`,
        },
      );
};

export const parseDateForTodayAndTomorrow = (date: string) => {
  if (dayjs(date).isToday()) {
    return 'Today';
  }
  if (dayjs(date).subtract(1, 'days').isToday()) {
    return 'Tomorrow';
  }
  return dayjs(date).format(DateFormat.MM_DD);
};

export const validateBirth = (val: string, message: string) => {
  const age = val ? dayjs().diff(dayjs(val).format(DateFormat.YYYY_MM_DD), 'year') : 0;
  const isValidAge = age >= 18 && age <= 62; // min and max age

  return (isValidAge && dayjs(val, FRONTEND_DATE_FORMAT).format(FRONTEND_DATE_FORMAT) === val) || message;
};

export const getTimezoneAbbreviation = (userTimezone: string) => {
  let result;
  try {
    result = Intl.DateTimeFormat('en-US', { timeZone: userTimezone, timeZoneName: 'short' })
      .format(new Date())
      .split(' ')?.[1];
  } catch (error) {
    result = 'Invalid timezone';
    console.error(error);
  }
  return result;
};

export const getRemainingTime = (date?: string) => {
  if (!date) return '';

  const days = dayjs(date).diff(dayjs(), 'days'); // Difference in whole days
  const hours = dayjs(date).diff(dayjs(), 'hours') % 24; // Remainder hours after full days

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }

  return hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
};
