import { useState } from 'react';

import { Common } from '@thecvlb/design-system';

import { notifySuccess } from 'components/common/Toast/Toast';
import { camelToFlat } from 'components/filters/AppliedFiltersTags/appliedFiltersTags.settings';
import { CancelAppointmentReason } from 'enums/appointments';
import { useAppDispatch } from 'hooks/redux';
import {
  useCancelAppointmentMutation,
  useUpdateCompleteOrCancelReasonMutation,
} from 'store/appointments/appointmentsSlice';
import { closeModal } from 'store/modal/modalSlice';

import { CancelAppointmentProps } from './cancelAppointment.types';

const CancelAppointment: React.FC<CancelAppointmentProps> = ({ appointmentId, handleSuccess, previousSelection }) => {
  const dispatch = useAppDispatch();
  const [selectedReason, setSelectedReason] = useState<CancelAppointmentReason>(
    previousSelection || CancelAppointmentReason.NoLongerNeeded,
  );

  const [cancelAppointment, { isLoading }] = useCancelAppointmentMutation();
  const [updateCompleteOrCancelReason, { isLoading: isUpdatingLoading }] = useUpdateCompleteOrCancelReasonMutation();

  const isEditForm = !!previousSelection;

  const isSelectReason = (reason: string) => reason === selectedReason;

  const handleClose = () => {
    dispatch(closeModal());
  };

  const successMessages = {
    cancel: 'Appointment has been successfully cancelled',
    update: 'Appointment cancel reason has been successfully updated',
  };

  const handleResponse = (response: { message: string }, defaultMessage: string) => {
    notifySuccess(response.message ?? defaultMessage);
    if (handleSuccess) handleSuccess();
    dispatch(closeModal());
  };

  const handleCancelAppointment = () => {
    isEditForm
      ? updateCompleteOrCancelReason({ appointmentId, body: { reason: selectedReason } })
          .unwrap()
          .then((response) => {
            handleResponse(response, successMessages.update);
          })
      : cancelAppointment({ id: appointmentId, cancelReason: selectedReason })
          .unwrap()
          .then((response) => {
            handleResponse(response, successMessages.cancel);
          });
  };

  return (
    <div data-testid="cancel_appo_popup" className="max-w-sm rounded-lg bg-white p-5">
      <h3 className="mb-2 text-xl font-bold leading-6">Cancellation reason</h3>
      <div className="my-6 flex flex-col gap-3">
        {(Object.keys(CancelAppointmentReason) as Array<CancelAppointmentReason>).map((reason) => (
          <Common.RadioButton
            key={reason}
            color="blue"
            size="md"
            value={reason}
            checked={isSelectReason(reason)}
            onChange={(e) => setSelectedReason(e.target.value as CancelAppointmentReason)}
          >
            {camelToFlat(reason)}
          </Common.RadioButton>
        ))}
      </div>
      <div className="flex w-full justify-between gap-2">
        <Common.Button
          dataTestId="keep_appo_btn"
          onClick={handleClose}
          color="white-alt"
          size="sm"
          className="w-1/2 grow justify-center"
          disabled={isLoading}
        >
          {isEditForm ? 'Cancel' : 'Keep appointment'}
        </Common.Button>
        <Common.Button
          dataTestId="cancel_appo_btn"
          onClick={handleCancelAppointment}
          color="red"
          size="sm"
          className="w-1/2 grow justify-center whitespace-nowrap"
          disabled={isLoading || isUpdatingLoading}
          isLoading={isLoading || isUpdatingLoading}
        >
          {isEditForm ? 'Edit cancel reason' : 'Cancel appointment'}
        </Common.Button>
      </div>
    </div>
  );
};

export default CancelAppointment;
