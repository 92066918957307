import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import Loader from 'components/common/Loader';
import Form from 'components/patient/Channel/Form';
import Messages from 'components/patient/Channel/Messages';
import { TemplatesProvider } from 'contexts/TemplatesContext/TemplatesContext';
import { MessageTemplates, MessageType, Status } from 'enums/messages';
import { useChatScroll } from 'hooks/common/useChatScroll';
import { useAppSelector } from 'hooks/redux';
import { selectChannels } from 'store/channels/channelsSlice';
import { selectChat } from 'store/chat/chatSlice';
import { selectUser } from 'store/user/userSlice';
import { HAS_PERMISSION_TO_SENT_MESSAGES, hasPermission } from 'utils/popupsContentPermission';

import { ChannelProps } from './channel.types';

const selectChannelState = createSelector([selectUser, selectChat, selectChannels], (user, chat, channels) => ({
  userType: user.userType,
  isLoadingMessages: chat.loadingMessagesStatus === Status.Pending,
  isLoadingChannels:
    channels.loadingChannelsStatus === Status.Idle || channels.loadingChannelsStatus === Status.Pending,
  isRejectedMessages: chat.loadingMessagesStatus === Status.Rejected,
  isRejectedChannels: channels.loadingChannelsStatus === Status.Rejected,
  messagesCount: chat.totalCount,
}));

const Channel: React.FC<ChannelProps> = ({ channel, type }) => {
  const { userType, isLoadingMessages, isLoadingChannels, isRejectedMessages, isRejectedChannels, messagesCount } =
    useAppSelector(selectChannelState);
  const ref = useChatScroll(messagesCount);

  const hasPermissionToSendMessage = hasPermission(HAS_PERMISSION_TO_SENT_MESSAGES, userType.name);
  const channelHeaderClasses = classNames(
    'flex max-h-14 flex-col items-center justify-center gap-1 border-gray-200 py-1',
    {
      'border-b': !!channel,
    },
  );
  const massagesContainerClasses = classNames(
    'relative mt-6 h-96 max-h-96 flex flex-col-reverse overflow-y-auto overflow-x-hidden  border-gray-200 px-4 py-0',
    {
      'border-b': !!channel,
      'items-center justify-center': isRejectedChannels || isRejectedMessages,
    },
  );

  return (
    <div data-testid="chat-area" className="flex h-full w-full flex-col">
      <Loader isVisible={isLoadingMessages} />
      <div className={channelHeaderClasses}>
        <p data-testid="channel_name_at_top_center" className="text-lg font-bold text-gray-700">
          {isLoadingChannels ? <Skeleton className="w-44" /> : channel?.channelTitle}
        </p>
        <p className="text-sm font-medium text-gray-500">
          {isLoadingChannels && <Skeleton className="w-28" />}
          {channel && type === MessageType.Medical
            ? 'Patient – Medical'
            : channel && type === MessageType.Support
              ? 'Patient – Support'
              : null}
        </p>
      </div>

      <>
        <div data-testid="channel_messages_container" className={massagesContainerClasses} ref={ref}>
          <Messages type={type} />
        </div>
        {hasPermissionToSendMessage && channel && (
          <div className="m-4">
            <TemplatesProvider templatesType={MessageTemplates.Patient}>
              <Form patientId={channel.patientId} channelId={channel.channelId} type={type} />
            </TemplatesProvider>
          </div>
        )}
      </>
    </div>
  );
};

export default Channel;
