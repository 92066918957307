import { createColumnHelper } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import dayjs from 'dayjs';
import upperFirst from 'lodash/upperFirst';

import Header from 'components/tables/Header';
import { formatHeaderText, getColorMapValue } from 'components/tables/OrdersTable/ordersTable.settings';
import { getNumberWithOrdinal } from 'components/tasks/slidingPane/AppointmentBanner/appointmentBanner.settings';
import OrderDate from 'components/tasks/slidingPane/AppointmentBanner/OrderDate';
import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';
import { DateFormat } from 'enums/dateFormats';
import { PatientProps } from 'store/patients/patients.types';

import { getPrescriptionPaymentColorTag, getPrescriptionTypeData } from './patientPrescriptions.settings';
import { PatientPrescriptionsTableProps } from './patientPrescriptions.types';
import PrescriptionsActionCell from './PrescriptionsActionCell';
import TitrationCell from './TitrationCell';

const columnHelper = createColumnHelper<PatientPrescriptionsTableProps>();

export const columns = (patientInfo?: PatientProps) => [
  columnHelper.accessor('name', {
    header: () => <Header text="Prescription" sortField="name" />,
    size: 250,
    cell: (info) => (
      <span className="flex max-w-[300px] flex-col gap-1 font-medium">
        <span className="font-bold">{info.row.original.name}</span>
        {info.row.original.subname && <span>{info.row.original.subname}</span>}
        <span>{info.row.original.sig}</span>
      </span>
    ),
  }),
  columnHelper.accessor('type', {
    header: () => <Header text="Type" sortField="type" />,
    cell: (info) => {
      const iconData = getPrescriptionTypeData(info.getValue());
      return (
        <div className="relative h-8 w-8">
          <Common.Icon name={iconData.iconName} className={classNames(iconData.classNames || 'h-8 w-8')} />
          {iconData.showLifeMDLogo && <Common.Icon name={'lifemd'} className="absolute -top-2 right-0.5 h-3 w-3" />}
        </div>
      );
    },
  }),
  columnHelper.accessor('signedAt', {
    header: () => <Header text="Prescribed" sortField="signedAt" />,
    cell: (info) => {
      const signedAt = info.getValue();
      return signedAt ? (
        dayjs(signedAt).isToday() ? (
          'Today'
        ) : (
          dayjs(signedAt).format(FRONTEND_DATE_FORMAT)
        )
      ) : (
        <Common.ColorTag color={'gray'} text={'Draft'} />
      );
    },
  }),
  columnHelper.accessor('qty', {
    header: () => <Header text="Qty" sortField="qty" />,
    cell: (info) => (
      <p className="truncate text-sm font-medium">
        {info.getValue()} {info.row.original.qtyUnits}
      </p>
    ),
  }),
  columnHelper.accessor('orderStatus', {
    header: () => <Header text="Order status" sortField="orderStatus" noSort />,
    cell: (info) => {
      const removedStatus = info.row.original.deletedAt && 'Removed';
      const fulfillmentState = info.row.original.fulfillmentState;
      const value = removedStatus || fulfillmentState || info.getValue();
      return <>{!!value && <Common.ColorTag color={getColorMapValue(value)} text={formatHeaderText(value) || ''} />}</>;
    },
  }),
  columnHelper.accessor('paymentStatus', {
    header: () => <Header text="Payment" sortField="paymentStatus" />,
    cell: (info) => {
      const value = info.getValue() || 'N/A';
      return (
        <>{!!value && <Common.ColorTag color={getPrescriptionPaymentColorTag(value)} text={upperFirst(value)} />}</>
      );
    },
  }),
  columnHelper.accessor('titration', {
    header: () => <Header text="Titrated dosage" sortField="titration" noSort />,
    cell: (info) => {
      const titration = info.getValue();
      const titrations = info.row.original.titrations;
      return (
        <TitrationCell
          titration={titration}
          titrations={titrations}
          prescriptionId={info.row.original.id}
          patientInfo={patientInfo}
        />
      );
    },
  }),
  columnHelper.accessor('renews', {
    header: () => <Header text="Renews" sortField="renews" />,
    size: 200,
    cell: (info) => {
      const value = info.getValue();
      return (
        <>
          {!!value && (
            <OrderDate
              type="renewalDate"
              date={dayjs(value).format(DateFormat.MM_DD_YYYY)}
              orderId={info.row.original.id}
            />
          )}
        </>
      );
    },
  }),
  columnHelper.accessor('renewal', {
    header: () => <Header text="Renewal" sortField="renewal" noSort />,
    cell: (info) => {
      const value = info.getValue();
      return <span>{!!value && getNumberWithOrdinal(value || 1)}</span>;
    },
  }),
  columnHelper.accessor('renewalTaskId', {
    header: () => <Header text="Actions" sortField="renewalTaskId" noSort />,
    cell: (info) => <PrescriptionsActionCell prescription={info.row.original} patientInfo={patientInfo} />,
    size: 200,
  }),
];
