import { useEffect, useMemo, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';
import upperFirst from 'lodash/upperFirst';

import Loader from 'components/common/Loader';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { Titration } from 'store/prescriptions/prescriptions.types';
import { useUpdatePrescriptionMutation } from 'store/prescriptions/prescriptionsSlice';

import { stringifyNumber } from './editTitration.settings';
import { EditTitrationsProps } from './editTitrations.types';
import TitrationItem from './TitrationItem';

const EditTitrations: React.FC<EditTitrationsProps> = ({ prescriptionId, titrations, patientName }) => {
  const dispatch = useAppDispatch();
  const [newTitrations, setNewTitrations] = useState<Titration[]>([]);
  const [updatePrescription, { isLoading }] = useUpdatePrescriptionMutation();

  const titrationsLength = newTitrations.length;
  const errors = useMemo(() => {
    return newTitrations.find((titration) => !Number(titration.qty) || titration.qty <= 0);
  }, [newTitrations]);

  const handleSaveTitrations = () => {
    const titrationsList = newTitrations.map((titration) => {
      const newTitration = { ...titration };
      if (newTitration.createdAt) delete newTitration.createdAt;
      if (newTitration.updatedAt) delete newTitration.updatedAt;
      if (newTitration.qty) newTitration.qty = Number(newTitration.qty);
      if (newTitration._id?.includes('customId') || ('_id' in newTitration && !newTitration._id)) {
        delete newTitration._id;
      }
      newTitration.units = 'ml';
      return newTitration;
    });

    updatePrescription({ prescriptionId, titrations: titrationsList })
      .unwrap()
      .then(() => dispatch(closeModal()));
  };

  const handleAddTitration = () => {
    setNewTitrations((prevData) => [
      ...prevData,
      {
        qty: 0.1,
        units: 'ml',
        adjustedAt: dayjs().local().utc().format(DateFormat.YYYY_MM_DDTHH_mm_ss_ss_Z),
        _id: `customId: ${nanoid()}`,
      },
    ]);
  };

  const onChangeTitration = (value: string, id: string, type: 'qty' | 'adjustedAt') => {
    setNewTitrations((prevData) =>
      prevData.map((titration) => {
        if (titration._id === id) {
          return { ...titration, [type]: value };
        } else return titration;
      }),
    );
  };

  useEffect(() => {
    setNewTitrations(
      titrations.map((titration) => (titration._id ? titration : { ...titration, _id: `customId: ${nanoid()}` })) || [],
    );
  }, [titrations]);

  return (
    <div className="p-6">
      {isLoading && <Loader isVisible />}

      <h2 className="mb-2 text-xl font-bold text-gray-700">Edit Titration</h2>
      <p className="mb-6 text-lg font-medium text-gray-700">{patientName}</p>

      <div className="flex flex-col gap-4">
        {newTitrations.map((titration, index) => {
          let label = 'Titrated dosage';
          if (titrationsLength > 1) {
            if (index === titrationsLength - 1) label = 'Current titrated dosage';
            else {
              const stringifyNumberValue = upperFirst(stringifyNumber(index + 1) || '');
              label = stringifyNumberValue ? `${stringifyNumberValue} titrated dosage` : 'Titrated dosage';
            }
          }
          return <TitrationItem key={titration._id} label={label} titration={titration} onChange={onChangeTitration} />;
        })}
      </div>
      <Common.Button
        color="white"
        preIcon="stethoscope"
        className="mt-4 flex w-full justify-center"
        size="sm"
        onClick={handleAddTitration}
      >
        Add titrated dosage
      </Common.Button>

      <div className="mt-6 grid w-full grid-cols-2 gap-x-2">
        <Common.Button
          color="white-alt"
          className="flex w-full justify-center"
          onClick={() => dispatch(closeModal())}
          size="sm"
          type="button"
        >
          Cancel
        </Common.Button>
        <Common.Button
          disabled={!!errors || isLoading}
          color="blue"
          className="flex w-full justify-center"
          size="sm"
          onClick={handleSaveTitrations}
        >
          Save
        </Common.Button>
      </div>
    </div>
  );
};

export default EditTitrations;
