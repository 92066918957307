import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { useFormContext } from 'react-hook-form';

import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import { PROSPECT_STATUS_OPTIONS } from 'constants/prospect';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { ProspectPatientProps } from 'store/patients/patients.types';
import { useUpdateProspectMutation } from 'store/patients/patientsSlice';
import { validation } from 'utils/helpers';

import { ProspectDetailsFormData } from '../prospectDetails.types';

const ProspectDetailsForm: React.FC<
  Partial<
    Pick<ProspectPatientProps, '_id' | 'prospectNotes' | 'prospectStatus' | 'isChargifyCreated' | 'isElationCreated'>
  >
> = ({ _id, prospectStatus, prospectNotes, isChargifyCreated, isElationCreated }) => {
  const dispatch = useAppDispatch();
  const { control, formState, handleSubmit, reset } = useFormContext<ProspectDetailsFormData>();
  const [updateProspect, { isLoading }] = useUpdateProspectMutation();

  const onFormSubmit = (formData: ProspectDetailsFormData) => {
    const body = {
      prospectNotes: formData?.prospectNotes,
      prospectStatus: formData?.prospectStatus?.value,
    };

    if (_id) {
      updateProspect({ id: _id, body })
        .unwrap()
        .then((data) => {
          notifySuccess(data.message);
          dispatch(closeModal());
        });
    }
  };

  useEffect(() => {
    if (prospectNotes && prospectStatus) {
      reset({
        prospectNotes: prospectNotes,
        prospectStatus: { value: prospectStatus, label: prospectStatus },
      });
    }
  }, [reset, prospectNotes, prospectStatus]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="mb-6 mt-4 flex flex-col gap-4">
        <div className="flex flex-row gap-1 text-gray-700">
          <h2 className="min-w-[116px] text-base font-semibold">Tags</h2>
          {isElationCreated || isChargifyCreated ? (
            <>
              {isElationCreated && <Common.ColorTag dataTestId="tag" color="blue" text="Elation created" />}
              {isChargifyCreated && <Common.ColorTag dataTestId="tag" color="blue" text="Chargify created" />}
            </>
          ) : (
            <span className="text-sm font-medium">None</span>
          )}
        </div>
        <ControlledTextArea
          dataTestId="description_field"
          control={control}
          name="prospectNotes"
          placeholder="Description"
          label="Notes"
          rows={3}
          errors={formState.errors.prospectNotes}
          labelDirection="row"
          disabled={isLoading}
        />
        <ControlledSelect
          dataTestId="status_select"
          control={control}
          labelDirection="row"
          options={PROSPECT_STATUS_OPTIONS}
          placeholder="Select status..."
          label="Status"
          name="prospectStatus"
          rules={validation('Status')}
          disabled={isLoading}
        />
      </div>
      <div className="flex w-full items-center justify-end gap-2">
        <Common.Button
          dataTestId="cancel_btn"
          type="button"
          color="white-alt"
          onClick={() => dispatch(closeModal())}
          size="sm"
        >
          Cancel
        </Common.Button>
        <Common.Button dataTestId="save_btn" type="submit" disabled={isLoading} color="blue" size="sm">
          Save
        </Common.Button>
      </div>
    </form>
  );
};

export default ProspectDetailsForm;
