import { ChangeEvent, useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';

import { DateFormat } from 'enums/dateFormats';

import CustomTimeInput from './CustomTimeInput/CustomTimeInput';
import { TimeInputFormValues } from './timeInput.types';

const TimeInput: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { setValue, getValues } = useFormContext<TimeInputFormValues>();
  const [time, setTime] = useState<{
    time: string;
    meridiem: string;
  }>({
    time: dayjs().set('hour', 6).set('minutes', 0).format(DateFormat.hh_mm),
    meridiem: 'am',
  });

  const wrapperClasses = classNames(
    'flex items-center justify-between self-end border border-gray-400 rounded-lg px-[7.5px] py-[10px] ',
    className ?? 'w-10/12',
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTime((prevState) => ({
      time: dayjs(event.target.value, DateFormat.hh_mm).format(DateFormat.hh_mm),
      meridiem: prevState.meridiem,
    }));
  };

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setTime((prevState) => ({
      time: prevState.time,
      meridiem: event.target.value,
    }));
  };

  useEffect(() => {
    const isValidTime = dayjs(time.time, DateFormat.hh_mm).isValid() && dayjs(time.meridiem, DateFormat.a).isValid();

    if (setValue && getValues && isValidTime) {
      setValue('dueDate', {
        date: getValues('dueDate.date'),
        time: dayjs(time.time + ' ' + time.meridiem, DateFormat.hh_mm_a).format(DateFormat.hh_mm_a),
      });
    }
  }, [time, setValue, getValues]);

  return (
    <div data-testid="time_scope" className={wrapperClasses}>
      <div className="relative flex items-center">
        <Common.Icon name="clock" className="mr-[6.5px] h-4 w-4 flex-none text-gray-500" />
        <div className="flex">
          <CustomTimeInput initialTime="06:00" onChange={handleChange} />
          <select
            data-testid="am_pm_select"
            value={time.meridiem}
            onChange={handleSelect}
            className="appearance-none border-none bg-none p-0 text-sm text-gray-500 focus:ring-0 focus:ring-offset-0"
          >
            <option key="am" value="am">
              am
            </option>
            <option key="pm" value="pm">
              pm
            </option>
          </select>
        </div>
      </div>

      <span className="text-sm text-gray-500">{dayjs().tz(dayjs.tz.guess()).format(DateFormat.z)}</span>
    </div>
  );
};

export default TimeInput;
