import { createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';

import { DateFormat } from 'enums/dateFormats';
import { Status } from 'enums/messages';
import { NewStaffTaskNoteBody } from 'models/tasks.types';
import type { RootState } from 'store';
import { StaffNoteProps, StaffNotesState } from 'store/staffNotes/staffNotes.types';
import { isImageByFilename } from 'utils/files';
import { getMessageType } from 'utils/helpers';

import { formatStaffNotes, getStaffNotesImages } from './staffNotes.settings';
import { apiSlice } from '../api/apiSlice';

const staffNotesAdapter = createEntityAdapter<StaffNoteProps, EntityId>({
  selectId: (staffNote) => staffNote._id,
  sortComparer: (a, b) => a.createdAt?.localeCompare(b.createdAt),
});

const initialState: StaffNotesState = {
  staffNotesLoadingStatus: Status.Idle,
  staffNotesList: staffNotesAdapter.getInitialState(),
  totalStaffNotesCount: 0,
  images: [],
};

export const staffNotesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      initStaffNotes: build.query<
        { staffNotes: StaffNoteProps[]; totalCount: number },
        { id: string; limit: number; pageNo: number }
      >({
        query: ({ id, limit, pageNo }) => ({
          url: `/patients/${id}/staff-notes`,
          params: {
            limit,
            pageNo,
            sortField: 'createdAt',
            sortOrder: 'DESC',
          },
        }),
        transformResponse: (response: { data: StaffNoteProps[]; info: { totalCount: number } }) => ({
          staffNotes: response.data,
          totalCount: response.info.totalCount,
        }),
        providesTags: (result) =>
          result
            ? ['StaffNote', ...result.staffNotes.map(({ _id }) => ({ type: 'StaffNote', id: _id }))]
            : ['StaffNote'],
      }),
      updateStaffNote: build.mutation({
        query: ({ messageId, message, type }) => {
          const messagesType = getMessageType(type);

          const params = {
            url: `/messages/${messageId}?type=${messagesType}`,
            method: 'PATCH',
            body: { message },
          };

          return params;
        },
        invalidatesTags: ['StaffNote'],
      }),
      deleteStaffNote: build.mutation({
        query: ({ messageId, type }) => {
          const messagesType = getMessageType(type);

          const params = {
            url: `/messages/${messageId}?type=${messagesType}`,
            method: 'DELETE',
          };

          return params;
        },
        invalidatesTags: ['StaffNote'],
      }),
      createStaffNote: build.mutation<
        StaffNoteProps,
        { patientId: string; body: { note: string; isUrgent: boolean; staffNoteFile?: File | null } }
      >({
        query: ({ patientId, body }) => {
          const formData = new FormData();
          formData.append('note', body.note);
          if (body.staffNoteFile) {
            formData.append('staffNoteFile', body.staffNoteFile);
          }

          return {
            url: `/patients/${patientId}/staff-notes`,
            method: 'POST',
            body: formData,
          };
        },
        transformResponse: (response: { data: StaffNoteProps }) => response.data,
        async onQueryStarted({ patientId }, { dispatch, queryFulfilled }) {
          const { data: staffNote } = await queryFulfilled;

          const patchResult = dispatch(
            staffNotesApiSlice.util.updateQueryData(
              'initStaffNotes',
              { id: patientId, limit: 10, pageNo: 0 },
              (draftNotes) => {
                draftNotes.staffNotes.push(staffNote);
              },
            ),
          );

          queryFulfilled.catch(patchResult.undo);
        },
        invalidatesTags: ['StaffNote'],
      }),
      createTaskStaffNote: build.mutation<StaffNoteProps, { taskId: string; body: NewStaffTaskNoteBody }>({
        query: ({ taskId, body }) => {
          const formData = new FormData();
          formData.append('note', body.note);
          formData.append('isUrgent', body.isUrgent.toString());

          if (body?.audience) formData.append('audience', body?.audience.join());

          if (body.staffNoteFile) {
            formData.append('staffNoteFile', body.staffNoteFile);
          }

          return {
            url: `/tasks/${taskId}/staff-notes`,
            method: 'POST',
            body: formData,
          };
        },
        transformResponse: (response: { data: StaffNoteProps }) => response.data,
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            // Note: we have slice below to avoid circular dependency
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            dispatch(addStaffNote(data));
          } catch (err) {
            console.error(err);
          }
        },
      }),
    };
  },
});

let lastRequestId: string;

const staffNotesSlice = createSlice({
  name: 'staffNotes',
  initialState,
  reducers: {
    addStaffNote: (state, action: PayloadAction<StaffNoteProps>) => {
      staffNotesAdapter.upsertOne(state.staffNotesList, action.payload);
    },
    clearStaffNotes: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(staffNotesApiSlice.endpoints.initStaffNotes.matchPending, (state, action) => {
        lastRequestId = action.meta.requestId;
        state.staffNotesLoadingStatus = Status.Pending;
      })
      .addMatcher(staffNotesApiSlice.endpoints.initStaffNotes.matchFulfilled, (state, action) => {
        state.staffNotesLoadingStatus = Status.Fulfilled;
        if (lastRequestId === action.meta.requestId) {
          staffNotesAdapter.upsertMany(state.staffNotesList, action.payload.staffNotes);
          state.totalStaffNotesCount = action.payload.totalCount;
          state.images = [...getStaffNotesImages(action.payload.staffNotes), ...state.images];
        } else {
          state = initialState;
        }
      })
      .addMatcher(staffNotesApiSlice.endpoints.initStaffNotes.matchRejected, (state) => {
        state.staffNotesLoadingStatus = Status.Rejected;
      })
      .addMatcher(staffNotesApiSlice.endpoints.createStaffNote.matchFulfilled, (state, { payload }) => {
        staffNotesAdapter.upsertOne(state.staffNotesList, payload);
        if (payload.fileName && payload.filePath && isImageByFilename(payload.fileName)) {
          state.images.push(payload.filePath);
        }
      })
      .addMatcher(staffNotesApiSlice.endpoints.createTaskStaffNote.matchFulfilled, (state, { payload }) => {
        if (payload.fileName && payload.filePath && isImageByFilename(payload.fileName)) {
          state.images.push(payload.filePath);
        }
      });
  },
});

export const staffNotesSelectors = staffNotesAdapter.getSelectors<RootState>(
  (state) => state.staffNotes.staffNotesList,
);

export const selectStaffNotes = (state: RootState) => state.staffNotes;

export const selectFormattedStaffNotes = createSelector(staffNotesSelectors.selectAll, (notes) => {
  const formattedNotes = formatStaffNotes(notes);
  return groupBy(formattedNotes, (note) => dayjs(note.date).format(DateFormat.MM_DD_YYYY));
});
export const { clearStaffNotes, addStaffNote } = staffNotesSlice.actions;

export const {
  useLazyInitStaffNotesQuery,
  useCreateStaffNoteMutation,
  useCreateTaskStaffNoteMutation,
  useDeleteStaffNoteMutation,
  useUpdateStaffNoteMutation,
} = staffNotesApiSlice;

export default staffNotesSlice.reducer;
