import { useEffect, useRef, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import upperFirst from 'lodash/upperFirst';
import { useForm } from 'react-hook-form';
import { useClickAway } from 'react-use';

import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import { PlanRecommendationStatus } from 'enums/planRecommendation';
import { TagCategories, TagTypes } from 'enums/tagTypes';
import { useAppSelector } from 'hooks/redux';
import {
  selectPatient,
  useEditPatientTagsMutation,
  useGetTagsListQuery,
  useGetTagsQuery,
} from 'store/patients/patientsSlice';
import { getGroupedTags } from 'utils/tags';

import { getBadgeClasses, mainCategoriesType, otherCategoriesType, sortedTags } from './tags.settings';
import { TagsProps } from './tags.types';

const selectPaymentCardState = createSelector([selectPatient], (patient) => ({
  isLoadingTagList: patient.isLoadingTagList,
}));

const Tag: React.FC<TagsProps> = ({
  patientId,
  size = 'sm',
  isPatientInactive,
  planRecommendationStatus,
  planRecommendationName,
  setIsPrevPlanWM,
}) => {
  const { control, getValues, setValue } = useForm({ reValidateMode: 'onChange' });
  const { data: tagList, isLoading: isLoadingTags } = useGetTagsListQuery({
    tagTypes: [TagTypes.Both, TagTypes.Manual],
  });
  const { isLoadingTagList } = useAppSelector(selectPaymentCardState);

  const { data: tags, refetch } = useGetTagsQuery({ patientId });
  const [editPatientTags, { isLoading }] = useEditPatientTagsMutation();

  const [isOpenTagMenu, setIsOpenTagMenu] = useState(false);
  const ref = useRef(null);
  const patientTags = tags?.data ?? [];
  const mainTags = patientTags.filter((tag) => mainCategoriesType.includes(tag.category)).sort(sortedTags);
  const otherTags = patientTags.filter((tag) => otherCategoriesType.includes(tag.category)).sort(sortedTags);

  const tagMenu = classNames(
    'absolute b-0 shadow-lg bg-white rounded-lg p-6 z-20 bg-white origin-top transition ease-in-out duration-300 transform w-[360px] ',
    isOpenTagMenu ? 'scale-y-100' : 'scale-y-0',
  );

  const tagsClassNames = classNames(
    'mt-2 flex overflow-x-scroll whitespace-nowrap text-xs scrollbar-hide cursor-pointer gap-1',
    {
      'max-w-4xl': size === 'lg',
      'max-w-xs': size === 'sm',
    },
  );

  const tagOptions = getGroupedTags(tagList?.data || []);
  const systemTypes = (patientTags || []).filter((tag) => tag.type === TagTypes.System);

  const onHandleTagMenu = () => setIsOpenTagMenu(!isOpenTagMenu);

  const onSave = () => {
    patientId &&
      editPatientTags({
        patientId,
        body: ((getValues('customTags') as { label: string; value: string }[]) || []).map((item) => item.value),
      })
        .unwrap()
        .then((result) => {
          notifySuccess(result.message);
          refetch();
          setIsOpenTagMenu(false);
        });
  };

  const getMainTagTitle = (category: TagCategories, name: string) => {
    const shouldShowPlanRecommendationName =
      planRecommendationStatus === PlanRecommendationStatus.ACCEPTED ||
      planRecommendationStatus === PlanRecommendationStatus.PENDING;

    if (category === TagCategories.SUB_PLAN && name === 'Maintenance' && shouldShowPlanRecommendationName) {
      const subPlanName = upperFirst(planRecommendationName?.toLowerCase().replace('low-dose', '').trim() || '');

      const maintenanceTitle =
        planRecommendationStatus === PlanRecommendationStatus.PENDING ? 'Maintenance • Recommended ' : 'Maintenance ';

      return `${maintenanceTitle} ${subPlanName && `• ${subPlanName}`} `;
    }

    return name;
  };

  useClickAway(ref, () => {
    isOpenTagMenu && setIsOpenTagMenu(false);
  });

  useEffect(() => {
    if (tags?.data) {
      setValue(
        'customTags',
        tags.data.filter((tag) => tag.type !== TagTypes.System).map((tag) => ({ value: tag._id, label: tag.name })),
      );
      setIsPrevPlanWM?.(tags.data.some((tag) => tag.name.toLowerCase() === 'Prev: Weight Management'.toLowerCase()));
    }
  }, [setIsPrevPlanWM, setValue, tags]);

  return (
    <div ref={ref}>
      <div className="relative">
        <div className="absolute left-0 top-0 h-4 w-1.5  bg-gradient-to-r from-gray-50" />
        {isLoadingTagList ? (
          <div className="mt-2 h-4 w-full  animate-pulse rounded-lg bg-slate-200" />
        ) : (
          <div className={tagsClassNames} onClick={onHandleTagMenu}>
            {isPatientInactive && <span className={getBadgeClasses({ isRed: true })}>Inactive</span>}
            {mainTags.map((tag) => (
              <span data-testid="main-tag" key={tag._id} className={getBadgeClasses({ tag })}>
                {getMainTagTitle(tag.category, tag.name)}
              </span>
            ))}
            {mainTags.length > 0 && otherTags.length > 0 && <span className="mx-1 h-[18px] min-w-[1px] bg-gray-400" />}
            {otherTags.map((tag) => (
              <span data-testid="other-tag" key={tag._id} className={getBadgeClasses({ tag })}>
                {tag.name}
              </span>
            ))}
            <span className={getBadgeClasses({ additionalClasses: 'mr-3' })}>
              <Common.Icon name="pencil" className="h-3 w-3" />
            </span>
          </div>
        )}
        <div className="absolute right-0 top-0 h-4 w-4  bg-gradient-to-l from-gray-50" />
      </div>
      <div data-testid="tags_popup" className={tagMenu}>
        <p className="mb-6 text-xl font-bold text-gray-700">Patient tags</p>
        <p className="mb-2 text-sm font-semibold text-gray-700">System tags</p>
        <div className="mb-4 flex flex-wrap gap-2 text-xs">
          {systemTypes.map((tag) => (
            <span key={tag._id} className={getBadgeClasses({ tag })}>
              {getMainTagTitle(tag.category, tag.name)}
            </span>
          ))}
        </div>

        {isLoadingTags ? (
          <div data-testid="loading_tags_skeleton" className="h-8 w-full animate-pulse rounded-md bg-slate-200" />
        ) : (
          <ControlledMultiSelect
            dataTestId="tags_select"
            control={control}
            label="Custom tags"
            name="customTags"
            placeholder="Select custom tags..."
            options={tagOptions}
            labelDirection="col"
          />
        )}

        <div className="mt-6 flex justify-between gap-2">
          <Common.Button
            dataTestId="cancel_btn"
            type="button"
            color="white-alt"
            className="grow justify-center"
            onClick={() => setIsOpenTagMenu(false)}
            size="sm"
          >
            Cancel
          </Common.Button>
          <Common.Button
            dataTestId="save_btn"
            className="grow justify-center"
            onClick={onSave}
            disabled={isLoading}
            type="submit"
            color="blue"
            size="sm"
          >
            Save
          </Common.Button>
        </div>
      </div>
    </div>
  );
};

export default Tag;
