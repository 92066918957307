const PickerSkeleton = () => {
  return (
    <div className="mt-8 flex animate-pulse flex-col gap-2 ">
      <div className="h-12 w-full rounded-md bg-slate-200" />
      <div className="h-12 w-full rounded-md bg-slate-200" />
      <div className="h-12 w-full rounded-md bg-slate-200" />
      <div className="h-12 w-full rounded-md bg-slate-200" />
    </div>
  );
};

export default PickerSkeleton;
