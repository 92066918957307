import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';
import {
  AppointmentTimeProps,
  AvailableDatesDataProps,
  CombinedTimeSlots,
} from 'store/appointments/appointments.types';

import { SlotData } from './dateAndTime.types';

export const buildTimeSlotList = (data: (AppointmentTimeProps | CombinedTimeSlots)[]): SlotData[] =>
  data.map((time) => ({
    displayName: (time as CombinedTimeSlots)?.displayName ?? '',
    doctorId: (time as CombinedTimeSlots)?.doctorId ?? '',
    label: `${dayjs(time.startTime).format(DateFormat.hh_mma_z)}`,
    value: time.startTime,
    valueEnd: time.endTime,
  }));

export const onFilterByAvailableDates = (data?: AvailableDatesDataProps[]): string[] =>
  data?.map((date) => date.date) || [];

export const onFilterByNotAvailableDates = (
  availableDates: AvailableDatesDataProps[],
  startDate: string,
  endDate: string,
): string[] => {
  if (Array.isArray(availableDates) && availableDates.length !== 0) {
    const unavailableDates = [];

    for (
      let currentDate = new Date(startDate);
      currentDate <= new Date(endDate);
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const formattedDate = dayjs(currentDate).format(DateFormat.YYYY_MM_DD);

      const isAvailable = availableDates.some((obj) => obj.date === formattedDate);

      if (!isAvailable) {
        unavailableDates.push(formattedDate);
      }
    }

    return unavailableDates;
  }
  return [];
};
