import React from 'react';

import { Common } from '@thecvlb/design-system';

import { FilePreviewProps } from './filePreview.types';

const FilePreview: React.FC<FilePreviewProps> = ({ previews, onClose }) => (
  <div className="flex">
    {previews.length > 0 &&
      previews.map((src, index) => (
        <React.Fragment key={index}>
          {typeof src === 'string' && (
            <div className="relative m-2 flex h-12 w-12 items-center justify-center overflow-hidden rounded-xl shadow">
              {src === 'document' ? (
                <Common.Icon name="articles" className="mx-auto  h-9 w-9" />
              ) : src === 'video' ? (
                <Common.Icon name="video" className="mx-auto h-9 w-9" />
              ) : (
                <img src={src} alt="preview" className="h-full w-full object-cover text-center text-sm" />
              )}
              <button
                type="button"
                onClick={() => onClose(index)}
                className="absolute right-1 top-1 box-border flex rounded-full border border-white bg-gray-700 p-0.5 text-white"
              >
                <Common.Icon name="close" className="h-2 w-2" />
              </button>
            </div>
          )}
        </React.Fragment>
      ))}
  </div>
);

export default FilePreview;
