import { Common } from '@thecvlb/design-system';
import { useDispatch } from 'react-redux';

import BillingConfigurationStandard from 'components/modals/BillingAndCostConfiguration/BillingConfigurationStandard';
import { openModal } from 'store/modal/modalSlice';

const EditCell: React.FC<{ id: string; status: string }> = ({ id, status }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex gap-1">
      <button
        data-testid="pencil_icon"
        onClick={() =>
          dispatch(
            openModal({
              size: 'lg',
              modalContent: <BillingConfigurationStandard id={id} status={status} />,
              hideClose: true,
            }),
          )
        }
      >
        <Common.Icon name="pencil" className="h-4 w-4 hover:text-primary-500" />
      </button>
    </div>
  );
};

export default EditCell;
