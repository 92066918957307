import { useEffect } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query/react';
import classNames from 'classnames';
import isString from 'lodash/isString';
import { useMatch } from 'react-router-dom';

import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import { PathName } from 'enums/pathName';
import useZoomCall from 'hooks/common/useZoomCall';
import { useGetPatientItems } from 'hooks/components/PatientInfo/useGetPatientItems';
import { useAppSelector } from 'hooks/redux';
import { useGetPatientQuery, useLazyGetCallTokenQuery } from 'store/patients/patientsSlice';
import { selectVideoCall } from 'store/videoCall/videoCallSlice';

import { PatientInfoProps } from './patientInfo.types';

const selectPatientInfoState = createSelector([selectVideoCall], (videoCall) => ({
  alreadyOnCall: videoCall.isOpen,
}));

const PatientInfo: React.FC<PatientInfoProps> = ({
  patientId,
  wrapperClassNames,
  labelClassNames,
  isTaskDetails,
  isSlidingPanel,
}) => {
  const { setToken, token } = usePhoneCall();
  const { alreadyOnCall } = useAppSelector(selectPatientInfoState);
  const { isConnectionError: zoomCallInProgress } = useZoomCall();

  const { patient } = useGetPatientQuery(patientId ? { id: patientId } : skipToken, {
    selectFromResult: ({ data }) => ({
      patient: data,
    }),
  });
  const [getCallToken] = useLazyGetCallTokenQuery();

  const isPatient = !!useMatch(`${PathName.Patient}/:id`);

  const wrapperClasses = classNames(wrapperClassNames || 'grid grid-cols-2 gap-2 gap-x-8');
  const labelClasses = classNames('text-base mr-1 min-w-fit flex-none text-gray', labelClassNames || 'w-24');
  const redDotClasses = classNames(
    'absolute -left-2  h-1 w-1 rounded-xl bg-red-500',
    isTaskDetails ? 'top-2' : 'top-4',
  );

  const { items } = useGetPatientItems({
    patientId,
    isSlidingPanel,
    isTaskDetails,
    phoneCallToken: token,
  });

  useEffect(() => {
    if (patient?._id && !alreadyOnCall && !zoomCallInProgress) {
      getCallToken({ id: patient._id }, true).then(({ data }) => data?.token && setToken(data.token));
    }
  }, [getCallToken, patient?._id, setToken, alreadyOnCall, zoomCallInProgress, token]);

  return (
    <div className={wrapperClasses}>
      {items?.map((el) => (
        <div data-testid="personal_info_data_field" key={el.label} className="relative flex items-baseline">
          {(isPatient || (!isPatient && el.label !== 'Status')) && (
            <>
              {el.showBadge && <span className={redDotClasses} />}
              <p className={labelClasses}>{el.label}</p>
              {isString(el.value) ? (
                <p
                  className={classNames('text-base text-gray-700', {
                    underline: el.isUnderline,
                  })}
                >
                  {el.value}
                </p>
              ) : (
                el.value
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default PatientInfo;
