import { useParams } from 'react-router-dom';

import HealthMetricsModal from 'components/modals/HealthMetrics';
import UpdateWeight from 'components/modals/HealthMetrics/Weight/Weight';
import WeightDetails from 'components/tasks/slidingPane/WeightDetails';
import { HealthMetricTypes } from 'enums/chart';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { useGetChartHealthMetricsQuery } from 'store/patients/patientsSlice';

import { WeightProps } from './weight.types';
import HealthMetric from '../../HealthMetric/HealthMetric';

const Weight: React.FC<WeightProps> = ({ weight, patient }) => {
  const dispatch = useAppDispatch();
  const { patientId = '' } = useParams<{ patientId: string }>();

  const { data: weightChartData } = useGetChartHealthMetricsQuery({
    params: { metricType: HealthMetricTypes.Weight },
    patientId,
  });

  const openCreateMetricModal = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: (
          <HealthMetricsModal type={HealthMetricTypes.Weight}>
            <UpdateWeight />
          </HealthMetricsModal>
        ),
      }),
    );
  };

  const weightSubtitle = weight ? `${weight} lbs` : '';

  return (
    <HealthMetric
      type={HealthMetricTypes.Weight}
      value={weight}
      metricSubtitle={weightSubtitle}
      openMetricModal={openCreateMetricModal}
    >
      <WeightDetails
        patientId={patientId}
        weightManagement={weightChartData?.data.weightManagement}
        className="mt-0"
        patientName={`${patient?.firstName} ${patient?.lastName}`}
      />
    </HealthMetric>
  );
};

export default Weight;
