import startCase from 'lodash/startCase';

import { PROBLEM_TYPE_FILTER } from 'components/modals/CustomerFeedbackFilters/customerFeedbackFiltersForm.settings';
import { URGENCY_TASK_FILTER } from 'components/modals/NotificationsFiltersForm/NotificationsFiltersForm.settings';
import {
  ISSUE_DESCRIPTION_FILTER,
  ISSUE_TYPE_FILTER,
  STATUS_FILTER,
} from 'components/modals/StaffFeedbackFiltersForm/staffFeedbackFiltersForm.settings';
import { ALERT_UNIT_OPTIONS } from 'constants/alerts';
import { MEMBERSHIP_PROSPECT } from 'constants/prospect';
import { FUTURE_TASKS_FILTERS, ONBOARDING_STATUSES_EXTENDED } from 'constants/taskFilter';
import { CATEGORY_OPTIONS_HISTORY } from 'constants/tasks';

interface FilterProps {
  label: string;
  value: string | string[] | { label: string; value: string };
}

const getOnboardingStatuses = (name: string) => {
  let option;
  ONBOARDING_STATUSES_EXTENDED.forEach((item) => {
    if (item.value === name) {
      option = item;
    } else if (!!item.options) {
      const groupedOption = item.options.find((groupOption) => groupOption.value === name);
      if (groupedOption) option = groupedOption;
    }
  });
  return option;
};

const getEventKey = (name: string) => {
  return CATEGORY_OPTIONS_HISTORY.find((item) => item.value === name);
};

export const mapFilterToFormData = (
  filter: FilterProps,
  tableType: string,
  options?: { label: string; value: string }[],
): [string, unknown] => {
  switch (filter.label) {
    case 'eventKey':
      if (typeof filter.value === 'string') {
        return [filter.label, { ...getEventKey(filter.value) }];
      } else if (Array.isArray(filter.value)) {
        return [filter.label, filter.value.map((name: string) => getEventKey(name))];
      }
      break;
    case 'onboardingStatuses':
      if (typeof filter.value === 'string') {
        return [filter.label, getOnboardingStatuses(filter.value)];
      } else if (Array.isArray(filter.value)) {
        return [filter.label, filter.value.map((name: string) => getOnboardingStatuses(name))];
      }
      break;
    case 'patientNames':
    case 'assignedToNames':
    case 'activeStates':
    case 'eventType':
      if (typeof filter.value === 'string') {
        return [
          filter.label,
          [
            {
              label: filter.value,
              value: filter.value,
            },
          ],
        ];
      } else if (Array.isArray(filter.value)) {
        return [
          filter.label,
          filter.value.map((name: string) => ({
            value: name,
            label: name,
          })),
        ];
      }
      break;
    case 'issueDescriptions':
      const issueDescriptionsOptions = (Array.isArray(filter.value) ? filter.value : [filter.value]).map(
        (issueDescription) => ISSUE_DESCRIPTION_FILTER.find((issue) => issue.value === issueDescription),
      );
      return [filter.label, issueDescriptionsOptions];
    case 'userType.name':
      return [
        'userType',
        {
          label: filter.value,
          value: filter.value,
        },
      ];
    case 'doctorName':
    case 'membership':
    case 'taskType':
    case 'criterionField':
    case 'alertUnit':
    case 'occurenceOrder':
    case 'triggerTime.occurenceOrder':
    case 'audience':
    case 'pharmacyType':
    case 'orderStatus':
    case 'triggerUnit':
    case 'length':
    case 'triggerType':
    case 'assignedTo':
    case 'payType':
    case 'staff':
    case 'appointmentType':
    case 'state':
    case 'prospectStatus':
    case 'rating':
    case 'providerName':
      return [
        filter.label,
        {
          label: filter.value,
          value: filter.value,
        },
      ];
    case 'futureTasksRange':
      return [filter.label, FUTURE_TASKS_FILTERS.find((item) => item.value === filter.value)];
    case 'planId':
      return [filter.label, MEMBERSHIP_PROSPECT.find((item) => item.value.toString() === filter.value)];
    case 'urgencyTask':
      return [filter.label, URGENCY_TASK_FILTER.find((item) => item.value.toString() === filter.value)];
    case 'tags':
    case 'patientTags':
      if (filter.value) {
        const result = options?.filter((item) => {
          if (Array.isArray(filter.value)) {
            return filter.value.includes(item.value);
          } else if (typeof filter.value === 'string') {
            return filter.value === item.value;
          }
        });
        return [filter.label, result?.map((item) => ({ label: item.label, value: item.value }))];
      }
      break;
    case 'author.name':
      return ['author', filter.value];
    case 'audience.shortCode':
      return [
        'audience',
        {
          label: filter.value,
          value: filter.value,
        },
      ];
    case 'status':
      if (['staffFeedback'].includes(tableType)) {
        return [filter.label, STATUS_FILTER.find((statusFilter) => statusFilter.value === filter.value)];
      }
      if (
        [
          'patients',
          'staffs',
          'roles',
          'aging',
          'appointmentTypes',
          'articles',
          'frontdeskRequestTypes',
          'patientMessageTemplates',
          'internalNoteTemplates',
          'internalAutomations',
          'patientAutomations',
          'billingConfiguration',
          'staffCosts',
          'reviews',
        ].includes(tableType)
      ) {
        return [
          filter.label,
          {
            label: startCase(typeof filter.value === 'string' ? filter.value : ''),
            value: filter.value,
          },
        ];
      }
      break;
    case 'problemType':
      if (['customerFeedback'].includes(tableType)) {
        return [filter.label, PROBLEM_TYPE_FILTER.find((problemType) => problemType.value === filter.value)];
      }
      break;
    case 'issueType':
      if (['staffFeedback'].includes(tableType)) {
        return [filter.label, ISSUE_TYPE_FILTER.find((issueType) => issueType.value === filter.value)];
      }
      break;
    case 'patientName':
      if (['reviews', 'customerFeedback', 'staffFeedback'].includes(tableType)) {
        return [
          filter.label,
          {
            label: filter.value,
            value: filter.value,
          },
        ];
      }
      break;
    case 'category':
      if (['appointmentTypes', 'articles', 'billingConfiguration', 'activityFeed'].includes(tableType)) {
        return [
          filter.label,
          {
            label: filter.value,
            value: filter.value,
          },
        ];
      }
      break;
    case 'triggerTime.timeInHours':
      return ['timeInHours', filter.value];
    case 'triggerTime.occurenceOrder':
      return [
        'triggerUnit',
        {
          label: filter.value,
          value: filter.value,
        },
      ];

    default:
      return [filter.label, filter.value];
  }
  return [filter.label, filter.value];
};

export const getAlertUnitValue = (alertUnit: FilterProps, occurenceOrder: FilterProps) => {
  const unit =
    alertUnit.value === 'hrs' || alertUnit.value === 'hours'
      ? 'Hours'
      : alertUnit.value === 'min' || alertUnit.value === 'minutes'
        ? 'Mins'
        : '';
  const label = `${unit} (${occurenceOrder.value})`;
  const value = ALERT_UNIT_OPTIONS.find((item) => item.label === label);
  return value ?? alertUnit;
};
