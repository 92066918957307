import { ChangeEvent } from 'react';

import { Common } from '@thecvlb/design-system';
import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { FieldValues, useController } from 'react-hook-form';

import { ControlledComboboxProps } from './controlledCombobox.types';
const ControlledCombobox = <TFieldValues extends FieldValues>({
  control,
  name,
  options,
  helperText,
  size = 'md',
  labelDirection = 'row',
  placeholder,
  label,
  rules,
  errors,
  onChange,
  preIcon,
  postIcon,
  defaultValue,
  inputValue = '',
  onInputChange,
  hideComboboxButton,
  isLoading,
  wrapperClassName,
  labelClassName,
}: ControlledComboboxProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const removePhoneNumber = (value: string) => {
    const result = value.includes('•') ? value.split('•')[0].trim() : value;
    return result;
  };

  const handleChange = (value: Option) => {
    const newValue = { ...value, label: removePhoneNumber(value.label) };

    onChange && onChange(newValue);
    field.onChange(newValue);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => onInputChange && onInputChange(event);

  return (
    <Common.AutocompleteInputSelect
      name={name}
      size={size}
      preIcon={preIcon}
      postIcon={postIcon}
      labelDirection={labelDirection}
      errors={errors || error}
      helperText={helperText || error?.message}
      selected={field.value}
      options={options}
      onChange={handleChange}
      placeholder={placeholder}
      label={label}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      hideComboboxButton={hideComboboxButton}
      isLoading={isLoading}
      customWrapperClassName={wrapperClassName}
      customLabelClassName={labelClassName}
    />
  );
};

export default ControlledCombobox;
