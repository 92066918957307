import { Common } from '@thecvlb/design-system/lib/src';

import IdentityModal from 'components/modals/IdentityModal';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';

import { getIsIdentityVerified, getIsNotSubmitted } from './identityField.settings';
import { IdentityFieldProps } from './identityField.types';

const IdentityField: React.FC<IdentityFieldProps> = ({ hasEditIdentityPermission, identity, patientId }) => {
  const dispatch = useAppDispatch();
  const isVerified = getIsIdentityVerified(identity);
  const isNotSubmitted = getIsNotSubmitted(identity);

  const text = isNotSubmitted ? 'Not submitted' : isVerified ? 'Verified' : 'Unverified';
  const color = isNotSubmitted ? 'gray' : isVerified ? 'green' : 'red';
  const icon = isVerified ? 'check' : 'close';

  const openIdentityModal = () => {
    dispatch(
      openModal({
        modalContent: <IdentityModal identity={identity} patientId={patientId} />,
        size: 'sm',
      }),
    );
  };

  return (
    <span className="flex w-full items-center justify-between text-base">
      <Common.ColorTag color={color} text={text} {...(!isNotSubmitted && { icon })} />
      {hasEditIdentityPermission && (
        <Common.Button color="white-alt" size="sm" onClick={openIdentityModal}>
          Undo
        </Common.Button>
      )}
    </span>
  );
};

export default IdentityField;
