import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

export const useDelayedScroll = (cb: () => void, time = 0) => {
  const delayedExec = (after: number | undefined, fn: { (): void; (): void }) => {
    let timer: NodeJS.Timeout;
    return () => {
      timer && clearTimeout(timer);
      timer = setTimeout(fn, after);
    };
  };
  return delayedExec(time, () => {
    cb();
  });
};

export const getDateValue = (date: string) => {
  if (date) {
    return `${
      dayjs(date.toString()).isToday()
        ? 'Today, '
        : dayjs(date.toString()).isTomorrow()
          ? 'Tomorrow, '
          : `${dayjs(date.toString()).format(DateFormat.dddd)}, `
    } ${dayjs(date.toString()).format(DateFormat.MMMM_D)}: `;
  }

  return '';
};
