import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { getPrescriptionStatusText } from './resultStatusPoint.settings';
import { ResultStatusPointProps } from './resultStatusPoint.types';

const LineIcon = () => <div className="h-px w-2.5 rounded-lg bg-white" />;

const ResultStatusPoint: React.FC<ResultStatusPointProps> = ({ type, isSuccess, dataTestId }) => {
  const iconWrapperClasses = classNames(
    'flex-none flex items-center justify-center h-4 w-4 rounded-full p-0.5 text-white',
    {
      'bg-red-500': !isSuccess,
      'bg-gray-500': isSuccess && type === 'prescription_not_signed',
      'bg-green-500': isSuccess && type === 'prescription_status',
    },
  );

  const statusSectionClasses = classNames('flex w-3/4 items-center gap-3 pl-4', {
    'text-red': !isSuccess,
    'text-gray-500': isSuccess && type === 'prescription_not_signed',
  });

  const text = getPrescriptionStatusText(type, isSuccess);

  return type === 'prescription_not_signed' && !isSuccess ? null : (
    <span data-testid={dataTestId} className={statusSectionClasses}>
      <span className={iconWrapperClasses}>
        {isSuccess ? <Common.Icon name={'check'} className="h-3 w-3" /> : <LineIcon />}
      </span>
      {text}
    </span>
  );
};

export default ResultStatusPoint;
