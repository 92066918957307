import { Common } from '@thecvlb/design-system';
import { useDispatch } from 'react-redux';

import { closeModal } from 'store/modal/modalSlice';
import { useDeleteDocumentMutation } from 'store/patients/patientsSlice';

const DeleteDocument: React.FC<{ title: string; documentId: string; patientId: string }> = ({
  title,
  documentId,
  patientId,
}) => {
  const dispatch = useDispatch();
  const [deleteDocument, { isLoading }] = useDeleteDocumentMutation();

  const handleDelete = () => {
    deleteDocument({ documentId, patientId })
      .unwrap()
      .then(() => dispatch(closeModal()))
      .catch((error) => console.error(error));
  };

  return (
    <>
      <div className="p-4">
        <h3 className="mb-2 text-xl font-bold">Delete file?</h3>
        <p className="mb-6 text-base font-medium">
          “<span className="break-all font-bold">{title}</span>” will be deleted. This action cannot be undone.
        </p>
        <div className="flex flex-col-reverse gap-3 md:flex-row">
          <Common.Button className="grow" fullWidthOnMobile onClick={() => dispatch(closeModal())} color="white-alt">
            Cancel
          </Common.Button>
          <Common.Button className="grow" fullWidthOnMobile onClick={handleDelete} color="red" disabled={isLoading}>
            Delete
          </Common.Button>
        </div>
      </div>
    </>
  );
};

export default DeleteDocument;
