import { useCallback, useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import queryString from 'query-string';
import { Control, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import DateRangePickerInput from 'components/common/DateRangePickerInput/DateRangePickerInput';
import SearchCombobox from 'components/common/form/SearchCombobox';
import FilterButtons from 'components/filters/FilterButtons';
import TaskCategoriesFilter from 'components/filters/TaskCategoriesFilter';
import AutocompleteMultiSelect from 'components/forms/controlled/AutocompleteMultiSelect';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import Footer from 'components/modals/Footer';
import { Options } from 'components/patient/History/history.types';
import { FUTURE_TASKS_FILTERS, ONBOARDING_STATUSES_EXTENDED } from 'constants/taskFilter';
import { TASK_STATUSES } from 'constants/tasks';
import { TagTypes } from 'enums/tagTypes';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLazyGetUsersQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { useGetTagsListQuery } from 'store/patients/patientsSlice';
import { useUpdateTaskFiltersDetailsMutation } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';
import { AT_LEAST_ONE_SPEC_CHAR_REGEXP } from 'utils/regExp';
import { getGroupedTags } from 'utils/tags';

import { defaultValues } from './TasksFilterForm.settings';
import { TasksFiltersFormProps } from './tasksFiltersForm.types';

const selectTasksFiltersFormState = createSelector(selectUser, (user) => ({
  timezone: user.timezone,
}));

const TasksFiltersForm: React.FC<{
  isSinglePatient?: boolean;
}> = ({ isSinglePatient = false }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const { timezone } = useAppSelector(selectTasksFiltersFormState);

  // @Mike TODO: replace useForm with useFormContext
  const { handleSubmit, control, reset, watch, setValue, getValues } = useForm<TasksFiltersFormProps>();

  const { data: patientTags } = useGetTagsListQuery({ tagTypes: [TagTypes.Both, TagTypes.Manual, TagTypes.System] });
  const [getPatientList, { data: patientList }] = useLazyGetUsersQuery();
  const [getStaffList, { data: staffList }] = useLazyGetUsersQuery();

  const [updateFilters] = useUpdateTaskFiltersDetailsMutation();

  const tagsOptions = useMemo(
    () => patientTags?.data?.map((tag) => ({ label: tag.name, value: tag._id })),
    [patientTags?.data],
  );
  const patientTagsOptions = useMemo(() => getGroupedTags(patientTags?.data || []), [patientTags?.data]);

  const [formData, setFormData] = useTableFiltersData('tasks', defaultValues, reset, tagsOptions);

  const getValue = (data: Options | Options[]) => {
    if (Array.isArray(data)) {
      return data?.length && data?.map((item) => item?.value);
    }
    return data?.value;
  };

  const getListPatientsName = () => {
    const listPatientsName = patientList?.length
      ? patientList.map((item) => {
          return { label: item?.name as string, value: item?.name as string };
        })
      : [];
    return Array.from(new Set(listPatientsName.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));
  };

  const getListAssignedToName = useCallback(() => {
    const unassignedItem = { label: 'Unassigned', value: 'Unassigned' };

    const uniquePatients = new Set([
      JSON.stringify(unassignedItem),
      ...(staffList?.map((item) => JSON.stringify({ label: item?.name as string, value: item?.name as string })) ?? []),
    ]);

    return Array.from(uniquePatients, (item) => JSON.parse(item));
  }, [staffList]);

  const getAssignedOptionsList = (value?: string) =>
    value && getStaffList({ userType: 'Staff', limit: 20, pageNo: 0, searchKey: value.trim() ?? '' });

  const handleChangeOnboardingStatuses = useCallback(
    (options: unknown) => {
      const newValues = (options as { label: string; value: string; groupLabel: string }[]).map((option) => ({
        label: option?.groupLabel ? `${option.groupLabel}: ${option.label}` : option.label,
        value: option.value,
      }));

      setValue('onboardingStatuses', newValues);
    },
    [setValue],
  );

  const onSubmit = (data: TasksFiltersFormProps) => {
    const parsedUrl = queryString.parse(location.search);
    setFormData({ ...formData, ...data });
    const selectedCategory: string[] = data?.category
      ? Array.isArray(data.category)
        ? data.category
        : [data.category]
      : [];

    /**
     * We get the “TaskTags” filter from categories, because according to the UI, “TaskTags” is part of the “Category” filter
     * But the backend should receive them as different types of filters
     * For this, we separate lists with 'Categories' and 'TaskTags'
     */

    const category = selectedCategory.filter((item) => item !== 'nutrition' && item !== 'weight-management');
    const taskTags = selectedCategory.filter((item) => item === 'nutrition' || item === 'weight-management');

    /**
     * If we chose “weight-management” or “nutrition” from the “TaskTags” filter, we must pass the “Appointment” category
     */
    const shouldIncludeAppointmentCategory = taskTags.length > 0 && !category.includes('Appointment');

    const futureTasksRange = !!data.futureTasksRange?.value ? `${data.futureTasksRange?.value}` : undefined;

    const filters = {
      ...parsedUrl,
      pageNo: '0',
      dueDate: data?.dueDate,
      startDueDate: data?.startDueDate,
      endDueDate: data?.endDueDate,
      startCreateDate: data?.startCreateDate,
      endCreateDate: data?.endCreateDate,
      localTz: timezone,
      assignedToNames: getValue(data?.assignedToNames as []),
      doctorName: data?.doctorName?.value?.replace(AT_LEAST_ONE_SPEC_CHAR_REGEXP, '').trim(),
      ...(data?.status === 'All' ? { status: '' } : { status: data?.status }),
      patientNames: getValue(data?.patientNames as []),
      futureTasksRange: futureTasksRange,
      onboardingStatuses:
        data?.onboardingStatuses &&
        (Array.isArray(data?.onboardingStatuses) ? data?.onboardingStatuses : [data?.onboardingStatuses])?.map(
          (active) => active.value as string,
        ),
      category: shouldIncludeAppointmentCategory ? [...category, 'Appointment'] : category,
      taskTags: taskTags,
      patientTags: data?.patientTags && _map(data.patientTags, 'value'),
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    !isSinglePatient && updateFilters({ body: { futureTasksRange } });
    if (!isEqual(appliedFilterValues, parsedUrl)) {
      // @Mike TODO: use setSearchParams instead of navigate
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <form className="w-[410px]" onSubmit={handleSubmit(onSubmit)}>
      <div data-testid="category_filter_options_block" className="flex w-[390px] flex-wrap py-1">
        <TaskCategoriesFilter name="category" setValue={setValue} />
      </div>
      <div data-testid="status_filter_options_block" className="mb-3 flex justify-around border-y border-gray/20 py-4">
        {/* @Mike TODO: replace FilterButtons component by new one */}
        <FilterButtons
          defaultSelected={formData?.status}
          name="status"
          control={control as unknown as Control}
          data={TASK_STATUSES}
        />
      </div>
      <div className="flex flex-col gap-4">
        <ControlledMultiSelect
          control={control}
          labelDirection="row"
          options={ONBOARDING_STATUSES_EXTENDED}
          placeholder="Select onboarding"
          label="Onboarding"
          name="onboardingStatuses"
          defaultInputValue={formData?.onboardingStatuses}
          onChange={handleChangeOnboardingStatuses}
          isGrouped
        />
        <AutocompleteMultiSelect
          isDisabled={isSinglePatient}
          control={control}
          label="Patient"
          name="patientNames"
          placeholder="Select patient"
          labelDirection="row"
          value={formData?.patientNames}
          options={getListPatientsName()}
          getOptionsFunc={(value) => {
            return (
              value && getPatientList({ userType: 'Patient', limit: 20, pageNo: 0, searchKey: value.trim() ?? '' })
            );
          }}
        />

        <AutocompleteMultiSelect
          control={control}
          label="Assigned to"
          name="assignedToNames"
          placeholder="Assign"
          labelDirection="row"
          value={formData?.assignedToNames}
          options={getListAssignedToName()}
          getOptionsFunc={getAssignedOptionsList}
        />

        <SearchCombobox
          size="sm"
          control={control}
          setValue={setValue}
          name="doctorName"
          label="Physician"
          userType="Physician"
          placeholder="Physician"
          minLengthInput={1}
          labelDirection="row"
          defaultValue={formData?.doctorName}
        />
        <DateRangePickerInput
          labelDirection="row"
          label="Due date"
          placeholder={'Select a date range'}
          fromValue={getValues('startDueDate') ? getValues('startDueDate') : ''}
          setFromValue={(value) => setValue('startDueDate', value?.toString())}
          toValue={getValues('endDueDate') ? getValues('endDueDate') : ''}
          setToValue={(value) => setValue('endDueDate', value?.toString())}
          startAllowedDate={dayjs().subtract(100, 'year').toDate()}
          size="sm"
          wrapperClasses="w-full relative"
        />
        <DateRangePickerInput
          labelDirection="row"
          label="Created date"
          placeholder={'Select a date range'}
          fromValue={getValues('startCreateDate') ? getValues('startCreateDate') : ''}
          setFromValue={(value) => setValue('startCreateDate', value?.toString())}
          toValue={getValues('endCreateDate') ? getValues('endCreateDate') : ''}
          setToValue={(value) => setValue('endCreateDate', value?.toString())}
          startAllowedDate={dayjs().subtract(100, 'year').toDate()}
          lastAllowedDate={new Date()}
          size="sm"
          wrapperClasses="w-full relative"
        />
        <ControlledMultiSelect
          control={control}
          labelDirection="row"
          options={patientTagsOptions}
          placeholder="Select tags"
          label="Tags"
          name="patientTags"
          defaultInputValue={formData?.patientTags}
          isGrouped
        />
        <ControlledSelect
          control={control}
          labelDirection="row"
          options={FUTURE_TASKS_FILTERS}
          placeholder="Select future tasks"
          label="Future tasks"
          name="futureTasksRange"
          defaultValue={formData?.futureTasksRange}
        />
      </div>
      <Footer
        appliedFiltersTagsOptions={tagsOptions}
        onClick={() => handleReset(dispatch, reset, defaultValues, navigate)}
      />
    </form>
  );
};

export default TasksFiltersForm;
