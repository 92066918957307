import { useRef } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

import { handleProcessFile } from './fileUploader.settings';
import { FileUploaderProps } from './fileUploader.types';

const FileUploader: React.FC<FileUploaderProps> = ({
  accept = 'image/png, image/jpeg, image/jpg, .heic',
  label,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBrowseFiles = () => {
    inputRef.current?.click();
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e?.target.files?.[0];
    if (!newFile) return;

    handleProcessFile(newFile, onChange);
    e.target.value = '';
  };
  return (
    <div
      className="flex h-min w-32 cursor-pointer flex-col items-center gap-0.5 rounded-xl border border-dashed border-gray-400 px-4 py-6 text-center text-gray"
      onClick={handleBrowseFiles}
    >
      <input
        type="file"
        className="hidden"
        accept={accept}
        ref={inputRef}
        onChange={handleChangeFile}
        data-testid="file-uploader"
      />
      <Common.Icon name="camera" className="h-5 w-5 text-gray-500" />
      <span className="text-sm font-semibold text-gray-500">{label}</span>
    </div>
  );
};

export default FileUploader;
