import classNames from 'classnames';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import { DateFormat } from 'enums/dateFormats';

import { PharmacyInfoProps } from './pharmacyInfo.types';

const OrderInfo: React.FC<PharmacyInfoProps> = ({ pharmacy, wrapperClassNames, labelClassNames }) => {
  const wrapperClasses = classNames(wrapperClassNames || 'grid grid-cols-2 gap-x-6 gap-y-2');
  const labelClasses = classNames('text-base mr-5 min-w-fit flex-none text-gray', labelClassNames || 'w-28');

  const prettyLabel = (objectValue: string) => {
    return startCase(objectValue.replace(/([A-Z])/g, ' $1').trim());
  };
  const formatDate = (objectValue: string) => {
    return dayjs(objectValue).format(DateFormat.MMM_D_YYYY_h_mm_a_z);
  };
  const isNotDate = (objectValue: string) => {
    return isNaN(new Date(objectValue).getTime());
  };

  const formatValue = (objectValue: unknown) => {
    if (typeof objectValue === 'string') {
      const formattedValue = isNotDate(objectValue) ? objectValue : formatDate(objectValue);
      return formattedValue;
    } else if (typeof objectValue === 'number' || typeof objectValue === 'boolean') {
      return objectValue;
    }
  };

  return (
    <div>
      <h4 className="mb-2 text-base font-bold text-gray-700">Pharmacy info</h4>
      <div className={wrapperClasses}>
        {Object.entries(pharmacy).map(([label, value]) => (
          <div key={label} className="flex">
            <p className={labelClasses}>{prettyLabel(label)}</p>
            <p className={classNames('text-base text-gray-700')}>{formatValue(value)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderInfo;
