import { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import CurrentRoleSelect from 'components/modals/components/EditProfileForm/components/CurrentRoleSelect';
import PaySection from 'components/modals/components/EditProfileForm/components/PaySection';
import { getBulkEditUpdateData } from 'components/modals/components/EditProfileForm/editProfileForm.settings';
import { ProfileFormStateProps } from 'components/modals/components/EditProfileForm/editProfileForm.types';
import PopupFooter from 'components/modals/components/PopupFooter';
import { useAppSelector } from 'hooks/redux';
import { Option } from 'models/form.types';
import { resetBulkEdits, selectBulkEdit } from 'store/bulkEdit/bulkEditSlice';
import { useGetLicensedInQuery, useGetSpecialtyAreasQuery } from 'store/lookup/lookupSlice';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { useGetRolesQuery } from 'store/roles/rolesSlice';
import { useUpdateStaffMembersMutation } from 'store/staffs/staffsSlice';
import { validation } from 'utils/helpers';
import { EDIT_POPUP_PERMISSION, hasPermission } from 'utils/popupsContentPermission';

import UpdateFailed from '../UpdateFailed';

const StaffsBulkEdit = () => {
  const { bulkEdits } = useAppSelector(selectBulkEdit);
  const dispatch = useDispatch();
  const [updateStaffMembers, { isLoading }] = useUpdateStaffMembersMutation({ fixedCacheKey: 'staffBulkEdit' });
  const { data: specialtyAreas } = useGetSpecialtyAreasQuery();
  const { data: licensedInData } = useGetLicensedInQuery();
  const { data: rolesData, isSuccess: isSuccessRoles } = useGetRolesQuery({ params: { limit: 50, status: 'active' } });
  const [specialtyAreasOptions, setSpecialtyAreasOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (specialtyAreas) {
      const areaOptions = specialtyAreas.map((area) => ({ label: area.name, value: area._id }));
      setSpecialtyAreasOptions(areaOptions);
    }
  }, [specialtyAreas]);

  const methods = useForm<ProfileFormStateProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const roleOptions = useMemo(
    () =>
      rolesData?.roles?.length && isSuccessRoles
        ? rolesData?.roles?.map((role) => ({ label: role.name, value: role._id }))
        : [],
    [isSuccessRoles, rolesData?.roles],
  );
  const formRoleId = methods.watch('role')?.value;

  const staffRoleShortCode = useMemo(() => {
    const formRole = rolesData?.roles?.find((role) => role._id === formRoleId);
    return formRole?.userType?.shortCode ?? '';
  }, [formRoleId, rolesData?.roles]);

  const handleSaveStaffs = async (formData: ProfileFormStateProps) => {
    const updateData = getBulkEditUpdateData(formData);

    if (formData) {
      const body = {
        staffIds: bulkEdits,
        updateData,
      };
      updateStaffMembers(body)
        .unwrap()
        .then((data) => {
          if (data.data?.failed.length) {
            dispatch(
              openModal({
                size: 'sm',
                hideClose: true,
                modalContent: <UpdateFailed requestBody={body} />,
              }),
            );
          } else {
            notifySuccess(data.message);
            dispatch(resetBulkEdits());
            dispatch(closeModal());
          }
        });
    }
  };

  const itemsLabel = `${bulkEdits.length} ${bulkEdits.length === 1 ? 'item' : 'items'}`;

  return (
    <>
      <Loader isVisible={isLoading} />
      <div>
        <p className="mb-6 text-xl font-bold">{`${itemsLabel} selected`}</p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSaveStaffs)} className="mt-5 grid grid-cols-2 gap-x-10 gap-y-3">
            <div className="col-span-2 h-px w-full bg-gray-200" />
            <CurrentRoleSelect isOptional roleOptions={roleOptions} />
            {staffRoleShortCode &&
              hasPermission(EDIT_POPUP_PERMISSION.biographyAndEducationSection, staffRoleShortCode) && (
                <>
                  <div className="col-span-2 h-max">
                    <ControlledMultiSelect
                      control={methods.control}
                      label="Licensed in"
                      name="licensedIn"
                      placeholder="Select display licensed in..."
                      options={licensedInData}
                      rules={validation('Licensed in', null, null, true)}
                      labelDirection="row"
                    />
                  </div>
                  <div className="col-span-2 h-max">
                    <ControlledMultiSelect
                      control={methods.control}
                      label="Specialty areas"
                      name="specialtyAreas"
                      placeholder="Select display specialty areas..."
                      options={specialtyAreasOptions}
                      rules={validation('Specialty areas', null, null, true)}
                      labelDirection="row"
                    />
                  </div>
                  <div className="col-span-2 h-px w-full bg-gray-200" />
                </>
              )}
            <PaySection isOptional />
            <div className="col-span-2 h-px w-full bg-gray-200" />
            <div className="col-span-2">
              <PopupFooter hiddenDeleteButton disabled={isLoading} />
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default StaffsBulkEdit;
