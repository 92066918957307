import dayjs from 'dayjs';

import { notifyError } from 'components/common/Toast/Toast';
import { MembershipPlanProps, PricePointProps } from 'components/crossSell/PlanType/planType.types';
import { SIGNUP_COUPON_CODES } from 'constants/crossSell';
import { DateFormat } from 'enums/dateFormats';
import { MessageType } from 'enums/messages';
import { SignupCouponCodes } from 'store/crossSell/crossSell.types';

export const addDashesToPhoneNumber = (phone: string) => {
  return phone ? `${phone.slice(1, 2)}-${phone.slice(2, 5)}-${phone.slice(5, 8)}-${phone.slice(8)}` : '';
};

export const getTaskStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'urgent':
      return 'text-red';
    case 'new':
      return 'text-secondary';
    case 'active':
      return 'text-yellow';
    case 'sent':
      return 'text-primary';
  }
};

export const resetZIndex = () => {
  const collection = document.getElementsByClassName('fc-timegrid-event-harness fc-timegrid-event-harness-inset');
  return new Promise((res) =>
    res(
      [...collection].forEach((node) => {
        (node as HTMLDivElement).style.zIndex = '1';
      }),
    ),
  );
};

export const resetZIndexForColumns = () => {
  const collection = document.getElementsByClassName('fc-timegrid-col-frame');
  return new Promise((res) =>
    res(
      [...collection].forEach((node) => {
        if (!node.querySelector('.fc-timegrid-now-indicator-arrow')) {
          (node as HTMLDivElement).style.zIndex = '1';
        }
      }),
    ),
  );
};

export const getDefaultPricePoint = (pricePoints: PricePointProps[]): PricePointProps | undefined =>
  pricePoints?.find((item) => item.isDefault);

export const getPaymentAmount = (promoCode: SignupCouponCodes | '', plan?: MembershipPlanProps | null) => {
  if (!plan) {
    return {
      discountPercentage: '',
      discountValue: '',
      amountDue: '',
    };
  }
  const discountPercentage = SIGNUP_COUPON_CODES[promoCode] || '0';
  const pricePoint = getDefaultPricePoint(plan.pricePoints);
  const initialCost = Number(pricePoint?.totalCost) ?? 49;
  const discountValue = (initialCost * parseFloat(discountPercentage)) / 100;
  const amountDue = discountPercentage !== '0' ? (initialCost - discountValue).toFixed(2) : String(initialCost);
  return {
    discountPercentage,
    discountValue: discountValue.toFixed(2),
    amountDue,
  };
};

export const validation = (
  fieldName: string,
  regexRule?: RegExp | null,
  errorMessage?: string | null,
  isOptionalField?: boolean,
) => {
  return {
    required: {
      value: !isOptionalField,
      message: `${fieldName} is required`,
    },
    ...(regexRule && {
      pattern: {
        value: regexRule,
        message: errorMessage || 'Please enter a valid data',
      },
    }),
  };
};

export const getISOStringIncludingTimezone = (
  date: string | number | Date | dayjs.Dayjs,
  timeZone: string,
  dateFormat?: string,
) => {
  return dayjs(date, dateFormat).tz(timeZone).format(DateFormat.YYYY_MM_DDTHH_mm_ss_ssZ);
};

const getGraphicsCardInfo = () => {
  const canvasElement = document.createElement('canvas');
  const gl = canvasElement.getContext('webgl2');
  canvasElement?.setAttribute('id', 'value');
  if (!gl) {
    return {
      error: 'no webgl',
    };
  }
  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
  const graphicCardInfo = gl.getParameter(debugInfo?.UNMASKED_RENDERER_WEBGL ?? 0);
  canvasElement.remove();
  return graphicCardInfo;
};

export const isIntelGraphicsCard = () => {
  const graphicCard = getGraphicsCardInfo();
  return graphicCard.includes('Intel');
};

export const isSafariBrowser = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);
  return isSafari;
};

export const isFirefoxBrowser = () => {
  const isFirefox = /firefox|fxios/i.test(navigator?.userAgent);
  return isFirefox;
};

export const chackIsMacOS = () => /Mac/i.test(navigator?.userAgent);

export const handleRequestCatch = (e: MessageEvent, alternativeMessage = 'Error, please try again later') => {
  if ('status' in e && e.status === 500) {
    return;
  }
  notifyError(e.data?.message || alternativeMessage);
};

export const getMessageType = (type: MessageType) => {
  return type === MessageType.Medical ? 'medical' : type === MessageType.Support ? 'support' : 'staff-notes';
};
