import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { FeedbackStatuses } from 'enums/customerFeedback';
import { useAppSelector } from 'hooks/redux';
import { selectCustomerFeedback } from 'store/customerFeedback/customerFeedbackSlice';
import { closeModal } from 'store/modal/modalSlice';

import { getPrevNextCustomerFeedbackId } from './customerFeedback.settings';
import FeedbackDetails from './FeedbackDetails';

const CustomerFeedback: React.FC<{
  id: string;
}> = ({ id }) => {
  const dispatch = useDispatch();
  const { data: customerFeedback } = useAppSelector(selectCustomerFeedback);
  const [feedbackId, setFeedbackId] = useState(id);
  const feedback = useMemo(
    () => customerFeedback.find((item) => item._id === feedbackId),
    [feedbackId, customerFeedback],
  );
  const { prevFeedbackId, nextFeedbackId } = useMemo(
    () => getPrevNextCustomerFeedbackId(feedbackId, customerFeedback),
    [feedbackId, customerFeedback],
  );

  useEffect(() => {
    if (!feedbackId) {
      dispatch(closeModal());
    }
  }, [feedbackId, dispatch]);

  const handlePagination = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.name === 'prev' && prevFeedbackId) {
      setFeedbackId(prevFeedbackId);
    }
    if (event.currentTarget.name === 'next' && nextFeedbackId) {
      setFeedbackId(nextFeedbackId);
    }
  };

  const isNextFeedbackIconDisabled = !nextFeedbackId;
  const isPrevFeedbackIconDisabled = !prevFeedbackId;
  const nextFeedbackClassNames = classNames({ 'text-gray-500': isNextFeedbackIconDisabled });
  const prevFeedbackClassNames = classNames({ 'text-gray-500': isPrevFeedbackIconDisabled });

  return (
    <>
      <div data-testid="customer_feedback_popup" className="p-6">
        <div className="flex content-end justify-between">
          <h1 data-testid="patient_name" className="mb-9 text-xl font-bold text-gray-700">
            Review: {feedback?.patientInfo?.name}
          </h1>
          <div className="flex items-start overflow-hidden">
            <button className="p-1" onClick={handlePagination} disabled={isPrevFeedbackIconDisabled} name="prev">
              <Common.Icon name="arrow-left" className={prevFeedbackClassNames} />
            </button>
            <button className="p-1" onClick={handlePagination} disabled={isNextFeedbackIconDisabled} name="next">
              <Common.Icon name="arrow-right" className={nextFeedbackClassNames} />
            </button>
          </div>
        </div>

        <FeedbackDetails feedback={feedback} />

        <div className="mt-10 flex justify-end gap-x-2">
          <Common.Button color="blue" onClick={() => dispatch(closeModal())} size="sm" name={FeedbackStatuses.Hidden}>
            Close
          </Common.Button>
        </div>
      </div>
    </>
  );
};

export default CustomerFeedback;
