import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { FieldValues, Path, PathValue } from 'react-hook-form';

import ControlledCombobox from 'components/forms/controlled/ControlledCombobox';
import { useSearchComboboxData } from 'hooks/components/SearchCombobox/useSearchComboboxData';

import { ComboBoxProps } from './searchCombobox.types';

const SearchCombobox = <TFieldValues extends FieldValues>({
  control,
  setValue,
  label,
  name,
  userType,
  minLengthInput = 3,
  placeholder,
  labelDirection = 'col',
  isRequired = false,
  preIcon,
  defaultValue,
  size,
  validationMessage,
}: ComboBoxProps<TFieldValues>) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [query, setQuery] = useState('');

  const { searchData, isFetching, isLoading } = useSearchComboboxData(query, userType);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= minLengthInput) {
      setQuery(event.target.value);
    }
    if (!event.target.value.length) {
      // TODO: temporary solution need to check how to create reusable component with react-hook-form
      setValue(name, '' as PathValue<TFieldValues, Path<TFieldValues>>);
    }
  };

  const handleChange = useCallback(
    (value: Option) => setValue(name, value as PathValue<TFieldValues, Path<TFieldValues>>),
    [name, setValue],
  );

  useEffect(() => {
    if (searchData?.length) setOptions(searchData as Option[]);
  }, [searchData]);

  return (
    <ControlledCombobox
      size={size}
      label={label}
      labelDirection={labelDirection}
      control={control}
      name={name}
      options={options}
      preIcon={preIcon}
      defaultValue={defaultValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={query}
      hideComboboxButton
      placeholder={placeholder}
      isLoading={isLoading || isFetching}
      rules={{
        required: {
          value: Boolean(isRequired),
          message: validationMessage || 'Please select a patient',
        },
      }}
    />
  );
};

export default SearchCombobox;
