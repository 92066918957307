import { Common } from '@thecvlb/design-system/lib/src';
import { useDispatch } from 'react-redux';

import Automation from 'components/modals/Automation';
import { openModal } from 'store/modal/modalSlice';

const EditCell: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-1">
      <button
        onClick={() =>
          dispatch(openModal({ modalContent: <Automation isPatientTriggers id={id} />, size: 'lg', hideClose: true }))
        }
      >
        <Common.Icon name="pencil" className="h-4 w-4 hover:text-primary-500" />
      </button>
    </div>
  );
};

export default EditCell;
