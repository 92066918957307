import { MouseEvent } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useAppSelector } from 'hooks/redux';
import { resetBulkEdits, selectBulkEdit } from 'store/bulkEdit/bulkEditSlice';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { ReviewStatuses } from 'store/reviews/reviews.types';
import { useModerateReviewsMutation } from 'store/reviews/reviewsSlice';

import UpdateFailed from '../UpdateFailed';

const ReviewsBulkEdit = () => {
  const { bulkEdits } = useAppSelector(selectBulkEdit);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const reviewStatus = searchParams.get('status');

  const isHidden = reviewStatus === ReviewStatuses.Hidden;
  const isPublished = reviewStatus === ReviewStatuses.Published;

  const [moderateReviews, { isLoading }] = useModerateReviewsMutation({
    fixedCacheKey: 'reviewsBulkEdit',
  });
  const itemsLabel = `${bulkEdits.length} ${bulkEdits.length === 1 ? 'item' : 'items'}`;

  const handleModerateReviews = (event: MouseEvent<HTMLButtonElement>) => {
    if (!bulkEdits.length) return;
    const status = event.currentTarget.name;
    const body = {
      reviewIds: bulkEdits,
      status,
    };
    moderateReviews(body)
      .unwrap()
      .then((data) => {
        if (data.data?.failed.length) {
          dispatch(
            openModal({
              size: 'sm',
              hideClose: true,
              modalContent: <UpdateFailed requestBody={body} />,
            }),
          );
        } else {
          notifySuccess(data?.message);
          dispatch(resetBulkEdits());
          dispatch(closeModal());
        }
      });
  };
  return (
    <>
      <Loader isVisible={isLoading} />
      <div>
        <p className="mb-6 text-xl font-bold">{`${itemsLabel} selected`}</p>
        <div className="flex justify-between gap-2">
          <Common.Button
            color="red-alt"
            size="sm"
            onClick={handleModerateReviews}
            name={ReviewStatuses.Hidden}
            disabled={isLoading || isHidden}
          >
            <Common.Icon name="not-available" />
            Hide {itemsLabel}
          </Common.Button>

          <Common.Button
            color="blue"
            onClick={handleModerateReviews}
            name={ReviewStatuses.Published}
            size="sm"
            disabled={isLoading || isPublished}
          >
            <Common.Icon name="thumb-up" className="" />
            Publish {itemsLabel}
          </Common.Button>
        </div>
      </div>
    </>
  );
};

export default ReviewsBulkEdit;
