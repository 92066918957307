import { useEffect, useMemo } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { ChangePlanFormData } from 'components/modals/ChangePlan/changePlan.types';
import { useGetAccountBalancesQuery, useLazyGetPreviewChangeSubscriptionQuery } from 'store/patients/patientsSlice';
import { validation } from 'utils/helpers';

import { ConfirmPaymentProps } from './confirmPayment.types';
import CreditBalanceCard from './CreditBalanceCard';
import PaymentCard from './PaymentCard';
import ProviderAppointmentCard from './ProviderAppointmentCard';
import UpdatePlanCard from './UpdatePlanCard';

const ConfirmPayment: React.FC<ConfirmPaymentProps> = ({
  patientInfo,
  newPlan,
  currentPlan,
  activePP,
  hideTitle,
  disabledPayment,
  membershipData,
  newPricePoint,
  isCreateAppointment,
}) => {
  const { control, setValue } = useFormContext();
  const formData = useWatch({ control }) as ChangePlanFormData;
  const patientId = patientInfo?._id || '';
  const planId = newPlan?._id || '';
  const isSamePlanConfiguration = useMemo(() => {
    if (isCreateAppointment) {
      return currentPlan?._id === newPlan?._id;
    }
    return currentPlan?._id === newPlan?._id && activePP?.planPricePointId === newPricePoint?.planPricePointId;
  }, [
    isCreateAppointment,
    currentPlan?._id,
    newPlan?._id,
    activePP?.planPricePointId,
    newPricePoint?.planPricePointId,
  ]);

  const planPricePointId = (!!newPricePoint && newPricePoint.planPricePointId) || '';
  const { data: balance, isFetching, refetch: refetchBalance } = useGetAccountBalancesQuery({ patientId });
  const [getPreviewChangeSubscription, { data: previewSubscription, isFetching: isFetchingPreview }] =
    useLazyGetPreviewChangeSubscriptionQuery();

  const isLoading = isFetching || isFetchingPreview;
  const creditBalance = balance?.creditBalance;
  const patientFirstName = patientInfo?.firstName;
  const isCreditedFinalPrice = !!previewSubscription && !!Number(previewSubscription.creditApplied);
  const showUpdatePlanCard = !!patientInfo && !!newPricePoint;
  const showPaymentProfiles = (!!previewSubscription && !isCreditedFinalPrice) || (!previewSubscription && !isLoading);

  const providerName = formData.randomProviderName || patientInfo?.doctor || '';

  const refetchPreviewSubscription = () => {
    refetchBalance();
    getPreviewChangeSubscription({
      patientId,
      planId,
      planPricePointId,
      couponCode: formData.couponCode?.value,
    });
  };

  useEffect(() => {
    if (isCreditedFinalPrice) {
      setValue('confirmation', true);
    }
  }, [isCreditedFinalPrice, setValue]);

  useEffect(() => {
    if (!isSamePlanConfiguration) {
      getPreviewChangeSubscription({
        patientId,
        planId,
        planPricePointId,
        couponCode: formData.couponCode?.value,
      });
    }
  }, [
    formData.couponCode?.value,
    getPreviewChangeSubscription,
    isSamePlanConfiguration,
    patientId,
    planId,
    planPricePointId,
  ]);

  return (
    <div className="flex flex-col gap-16">
      {isLoading && (
        <>
          <div>
            {!hideTitle && <div className="mb-4 h-5 w-32 animate-pulse rounded-2xl bg-slate-200" />}
            <div className="mb-4 h-64 w-full animate-pulse rounded-2xl bg-slate-200" />
          </div>
          <div>
            <div className="mb-4 h-5 w-32 animate-pulse rounded-2xl bg-slate-200" />
            <div className="mb-4 h-20 w-full animate-pulse rounded-2xl bg-slate-200" />
          </div>
        </>
      )}
      {showUpdatePlanCard && (
        <div>
          {!hideTitle && !isLoading && <h3 className="mb-6 text-base font-bold text-gray-700">Order confirmation</h3>}
          {isSamePlanConfiguration
            ? activePP && (
                <ProviderAppointmentCard
                  category={formData.displayName || ''}
                  creditBalance={creditBalance}
                  membershipData={membershipData}
                  pricePoint={activePP}
                  providerName={providerName}
                  time={formData.startTime || ''}
                />
              )
            : !!previewSubscription && (
                <UpdatePlanCard
                  {...(!!currentPlan &&
                    !!activePP && { oldPlan: { planName: currentPlan?.planName, pricePoint: activePP } })}
                  plan={{ planName: newPlan?.planName || '', pricePoint: newPricePoint }}
                  patientInfo={patientInfo}
                  creditBalance={creditBalance}
                  previewSubscription={previewSubscription}
                />
              )}
        </div>
      )}
      {showPaymentProfiles && (
        <div>
          <h3 className="mb-4 text-base font-bold text-gray-700">Payment</h3>
          <div className="flex flex-col gap-4">
            {creditBalance && Number(creditBalance) > 0 && (
              <CreditBalanceCard firstName={patientFirstName} creditBalance={creditBalance} />
            )}
            <PaymentCard
              patientInfo={patientInfo}
              disabledPayment={disabledPayment}
              onCreateFirstCard={refetchPreviewSubscription}
            />
          </div>

          <div className="mt-6">
            <Controller
              control={control}
              name="confirmation"
              rules={validation('Confirmation')}
              render={({ field }) => (
                <div>
                  <Common.Checkbox
                    checked={field.value}
                    color="blue"
                    size="sm"
                    name={field.name}
                    value={field.value}
                    onChange={(event) => field.onChange(event.target.checked)}
                  >
                    I have approval from the patient to charge their card.
                  </Common.Checkbox>
                </div>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmPayment;
