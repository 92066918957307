import classNames from 'classnames';

import { TooltipProps } from './tooltip.types';

const Tooltip: React.FC<TooltipProps> = ({ children }) => {
  const wrapperClassName = 'absolute bottom-[200%] left-[50%] w-[250px] ml-[-125px] flex z-[120]';
  const tooltipClassName = 'rounded-lg shadow text-sm p-3 text-white bg-black mx-auto';
  const arrowClassName = classNames('bg-black absolute w-4 h-4 origin-center rotate-45 m-auto inset-x-0 -bottom-2');

  return (
    <div className={wrapperClassName}>
      <div className={tooltipClassName}>
        {children}
        <div className={arrowClassName} />
      </div>
    </div>
  );
};

export default Tooltip;
