import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import { useFormContext } from 'react-hook-form';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { validation } from 'utils/helpers';

const CurrentRoleSelect: React.FC<{ roleOptions: OptionProps[]; isOptional?: boolean }> = ({
  roleOptions,
  isOptional = false,
}) => {
  const { control } = useFormContext();

  return (
    <>
      <div className="col-span-2 w-1/2 pr-5">
        <ControlledSelect
          control={control}
          labelDirection="row"
          options={roleOptions}
          placeholder="Select role..."
          label="Role"
          name="role"
          rules={validation('Role', null, null, isOptional)}
        />
      </div>

      <div className="col-span-2 h-px w-full bg-gray-200" />
    </>
  );
};

export default CurrentRoleSelect;
