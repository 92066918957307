import { createRef, useCallback, useEffect } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import ActionButton from 'components/common/ActionButton';
import ViewAsButton from 'components/common/ViewAsButton';
import { getModalType } from 'components/tasks/slidingPane/Actions/actions.settings';
import Dropdown from 'components/tasks/slidingPane/Dropdown';
import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import { ActionsModalTypes } from 'enums/actionsModalTypes';
import useZoomCall from 'hooks/common/useZoomCall';
import { useAppSelector } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import {
  useLazyGetCallTokenQuery,
  useLazyLoginLikePatientQuery,
  useLazyViewEMRQuery,
  useLazyViewMaxioQuery,
} from 'store/patients/patientsSlice';
import { selectVideoCall } from 'store/videoCall/videoCallSlice';

import { ContactButtonsProps } from './contactButtons.types';

const selectContactButtonsState = createSelector([selectVideoCall], (videoCall) => ({
  alreadyOnCall: videoCall.isOpen,
}));

const ContactButtons: React.FC<ContactButtonsProps> = ({ patient }) => {
  const { alreadyOnCall } = useAppSelector(selectContactButtonsState);
  const { isZoomCallOpen: zoomCallInProgress } = useZoomCall();
  const {
    setPatientId,
    showCallCard,
    token,
    setToken,
    hasGlobalInstance,
    hasSlidingPanelInstance,
    initiatePhoneCallProps,
  } = usePhoneCall();
  const emailRef = createRef<HTMLAnchorElement>();
  const [loginLikePatient] = useLazyLoginLikePatientQuery();
  const [viewMaxio] = useLazyViewMaxioQuery();
  const [viewEMR] = useLazyViewEMRQuery();
  const [getCallToken, { data }] = useLazyGetCallTokenQuery();
  const dispatch = useDispatch();

  const patientFullName = patient.firstName + ' ' + patient.lastName;

  const handleViewEMR = () => viewEMR({ id: patient._id });

  const handleViewMaxio = useCallback(() => {
    patient?._id && viewMaxio({ id: patient._id });
  }, [viewMaxio, patient._id]);

  const handleLoginAsPatient = useCallback(() => {
    loginLikePatient({ id: patient?._id });
  }, [loginLikePatient, patient._id]);

  const handleOpenModal = useCallback(
    (type: ActionsModalTypes) => () => {
      dispatch(openModal(getModalType(type, patient._id, patientFullName, patient)));
    },
    [dispatch, patient, patientFullName],
  );

  // NOTE: we shouldn't show the call card if it's already shown
  // NOTE: we have separated the Phone call pop-up instance for the sliding panel and the global instance
  const handleCall = () => {
    if (patient && !showCallCard && !hasGlobalInstance && !hasSlidingPanelInstance) {
      const isSlidingPanel = false;
      initiatePhoneCallProps({
        isSlidingPanel,
        phoneCallToken: token,
      });
    }
  };

  useEffect(() => {
    if (patient._id && !alreadyOnCall && !zoomCallInProgress) getCallToken({ id: patient._id });
  }, [getCallToken, patient._id, alreadyOnCall, zoomCallInProgress, token]);

  useEffect(() => {
    if (data && patient && !alreadyOnCall && !zoomCallInProgress) {
      setPatientId(patient._id);
      setToken(data.token);
    }
  }, [patient, data, setPatientId, setToken, alreadyOnCall, zoomCallInProgress]);

  return (
    <div className="flex items-center gap-4">
      <a ref={emailRef} href={`mailto:${patient.email}`} />
      <Dropdown
        handleLoginAsAPatient={handleLoginAsPatient}
        handleCreateNewTask={handleOpenModal(ActionsModalTypes.CreateNewTask)}
        handleCreateReminder={handleOpenModal(ActionsModalTypes.CreateReminder)}
        handleViewMaxio={handleViewMaxio}
        handleStopReceivingTasks={handleOpenModal(ActionsModalTypes.StopReceivingTasks)}
        handlePrescribeCompound={handleOpenModal(ActionsModalTypes.PrescribeCompound)}
        handleRemoveBillingBlockModal={handleOpenModal(ActionsModalTypes.RemoveBillingBlock)}
      />
      <ViewAsButton type="emr" onClick={handleViewEMR} />
      <ActionButton
        dataTestId="phone"
        type="phone"
        onClick={handleCall}
        disabled={alreadyOnCall || zoomCallInProgress || !patient.phone || showCallCard}
      />
    </div>
  );
};

export default ContactButtons;
