import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

import ActionsCell from './ActionsCell';
import { ProspectTableProps } from './prospectTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<ProspectTableProps>();
export const columns = [
  columnHelper.accessor('patientName', {
    header: () => <Header text="Name" sortField="patientName" />,
    cell: (info) => <span className="font-normal">{info.getValue()}</span>,
  }),
  columnHelper.accessor('planName', {
    header: () => <Header text="Plan selected" sortField="planName" />,
    cell: (info) => <span className="font-normal">{info.getValue()}</span>,
  }),
  columnHelper.accessor('createdAt', {
    header: () => <Header text="Created" sortField="createdAt" />,
    cell: (info) => <span>{dayjs(info.getValue()).format(DateFormat.MMM_DYYYY)}</span>,
  }),
  columnHelper.accessor('updatedAt', {
    header: () => <Header text="Updated" sortField="updatedAt" />,
    cell: (info) => <span>{dayjs(info.getValue()).format(DateFormat.MMM_DYYYY)}</span>,
  }),
  columnHelper.accessor('state', {
    header: () => <Header text="State" sortField="state" />,
    cell: (info) => <span className="font-normal">{info.getValue()}</span>,
  }),
  columnHelper.accessor('email', {
    header: () => <Header text="Email" sortField="email" />,
    cell: (info) => <p className="max-w-[150px] truncate text-sm font-medium">{info.getValue()}</p>,
  }),
  columnHelper.accessor('prospectStatus', {
    header: () => <Header text="Status" sortField="prospectStatus" />,
    cell: (info) => <span className="font-normal">{info.getValue()}</span>,
  }),
  columnHelper.accessor('actions', {
    header: () => <Header text="Actions" sortField="actions" />,
    cell: (info) => <ActionsCell prospectPatient={info.row.original} />,
  }),
];
