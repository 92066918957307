import { ChangeEventHandler } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

import { ExceptionItemProps } from './exceptionItem.types';

const ExceptionItem: React.FC<ExceptionItemProps> = ({
  exception,
  loading,
  handleClick,
  timezone,
  isPhysicianTab,
  onChangeExceptionTypeCheckboxes,
  date,
}) => {
  const isUnavailable = !exception.startTime && !exception.endTime;

  const timeViewClasses = classNames(
    'text-sm lowercase',
    isPhysicianTab ? 'py-2.5 px-3 text-gray rounded border border-gray w-24 shadow' : 'text-gray-700',
  );

  const handleExceptionType: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChangeExceptionTypeCheckboxes(date, event.currentTarget.name, event.target.checked, exception);
  };

  return isUnavailable ? (
    <div data-testid="time_container" key={nanoid()} className="my-2 flex items-center gap-3">
      <span className="text-sm text-gray">Unavailable</span>
      {isPhysicianTab && (
        <button disabled={loading} onClick={handleClick}>
          <Common.Icon name="trash" />
        </button>
      )}
    </div>
  ) : (
    <div key={nanoid()}>
      <div data-testid="time_container" className="my-2 flex items-center gap-3">
        <div data-testid="start_time" className={timeViewClasses}>
          {exception.startTime ? dayjs(exception.startTime).tz(timezone).format(DateFormat.hh_mm_A) : '00:00'}
        </div>
        <span className="text-sm">to</span>
        <div data-testid="end_time" className={timeViewClasses}>
          {exception.endTime ? dayjs(exception.endTime).tz(timezone).format(DateFormat.hh_mm_A) : '11:59 pm'}
        </div>
        {isPhysicianTab && (
          <button data-testid="trash_btn" disabled={loading} onClick={handleClick}>
            <Common.Icon name="trash" />
          </button>
        )}
      </div>
      <div className="relative my-3">
        <div className="flex items-center gap-4">
          <Common.Checkbox
            checked={exception.queue}
            key="queue"
            color="blue"
            name={'queue'}
            onChange={handleExceptionType}
          >
            Queue
          </Common.Checkbox>
          <Common.Checkbox
            checked={exception.appointments}
            key="appointments"
            color="blue"
            name={'appointments'}
            onChange={handleExceptionType}
          >
            Appointments
          </Common.Checkbox>
        </div>
      </div>
    </div>
  );
};

export default ExceptionItem;
