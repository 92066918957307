import AssignProvider from 'components/modals/AssignProvider';
import Benefits from 'components/modals/Benefits';
import CreateReminder from 'components/modals/CreateReminder';
import CreateTask from 'components/modals/CreateTaskForm';
import PrescribeCompound from 'components/modals/PrescribeCompound';
import RemoveBillingBlock from 'components/modals/RemoveBillingBlock';
import StopReceivingTasks from 'components/modals/StopReceivingTasks';
import { ActionsModalTypes } from 'enums/actionsModalTypes';
import { OpenModalPayload } from 'store/modal/modalSlice.types';
import { PatientProps } from 'store/patients/patients.types';

export const getModalType = (
  type: ActionsModalTypes,
  patientId: string,
  patientName?: string,
  patientInfo?: PatientProps,
): OpenModalPayload => {
  const modalType: OpenModalPayload = {
    size: 'sm',
    hideClose: true,
    modalContent: <></>,
  };

  const hardBlockedPaymentsCount = patientInfo?.paymentBlockInfo?.hardBlockedCount;

  const patient = patientName ? { id: patientId, fullName: patientName, state: patientInfo?.state } : null;
  switch (type) {
    case ActionsModalTypes.CheckBenefits:
      return { ...modalType, modalContent: <Benefits patientId={patientId} />, size: 'lg' };
    case ActionsModalTypes.PrescribeCompound:
      return {
        ...modalType,
        modalContent: <PrescribeCompound patientId={patientId} patientInfo={patientInfo} />,
        size: 'sm',
        drag: true,
      };
    case ActionsModalTypes.AssignProvider:
      return { ...modalType, modalContent: <AssignProvider /> };
    case ActionsModalTypes.CreateNewTask:
      return {
        ...modalType,
        modalContent: <CreateTask patient={patient} />,
      };
    case ActionsModalTypes.StopReceivingTasks:
      return { ...modalType, modalContent: <StopReceivingTasks patientId={patientId} /> };
    case ActionsModalTypes.CreateReminder:
      return { ...modalType, modalContent: <CreateReminder /> };
    case ActionsModalTypes.RemoveBillingBlock:
      return {
        ...modalType,
        modalContent: <RemoveBillingBlock patientId={patientId} hardBlockedPaymentsCount={hardBlockedPaymentsCount} />,
        size: 'base',
      };
    default:
      return modalType;
  }
};
