import { useEffect, useMemo, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { FieldError, FormProvider, useController, useForm, useWatch } from 'react-hook-form';

import DatePickerInput from 'components/common/DatePickerInput';
import SearchCombobox from 'components/common/form/SearchCombobox/SearchCombobox';
import TimeInput from 'components/common/form/timeInput';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { DateFormat } from 'enums/dateFormats';
import { MedicationBrandNames } from 'enums/medications';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useCreateTaskStaffNoteMutation } from 'store/staffNotes/staffNotesSlice';
import { CreateNewTaskParams, RequestPAProps } from 'store/tasks/task.types';
import { selectTask, useCreateNewTaskMutation, useRequestPAMutation } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { validation } from 'utils/helpers';

import { assignToOptions } from './createTaskForm.settings';
import { CreateTaskFormProps, CreateTaskFormState, TaskCategory } from './createTaskForm.types';

const taskCategoryOptions = [
  { label: TaskCategory.Request, value: TaskCategory.Request },
  { label: TaskCategory.RequestPA, value: TaskCategory.RequestPA },
];

dayjs.extend(LocalizedFormat);

const selectCreateTaskFormState = createSelector([selectTask, selectUser], (task, user) => ({
  taskDetails: task.taskDetails,
  userId: user._id,
}));

const CreateTaskForm = ({ patient }: CreateTaskFormProps) => {
  const dispatch = useAppDispatch();
  const { taskDetails, userId } = useAppSelector(selectCreateTaskFormState);
  const [requestPA, { isLoading: isLoadingRequestPA }] = useRequestPAMutation();
  const [createNewTask, { isLoading }] = useCreateNewTaskMutation();
  const [sendTaskStaffNote] = useCreateTaskStaffNoteMutation();
  const methods = useForm<CreateTaskFormState>();
  const { handleSubmit, control, watch, setValue, getValues, register, formState } = methods;
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name: 'assignTo',
    defaultValue: assignToOptions[2].value, // default value is 'Medical assistant'
    rules: {
      required: 'Select assign to option',
    },
  });

  const isStaffMember = watch('assignTo') === 'SSM';
  const formData = useWatch({ control }) as CreateTaskFormState;
  const isRequestPA = formData.taskCategory?.value === TaskCategory.RequestPA;
  const disableCreateTask = isLoading || isLoadingRequestPA;

  const [isDueDate, setIsDueDate] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const [dueDate, setDueDate] = useState(dayjs().format(DateFormat.MM_DD_YYYY));

  const patientInfo = useMemo(() => {
    const label = taskDetails?.personalInfo?.firstName
      ? `${taskDetails.personalInfo.firstName} ${taskDetails.personalInfo.lastName}`
      : patient
        ? patient.fullName
        : '';

    const value = taskDetails?.personalInfo?._id || patient?.id || '';
    const id = value;

    return {
      label,
      value,
      id,
    };
  }, [patient, taskDetails.personalInfo]);

  const hasDefaultPatient = Object.values(patientInfo).every((value) => Boolean(value));

  const medicationOptions = Object.values(MedicationBrandNames).map((medication) => ({
    value: medication,
    label: medication,
  }));

  const handleCancel = () => dispatch(closeModal());

  const onSubmit = (data: CreateTaskFormState) => {
    const reqPAData: RequestPAProps = {
      patientUserId: data.patient.id,
      isUrgent: data.isUrgent,
      note: data.medication?.value || '',
      medication: data.medication?.value,
    };
    const reqData: CreateNewTaskParams = {
      patientId: data.patient.id,
      note: data.taskDetails,
      ...(isDueDate && { dueDate: dayjs(data.dueDate.date + ' ' + data.dueDate.time).toISOString() }),
      isUrgent: data.isUrgent,
      ...(Boolean(taskDetails._id) && { parentTaskId: taskDetails._id }),
    };

    if (data?.assignTo?.toLowerCase() === 'myself') {
      (isRequestPA ? reqPAData : reqData).assignedTo = userId;
    } else if (data?.assignTo?.toLowerCase() === 'ssm' && data?.staff?.id) {
      (isRequestPA ? reqPAData : reqData).assignedTo = data.staff.id;
    } else {
      (isRequestPA ? reqPAData : reqData).audience = data.assignTo;
    }

    (isRequestPA ? requestPA({ ...reqPAData }) : createNewTask({ body: reqData }))
      .unwrap()
      .then((res) => {
        // Send staff note if it is a request task
        if (!isRequestPA && data.taskDetails && res?.data?.id) {
          sendTaskStaffNote({
            taskId: res.data.id,
            body: { note: data.taskDetails, isUrgent: data.isUrgent, staffNoteFile: null },
          });
        }

        notifySuccess('Task has been successfully created');
      })
      .finally(() => {
        dispatch(closeModal());
      });
  };

  useEffect(() => {
    setValue('taskCategory', taskCategoryOptions[0]);
  }, [setValue]);

  useEffect(() => {
    setValue('isUrgent', isUrgent);
  }, [isUrgent, setValue]);

  useEffect(() => {
    setValue('dueDate', {
      time: getValues('dueDate.time'),
      date: dayjs(dueDate.toString(), DateFormat.MM_DD_YYYY).format(DateFormat.MM_DD_YYYY),
    });
  }, [dueDate, setValue, getValues]);

  return (
    <>
      <Loader isVisible={isLoading} />
      <div data-testid="create_task_form" className="p-6">
        <h2 data-testid="header" className="mb-6 text-xl font-bold text-gray-700">
          Create new task
        </h2>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <ControlledSelect
              control={control}
              options={taskCategoryOptions}
              name="taskCategory"
              className="w-full"
              label="Task category"
              rules={validation('Task category')}
              errors={formState.errors.taskCategory}
            />

            <SearchCombobox
              control={control}
              setValue={setValue}
              name="patient"
              label="For"
              userType="Patient"
              placeholder="Search for a patient"
              defaultValue={hasDefaultPatient ? patientInfo : ''}
              isRequired
              size="sm"
              preIcon="search"
              labelDirection="col"
            />
            <Common.SelectAlt
              dataTestId="assign_to_dropdown"
              label="Assign to"
              size="sm"
              name={field.name}
              value={field.value || ''}
              options={assignToOptions}
              onChange={(value) => {
                field.onChange(value);
              }}
              hideSuccessState
              error={errors[field.name] as FieldError}
            />
            {isStaffMember && (
              <SearchCombobox
                size="sm"
                control={control}
                setValue={setValue}
                name="staff"
                label="Staff member"
                userType="Staff"
                placeholder="Search for staff"
                labelDirection="col"
                preIcon="search"
              />
            )}
            {isRequestPA && (
              <ControlledSelect
                control={control}
                labelDirection="col"
                options={medicationOptions}
                rules={validation('Medication')}
                placeholder="Select..."
                label="Medication"
                name="medication"
              />
            )}
            {!isRequestPA && (
              <Common.TextArea
                dataTestId="task_details_field"
                label="Task details"
                size="sm"
                placeholder="What do you need completed?"
                {...register('taskDetails', {
                  required: { value: true, message: 'Please fill out this field' },
                })}
                helper={formState.errors.taskDetails ? 'Please fill out this field' : ''}
                errors={formState.errors.taskDetails}
              />
            )}
            {!isRequestPA && (
              <Common.Checkbox
                dataTestId="add_due_date_checkbox"
                value="dueDate"
                size="sm"
                color="blue"
                onChange={(event) => {
                  setIsDueDate(event.target.checked);
                }}
                checked={isDueDate}
              >
                Add a due date
              </Common.Checkbox>
            )}
            {isDueDate && (
              <div className="flex items-center gap-4">
                <DatePickerInput
                  dataTestId="due_date_scope"
                  wrapperClasses="w-11/12"
                  label="Due"
                  inputValue={dueDate}
                  setInputValue={(value) => setDueDate(value)}
                  setSelectedDate={() => {}}
                  inputClassName="!text-gray-500"
                  size="sm"
                />
                <TimeInput className="h-[33px] w-10/12 rounded-md" />
              </div>
            )}
            <Common.Checkbox
              dataTestId="mark_as_urgent_checkbox"
              size="sm"
              value="isUrgent"
              color="blue"
              onChange={(event) => setIsUrgent(event.target.checked)}
              checked={isUrgent}
            >
              Mark as urgent
            </Common.Checkbox>
            <div className="mt-6 flex gap-2">
              <button
                data-testid="cancel_btn"
                className="w-full rounded-lg bg-gray-100 py-[7.5px] text-sm font-bold text-gray-700 disabled:bg-gray-200 disabled:text-gray"
                disabled={isLoading}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                data-testid="create_task_btn"
                className="w-full rounded-lg bg-primary py-[7.5px] text-sm font-bold text-white disabled:bg-gray-200 disabled:text-gray"
                disabled={disableCreateTask}
              >
                Create task
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default CreateTaskForm;
