import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { Role } from 'enums/role';
import { useAppSelector } from 'hooks/redux';
import { selectUser } from 'store/user/userSlice';
import { getTimezoneAbbreviation } from 'utils/datesAndTime';

import UpdateTimezonePopover from './UpdateTimezonePopover';

const UpdateTimezone = () => {
  const { timezone, userType } = useAppSelector(selectUser);

  const showUpdateTimezone = userType.name === Role.PH;
  const timezoneAbr = getTimezoneAbbreviation(showUpdateTimezone ? timezone : dayjs.tz.guess());

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-1">
        <Common.Icon name="clock" className="text-gray" />

        <span className="text-sm font-medium text-gray-700">Based on {timezoneAbr}</span>
      </div>
      {showUpdateTimezone && <UpdateTimezonePopover popoverButtonTitle="Update timezone" />}
    </div>
  );
};

export default UpdateTimezone;
