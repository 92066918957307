import React from 'react';

import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { setCardColor } from 'utils/setCardColor';

import { LeaderboardCardProps } from './leaderboardCard.types';

export const LeaderboardCard: React.FC<LeaderboardCardProps> = ({ title, text, color, isFetching }) => {
  const divClassName = classNames(
    'flex items-center text-center shadow justify-center md:h-[144px] gap-4 rounded-xl w-full',
    !isFetching && setCardColor(color),
  );
  const alignClassName = 'flex-col text-center justify-around gap-3 w-[60%]';
  const hClassName = classNames(
    'flex-col w-[30%] justify-between px-8 pl-6 text-center text-2xl font-bold text-white',
    {
      'flex-1 font-bold': color === 'primary',
    },
  );
  const tClassName = 'text-left text-base font-bold';
  const pClassName = 'text-left text-sm';

  return (
    <React.Fragment>
      {isFetching ? (
        <Skeleton height={'138px'} className="rounded-xl" containerClassName="flex-col gap-4 w-full" />
      ) : (
        <div className={divClassName}>
          <h1 className={hClassName}>{title}</h1>
          <div className={alignClassName}>
            <div className="flex pb-3">
              <p className={`${tClassName} mr-10 basis-1/6 text-right`}>Rank</p>
              <p className={`${tClassName} basis-1/2`}>Staff</p>
              <p className={`${tClassName} basis-1/4`}>Completed</p>
            </div>
            {text?.map((data) => (
              <div className="flex" key={data.id}>
                <p className={`${pClassName} mr-10 basis-1/6 text-right`}>{data.rank}</p>
                <p className={`${pClassName} basis-1/2`}>{data.name}</p>
                <p className={`${pClassName} basis-1/4`}>{data.completedCount}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
