import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { HealthMetricTypes } from 'enums/chart';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import {
  useAddHealthMetricMutation,
  useDeleteHealthMetricMutation,
  useUpdateHealthMetricMutation,
} from 'store/patientChart/patientChartSlice';
import { selectTask } from 'store/tasks/tasksSlice';

import { FormDataProps, HealthMetricsModalProps } from './healthMetricsModal.types';
import MeasurementDateInput from './MeasurementDateInput';
import { getHealthMetricTitle } from '../../patient/Chart/HealthMetric/healthMetric.settings';

const HealthMetricsModal: React.FC<HealthMetricsModalProps> = ({ type, children, isEdit = false, metricDate }) => {
  const dispatch = useAppDispatch();
  const { taskDetails } = useAppSelector(selectTask);
  const patientIdFromTask = taskDetails?.personalInfo?._id;
  const params = useParams<{ patientId: string }>();
  const patientId = params?.patientId || patientIdFromTask || '';

  const [addHealthMetric, { isLoading: isAddLoading }] = useAddHealthMetricMutation();
  const [updateHealthMetric, { isLoading: isUpdateLoading }] = useUpdateHealthMetricMutation();
  const [deleteHealthMetric, { isLoading: isDeleteLoading, isError: deleteError }] = useDeleteHealthMetricMutation();

  const isLoading = isAddLoading || isUpdateLoading || isDeleteLoading;

  const methods = useForm<FormDataProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
    if (metricDate) {
      methods.setValue('date', dayjs.utc(metricDate).format(DateFormat.MM_DD_YYYY));
    }
  }, [metricDate, methods]);

  const closeMetricModal = () => {
    dispatch(closeModal());
  };

  const deleteMetric = () => {
    if (patientId && type && metricDate) {
      const date = dayjs.utc(metricDate).format(DateFormat.YYYY_MM_DD);
      deleteHealthMetric({ patientId, metricType: type, collectedDate: date })
        .unwrap()
        .then(() => closeMetricModal());
    }
  };

  const onFormSubmit = async (data: FormDataProps) => {
    const formattedMetricDate = dayjs.utc(metricDate).format(DateFormat.YYYY_MM_DD);
    const formattedDateFromForm = dayjs(data.date).format(DateFormat.YYYY_MM_DD);

    const metrics = {
      ...(data.ft && { height: +data.ft * 12 + +data.in }),
      ...(data.lbs && { weight: +data.lbs }),
      ...(data.waistCircumference && { waistCircumference: +data.waistCircumference }),
      ...(data.SYS &&
        data.DIA &&
        data.pulse && {
          bloodPressure: {
            SYS: +data.SYS,
            DIA: +data.DIA,
            pulse: +data.pulse,
          },
        }),
      ...(data.total &&
        data.HDL &&
        data.nonHDL &&
        data.LDL && {
          cholesterol: {
            total: +data.total,
            HDL: +data.HDL,
            nonHDL: +data.nonHDL,
            LDL: +data.LDL,
          },
        }),
    };

    const body = {
      patientId,
      collectedDate: formattedDateFromForm,
      metrics,
    };

    // NOTE: If the date is changed, delete the old metric and add a new one.
    // That is how backend side is implemented.
    if (isEdit && formattedMetricDate !== formattedDateFromForm) {
      await deleteHealthMetric({
        patientId,
        metricType: type,
        collectedDate: formattedMetricDate,
        isPartOfEdit: true,
      });
    }

    const healthMetricOperation = isEdit && !deleteError ? updateHealthMetric : addHealthMetric;

    healthMetricOperation(body)
      .unwrap()
      .then(() => closeMetricModal());
  };

  const showDateInput = type !== HealthMetricTypes.BMI;

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col p-8" onSubmit={methods.handleSubmit(onFormSubmit)}>
        <h1 className="mb-2 text-xl font-bold text-gray-700">{getHealthMetricTitle(type)}</h1>
        <p className="mb-6 text-base text-gray-700">{isEdit ? 'Edit' : 'Add'} measurement</p>

        {children}

        {showDateInput && <MeasurementDateInput showTodayCheckbox={!isEdit} />}

        <div className="mt-6 flex flex-col gap-3 md:flex-row-reverse">
          <Common.Button
            color="blue"
            disabled={isLoading}
            isLoading={isUpdateLoading || isAddLoading}
            size="sm"
            fullWidthOnMobile
          >
            {isEdit ? 'Edit' : 'Add'} measurement
          </Common.Button>
          <Common.Button
            disabled={isLoading}
            color="white-alt"
            size="sm"
            type="button"
            fullWidthOnMobile
            onClick={closeMetricModal}
          >
            Cancel
          </Common.Button>
          {isEdit && (
            <Common.Button
              className="mr-auto"
              color="red-alt"
              disabled={isLoading}
              isLoading={isDeleteLoading}
              preIcon="trash"
              size="sm"
              type="button"
              onClick={deleteMetric}
            >
              Delete
            </Common.Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default HealthMetricsModal;
