import { BaseQueryApi, BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchAuthSession } from 'aws-amplify/auth';

import type { RootState } from 'store';
import { clearUser, setToken } from 'store/user/userSlice';

export const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: async (headers, api) => {
    try {
      // Re-Auth happens automatically when the token expires
      const session = await fetchAuthSession();
      if (session?.tokens) {
        const accessToken = session?.tokens.accessToken?.toString() ?? '';
        const idToken = session?.tokens.idToken?.toString() ?? '';

        // Set the token in the store to trigger pre-existing functionality
        if ('dispatch' in api) {
          const api2 = api as BaseQueryApi;
          if ((api2.getState() as RootState).user.accessToken !== accessToken) {
            api2.dispatch(setToken({ accessToken, idToken }));
          }
        }

        headers.set('Authorization', 'Bearer ' + accessToken);
        headers.set('idToken', idToken);
      }
    } catch (e) {
      if (e !== 'The user is not authenticated') console.error('Unable to fetch current session', e);
    } finally {
      headers.set('API-KEY', import.meta.env.VITE_API_KEY ?? '');
      headers.set('Platform', 'web-physician');
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);

  try {
    // Refresh token if user logged in
    // Note: aws-amplify no longer returns refreshToken or clockDrift with the AuthSession. These values are used under the hood to refresh/validate the user session
    if (result.error && result.error.status === 401) {
      api.dispatch(clearUser());
    }
  } catch (error) {
    // Clear user data if user logged out
    api.dispatch(clearUser());
  }

  return result;
};
