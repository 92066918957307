import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';
import { Role } from 'enums/role';

import { ArticlesTableProps } from './articlesTable.types';
import EditCell from './EditCell';
import Header from '../Header';

const columnHelper = createColumnHelper<ArticlesTableProps>();

export const columns = (role: string) => {
  const isAdmin = role == Role.AD;

  return [
    columnHelper.accessor('title', {
      header: () => <Header text="Title" sortField="title" />,
      size: isAdmin ? 150 : 600,
      cell: (info) => <span className="font-bold">{info.getValue()}</span>,
    }),
    ...(isAdmin
      ? [
          columnHelper.accessor('audience', {
            header: () => <Header text="Audience" sortField="audience" />,
            cell: (info) => {
              const audience = info.getValue().map((item) => item.shortCode);
              return <span>{audience.join(',')}</span>;
            },
          }),
        ]
      : []),
    columnHelper.accessor('category', {
      header: () => <Header text="Category" sortField="category" />,
      cell: (info) => (
        <span id={info?.getValue()} className="capitalize">
          {info?.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('author', {
      header: () => <Header text="Author" sortField="author" />,
      cell: (info) => <span id={info?.getValue().userId}>{info?.getValue().name}</span>,
    }),
    columnHelper.accessor('createdAt', {
      header: () => <Header text="Date created" sortField="createdAt" />,
      cell: (info) => <span>{dayjs(info.getValue()).format(DateFormat.MMM_DYYYY)}</span>,
    }),
    ...(isAdmin
      ? [
          columnHelper.accessor('_id', {
            header: () => <Header text="Unique ID" sortField="uniqueId" />,
          }),
        ]
      : []),
    ...(isAdmin
      ? [
          columnHelper.accessor('status', {
            header: () => <Header text="Status" sortField="status" />,
            cell: (info) => (
              <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>
                {info.getValue()}
              </span>
            ),
          }),
        ]
      : []),
    columnHelper.accessor('actions', {
      header: () => <span className="text-sm font-bold">Actions</span>,
      cell: (info) => <EditCell id={info.row.original._id} hiddenPencil={!isAdmin} />,
      enableSorting: false,
    }),
  ];
};
