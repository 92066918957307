import { isRouteErrorResponse, Navigate, useMatch, useNavigate, useRouteError } from 'react-router-dom';

import ErrorFallback from 'components/modals/ErrorFallback';
import NotFound from 'pages/NotFound';

import { RouterErrorProps } from '../router.types';

const ErrorPage = () => {
  const match = useMatch('login');
  const navigate = useNavigate();
  const error = useRouteError() as RouterErrorProps;
  const isNotFoundError = isRouteErrorResponse(error) && error.status === 404;

  const handleResetErrorBoundary = () => {
    navigate('/');
  };

  if (match?.pathname === '/login') {
    return <Navigate to="/" replace />;
  } else if (isNotFoundError) {
    return <NotFound />;
  } else {
    return <ErrorFallback resetErrorBoundary={handleResetErrorBoundary} error={error?.data || error?.statusText} />;
  }
};

export default ErrorPage;
