import React from 'react';

import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

const NowIndicator: React.FC<{ timezone: string }> = ({ timezone }) => {
  const currentTime = dayjs().tz(timezone).format(DateFormat.h_mm_a_z);

  return (
    <div
      id="now-indicator"
      className={`absolute top-[-6px] flex h-6 w-[73px] items-center justify-center rounded-2xl border-0 bg-red-500 text-white ${
        currentTime.length <= 9 ? 'text-sm' : 'text-xs'
      }`}
    >
      {currentTime}
    </div>
  );
};

export default NowIndicator;
