import { useCallback, useMemo } from 'react';

import { ColumnDef, getCoreRowModel, RowData, useReactTable } from '@tanstack/react-table';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { ArticlesTableProps } from 'components/tables/ArticlesTable/articlesTable.types';
import { columns } from 'components/tables/ArticlesTable/columns';
import BasicTable from 'components/tables/BasicTable';
import { StatusFilterData } from 'constants/filters';
import { PathName } from 'enums/pathName';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppSelector } from 'hooks/redux';
import { ArticleProps } from 'models/articles.types';
import { useGetArticlesQuery } from 'store/articles/articlesSlice';
import { selectUser } from 'store/user/userSlice';

const Articles = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userType } = useAppSelector(selectUser);

  const queryParams = useMemo(() => {
    return queryString.parse(searchParams.toString());
  }, [searchParams]);
  const { data, isFetching, isLoading, isUninitialized } = useGetArticlesQuery({ params: queryParams });

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns: columns(userType.name),
    data: data?.articles,
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<ArticlesTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = useCallback(
    (rowData: RowData) => {
      const article = rowData as ArticleProps;
      navigate(`${PathName.Articles}/${article?._id}`);
    },
    [navigate],
  );

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={StatusFilterData} queryParam="status" />
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable
          table={table}
          totalCount={data?.totalCount || 0}
          isFetching={isLoading}
          onClickRow={handleRowClick}
        />
      </Card>
    </>
  );
};

export default Articles;
