import { useCallback, useEffect, useMemo, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { ColumnDef } from '@tanstack/react-table';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { notifyError } from 'components/common/Toast/Toast';
import AssignedToTask from 'components/modals/AssignedToTask';
import TimeConflict from 'components/modals/TimeConflict';
import { Role } from 'enums/role';
import useLocationParams from 'hooks/common/location/useLocationParams';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useRefreshTasksList } from 'hooks/tasks/useRefreshTasksList/useRefreshTasksList';
import { TaskProps, TasksQueryParams } from 'models/tasks.types';
import { showQueueListPermission } from 'pages/Tasks/Tasks.settings';
import socketStaff from 'socket/socketStaff';
import { useLazyGetAppointmentsQuery } from 'store/appointments/appointmentsSlice';
import { openModal } from 'store/modal/modalSlice';
import {
  resetQueueState,
  selectQueue,
  setOnGetQueueDetails,
  useLazyGetQueueInfoQuery,
  useLazyGetQueueListQuery,
} from 'store/queue/queueSlice';
import { QueueTasksProps } from 'store/queue/queueSlice.types';
import { open } from 'store/slidingInPane/slidingInPaneSlice';
import { setAsyncProviderEnabled, setTasksSummary, useLazyGetTasksQuery } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { getISOStringIncludingTimezone } from 'utils/helpers';

import { columns, queueColumns } from './columns';
import TasksTable from './TasksTable';
import { TasksTableColumnsProps } from './tasksTable.types';

const selectTasksTableState = createSelector([selectUser, selectQueue], (user, queue) => ({
  queueScheduleDetails: queue.queueScheduleDetails,
  userType: user.userType,
  queueTask: queue.queueTask,
  userId: user._id,
}));

const ConnectedTasksTable: React.FC<{
  patientId?: string;
}> = ({ patientId }) => {
  const dispatch = useAppDispatch();
  const [showCancelModal, setShowCancelModal] = useState(false);

  const isQueueDisabled = useFlag('disable-queue');

  const {
    queueTask,
    queueScheduleDetails,
    userType: { name: userRole },
    userId,
  } = useAppSelector(selectTasksTableState);

  const memoizedQueueTask = useMemo(() => {
    return [queueTask];
  }, [queueTask]);
  const memoizedQueueColumns = useMemo(() => queueColumns(userRole), [userRole]);

  const [getTasks, { data: tasks, isFetching }] = useLazyGetTasksQuery();
  const [getQueueInfo, { data: queueInfo }] = useLazyGetQueueInfoQuery();
  const [getQueueList] = useLazyGetQueueListQuery();
  const [getAppointments, { isLoading, isFetching: isFetchingAppointments, data: appointments }] =
    useLazyGetAppointmentsQuery();

  const isLoadingCheckAvailable = isLoading || isFetchingAppointments;

  const { ['pageNo']: [pageNo = '0'] = [], sortField } = useLocationParams();
  const [searchParams] = useSearchParams({ limit: '50', pageNo: '0' });

  const queryParams: TasksQueryParams = useMemo(() => {
    const parsedQueryParams = queryString.parse(searchParams.toString());
    // remove taskModalOpenId from request payload
    if (parsedQueryParams?.taskModalOpenID) delete parsedQueryParams?.taskModalOpenID;
    return parsedQueryParams;
  }, [searchParams]);

  const count = queryParams.limit ?? 50;
  const totalCount = tasks?.totalCount || 0;

  const showingFrom = +pageNo + 1 === 1 ? +pageNo + 1 : +pageNo * count + 1;
  const showingTo = (+pageNo + 1) * count > totalCount ? totalCount : (+pageNo + 1) * count;

  const buildParamsForRequest = useCallback(
    () => ({
      params: {
        ...queryParams,
        ...(!!patientId && { patientId, enableSnoozedTasks: true, showAllPatientTasks: true }),
      },
    }),
    [patientId, queryParams],
  );

  const prevQueryParams = usePrevious(queryParams);

  const wasParamsChanged = useMemo(() => !isEqual(queryParams, prevQueryParams), [queryParams, prevQueryParams]);

  const refreshTasksList = useCallback(() => {
    getTasks(buildParamsForRequest());
  }, [buildParamsForRequest, getTasks]);

  const {
    showRefreshTasksButton,
    newTasksCount,
    handleOnClick: handleRefreshTasks,
    dismissRefreshCounts,
  } = useRefreshTasksList({ refreshTasksList });

  const havePermissionToQueue =
    userRole === Role.MA || userRole === Role.PH || userRole === Role.NP || userRole === Role.AD;
  const hasPhAbilityToQueue = queueScheduleDetails.providersQueueData?.find((provider) => provider.userId === userId);
  const showQueueForPH =
    (userRole === Role.PH || userRole === Role.NP) &&
    queueInfo &&
    !queueInfo.data.taskInProgress &&
    hasPhAbilityToQueue;
  const showQueueList = showQueueListPermission(userRole);
  const showQueue = Boolean(showQueueList || showQueueForPH) && !isQueueDisabled;

  const [regularTasks, regularTasksColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching,
    data: tasks?.tasks ?? [],
    columns,
  });

  const handleTakePatient = useCallback(() => {
    dispatch(open({ isQueue: true }));
    if (showCancelModal) setShowCancelModal(false);
  }, [dispatch, showCancelModal]);

  const handleOpenTask = useCallback(
    (task: TaskProps | QueueTasksProps) => {
      if (task._id) {
        const updatedTask = task as TaskProps;

        dispatch(
          openModal({
            size: 'sm',
            hideClose: true,
            modalContent: <AssignedToTask taskId={updatedTask._id} assignToName={updatedTask?.assignedToInfo?.name} />,
          }),
        );
        return;
      }
      if (!showQueueList && queueInfo?.data.patientsCount) {
        getAppointments(
          {
            startTime: getISOStringIncludingTimezone(dayjs(), dayjs.tz.guess()),
            endTime: getISOStringIncludingTimezone(dayjs().add(20, 'minute'), dayjs.tz.guess()),
            appointmentStatus: 'pending',
            limit: 1,
          },
          true,
        )
          .unwrap()
          .then((data) => {
            if (data && !data.length) {
              handleTakePatient();
            } else {
              setShowCancelModal(true);
            }
          })
          .catch((error) => notifyError(error.data?.message || error.message));
      }
    },
    [showQueueList, queueInfo?.data.patientsCount, dispatch, getAppointments, handleTakePatient],
  );

  useEffect(() => {
    return () => {
      // reset queue data on unmount
      dispatch(resetQueueState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tasks) {
      dispatch(setTasksSummary(tasks));
      dispatch(setAsyncProviderEnabled(tasks.asyncProviderEnabled));
    }
  }, [tasks, dispatch]);

  useEffect(() => {
    if (wasParamsChanged) {
      getTasks(buildParamsForRequest(), true);
      dispatch(setOnGetQueueDetails(() => getTasks(buildParamsForRequest())));
      if (showRefreshTasksButton) dismissRefreshCounts();
    }
  }, [
    dispatch,
    getTasks,
    queryParams,
    dismissRefreshCounts,
    showRefreshTasksButton,
    wasParamsChanged,
    buildParamsForRequest,
  ]);

  useEffect(() => {
    if (havePermissionToQueue && !isQueueDisabled) {
      getQueueInfo();
      if (showQueueList) {
        getQueueList();
      }

      const handleQueueDataUpdated = () => {
        getQueueInfo();
        if (showQueueList) {
          getQueueList();
        }
      };

      socketStaff.on('queueDataUpdated', handleQueueDataUpdated);

      return () => {
        socketStaff.off('queueDataUpdated', handleQueueDataUpdated);
      };
    }
  }, [getQueueInfo, getQueueList, havePermissionToQueue, showQueueList, isQueueDisabled]);

  return (
    <div className="relative">
      {showCancelModal && (
        <TimeConflict
          setShowModal={setShowCancelModal}
          appointmentStartTime={appointments?.[0]?.appointmentTime?.startTime || ''}
          onTakePatient={handleTakePatient}
          isLoading={isLoadingCheckAvailable}
        />
      )}
      <TasksTable
        regularTasksData={regularTasks}
        regularTasksColumns={regularTasksColumns as ColumnDef<TasksTableColumnsProps>[]}
        sortField={sortField}
        showQueue={showQueue}
        queueTableData={memoizedQueueTask}
        queueTableColumns={memoizedQueueColumns}
        totalCount={totalCount}
        isFetching={isFetching}
        showingFrom={showingFrom}
        showingTo={showingTo}
        handleOpenTask={handleOpenTask}
        showRefreshTasksButton={showRefreshTasksButton}
        newTasksCount={newTasksCount}
        handleRefreshTasks={handleRefreshTasks}
      />
    </div>
  );
};

export default ConnectedTasksTable;
