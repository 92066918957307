import { Label, Radio, RadioGroup } from '@headlessui/react';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';

import { PATIENT_MESSAGE_TAB } from 'constants/chat';
import { MessageType, Status } from 'enums/messages';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { resetChannels, selectChannels } from 'store/channels/channelsSlice';
import { selectChat } from 'store/chat/chatSlice';

import { MessagesTypePickerProps } from './messagesTypePicker.types';

const selectLoadingState = createSelector([selectChannels, selectChat], (channels, chat) => ({
  isLoadingChannels:
    channels.loadingChannelsStatus === Status.Pending || channels.loadingChannelsStatus === Status.Idle,
  isLoadingMessages:
    chat.loadingMessagesStatus === Status.Pending ||
    (Boolean(channels.currentChannel) && chat.loadingMessagesStatus === Status.Idle),
}));

const MessagesTypePicker: React.FC<MessagesTypePickerProps> = ({ location, messagesType, setMessagesType }) => {
  const dispatch = useAppDispatch();
  const { isLoadingChannels, isLoadingMessages } = useAppSelector(selectLoadingState);
  const isDisabled = (isLoadingChannels || isLoadingMessages) && messagesType !== MessageType.SMS;

  const radioGroupClasses = classNames('flex ', {
    'gap-1 my-4': location === 'Tasks',
    'gap-4 my-2': location === 'Patient',
  });

  const getButtonClasses = (isActive: boolean) =>
    classNames('text-sm text-gray font-semibold py-2 px-4', {
      'text-secondary-600 bg-secondary-100 rounded-full': isActive,
      'cursor-pointer': !isActive,
    });

  const handleChange = (type: MessageType) => {
    dispatch(resetChannels());
    setMessagesType(type);
  };

  return (
    <RadioGroup value={messagesType} onChange={handleChange} className={radioGroupClasses} disabled={isDisabled}>
      {PATIENT_MESSAGE_TAB.map((option) => (
        <Radio value={option.value} key={option.value}>
          {({ checked }) => <Label className={getButtonClasses(checked)}>{option.label}</Label>}
        </Radio>
      ))}
    </RadioGroup>
  );
};

export default MessagesTypePicker;
