import { useEffect, useMemo } from 'react';

import { ColumnDef, getCoreRowModel, RowData, useReactTable } from '@tanstack/react-table';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import BulkEdit from 'components/filters/BulkEdit';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import Review from 'components/modals/Review';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/ReviewsTable/columns';
import { ReviewsTableProps } from 'components/tables/ReviewsTable/reviewsTable.types';
import { getReviewsFiltersData } from 'constants/filters';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { ReviewProps, ReviewsQueryParams } from 'store/reviews/reviews.types';
import { resetReviews, useGetReviewsQuery } from 'store/reviews/reviewsSlice';

const Reviews: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const queryParams: ReviewsQueryParams = useMemo(() => {
    return queryString.parse(searchParams.toString());
  }, [searchParams]);

  const { data, isFetching, isUninitialized } = useGetReviewsQuery({ params: queryParams });
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.data,
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<ReviewsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  const counts = {
    totalCount: data?.info?.counter.total ?? 0,
    newCount: data?.info?.counter.new ?? 0,
    publishedCount: data?.info?.counter.published ?? 0,
    hiddenCount: data?.info?.counter.hidden ?? 0,
  };

  const filterCounts = getReviewsFiltersData(counts);

  const handleClickRow = (rowData: RowData) => {
    dispatch(
      openModal({
        size: 'lg',
        modalContent: <Review id={(rowData as ReviewProps)._id} reviews={tableData} />,
        hideClose: true,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetReviews());
    };
  }, [dispatch]);

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={filterCounts} queryParam="status" />
        <div className="flex gap-2">
          <BulkEdit />
          <SearchForm showSearchBar />
        </div>
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable
          table={table}
          totalCount={data?.info.totalCount || 0}
          isFetching={isFetching}
          onClickRow={handleClickRow}
        />
      </Card>
    </>
  );
};

export default Reviews;
