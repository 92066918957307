import { useFormContext } from 'react-hook-form';

import InputField from 'components/forms/controlled/InputField';
import { HealthMetrics } from 'store/patientChart/patientChart.types';
import { validation } from 'utils/helpers';
import { NUMBER_REGEXP } from 'utils/regExp';

const UpdateBloodPressure: React.FC<{ metric?: HealthMetrics }> = ({ metric }) => {
  const { control, formState } = useFormContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-6 md:gap-4">
        <div>
          <InputField
            name="SYS"
            label="Blood pressure"
            control={control}
            labelDirection="row"
            type="number"
            maxLength={10}
            errors={formState.errors.SYS}
            rules={validation('SYS', NUMBER_REGEXP)}
            postText="SYS"
            defaultValue={metric?.bloodPressure?.SYS}
          />
        </div>
        <div className="md:w-[134px] md:flex-none">
          <InputField
            name="DIA"
            control={control}
            type="number"
            maxLength={10}
            errors={formState.errors.DIA}
            rules={validation('DIA', NUMBER_REGEXP)}
            postText="DIA"
            defaultValue={metric?.bloodPressure?.DIA}
          />
        </div>
      </div>
      <div className="flex gap-6 md:gap-4">
        <InputField
          name="pulse"
          label="Pulse"
          control={control}
          labelDirection="row"
          type="number"
          maxLength={10}
          errors={formState.errors.pulse}
          rules={validation('Pulse', NUMBER_REGEXP)}
          postText="/min"
          defaultValue={metric?.bloodPressure?.pulse}
        />
      </div>
    </div>
  );
};

export default UpdateBloodPressure;
