import { Options } from 'components/patient/History/history.types';
import { MEMBERSHIP_PROSPECT, PROSPECT_STATUS_OPTIONS } from 'constants/prospect';

import { ProspectFilterFormTypes } from './prospectFilterForm.types';

export const defaultValues: ProspectFilterFormTypes = {
  patientName: '',
  endDate: '',
  startDate: '',
  planId: { label: '', value: '' },
  appointmentType: { label: '', value: '' },
  email: '',
  state: { label: '', value: '' },
  prospectStatus: { label: '', value: '' },
};

export const getProspectFiltersValue = (
  key: string,
  val: string,
  getAppointmentTypeList: () => Options[],
  licensedInData?: {
    label: string;
    value: string;
  }[],
) => {
  const currentKey = key === 'author.name' ? 'author' : key === 'audience.shortCode' ? 'audience' : key;
  let currentValue;
  switch (currentKey) {
    case 'prospectStatus':
      currentValue = PROSPECT_STATUS_OPTIONS.find((item) => item.value === val);
      break;
    case 'state':
      currentValue = licensedInData?.find((item) => item.label === val);
      break;
    case 'planId':
      currentValue = MEMBERSHIP_PROSPECT.find((item) => item.value === val);
      break;
    case 'appointmentType':
      if (Array.isArray(val)) {
        currentValue = [];
        val.forEach((appointmentType) => {
          currentValue.push(getAppointmentTypeList()?.find((item) => item.value === appointmentType));
        });
      } else {
        currentValue = getAppointmentTypeList()?.find((item) => item.value === val);
      }
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};
