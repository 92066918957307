import { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';

import { DateFormat } from 'enums/dateFormats';
import { BillingHistoryData } from 'store/patients/patients.types';

import { InvoiceModalPros } from './invoiceModal.types';
import RefundForm from '../RefundForm';

const InvoiceModal: React.FC<InvoiceModalPros> = ({ data }) => {
  const [isRefunding, setIsRefunding] = useState(false);
  const [refundResponseData, setRefundResponseData] = useState<BillingHistoryData | null>(null);

  const isRefunded = !!Number(refundResponseData?.refundAmount) || !!Number(data.refundAmount);

  const last4cardDigits = data.payment?.paymentMethod?.maskedCardNumber?.slice(
    data.payment?.paymentMethod?.maskedCardNumber.length - 4,
  );

  const invoiceData = refundResponseData || data;

  const { firstName, lastName } = invoiceData?.customer;
  const { street: streetTo, zip: zipTo, city: cityTo, state: stateTo } = invoiceData?.billingAddress;
  const billingToAddressSeparator = stateTo && (stateTo || cityTo) ? ',' : '';
  const billingToAddress = `${zipTo || ''} ${cityTo || ''}${billingToAddressSeparator} ${stateTo || ''}`;

  const { street: sellerStreet, zip: sellerZip, city: sellerCity, state: sellerState } = invoiceData?.seller?.address;
  const sellerAddressSeparator = sellerState && (sellerState || sellerCity) ? ',' : '';
  const sellerAddress = `${sellerZip || ''} ${sellerCity || ''}${sellerAddressSeparator} ${sellerState || ''}`;

  return (
    <div className="p-6">
      <h1 className="text-xl font-bold">Invoice #{invoiceData?.number}</h1>
      <p className="text-base font-medium text-gray">{invoiceData?.title}</p>

      {isRefunded && (
        <div data-testid="refund-info-alert" className="mt-4 flex items-center rounded-lg bg-blue-100 p-4">
          <Common.Icon name="info" className="mr-2 shrink-0 text-blue-500" />
          <div>
            <p className="text-base font-medium">
              Refunded by {invoiceData?.refund?.refundedBy} at{' '}
              {dayjs(invoiceData?.refund?.refundedAt).format(DateFormat.h_mm_a_z__MMM_D_YYYY)}
            </p>
            {invoiceData.refund?.note && (
              <p className="text-base font-medium">
                <span className="font-bold">Notes: </span>
                {invoiceData.refund?.note}
              </p>
            )}
          </div>
        </div>
      )}

      {isRefunding && (
        <RefundForm
          invoiceId={invoiceData.uid}
          setIsRefunding={setIsRefunding}
          setRefundResponseData={setRefundResponseData}
        />
      )}

      <div className="my-4">
        <div className="mb-2 flex w-full">
          <div className="w-[50%] text-base font-bold">Billing to:</div>
          <div className="text-base font-bold">Billing from:</div>
        </div>
        <div className="flex w-full">
          <div className="w-[50%]">
            <div className="text-base">{`${firstName} ${lastName}`}</div>
            <div className="text-base">{streetTo || ''}</div>
            <div className="text-base">{billingToAddress}</div>
          </div>
          <div>
            <div className="text-base">{invoiceData?.seller?.name}</div>
            <div className="text-base">{sellerStreet ?? ''}</div>
            <div className="text-base">{sellerAddress}</div>
          </div>
        </div>
      </div>

      <h2 className="mb-2 text-base font-bold">Date</h2>
      <p className="text-base">{dayjs(invoiceData.createdAt).format(DateFormat.MMM_D_YYYY_h_mm_a_z)}</p>

      <div className="my-4 flex flex-col gap-4 rounded-xl border border-gray-200 bg-gray-50 p-4">
        <p className="flex justify-between text-base md:font-bold">
          <span>{invoiceData.title}</span>
          <span className={classNames({ ' text-gray line-through': isRefunded })}>${invoiceData.amount}</span>
        </p>
        {isRefunded ? (
          <>
            {!!invoiceData.discount?.discountAmount && (
              <p className="flex justify-between text-base font-bold text-gray">
                <span>Discount</span>
                <span className="line-through">-${invoiceData.discount?.discountAmount}</span>
              </p>
            )}
            {!!invoiceData.credit && (
              <p className="flex justify-between text-base text-gray">
                <span>LifeMD credit</span>
                <span className="text-gray line-through">-${Number(invoiceData.credit.appliedAmount).toFixed(2)}</span>
              </p>
            )}
            <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
              <p className="flex justify-between text-base font-bold">
                <span>Total paid</span>
                <span className="text-gray line-through">${invoiceData.paidAmount}</span>
              </p>
            </div>
            <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
              <p className="flex justify-between text-base font-bold">
                <span>Total refunded</span>
                <span className="text-green">+${invoiceData.refundAmount}</span>
              </p>
              <p className="text-sm text-gray">
                Refunded to {capitalize(invoiceData.payment?.paymentMethod?.cardBrand)} – {last4cardDigits}
              </p>
            </div>
          </>
        ) : (
          <>
            {invoiceData.discount?.discountAmount && (
              <p className="flex justify-between text-base text-orange md:font-bold">
                <span className="text-orange">Discount</span>
                <span>-${invoiceData.discount?.discountAmount}</span>
              </p>
            )}
            {!!invoiceData.credit && (
              <p className="flex justify-between text-base text-gray">
                <span>LifeMD credit</span>
                <span>-${invoiceData.credit.appliedAmount}</span>
              </p>
            )}
            <p className="flex justify-between text-base font-bold">
              <span>Total due</span>
              <span>${invoiceData.paidAmount}</span>
            </p>
            <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
              <p className="flex justify-between text-base font-bold">
                <span>Amount paid</span>
                <span>${invoiceData.paidAmount}</span>
              </p>
              {invoiceData.payment?.paymentMethod?.cardBrand && (
                <p className="text-sm text-gray">
                  Payment from {capitalize(invoiceData.payment?.paymentMethod?.cardBrand)} – {last4cardDigits}
                </p>
              )}
            </div>
          </>
        )}
      </div>

      <div className="flex justify-end gap-2">
        <Common.Button
          color="white-alt"
          size="sm"
          disabled={isRefunded || isRefunding || !data.refundable}
          onClick={() => setIsRefunding(true)}
        >
          Refund invoice
        </Common.Button>
        {invoiceData?.invoiceUrl && (
          <Common.Button
            color="blue"
            size="sm"
            onClick={() => window.open(invoiceData.invoiceUrl, '_blank')}
            preIcon="download"
          >
            Download
          </Common.Button>
        )}
      </div>
    </div>
  );
};

export default InvoiceModal;
