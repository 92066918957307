import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Rating } from 'react-simple-star-rating';

import { CustomerFeedbackProblemType } from 'enums/customerFeedback';
import { DateFormat } from 'enums/dateFormats';

import { CustomerFeedbackTableProps } from './customerFeedbackTable.types';
import Header from '../Header';
const columnHelper = createColumnHelper<CustomerFeedbackTableProps>();

export const columns = [
  columnHelper.accessor('patientInfo', {
    header: () => <Header text="Name" sortField="patientName" />,
    id: 'patientName',
    size: 300,
    cell: (info) => (
      <span className="max-w-[300px] font-medium">
        {info.row.original.patientInfo?.name}
        {info.row.original.patientInfo?.dob ? `, ${dayjs().diff(info.row.original.patientInfo.dob, 'year')}` : null}
      </span>
    ),
  }),
  columnHelper.accessor('rating', {
    header: () => <Header text="Rating" sortField="rating" />,
    cell: (info) => (
      <div>
        <Rating
          initialValue={Number(info.getValue()) || 0}
          readonly={true}
          SVGclassName="inline-block"
          size={16}
          SVGstorkeWidth="1"
          fillColor="#FFA700"
          SVGstrokeColor="#FFA700"
          emptyColor="transparent"
        />
      </div>
    ),
  }),
  columnHelper.accessor('createdAt', {
    header: () => <Header text="Date" sortField="createdAt" />,
    cell: (info) => (dayjs(info.getValue()).isToday() ? 'Today' : dayjs(info.getValue()).format(DateFormat.MMM_D_YYYY)),
  }),
  columnHelper.accessor('providerInfo', {
    id: 'providerName',
    header: () => <Header text="Provider" sortField="providerName" />,
    cell: (info) => <p className="truncate text-sm font-medium">{info.row.original.providerInfo?.name}</p>,
  }),
  columnHelper.accessor('problemType', {
    header: () => <Header text="Problem" sortField="problemType" />,
    cell: (info) => {
      const value = info.getValue();
      const problemType =
        value === CustomerFeedbackProblemType.MyProvider
          ? 'My provider'
          : value === CustomerFeedbackProblemType.UsingApp
            ? 'Using the app'
            : '--';
      return <p className="truncate text-sm font-medium">{problemType}</p>;
    },
  }),
  columnHelper.accessor('reviewComment', {
    header: () => <Header text="Review content" sortField="reviewComment" noSort />,
    cell: (info) => {
      const reviewComment = info.getValue() || '--';
      return <p className="truncate text-sm font-medium">{reviewComment}</p>;
    },
    size: 250,
  }),
];
