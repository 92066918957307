import dayjs from 'dayjs';

import { EventType, EventTypes, TimeZoneOptions } from 'models/event.types';
import { EventDetailsProps } from 'store/calendar/calendar.types';

export const getEventTypeName = (type?: EventType) => {
  switch (type) {
    case EventTypes.TIME_OFF:
      return 'day off';
    default:
      return type ?? 'shift';
  }
};

export const getEventTime = (event: Partial<EventDetailsProps>, calendarTimezone: string, isUTCTime?: boolean) => {
  const startTime = dayjs
    .tz(event.start?.dateTime, isUTCTime ? TimeZoneOptions.UTC : event.start?.timeZone)
    .tz(calendarTimezone);
  const endTime = dayjs
    .tz(event.end?.dateTime, isUTCTime ? TimeZoneOptions.UTC : event.end?.timeZone)
    .tz(calendarTimezone);
  const isSameDay = startTime.isSame(endTime, 'day');

  return { startTime, endTime, isSameDay };
};
