import { Common } from '@thecvlb/design-system/lib/src';
import { useSearchParams } from 'react-router-dom';

import Alert from 'components/common/Alert';
import { HardBlockMessage } from 'enums/patientBillingStatus';
import { PatientProps } from 'store/patients/patients.types';

import { getRenewalStatusMessage } from './banners.settings';

const Banners = ({ patient, isOnTask = false }: { patient: PatientProps; isOnTask?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showRemoveBillingBlockAlert = patient?.paymentBlockInfo?.hardBlockedAt && !isOnTask;
  const showRenewalStatusAlert = patient?.renewalDetails?.status;

  const taskId = patient?.renewalDetails?.taskId;

  const openAppointmentModal = () => {
    if (!taskId) return;

    searchParams.set('taskModalOpenID', taskId);
    setSearchParams(searchParams);
  };

  const renewalStatus =
    !!patient?.renewalDetails?.status &&
    getRenewalStatusMessage(patient?.renewalDetails?.status, patient?.renewalDetails?.dueDate);

  const isAppointmentAlreadyOpened =
    searchParams.has('taskModalOpenID') && searchParams.get('taskModalOpenID') === taskId;

  return (
    <div className="m-7">
      {showRemoveBillingBlockAlert && (
        <Alert
          type="warning"
          containerClasses="bg-yellow-100"
          children={
            <span data-testid="please_view_msg" className="text-base text-gray-700">
              {HardBlockMessage.warning}
            </span>
          }
        />
      )}
      {showRenewalStatusAlert && (
        <Alert
          type="info"
          children={
            <div className="flex flex-col">
              <span data-testid="renewal_status_message" className="text-base text-gray-700">
                <span className="font-bold">Renewal:</span> {renewalStatus}
              </span>
              {!!taskId && !isAppointmentAlreadyOpened && (
                <Common.Button size="sm" color="white-alt" className="mt-2" onClick={openAppointmentModal}>
                  View appointment
                </Common.Button>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export default Banners;
