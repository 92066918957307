import { MouseEventHandler } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

import Loader from 'components/common/Loader';
import FileLabel from 'components/tasks/slidingPane/IdentitySection/FileLabel';
import FileUploader from 'components/tasks/slidingPane/IdentitySection/FileUploader';
import { DocumentSubCategory } from 'enums/patientIdentity';
import { useDeleteDocumentMutation, useUploadDocumentsMutation } from 'store/patients/patientsSlice';

import { IdentityFileCategories, IdentityFileProps } from './identityFile.types';

const IdentityFile: React.FC<IdentityFileProps> = ({
  file,
  type = 'selfPortrait',
  label,
  patientId,
  onOpenGallery,
  onChangeIdentityImg,
  onChangeBodyImg,
  category,
}) => {
  const [uploadDocuments, { isLoading }] = useUploadDocumentsMutation();
  const [deleteDocument, { isLoading: isDeleting }] = useDeleteDocumentMutation();
  const showLoader = isLoading || isDeleting;
  const fileLabel = type == 'selfPortrait' ? 'Photo:' : 'ID:';
  const isBodyImage = category === IdentityFileCategories.BODY_IMAGE;

  const onChangeImg = (newFile: File | null) => {
    if (newFile && !showLoader) {
      const body: FormData = new FormData();
      body.append('patientDocumentFile', newFile);
      body.append('category', category);
      if (category === IdentityFileCategories.USER_IDENTITY) {
        body.append(
          'subCategory',
          type === 'selfPortrait' ? DocumentSubCategory.SelfPortrait : DocumentSubCategory.GovernmentIssuedId,
        );
      }

      uploadDocuments({ body, patientId: patientId, isBodyImage })
        .unwrap()
        .then((data) => {
          if (data.data) {
            const uploadedFile = {
              _id: data.data._id,
              fileName: data.data.fileName,
              filePath: data.data.filePath,
            };
            if (isBodyImage) {
              onChangeBodyImg?.(uploadedFile, data.data._id);
            } else {
              onChangeIdentityImg?.({ [type]: uploadedFile });
            }
          }
        });
    }
  };

  const handleRemoveFile: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();

    if (file?._id && !showLoader)
      deleteDocument({ documentId: file._id, patientId, isBodyImage })
        .unwrap()
        .then(() => {
          if (isBodyImage) {
            onChangeBodyImg?.(null, file._id);
          } else {
            onChangeIdentityImg?.({ [type]: null });
          }
        });
  };

  return (
    <>
      {showLoader && <Loader isVisible />}
      {file?.filePath ? (
        <div className="flex w-32 flex-col gap-2">
          <div className="relative">
            <img
              className="w-32 cursor-pointer rounded-xl"
              src={file.filePath}
              onClick={onOpenGallery}
              alt={file.fileName}
            />
            <div onClick={handleRemoveFile}>
              <Common.Icon
                name="close"
                className="absolute right-2 top-2 h-6 w-6 cursor-pointer rounded-full bg-black/60 p-1 text-white"
              />
            </div>
          </div>

          {!isBodyImage && <FileLabel fileData={{ ...file, label: fileLabel }} />}
        </div>
      ) : (
        <FileUploader label={label} onChange={onChangeImg} />
      )}
    </>
  );
};

export default IdentityFile;
