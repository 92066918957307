import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { notifySuccess } from 'components/common/Toast/Toast';
import WeightChart from 'components/tasks/slidingPane/WeightChart';
import { HealthMetricTypes } from 'enums/chart';
import { useGetLatestHealthMetricsQuery } from 'store/patientChart/patientChartSlice';
import { useGetChartHealthMetricsQuery, useUpdateGoalWeightMutation } from 'store/patients/patientsSlice';

import BloodPressure from './BloodPressure';
import SideEffects from './SideEffects';
import Weight from './Weight';
import { WeightDetailsProps } from './weightDetails.types';

const WeightDetails: React.FC<WeightDetailsProps> = ({
  weightManagement,
  patientId,
  className,
  patientName,
  weightFromDevice,
  showChartTabs,
  isWMDevices,
}) => {
  const [updateGoalWeight] = useUpdateGoalWeightMutation();
  const { data, isFetching, isUninitialized, refetch } = useGetChartHealthMetricsQuery({
    params: { metricType: HealthMetricTypes.Weight },
    patientId,
  });
  const { data: latestMetrics } = useGetLatestHealthMetricsQuery({ patientId });
  const isLoading = isFetching || isUninitialized;

  const wrapperClassNames = twMerge('my-4', className);

  const updateWeight = (cb: () => void, goalWeight?: number) => {
    if (!goalWeight || !patientId) {
      cb();
      return;
    }
    updateGoalWeight({ id: patientId, goalWeight: +goalWeight })
      .unwrap()
      .then(() => {
        refetch();
        notifySuccess('Target weight updated successfully');
        cb();
      });
  };

  const allWeights = data?.data?.current;
  const currentWeightDate = allWeights?.[allWeights.length - 1]?.date;
  const currentDateDuration = dayjs.duration(dayjs(currentWeightDate).diff(), 'milliseconds').humanize();

  return (
    <div className={wrapperClassNames}>
      {weightManagement && (
        <div className="mb-4 flex w-full flex-col gap-2">
          <SideEffects weightManagement={weightManagement} />
          {weightManagement.otherSideEffects && (
            <div className="flex">
              <p className="w-32 text-base font-bold text-gray-700">Health issues</p>
              <p className="text-base font-medium">{weightManagement.otherSideEffects}</p>
            </div>
          )}
          <Weight
            weightManagement={weightManagement}
            latestMetrics={latestMetrics?.data}
            target={data?.data?.target}
            weights={data?.data?.current}
            isWMDevices={isWMDevices}
            updateGoalWeight={updateWeight}
            patientName={patientName}
          />
          {isWMDevices && <BloodPressure patientId={patientId} latestMetrics={latestMetrics?.data} />}
        </div>
      )}

      {isLoading ? (
        <div className="h-12 w-full animate-pulse bg-slate-200" />
      ) : (
        !!data?.data && (
          <div className="flex gap-2">
            <div className="flex-1">
              <WeightChart
                target={data.data.target}
                current={data.data.current}
                patientName={patientName}
                updateWeight={updateWeight}
                showTabs={showChartTabs}
              />
            </div>
            {showChartTabs && <div className="flex-1"></div>}
          </div>
        )
      )}
      {weightFromDevice && (
        <div className="mt-6 grid grid-cols-2 gap-2 text-center">
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Visceral fat</p>
            <p className="mt-1 text-xl font-bold">
              {weightFromDevice.visceralFat ? `${weightFromDevice.visceralFat} lbs` : '-'}
            </p>
          </div>
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Bone mass</p>
            <p className="mt-1 text-xl font-bold">
              {weightFromDevice.boneMass ? `${weightFromDevice.boneMass} lbs` : '-'}
            </p>
          </div>
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Muscle mass</p>
            <p className="mt-1 text-xl font-bold">{weightFromDevice.muscleMass ?? '-'} lbs</p>
          </div>
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Body fat %</p>
            <p className="mt-1 text-xl font-bold">{weightFromDevice.bodyFat ? `${weightFromDevice.bodyFat} %` : '-'}</p>
          </div>
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Total water %</p>
            <p className="mt-1 text-xl font-bold">
              {weightFromDevice.totalWater ? `${weightFromDevice.totalWater} %` : '-'}
            </p>
          </div>
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Last measured</p>
            <p className="mt-1 text-xl font-bold">{currentDateDuration ? `${currentDateDuration} ago` : ''}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeightDetails;
