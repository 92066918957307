import { Common } from '@thecvlb/design-system/lib/src';
import { useForm } from 'react-hook-form';

import { notifySuccess } from 'components/common/Toast/Toast';
import { HardBlockMessage } from 'enums/patientBillingStatus';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useRemoveBillingBlockMutation } from 'store/tasks/tasksSlice';

const RemoveBillingBlock: React.FC<{ patientId: string; hardBlockedPaymentsCount: number | undefined }> = ({
  patientId,
  hardBlockedPaymentsCount,
}) => {
  const isHardBlockedFirstTime = hardBlockedPaymentsCount === 1;
  const dispatch = useAppDispatch();
  const { handleSubmit } = useForm();

  const [removeBillingBlock, { isLoading }] = useRemoveBillingBlockMutation();

  const cancelRemoveBillingBlock = () => dispatch(closeModal());

  const handleRemoveBillingBlock = () => {
    if (patientId) {
      removeBillingBlock(patientId)
        .unwrap()
        .then((res) => {
          dispatch(closeModal());
          notifySuccess(res?.message);
        });
    }
  };

  const replaceCount = () =>
    !!hardBlockedPaymentsCount
      ? HardBlockMessage.manyTimesHardBlockedTitle.replace(/{count}/g, hardBlockedPaymentsCount.toString())
      : HardBlockMessage.blockedFewTimesTitle;

  const renderHardBlockContent = () => (
    <div>
      <p className="text-base font-medium">
        {isHardBlockedFirstTime ? HardBlockMessage.firstTimeHardBlockedTitle : replaceCount()}
      </p>
      <div data-testid="refund-info-alert" className="mt-6 flex items-center rounded-lg bg-blue-100 p-4">
        <Common.Icon name="info" className="mr-2 shrink-0 text-blue-500" />
        <div>
          <p className="text-base font-medium">{HardBlockMessage.info}</p>
        </div>
      </div>
      <div>
        {!isHardBlockedFirstTime && (
          <p className="mt-6 text-base font-medium">{HardBlockMessage.manyTimesHardBlockedMessage}</p>
        )}
      </div>
    </div>
  );

  return (
    <div className="p-6">
      <h2 className="mb-3 text-xl font-bold text-gray-700">Remove billing block</h2>

      <form onSubmit={handleSubmit(handleRemoveBillingBlock)} className="flex flex-col gap-4">
        {renderHardBlockContent()}
        <div className="mt-2 grid grid-cols-6 gap-x-2">
          <Common.Button
            type="button"
            color="white-alt"
            className="col-start-5 !w-full justify-center"
            onClick={cancelRemoveBillingBlock}
            size="sm"
          >
            Close
          </Common.Button>
          <Common.Button
            className="!w-full justify-center bg-red-500 text-white"
            type="submit"
            size="sm"
            disabled={isLoading}
          >
            Remove
          </Common.Button>
        </div>
      </form>
    </div>
  );
};

export default RemoveBillingBlock;
