import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { DateFormat } from 'enums/dateFormats';
import { Role } from 'enums/role';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useGetSubscriptionDetailsQuery, usePauseSubscriptionMutation } from 'store/patients/patientsSlice';
import { selectUser } from 'store/user/userSlice';

import { choosePauseTabs, choosePauseTabsAdmin } from './pauseSubscription.settings';
import { PauseSubscriptionProps } from './pauseSubscription.types';

const PauseSubscription: React.FC<PauseSubscriptionProps> = ({ patientInfo, currentPlan, activePP }) => {
  const dispatch = useAppDispatch();
  const [pauseSubscription, { isLoading: isLoadingPauseSubscription }] = usePauseSubscriptionMutation();
  const { data: subscriptionDetails, isFetching } = useGetSubscriptionDetailsQuery({
    patientId: patientInfo?._id || '',
  });
  const { userType } = useAppSelector(selectUser);
  const isAdmin = userType?.name === Role.AD;
  const methods = useForm();
  const [currentTab, setCurrentTab] = useState([choosePauseTabs[0].label]);
  const [pauseDuration, setPauseDuration] = useState<number>(choosePauseTabs[0].value as number);
  const subscriptionPaused = subscriptionDetails?.state === 'on_hold';
  const showLoading = isLoadingPauseSubscription;
  const pauseTabs = isAdmin ? [...choosePauseTabs, ...choosePauseTabsAdmin] : choosePauseTabs;

  useEffect(() => {
    setPauseDuration(choosePauseTabs[0].value as number);
  }, []);

  const handleBack = () => {
    dispatch(closeModal());
  };

  const handlePauseAccount = async () => {
    if (!patientInfo) return;
    const patientId = patientInfo._id;

    await pauseSubscription({
      patientId: patientId,
      pauseDuration: pauseDuration,
    })
      .unwrap()
      .then((response) => {
        dispatch(closeModal());
        notifySuccess(response.message);
      })
      .catch(() => {
        dispatch(closeModal());
      });
  };

  const handleClickTab = (button: Common.DataItemProps) => {
    setCurrentTab([button.label]);
    const pauseLength = button.value as number;
    setPauseDuration(pauseLength);
  };

  const conditionalPausedText = () => {
    if (subscriptionPaused) {
      return (
        <>
          <br />
          <br />
          Patient's plan is already paused
        </>
      );
    } else if (isAdmin) {
      return;
    } else {
      return (
        <>
          <br />
          <br />
          To request a longer hold, please reach out to a supervisor
        </>
      );
    }
  };

  return (
    <FormProvider {...methods}>
      {isFetching && <Loader isVisible />}
      <form className="p-6" onSubmit={methods.handleSubmit(handlePauseAccount)}>
        <h2 className="mb-2 text-xl font-bold">Pause plan</h2>
        <p className="mb-6 text-base">
          {currentPlan?.planName}, ${activePP?.totalCost} billed every {activePP?.paymentInterval.qty}{' '}
          {activePP?.paymentInterval.units}
        </p>
        <Common.Alert type="plain" colorableBackground className="mb-6 md:text-sm">
          Before pausing, please check if the patient has any prescriptions on hold or is due for renewal soon
          <br />
          <br />
          Pausing a patient's subscription effectively places the subscription "on hold" in Chargify
          {conditionalPausedText()}
        </Common.Alert>
        {subscriptionDetails && !subscriptionPaused && (
          <div>
            <h3 className="mb-3 text-base font-semibold">Background</h3>
            <div className="mb-6 grid grid-cols-2 gap-x-6 gap-y-2">
              <div className="flex">
                <p className="mr-5 w-24 flex-none text-base text-gray">Joined LifeMD</p>
                <p className="text-base">{dayjs(subscriptionDetails.createdAt).format(DateFormat.MMM_D_YYYY)}</p>
              </div>
              <div className="flex">
                <p className="mr-5 w-24 flex-none text-base text-gray">Total billings</p>
                <p className="text-base">${subscriptionDetails.totalRevenue}</p>
              </div>
              <div className="flex">
                <p className="mr-5 w-24 flex-none text-base text-gray">Last Billed</p>
                <p className="text-base">
                  {dayjs(subscriptionDetails.currentPeriodStartedAt).format(DateFormat.MMM_D_YYYY)}
                </p>
              </div>
            </div>
            <h3 className="mb-3 text-base font-semibold">Pause</h3>
            <Common.Tabs
              onChange={handleClickTab}
              data={pauseTabs}
              type="bar"
              defaultSelected={currentTab}
              className="mb-3 w-full rounded-3xl border border-gray-100 p-1 shadow"
              buttonsFullWidth
              lessPadding
            />
          </div>
        )}
        {subscriptionDetails && (
          <div className="flex flex-col gap-3 rounded-lg border border-gray-200 bg-gray-50 p-4">
            {!subscriptionPaused && (
              <>
                <p className="flex justify-between">
                  <span className="text-base font-semibold">Current renewal date</span>
                  <span className="font-semibold text-gray">
                    {dayjs(subscriptionDetails.currentPeriodEndsAt).format(DateFormat.MMM_D_YYYY)}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="text-base font-semibold">New renewal date</span>
                  <span className="font-semibold text-gray">
                    {dayjs(subscriptionDetails.currentPeriodEndsAt)
                      .add(pauseDuration, 'd')
                      .format(DateFormat.MMM_D_YYYY)}
                  </span>
                </p>
              </>
            )}
            {subscriptionDetails.automaticallyResumeAt && (
              <p className="flex justify-between">
                <span className="text-base font-semibold">Their plan will automatically renew on</span>
                <span className="font-semibold text-gray">
                  {dayjs(subscriptionDetails.automaticallyResumeAt).format(DateFormat.MMM_D_YYYY)}
                </span>
              </p>
            )}
          </div>
        )}
        <div className="mt-6 flex justify-end gap-2">
          <Common.Button color="white-alt" onClick={handleBack} size="sm" type="button">
            {subscriptionPaused ? 'Close' : 'Cancel'}
          </Common.Button>
          {!subscriptionPaused && (
            <Common.Button color={'blue'} size="sm" isLoading={showLoading} disabled={isFetching || subscriptionPaused}>
              Confirm
            </Common.Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default PauseSubscription;
