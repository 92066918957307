import { Common } from '@thecvlb/design-system';

import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';

import { ModalProps } from './modal.types';

const Modal: React.FC<ModalProps> = ({ children, size, isOpen, padding, hideClose, drag }) => {
  const dispatch = useAppDispatch();
  return (
    <Common.Modal
      isOpen={isOpen}
      {...(!hideClose && { close: () => dispatch(closeModal()) })}
      size={size}
      padding={padding}
      autoScroll={false}
      zIndex={110}
      drag={drag}
    >
      {children}
    </Common.Modal>
  );
};

export default Modal;
