import { Common } from '@thecvlb/design-system';

import { ManageMessageDropdownProps } from './manageMessageDropdown.types';

const ManageMessageDropdown: React.FC<ManageMessageDropdownProps> = ({ onDelete, onEdit }) => (
  <div className="absolute right-0 z-10 -mr-6 mt-1 w-40 rounded-lg border bg-white shadow-lg">
    <ul className="py-2">
      <li className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-gray-100" onClick={onEdit}>
        <span>Edit</span>
        <Common.Icon name="pencil" className="h-5 w-5" />
      </li>
      <li
        className="flex cursor-pointer items-center justify-between px-4 py-2 text-red-500 hover:bg-gray-100"
        onClick={onDelete}
      >
        <span>Delete</span>
        <Common.Icon name="trash" className="h-5 w-5" />
      </li>
    </ul>
  </div>
);

export default ManageMessageDropdown;
