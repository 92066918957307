import { Common } from '@thecvlb/design-system/lib/src';

import { formatPaymentMethod } from './PaymentIcon.settings';
import { PaymentIconProps } from './paymentIcon.types';

const PaymentIcon: React.FC<PaymentIconProps> = ({ cardNumber, cardType }) => {
  const parseIcon = (number?: string, type?: string) => {
    if (number || type) {
      const icon = formatPaymentMethod(number, type);
      switch (icon) {
        case 'visa':
          return <Common.Icon className="h-full w-full" name="visa" />;
        case 'master_card':
          return <Common.Icon className="h-full w-full" name="mastercard" />;
        case 'american_express':
          return <Common.Icon className="h-full w-full" name="amex" />;
        case 'discover':
          return <Common.Icon className="h-full w-full" name="discover" />;
        default:
          return null;
      }
    }
  };

  return <>{parseIcon(cardNumber, cardType)}</>;
};

export default PaymentIcon;
