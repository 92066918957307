import { Common } from '@thecvlb/design-system/lib/src';
import upperFirst from 'lodash/upperFirst';

import { WeightManagement } from 'models/tasks.types';

const SideEffects = ({ weightManagement }: { weightManagement: WeightManagement }) => {
  return (
    <div className="grid grid-cols-6 gap-2 text-left">
      <h3 className="col-span-1 truncate text-base font-bold text-gray-700">Side effects</h3>
      <div className="relative col-span-5">
        <div data-testid="side_effects" className="flex flex-col gap-2 pr-2">
          {weightManagement.sideEffects?.length ? (
            weightManagement.sideEffects.map(({ id, severity }) => (
              <Common.ColorTag
                color={severity === 'moderate-severe' ? 'red' : 'yellow'}
                text={`${upperFirst(id)} • ${severity === 'moderate-severe' ? 'Moderate/severe' : 'Mild'}`}
                key={id}
              />
            ))
          ) : (
            <Common.ColorTag color="green" text="None" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SideEffects;
