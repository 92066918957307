import { useCallback } from 'react';

import { createSelector } from '@reduxjs/toolkit';

import { MessageType } from 'enums/messages';
import { closeAlert, selectSlidingInPane } from 'store/slidingInPane/slidingInPaneSlice';

import { useAppDispatch, useAppSelector } from '../../redux';

const selectMedicalAlertState = createSelector(selectSlidingInPane, (slidingInPane) => ({
  isMedicalAlert: slidingInPane.alertsState[MessageType.Medical],
  isSupportAlert: slidingInPane.alertsState[MessageType.Support],
  isSMSAlert: slidingInPane.alertsState[MessageType.SMS],
}));

export const useAlert = ({
  type,
  isAvailableChannel,
  isLoading,
}: {
  type: MessageType;
  isLoading?: boolean;
  isAvailableChannel?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const { isMedicalAlert, isSupportAlert, isSMSAlert } = useAppSelector(selectMedicalAlertState);

  const showMedicalAlert = type === 'medical' && isMedicalAlert && isAvailableChannel;
  const showSupportAlert = type === 'support' && isSupportAlert && isAvailableChannel;
  const showAddChannelAlert = !isLoading && type === 'support' && isSupportAlert && !isAvailableChannel;

  const alertMessage = ` Your patient will see what you write here, in their ${
    type === 'support' ? '“Front desk”' : '“Care team”'
  } messages.`;

  const handleClose = useCallback(() => dispatch(closeAlert({ type })), [dispatch, type]);

  return { handleClose, alertMessage, showMedicalAlert, showSupportAlert, showAddChannelAlert, isSMSAlert };
};
