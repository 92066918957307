import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import {
  Control,
  FormState,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';

import { ObjectValues } from 'utils/common/types';

export const TemplateTypes = {
  internal: 'Internal note',
  patient: 'Patient messages',
  request: 'Request messages',
} as const;

export type TemplateType = ObjectValues<typeof TemplateTypes>;

export interface MessageTemplatesFormProps {
  type: TemplateType;
  popupHeaderTitle: string;
  id?: string;
  disabledButtons?: boolean;
  onDeleteTemplate: () => void;
  onFormSubmit: SubmitHandler<FormDataTemplates>;
  handleSubmit: UseFormHandleSubmit<FormDataTemplates>;
  getValues: UseFormGetValues<FormDataTemplates>;
  setValue: UseFormSetValue<FormDataTemplates>;
  control: Control<FormDataTemplates, object>;
  formState: FormState<FormDataTemplates>;
  variableOptions: OptionProps[];
}

export interface MessageTemplatesProps {
  id?: string;
}

export interface FormDataTemplates {
  message: string;
  shortCode: string;
  status: null | OptionProps;
  variables?: null | OptionProps;
}
