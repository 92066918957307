import React from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { setCardColor } from 'utils/setCardColor';

import { DashboardCardProps } from './dashboardCard.types';

export const DashboardCard: React.FC<DashboardCardProps> = ({ title, text, color, isInfoRequired, isFetching }) => {
  const divClassName = classNames(
    'flex-col gap-4 shadow md:h-[144px] rounded-xl w-full',
    !isFetching && setCardColor(color),
  );
  const alignClassName = 'flex items-center text-center justify-center align-center pb-6';
  const hClassName = classNames(
    'flex my-5 justify-center items-center text-2xl font-bold',
    { 'text-white': color === 'primary' || color === 'secondary-dark' },
    { 'text-primary': color === 'secondary-light' || color === 'blue' },
  );
  const tClassName = 'flex-1 text-center text-base font-bold';
  const pClassName = 'flex-1 text-center text-xl font-bold';

  return (
    <React.Fragment>
      {isFetching ? (
        <Skeleton height={'138px'} className="rounded-xl" containerClassName="flex-col gap-4 w-full" />
      ) : (
        <div className={divClassName}>
          <div className={hClassName}>
            <h1 className="pr-1 text-xl">{title}</h1>
            {isInfoRequired ? <Common.Icon name="info" /> : null}
          </div>
          <div className={alignClassName}>
            {text?.map((data, index) => (
              <div
                className={`flex-1 ${index === text.length - 1 ? 'border-none' : 'border-r border-gray-700'}`}
                key={data.key}
              >
                <p className={tClassName}>{data.key}</p>
                <h2 className={pClassName}>
                  {typeof data.value === 'number' && !Number.isInteger(data.value) ? data.value.toFixed(2) : data.value}
                </h2>
              </div>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
