export enum MarkCompleteReasons {
  Prescribed = 'prescribed',
  AdditionalInfoRequired = 'additional-info-required',
  NoRx = 'no-rx',
  RequiresAppointment = 'requires-appointment',
  SyncAppointmentHappenedOnPlatform = 'syncAppointmentHappenedOnPlatform',
  AppointmentHappenedOffPlatform = 'appointmentHappenedOffPlatform',
  PatientNoShow = 'patientNoShow',
}

export enum SnoozeDuration {
  OneWeek = 'one-week',
  TwoWeeks = 'two-weeks',
  ThreeWeeks = 'three-weeks',
  FourWeeks = 'four-weeks',
  SixWeeks = 'six-weeks',
  EightWeeks = 'eight-weeks',
  NoReminder = 'no-reminder',
}

export enum NotPrescribingReason {
  TooEarlyToPrescribe = 'too-early-to-prescribe',
  WaitingForLabs = 'waiting-for-labs',
  NotClinicallyAppropriate = 'not-clinically-appropriate',
  AlreadyFilled = 'already-filled',
  RecommendingMaintenancePlan = 'recommending-maintenance-plan',
}
