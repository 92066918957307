import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { compare, validate } from 'compare-versions';

import { useLazyGetMinAppVersionQuery } from 'store/versions/versionsSlice';

import { version } from '../../../../package.json';

const Banner = () => {
  const [getAppVersion, { data }] = useLazyGetMinAppVersionQuery({ pollingInterval: 600000 }); // run query every 10 minutes
  const showBanner = (newVersion: string) => {
    if (validate(newVersion) && compare(newVersion, version, '>')) return true;
    return false;
  };

  const handleUpdate = () => {
    window.location.reload();
  };

  const bannerClasses = classNames('flex w-full flex-col items-center justify-center gap-y-2 bg-yellow-100 py-4', {
    hidden: !showBanner(data?.physician.min || ''),
  });

  useEffect(() => {
    getAppVersion();
  }, [getAppVersion]);

  return (
    <div className={bannerClasses}>
      <div className="flex gap-1">
        <Common.Icon name="repeat" className="h-5 w-5 text-yellow" />
        <span className="text-base font-bold text-gray-700">LifeMD update required</span>
      </div>
      <p className="text-base text-gray-700">
        To ensure smooth functionality and access to new features, please update LifeMD.
      </p>
      <Common.Button size="sm" color="white-alt" onClick={handleUpdate}>
        Update LifeMD
      </Common.Button>
    </div>
  );
};

export default Banner;
