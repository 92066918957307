import { useEffect, useState } from 'react';

import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { useFormContext } from 'react-hook-form';

import Loader from 'components/common/Loader';
import ControlledCombobox from 'components/forms/controlled/ControlledCombobox';
import { LENGTH_OPTIONS, LENGTH_OPTIONS_WITH_DEFAULT } from 'constants/appointmentType';
import { useGetAppointmentLengthsQuery } from 'store/appointmentLength/appointmentLengthSlice';
import { LengthByPlan } from 'store/appointments/appointments.types';

import { getDefaultLengthsData, returnLengthOption } from './appointmentTypeLengths.settings';
import {
  AppointmentTypeLengthsFormProps,
  AppointmentTypeLengthsProps,
  LengthsItem,
  LengthsProps,
} from './appointmentTypeLengths.types';
import CustomLabel from './CustomLabel';
import { handleRequired } from '../appointmentType.settings';

const AppointmentTypeLengths: React.FC<AppointmentTypeLengthsProps> = ({ isEditDefault }) => {
  const { data: appointmentLengthsData, isLoading, isFetching } = useGetAppointmentLengthsQuery();
  const [appointmentLengths, setAppointmentLengths] = useState<LengthsProps>({
    initial: [],
    subsequent: [],
  });
  const { control, resetField, formState, watch } = useFormContext<AppointmentTypeLengthsFormProps>();
  const lengthOptions = isEditDefault ? LENGTH_OPTIONS : LENGTH_OPTIONS_WITH_DEFAULT;

  const onChangeLengths = (value: Option, field: 'initial' | 'subsequent', index: number) => {
    if (appointmentLengthsData) {
      const lengths: LengthByPlan[] = watch('lengthByPlan')?.length
        ? watch('lengthByPlan')
        : getDefaultLengthsData(appointmentLengthsData, isEditDefault);

      const isDefault = lengths[index].appointmentLengths[field].isDefault;
      lengths[index].appointmentLengths[field].length = Number(value.value);

      if (isDefault) {
        const length = lengths[index].appointmentLengths[field].length;
        lengths[index].appointmentLengths[field].isDefault = !length;
      }

      resetField('lengthByPlan', { defaultValue: lengths });
    }
  };

  useEffect(() => {
    if (appointmentLengthsData && !isFetching) {
      const lengths: LengthByPlan[] = watch('lengthByPlan')?.length
        ? watch('lengthByPlan')
        : getDefaultLengthsData(appointmentLengthsData, isEditDefault);

      const initial: LengthsItem[] = [];
      const subsequent: LengthsItem[] = [];

      appointmentLengthsData.forEach((length, index) => {
        const newInitialItem: LengthsItem = {
          planName: length.planName,
          label: length.appointmentLengths.initial.label.replace(/\s*\([^)]*\)/, ''),
          length: length.appointmentLengths.initial.length,
        };

        const newSubsequentItem: LengthsItem = {
          planName: length.planName,
          label: length.appointmentLengths.subsequent.label.replace(/\s*\([^)]*\)/, ''),
          length: length.appointmentLengths.subsequent.length,
        };

        initial.push(newInitialItem);
        subsequent.push(newSubsequentItem);

        resetField(`length.initial${index}`, {
          defaultValue: returnLengthOption(lengths, index, 'initial', isEditDefault),
          keepDirty: true,
        });
        resetField(`length.subsequent${index}`, {
          defaultValue: returnLengthOption(lengths, index, 'subsequent', isEditDefault),
          keepDirty: true,
        });
      });

      !watch('lengthByPlan')?.length && !isEditDefault && resetField('lengthByPlan', { defaultValue: lengths });
      setAppointmentLengths({ initial, subsequent });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentLengthsData, isEditDefault, isFetching, resetField, watch('lengthByPlan')]);

  const comboBoxWrapperClasses = '!items-center';

  return (
    <div className="flex flex-col gap-4">
      <Loader isVisible={isLoading || isFetching} />
      {appointmentLengths.initial.map((initialLength, index) => (
        <ControlledCombobox
          labelDirection="row"
          defaultValue={lengthOptions[0]}
          key={`length.initial${index}`}
          control={control}
          name={`length.initial${index}`}
          options={lengthOptions}
          label={<CustomLabel label={initialLength.label} planName={initialLength.planName} />}
          size="sm"
          errors={formState.errors?.length?.[`initial${index}`]}
          helperText={formState.errors?.length?.[`initial${index}`]?.message?.toString()}
          onChange={(value) => {
            onChangeLengths(value, 'initial', index);
          }}
          rules={{
            validate: {
              required: (value) => handleRequired(value, `${initialLength.label} is required`),
            },
          }}
          wrapperClassName={comboBoxWrapperClasses}
        />
      ))}

      {appointmentLengths.subsequent.map((subsequentLength, index) => (
        <ControlledCombobox
          key={`length.subsequent${index}`}
          control={control}
          labelDirection="row"
          defaultValue={lengthOptions[0]}
          options={lengthOptions}
          label={<CustomLabel label={subsequentLength.label} planName={subsequentLength.planName} />}
          size="sm"
          name={`length.subsequent${index}`}
          errors={formState.errors?.length?.[`subsequent${index}`]}
          helperText={formState.errors?.length?.[`subsequent${index}`]?.message?.toString()}
          rules={{
            validate: {
              required: (value) => handleRequired(value, `${subsequentLength.label} is required`),
            },
          }}
          onChange={(value) => {
            onChangeLengths(value, 'subsequent', index);
          }}
          wrapperClassName={comboBoxWrapperClasses}
        />
      ))}
    </div>
  );
};

export default AppointmentTypeLengths;
