export type SuggestionFlowType = 'PH' | 'other' | 'patientSummary';

export type SuggestedMessageRequest = {
  body: {
    flow: SuggestionFlowType;
    userId: string;
    text: string;
  };
  signal?: AbortSignal;
  force?: boolean; // force request withiut cache
};

export const RequestStatus = {
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
} as const;

export type RequestStatusType = (typeof RequestStatus)[keyof typeof RequestStatus];
