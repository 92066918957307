import { Role, RoleShortName } from 'enums/role';
import { TaskCategories } from 'enums/taskCategories';

export const EDIT_POPUP_PERMISSION = {
  timeZoneChange: [RoleShortName.Physician, RoleShortName.MasterPhysician, RoleShortName.NursePractitioner],
  biographyAndEducationSection: [
    RoleShortName.Physician,
    RoleShortName.MasterPhysician,
    RoleShortName.NursePractitioner,
  ],
  specializationField: [RoleShortName.Physician, RoleShortName.MasterPhysician],
  shortBio: [RoleShortName.Physician, RoleShortName.MasterPhysician],
  isShowAppointmentNotify: [RoleShortName.Physician, RoleShortName.MasterPhysician],
};

export const TASK_ACCORDING_TO_CATEGORY = {
  canTakeOver: [TaskCategories.Review, TaskCategories.PreConsult, TaskCategories.Onboarding],
  linkToPreConsult: [TaskCategories.Appointment, TaskCategories.PostConsult],
  IntakeItems: [TaskCategories.PreConsult, TaskCategories.Onboarding],
  RenewalBanner: [TaskCategories.Renewal],
  BasicInfoSection: [TaskCategories.Onboarding, TaskCategories.PreConsult],
  sendTaskToDoctor: [TaskCategories.Review],
  complexAndSimpleVisit: [TaskCategories.PostConsult],
  staffNotesCheckboxes: [TaskCategories.Review, TaskCategories.FrontDesk, TaskCategories.Requests],
  startAndCancelAppointment: [
    TaskCategories.Appointment,
    TaskCategories.Queue,
    TaskCategories.PreConsult,
    TaskCategories.Onboarding,
  ],
  showAssignProviderBtn: [TaskCategories.Onboarding, TaskCategories.PreConsult, TaskCategories.Appointment],
  onboardingDetailsStatuses: [
    TaskCategories.Onboarding,
    TaskCategories.PreConsult,
    TaskCategories.Appointment,
    TaskCategories.Queue,
    TaskCategories.Renewal,
    TaskCategories.PostConsult,
    TaskCategories.Async,
  ],
};

export const HAS_PERMISSION_TO_SENT_MESSAGES = [Role.MA, Role.CS, Role.PH, Role.NP];

export const TASK_POPUP_PERMISSIONS = {
  appointment: [Role.MA, Role.PH, Role.NP, Role.AD],
  request: [Role.MA, Role.CS, Role.NP, Role.AD],
  pharmacyInfo: [Role.MA, Role.PH, Role.NP],
  sendMessage: HAS_PERMISSION_TO_SENT_MESSAGES,
  sendStaffNote: [Role.MA, Role.CS, Role.PH, Role.NP, Role.AD],
  intakeItems: [Role.MA, Role.PH, Role.NP],
  sendTaskToDoctor: [Role.MA],
  staffNotesCheckboxes: [Role.PH],
};

export const MY_PROFILE = {
  review: [Role.PH],
};

export const HAS_PERMISSION_TO_STOP_RECEIVING_TASKS = [Role.PH, Role.MD, Role.NP];

export const hasPermission = (rolesHasPermission: string[], roleName: string) => {
  // TODO: refactor TaskType
  return rolesHasPermission.map((role) => role.toLowerCase()).includes(roleName?.toLowerCase());
};
