import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { MembershipPlanProps } from 'components/crossSell/PlanType/planType.types';
import { DoctorStateProps } from 'components/crossSell/SelectDoctorCard/selectDoctorCard.types';
import { GetAvailableDoctorsProps, PatientAppointmentType } from 'store/crossSell/crossSell.types';

export const crossSellApi = createApi({
  reducerPath: 'crossSellApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_PATIENT_URL,
    prepareHeaders: (headers) => {
      headers.set('API-KEY', import.meta.env.VITE_API_KEY ?? '');
      headers.set('Platform', 'web-physician');
      return headers;
    },
  }),
  endpoints: (build) => ({
    getDoctors: build.query({
      keepUnusedDataFor: 3,
      query: ({ itemsCount: limit, date, state, timezone, page: pageNo, planId, appointmentTypeId }) => ({
        url: `/auth/doctors`,
        params: { appointmentTypeId, pageNo, limit, date, state, timezone, planId },
      }),
      transformResponse: (response: {
        data: DoctorStateProps[];
        info: {
          totalCount: number;
        };
      }) => ({
        data: response.data,
        info: response.info,
      }),
    }),

    getAvailableDoctors: build.query<
      { data: DoctorStateProps[]; info: { totalCount: number } },
      GetAvailableDoctorsProps
    >({
      keepUnusedDataFor: 3,
      query: ({ limit, state, pageNo, isUnlimitedPlan, isInitialVisit }) => ({
        url: `/auth/available-doctors`,
        params: { pageNo, limit, state, isUnlimitedPlan, isInitialVisit },
      }),
      transformResponse: (response: { data: DoctorStateProps[]; info: { totalCount: number } }) => ({
        data: response.data,
        info: response.info,
      }),
    }),

    getStates: build.query<{ value: string; label: string }[], void>({
      query: () => '/lookup/state-list',
      transformResponse: (response: { data: { stateCode: string; name: string }[] }) => {
        return response.data.map((v) => ({
          value: v.stateCode,
          label: v.name,
        }));
      },
    }),

    getMembershipPlans: build.query<MembershipPlanProps[], void>({
      query: () => '/lookup/membership-plans',
      transformResponse: (response: { data: MembershipPlanProps[] }) => response.data,
    }),

    getPatientAppointmentTypes: build.query<PatientAppointmentType[], { params: string }>({
      query: ({ params }) => `/lookup/appointment-types?${params}`,
      transformResponse: (response: { data: PatientAppointmentType[] }) => response.data,
    }),
  }),
});

export const {
  useLazyGetDoctorsQuery,
  useLazyGetAvailableDoctorsQuery,
  useGetStatesQuery,
  useGetMembershipPlansQuery,
  useGetPatientAppointmentTypesQuery,
} = crossSellApi;
