import { FormHTMLAttributes } from 'react';

import { FieldValues } from 'react-hook-form';

import { OrderProps } from 'store/orders/orders.types';

export interface EditOrderFormProps extends FormHTMLAttributes<HTMLFormElement> {
  orderDetails?: OrderProps;
  onUpdateOrder?: () => void;
  onFormSubmit?: (id?: string) => void;
  isFetching?: boolean;
  orderId?: string;
}

export interface OrderFormStateProps extends FieldValues {
  memberId: string;
  rxGroup?: string;
  rxBin: string;
  pcn?: string;
  relationship: { label: string; value: string };
}

export const defaultValues: OrderFormStateProps = {
  memberId: '',
  rxGroup: '',
  rxBin: '',
  pcn: '',
  relationship: { label: '', value: '' },
};
