import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

import Alert from 'components/common/Alert';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useTriggerRenewalMutation } from 'store/weightManagement/weightManagementSlice';

const TriggerRenewal: React.FC<{
  patientName?: string;
  orderId: string;
  patientId: string;
  showNotWMWarning?: boolean;
}> = ({ orderId, patientName, patientId, showNotWMWarning }) => {
  const dispatch = useAppDispatch();
  const [triggerRenewal, { isLoading }] = useTriggerRenewalMutation();

  const handleTriggerRenewal = () => {
    triggerRenewal({ orderId, patientId })
      .unwrap()
      .then((response) => {
        notifySuccess(response.message);
        dispatch(closeModal());
      });
  };

  return (
    <div className="p-6">
      <h2 className="mb-2 text-xl font-bold text-gray-700">Trigger renewal?</h2>
      {showNotWMWarning && (
        <Alert
          type="error"
          containerClasses="mb-2 shadow-none"
          children={
            <p className="ml-1 text-base font-medium">
              <span className="font-bold">Billing status:</span> Patient does not have an active Weight Management plan.
              Please do not create a renewal without manager approval.
            </p>
          }
        />
      )}
      <p className="mb-6 text-base font-medium text-gray-700">
        {patientName || 'Patient'} will receive a questionnaire to renew their medication.
      </p>
      <div className="flex gap-2">
        <Common.Button
          onClick={() => dispatch(closeModal())}
          color="white-alt"
          size="sm"
          className="!w-full justify-center"
        >
          Cancel
        </Common.Button>
        <Common.Button
          color="blue"
          size="sm"
          className="!w-full justify-center"
          onClick={handleTriggerRenewal}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Trigger renewal
        </Common.Button>
      </div>
    </div>
  );
};

export default TriggerRenewal;
