import { createColumnHelper } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import { ColorTagProps } from '@thecvlb/design-system/lib/src/common/ColorTag/colorTag.types';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';
import { ActionLogs } from 'store/history/history.types';

import EventDetails from './EventDetails';
import Header from '../Header';

const setColor = (eventKey: string) => {
  switch (eventKey) {
    case 'appointment':
      return 'yellow';
    case 'message':
      return 'secondary';
    case 'pre-consult':
    case 'onboarding':
      return 'orange';
    case 'front-desk':
    case 'billing/account':
      return 'red';
    case 'review':
    case 'request':
    case 'pa-request':
    case 'task':
      return 'violet';
    case 'post-consult':
      return 'primary';
    case 'result':
      return 'blue';
    case 'notification':
      return 'pink';
    case 'app/patient activity':
    case 'provider-activity':
    case 'wm':
    case 'renewal':
      return 'green';
    case 'async':
      return 'warm-gray';
    case 'billing/appointment':
    case 'patient-verification':
    case 'system':
    case 'automations':
    case 'maintenance':
    case 'other':
      return 'gray';
    default:
  }
};

const columnHelper = createColumnHelper<ActionLogs>();

export const columns = [
  columnHelper.accessor('createdAt', {
    header: () => <Header text="Date" sortField="createdAt" />,
    cell: (info) => <span>{dayjs(info.getValue()).format(DateFormat.MMM_D_YYYY_h_mm_a_z)}</span>,
    size: 185,
  }),
  columnHelper.accessor('eventType', {
    header: () => <Header text="Event" sortField="eventType" />,
    cell: (info) => {
      let eventKey = '';
      Object.entries(info.row.original).forEach(([key, val]) => {
        if (key === 'eventKey' && val) {
          eventKey = val as string;
        }
      });
      const color = setColor(eventKey) as ColorTagProps['color'];
      return <Common.ColorTag color={color} text={info.getValue()} />;
    },
    size: 160,
  }),
  columnHelper.accessor('eventDetails', {
    header: () => <Header text="Details" sortField="eventDetails" />,
    cell: (info) => <EventDetails eventLog={info.row.original} />,
  }),
  columnHelper.accessor('createdBy', {
    header: () => <Header text="Author" sortField="createdBy" />,
    cell: (info) => <span>{info.getValue()}</span>,
  }),
];
