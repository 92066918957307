import { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

import Alert from 'components/common/Alert';
import Benefits from 'components/patient/Insurance/Benefits';
import { getDateOfBenefits } from 'components/patient/Insurance/Benefits/benefits.settings';
import InsuranceForm from 'components/patient/Insurance/InsuranceForm';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { selectPatient } from 'store/patients/patientsSlice';

const BenefitsModal = ({ patientId }: { patientId: string }) => {
  const dispatch = useAppDispatch();
  const { benefits } = useAppSelector(selectPatient);

  const [isUpdateInsurance, setIsUpdateInsurance] = useState(false);

  const showInsuranceForm = !benefits?.benefits || isUpdateInsurance;
  const showBenefits = benefits?.benefits && !isUpdateInsurance;
  const isInsurance = !!benefits?.insurance;
  const requestSentDate = getDateOfBenefits(benefits);

  return (
    <>
      <div className="p-6">
        <h2 className="mb-2 text-xl font-bold text-gray-700">Benefits</h2>
        {showInsuranceForm && (
          <div>
            <p className="mb-6 text-base font-medium">Enter insurance information to run a benefits check</p>
            {!isInsurance && (
              <Alert
                type="info"
                containerClasses="mb-5"
                children={
                  <p className="text-base font-medium">
                    Patient has indicated they do not want to use insurance to pay for their prescriptions
                  </p>
                }
              />
            )}
            {showInsuranceForm && (
              <InsuranceForm
                insuranceInfo={benefits?.insurance}
                patientId={patientId}
                closeForm={() => setIsUpdateInsurance(false)}
                isModal
              />
            )}
          </div>
        )}

        {showBenefits && (
          <div className="pt-2">
            <Benefits isModal data={benefits} mostRecentDate={requestSentDate} />
            <div className="flex justify-end gap-x-2 pt-6">
              <Common.Button color="white-alt" onClick={() => setIsUpdateInsurance(true)} size="sm">
                Update insurance
              </Common.Button>
              <Common.Button color="blue" onClick={() => dispatch(closeModal())} size="sm">
                Close window
              </Common.Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BenefitsModal;
