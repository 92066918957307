import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { useForm, useWatch } from 'react-hook-form';

import Loader from 'components/common/Loader';
import RadioButtonCard from 'components/common/RadioButtonList/RadioButtonList';
import { PlanRecommendationStatus } from 'enums/planRecommendation';
import { useAppDispatch } from 'hooks/redux';
import { useGetPlanRecommendationOptionsQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import {
  setLoadingTagList,
  useCreatePlanRecommendMutation,
  useDisablePlanRecommendationMutation,
  useLazyGetTagsQuery,
} from 'store/patients/patientsSlice';

import { getDisablePlanLabel, getTitleAndDescription } from './planRecommendation.settings';
import { PlanRecommendationFormProps, PlanRecommendationProps } from './planRecommendation.types';
import PlanRecommendationOptionCard from './PlanRecommendationOptionCard';

const PlanRecommendation: React.FC<PlanRecommendationProps> = ({ planRecommendations, patientId }) => {
  const dispatch = useAppDispatch();
  const { data: planRecommendationOptions, isLoading: isLoadingPlansList } = useGetPlanRecommendationOptionsQuery({
    patientId,
  });
  const [getTags] = useLazyGetTagsQuery();
  const [createPlanRecommend, { isLoading: isCreatingRecommendation }] = useCreatePlanRecommendMutation();
  const [disablePlanRecommendation, { isLoading: isDisablingPlanRecommendation }] =
    useDisablePlanRecommendationMutation();
  const methods = useForm<PlanRecommendationFormProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: { subPlanId: '' },
  });
  const formData = useWatch({ control: methods.control }) as PlanRecommendationFormProps;
  const subPlanId = formData?.subPlanId;

  const acceptedRecommendation = planRecommendations?.find((plan) => plan.status === PlanRecommendationStatus.ACCEPTED);

  const currentMedications = !!acceptedRecommendation
    ? planRecommendationOptions?.filter((planOption) => planOption._id === acceptedRecommendation.planRecommendationId)
    : planRecommendationOptions?.filter((planOption) => {
        return planRecommendations?.find(
          (plan) =>
            plan.planRecommendationId === planOption._id &&
            plan.status !== PlanRecommendationStatus.REJECTED &&
            plan.status !== PlanRecommendationStatus.DECLINED,
        );
      });

  const checkIfAccepted = (planId: string) =>
    planRecommendations?.find((plan) => plan.planRecommendationId === planId)?.status ===
    PlanRecommendationStatus.ACCEPTED;

  const checkIfPending = (planId: string) =>
    planRecommendations?.find((plan) => plan.planRecommendationId === planId)?.status ===
    PlanRecommendationStatus.PENDING;

  const isLoading = isCreatingRecommendation || isDisablingPlanRecommendation;
  const { title, description, showCurrentMedication, buttonName, selectPlanLabel } =
    getTitleAndDescription(planRecommendations);

  const handleChoosePlanRecommendation = (data: PlanRecommendationFormProps) => {
    if (!data.subPlanId) return;
    const isDisablePlanRecommendation =
      planRecommendations?.every((plan) => plan.status === PlanRecommendationStatus.PENDING) &&
      planRecommendations?.some((plan) => plan.planRecommendationId === data.subPlanId);

    (isDisablePlanRecommendation
      ? disablePlanRecommendation({ patientId })
      : createPlanRecommend({ patientId, body: { planRecommendationId: data.subPlanId } })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoadingTagList(true));
        setTimeout(async () => {
          await getTags({ patientId });
          dispatch(setLoadingTagList(false));
        }, 5000);
        dispatch(closeModal());
      });
  };

  return (
    <form className="p-6" onSubmit={methods.handleSubmit(handleChoosePlanRecommendation)}>
      <Loader isVisible={isLoadingPlansList} />
      <h2 className={classNames('text-xl font-bold text-gray-700', { 'mb-6': !description })}>{title}</h2>
      {description && <p className="mb-6 mt-2 text-base font-medium text-gray-700">{description}</p>}

      {showCurrentMedication && !!currentMedications?.length && (
        <div className="mb-4 w-full rounded-xl border border-gray-200 bg-gray-100/50 px-4 py-3 transition-all">
          <h2 className="mb-0.5 text-sm font-medium text-primary">Current recommendations</h2>
          <div className="flex flex-col">
            {currentMedications?.map((plan) => (
              <div className="flex flex-col gap-0.5" key={plan._id}>
                <span className="mb-0.5 font-semibold text-gray">{plan.title}</span>
                <span className="text-sm font-medium text-gray">{plan.description}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex flex-col gap-2">
        <h3 className="text-base font-bold text-gray-700">{selectPlanLabel}</h3>

        {planRecommendationOptions?.map(
          (plan) =>
            !(currentMedications?.some((med) => med._id === plan._id) || checkIfAccepted(plan._id)) && (
              <PlanRecommendationOptionCard
                key={plan._id}
                plan={plan}
                chosenSubPlanId={subPlanId}
                onChange={(planId) => methods.setValue('subPlanId', planId)}
              />
            ),
        )}
        {!!currentMedications?.length && currentMedications?.every((med) => checkIfPending(med._id)) && (
          <RadioButtonCard
            label={getDisablePlanLabel()}
            value={currentMedications?.[0]?._id}
            isSelected={!!subPlanId && subPlanId === currentMedications?.[0]?._id}
            color="blue"
            handleChange={(planId) => methods.setValue('subPlanId', planId)}
            className="rounded-xl"
          />
        )}
      </div>

      <div className="mt-6 flex justify-end gap-2">
        <Common.Button color="white-alt" onClick={() => dispatch(closeModal())} size="sm" type="button">
          Cancel
        </Common.Button>
        <Common.Button color="blue" size="sm" disabled={!subPlanId || isLoading}>
          {buttonName}
        </Common.Button>
      </div>
    </form>
  );
};

export default PlanRecommendation;
