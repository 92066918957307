import { useEffect } from 'react';

import { useToggle } from 'react-use';

const useEditorToolbar = () => {
  const [showToolbar, toggleFormatText] = useToggle(false);

  useEffect(() => {
    const editorToolbar = document.querySelector('.tox-editor-header');

    if (editorToolbar) {
      // @ts-expect-error - editorToolbar has the style property
      editorToolbar.style.display = showToolbar ? 'block' : 'none';
    }
  }, [showToolbar]);

  return { showToolbar, toggleFormatText };
};

export default useEditorToolbar;
