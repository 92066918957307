import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import ActionsCell from './ActionsCell';
import { LastInteractionCell } from './LastInteractionCell';
import { PatientsTableProps } from './patientsTable.types';
import Header from '../Header';
import OnboardingDetails from '../TaskTable/OnboardingDetails';

const columnHelper = createColumnHelper<PatientsTableProps>();
export const columns = [
  columnHelper.accessor('patient', {
    header: () => <Header text="Patient" sortField="patient" />,
    cell: (info) => (
      <>
        {info.getValue()} {dayjs().diff(info.row.original?.dob, 'year')} • {info.row.original?.stateShortCode}
      </>
    ),
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => <OnboardingDetails details={info.row.original} />,
  }),
  columnHelper.accessor('lastInteraction', {
    header: () => <Header text="Last interaction" sortField="lastInteraction" />,
    cell: (info) => {
      const lastInteraction = info.row.original.lastInteraction;
      return <LastInteractionCell lastInteraction={lastInteraction} />;
    },
  }),
  columnHelper.accessor('membership', {
    header: () => <Header text="Membership" sortField="membership" />,
  }),
  columnHelper.accessor('email', {
    header: () => <Header text="Email" sortField="email" />,
    cell: (info) => <p className="max-w-[150px] truncate text-sm font-medium">{info.getValue()}</p>,
  }),
  columnHelper.accessor('doctor', {
    header: () => <Header text="Provider" sortField="doctor" />,
  }),
  columnHelper.accessor('actions', {
    header: () => <Header text="Open tasks" sortField="openTasksCount" />,
    cell: (info) => <ActionsCell taskCount={info.row.original?.openTasksCount} patientId={info.row.original?._id} />,
  }),
];
