import classNames from 'classnames';

import { addDashesToPhoneNumber } from 'utils/helpers';

import { PharmacyInfoProps } from './pharmacyInfo.types';

const PharmacyInfo: React.FC<PharmacyInfoProps> = ({ pharmacyInfo }) => {
  const getValueClasses = (label: string) =>
    classNames('text-base', { underline: label === 'Email:' || label === 'Phone:' });

  const items = [
    { label: 'Name:', value: pharmacyInfo?.name },
    { label: 'Address:', value: pharmacyInfo?.address },
    {
      label: 'Phone:',
      value: pharmacyInfo?.phone && addDashesToPhoneNumber(`+1${pharmacyInfo?.phone}`),
    },
    { label: 'Fax:', value: pharmacyInfo?.fax && addDashesToPhoneNumber(`+1${pharmacyInfo?.fax}`) },
    { label: 'Email:', value: pharmacyInfo?.email },
  ];

  return (
    <div className="flex h-24 flex-col gap-2">
      {items.map((item) => (
        <div key={item.label} className="flex items-center">
          <p className="mr-1.5 w-20 min-w-fit flex-none text-base text-gray">{item.label}</p>
          <p className={getValueClasses(item.label)}>{item.value}</p>
        </div>
      ))}
    </div>
  );
};

export default PharmacyInfo;
