import dayjs from 'dayjs';

import { Benefit } from 'components/tables/BenefitsTable/benefitsTable.types';
import { CheckInsuranceResponseProps } from 'store/patients/patients.types';

export const getDateOfBenefits = (value?: CheckInsuranceResponseProps | null) => {
  const hasBenefits = value?.benefits && value?.benefits.length > 1;
  const benefitDate = hasBenefits ? value?.benefits[0]?.created : false;
  if (benefitDate) {
    return new Date(benefitDate).toISOString();
  } else if (value && Array.isArray(value)) {
    const validDates = value.filter((e) => Boolean(e));
    const mostRecentDate = Math.max(
      ...validDates.map((e) => {
        const mappedDate = typeof e === 'string' ? e : e.submittedVPC;
        return Date.parse(mappedDate);
      }),
    );
    if (mostRecentDate) {
      return new Date(mostRecentDate).toISOString();
    }
  }
};

export const getLastRequestDate = (value?: CheckInsuranceResponseProps) => {
  const tPSDateArray = value?.tpsSubmitBenefitsInvestigationDates;
  // Check if benefits created date is from last TPS request
  if (tPSDateArray && tPSDateArray.length > 0) {
    const lastElement = tPSDateArray.length == 1 ? tPSDateArray[0] : tPSDateArray[tPSDateArray.length - 1];
    if (typeof lastElement !== 'string') {
      return lastElement?.submittedVPC;
    }
    return lastElement;
  }
  return false;
};

export const benefitsAreLatest = (benefitDate: string, requestDate: string) => {
  const benefitDateToDate = new Date(benefitDate);
  const requestDateToDate = new Date(requestDate);
  return benefitDateToDate > requestDateToDate;
};

export const benefitsMethodIsElation = (value?: CheckInsuranceResponseProps) => {
  const hasBenefits = value?.benefits && value?.benefits.length > 0;
  const requestID = hasBenefits ? value?.benefits[0]?.request_id : false;
  return requestID && requestID == 9999999;
};

// Can update function to do other transforms of data to clearer views for our users
export const transformBenefitCopay = (value?: Benefit[]) => {
  const hasBenefits = value && value.length > 0;
  if (hasBenefits) {
    const benefitsData = value.map((benefit: Benefit) => {
      let formattedCopay;
      if (benefit?.copay) {
        if (benefit?.copay === 'null') {
          formattedCopay = 'N/A';
          // So all copay numbers go to the second decimal place
        } else if (benefit?.copay == '0.00') {
          formattedCopay = '0';
        } else if (Number(benefit?.copay)) {
          formattedCopay = `$${(Math.round(Number(benefit.copay) * 100) / 100).toFixed(2)}`;
        }
      }
      return { ...benefit, copay: formattedCopay };
    });
    return benefitsData;
  }
  return value;
};

export const generalErrorObject = (errorMessage: string) => {
  const errorObject = {
    rejectReason: '',
    followUp: '',
  };
  const errorArray = errorMessage.split('Follow up:');
  errorObject.rejectReason = errorArray[0].replace('Reject Reason:', '');
  errorObject.followUp = errorArray[1]?.includes('Resubmission not allowed') ? '' : errorArray[1];
  return errorObject;
};

export const getTimeDifference = (date: string) => {
  const currentDate = dayjs();
  const requestDate = dayjs(date);
  const hours = requestDate.diff(currentDate, 'hours');
  return -hours > 24;
};
