import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

export const formatTime = (day: Date, hours: number, minutes: number, isPhysicianTab: boolean, timezone: string) => {
  if (isPhysicianTab) {
    return dayjs(day).hour(hours).minute(minutes).tz(timezone, true).utc().format(DateFormat.YYYY_MM_DDTHH_mm_ss_ssZ);
  } else {
    return dayjs(day).hour(hours).minute(minutes).local().utc().format(DateFormat.YYYY_MM_DDTHH_mm_ss_ssZ);
  }
};
