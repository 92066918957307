import isObject from 'lodash/isObject';

import SettingsItem from 'components/settings/SettingsItem';
import { Mappings } from 'store/settings/settings.types';

const MainSection: React.FC<{ mappings?: Mappings; areaName?: string; sectionName?: string }> = ({
  mappings,
  areaName,
  sectionName,
}) => {
  const section = mappings && areaName && sectionName && mappings[areaName][sectionName];

  return (
    <>
      {isObject(section)
        ? Object.keys(section).map((item) => {
            return (
              <div key={item}>
                <div className="mb-4 font-bold">{item}</div>
                {Object.keys(section[item]).map((configItem) => {
                  return (
                    <div key={configItem}>
                      <div className="mb-6">
                        <div className="mb-3 text-primary">{configItem}</div>
                        {section[item][configItem].settings?.map((setting) => {
                          return <SettingsItem key={setting.name} setting={setting} />;
                        })}
                      </div>
                      <div className="mb-6 border-t border-gray-200"></div>
                    </div>
                  );
                })}
              </div>
            );
          })
        : null}
    </>
  );
};

export default MainSection;
