import { Common } from '@thecvlb/design-system/lib/src';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useRetryBulkEdit } from 'hooks/bulkEdits/useRetryBulkEdit';
import { useAppDispatch } from 'hooks/redux';
import { resetBulkEdits } from 'store/bulkEdit/bulkEditSlice';
import { closeModal } from 'store/modal/modalSlice';

import { UpdateFailedProps } from './updateFailed.types';

const UpdateFailed = ({ requestBody }: UpdateFailedProps) => {
  const dispatch = useAppDispatch();
  const { retry, editResult, isLoading } = useRetryBulkEdit();
  const failedItems = editResult?.data?.failed;
  const failedCount = failedItems?.length ?? 0;
  const updatedItems = editResult?.data?.updated;
  const updatedCount = updatedItems?.length ?? 0;
  const allCount = failedCount + updatedCount;
  const isAllFailed = failedCount === allCount;

  const handleOnRetry = () => {
    retry(requestBody)
      .unwrap()
      .then((res) => {
        if (!res.data?.failed.length) {
          notifySuccess(res.message);
          dispatch(resetBulkEdits());
          dispatch(closeModal());
        }
      });
  };

  return (
    <>
      <Loader isVisible={isLoading} />
      <div data-testid="update_failed_popup" className="p-6">
        <p data-testid="header" className="mb-4 text-xl font-bold">
          Update failed {!isAllFailed && `for ${failedCount} of ${allCount} items`}
        </p>
        <p data-testid="description" className="text-base font-medium text-gray-700">
          We were unable to update {isAllFailed ? 'these items' : 'the following items:'}
        </p>
        {!isAllFailed && (
          <ul className="mb-4 list-inside list-disc">
            {failedItems?.map((item) => (
              <li key={item.userId} className="font-bold">
                {item.username || item.patientName}
              </li>
            ))}
          </ul>
        )}
        {!isAllFailed && (
          <>
            <p className="text-base font-medium text-gray-700">Successfully updated:</p>
            <ul className="mb-4 list-inside list-disc">
              {updatedItems?.map((item) => <li key={item.userId}>{item.username || item.patientName}</li>)}
            </ul>
          </>
        )}
        <div className="mt-4 flex gap-2">
          <Common.Button
            size="sm"
            color="white-alt"
            onClick={() => dispatch(closeModal())}
            className="!w-full justify-center"
          >
            Close window
          </Common.Button>

          <Common.Button
            color="blue"
            size="sm"
            onClick={handleOnRetry}
            className="!w-full justify-center"
            disabled={isLoading}
          >
            Retry
          </Common.Button>
        </div>
      </div>
    </>
  );
};

export default UpdateFailed;
