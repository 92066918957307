import React, { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, RowData, useReactTable } from '@tanstack/react-table';
import isEqual from 'lodash/isEqual';
import { usePrevious } from 'react-use';

import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/BillingHistory/columns';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { BillingHistoryData } from 'store/patients/patients.types';
import { useLazyGetBillingHistoryQuery } from 'store/patients/patientsSlice';

import type { InvoiceTableProps } from './invoiceTable.types';
import InvoiceModal from '../InvoiceModal';
import useFilteredParams from '../useFilteredParams';

const InvoiceTable: React.FC<InvoiceTableProps> = ({ patientId }) => {
  const dispatch = useAppDispatch();
  const [getBillingHistory, { data: billingData, isFetching, isUninitialized }] = useLazyGetBillingHistoryQuery();

  const filteredParams = useFilteredParams();
  const prevQueryParams = usePrevious(filteredParams);

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: !!isFetching || !!isUninitialized,
    columns,
    data: billingData?.data,
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<BillingHistoryData, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (patientId && !isEqual(prevQueryParams, filteredParams)) {
      getBillingHistory({ id: patientId, params: filteredParams });
    }
  }, [patientId, getBillingHistory, filteredParams, prevQueryParams]);

  const handleInvoiceOnClick = (rowData: RowData) => {
    dispatch(
      openModal({
        size: 'lg',
        modalContent: <InvoiceModal data={rowData as BillingHistoryData} />,
      }),
    );
  };

  return (
    <BasicTable
      table={table}
      totalCount={billingData?.info.totalCount || 0}
      isFetching={isFetching}
      onClickRow={handleInvoiceOnClick}
    />
  );
};

export default InvoiceTable;
