import { useParams } from 'react-router-dom';

import WeightDetails from 'components/tasks/slidingPane/WeightDetails';
import { HealthMetricTypes } from 'enums/chart';

import { WeightProps } from './weightFromDevice.types';
import HealthMetric from '../../HealthMetric/HealthMetric';

const WeightFromDevice: React.FC<WeightProps> = ({ weight, patient }) => {
  const { patientId = '' } = useParams<{ patientId: string }>();

  const weightSubtitle = weight?.weight ? `${weight?.weight} lbs` : '';

  return (
    <>
      <HealthMetric
        type={HealthMetricTypes.Weight}
        value={weight?.weight}
        metricSubtitle={weightSubtitle}
        isTableOpenByDefault={false}
        isWMDevices
      >
        <WeightDetails
          patientId={patientId}
          className="mt-0"
          patientName={`${patient?.firstName} ${patient?.lastName}`}
          weightFromDevice={weight}
        />
      </HealthMetric>
    </>
  );
};

export default WeightFromDevice;
