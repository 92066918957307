import classNames from 'classnames';
import isString from 'lodash/isString';

import MIFOption from 'components/tasks/slidingPane/MIFDetails/MIFOption';

import { MIFQuestionProps } from './mifQuestion.types';

const MIFQuestion: React.FC<MIFQuestionProps> = ({ question, showAllOptions, displayNumber }) => {
  const wrapperClasses = classNames('flex gap-4 p-3 border-b border-black/5', { 'bg-red-100': question.warning });

  const displayNumberClasses = classNames(
    'flex-none h-6 w-6 rounded-3xl border py-0.5 text-center text-sm font-medium',
    question.warning ? 'border-red-500 text-red-500' : 'border-gray-500 text-gray-500',
  );

  const answersArray = Array.isArray(question.answer) ? question.answer : question.answer ? [question.answer] : [];
  const options = question.options || answersArray;

  return (
    <div className={wrapperClasses}>
      {/* If BE does not send the displayNumber than we should use normal numeration */}
      <span className={displayNumberClasses}>{question.displayNumber || displayNumber}</span>
      <div className="flex flex-col gap-1 text-base">
        <span className="font-semibold">{question.question}</span>
        <span className="flex flex-col gap-1 font-medium text-gray-500">
          {(showAllOptions ? options : answersArray).map((option) => (
            <MIFOption
              key={option}
              warning={question.warning}
              option={option}
              showAllOptions={showAllOptions}
              answersArray={answersArray}
            />
          ))}
        </span>
        {question.additionalDetails?.map((additionalOption) => {
          const additionalOptionValue = isString(additionalOption) ? additionalOption : additionalOption.value;
          return (
            <MIFOption
              key={additionalOptionValue}
              warning={question.warning}
              option={additionalOptionValue}
              showAllOptions={showAllOptions}
              answersArray={answersArray}
              isAdditionalOption
            />
          );
        })}
      </div>
    </div>
  );
};

export default MIFQuestion;
