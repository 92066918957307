import { ColumnDef } from '@tanstack/react-table';

import Header from 'components/tables/Header';

export const getDynamicColumns = (
  tableName: string,
  noSort?: boolean,
  data?: Record<string, string | number>[],
): ColumnDef<Record<string, string | number>>[] => {
  if (data && data[0]) {
    return Object.keys(data[0]).map((item) => ({
      header: () => <Header text={item} sortField={item} tableName={tableName} noSort={noSort} />,
      accessorKey: item,
      cell: ({ getValue }) => {
        const value = getValue() as number | string;
        return (
          <span className="font-medium">
            {typeof value === 'number' ? value.toFixed(2).replace(/[.,]00$/, '') : value}
          </span>
        );
      },
    }));
  } else return [];
};
