import { useCallback } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import upperFirst from 'lodash/upperFirst';

import Loader from 'components/common/Loader';
import CancelPlan from 'components/modals/CancelPlan';
import ChangePlan from 'components/modals/ChangePlan';
import ConnectedDeactivatePatient from 'components/modals/DeactivatePatient/ConnectedDeactivatePatient';
import PauseSubscription from 'components/modals/PauseSubscription';
import PlanRecommendation from 'components/modals/PlanRecommendation';
import ReactivatePatient from 'components/modals/ReactivatePatient';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { Status } from 'enums/status';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useGetMembershipPlansQuery } from 'store/crossSell/crossSellSlice';
import { openModal } from 'store/modal/modalSlice';
import { PatientProps } from 'store/patients/patients.types';
import { useLazyGetSubscriptionCancelReasonsQuery } from 'store/subscription/subscriptionSlice';
import { selectUser } from 'store/user/userSlice';

import AdvantageItem from './AdvantageItem';
import { getPlanRecommendationTitle, getSubPlanNames, priceInfo } from './planDetails.settings';
import { userRolePermission } from './planDetails.type';

const selectUserState = createSelector(selectUser, (user) => ({
  editingPermissions: user.userRoleInfo.editingPermissions,
}));

const PlanDetails: React.FC<{ patient: PatientProps }> = ({ patient }) => {
  const dispatch = useAppDispatch();
  const { editingPermissions } = useAppSelector(selectUserState);
  const [getSubscriptionCancelReasons] = useLazyGetSubscriptionCancelReasonsQuery();
  const { data: membershipPlans, isLoading, isFetching } = useGetMembershipPlansQuery();
  const showLoader = isLoading || isFetching;

  const planTitleClassName = 'font-bold';
  const wrapperClassName = 'rounded-xl border border-gray-200 bg-white p-4 md:bg-gray-50';
  const headingClassName = 'flex items-center justify-between';
  const dividerClassName = 'my-4 h-px w-full bg-gray-200';

  const currentPlan = membershipPlans?.find((plan) => plan._id === patient.activePlanId);
  const activePP = currentPlan?.pricePoints?.find((pp) => pp.planPricePointId === patient.activePricePoint);
  const insuranceCategory = activePP?.categories?.includes('insurance');
  const tripleTherapyCategory = activePP?.categories?.includes('triple-therapy');
  const isInactive = patient.status.toLowerCase() === 'inactive';
  const showSubPlanActions = patient?.planInfo?.planCode === PlanCodesProps.WeightManagementMembership;
  const showPlanRecommendation = showSubPlanActions && !tripleTherapyCategory && !insuranceCategory;
  const { title: planRecommendationTitle, subTitle: subPlanTitle } = getPlanRecommendationTitle(
    patient?.planRecommendations,
  );
  const subPlanName = getSubPlanNames(patient?.planRecommendations);

  const handleCancelAccount = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: <CancelPlan patientInfo={patient} />,
      }),
    );
  };

  const handleChangePlan = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: <ChangePlan patientInfo={patient} currentPlan={currentPlan} activePP={activePP} />,
      }),
    );
  };

  const handlePauseSubscription = () => {
    dispatch(
      openModal({
        size: 'lg',
        hideClose: true,
        modalContent: <PauseSubscription patientInfo={patient} currentPlan={currentPlan} activePP={activePP} />,
      }),
    );
  };

  const handlePlanRecommendation = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: <PlanRecommendation planRecommendations={patient?.planRecommendations} patientId={patient._id} />,
      }),
    );
  };

  const handleMouseEnterDeactivate = useCallback(() => {
    getSubscriptionCancelReasons(null, true);
  }, [getSubscriptionCancelReasons]);

  const handleDeactivate = useCallback(() => {
    dispatch(
      openModal({
        modalContent: <ConnectedDeactivatePatient patientId={patient._id} />,
        size: 'sm',
        hideClose: true,
      }),
    );
  }, [dispatch, patient._id]);

  const handleReactivate = useCallback(() => {
    dispatch(
      openModal({
        modalContent: <ReactivatePatient patientId={patient._id} />,
        size: 'sm',
        hideClose: true,
      }),
    );
  }, [dispatch, patient._id]);

  const formatedPatientStatus = upperFirst(patient?.status).toLowerCase();
  const hasEditPermission = editingPermissions?.find((val) => val === userRolePermission.PATIENT_STATUS_EDIT);
  const showDeactivateButton = formatedPatientStatus === Status.Active && hasEditPermission;
  const showReactivateButton = formatedPatientStatus === Status.Inactive && hasEditPermission;

  return (
    <>
      {showLoader && <Loader isVisible />}
      {!!currentPlan && !!activePP && (
        <>
          <h2 className="pb-4 font-bold">Plan details</h2>
          <div data-testid="billing_block" className={wrapperClassName}>
            <div className={headingClassName}>
              <h2 className={planTitleClassName} data-testid="current_plan">
                {currentPlan.planName}
                {subPlanTitle && (
                  <span className="font-medium">
                    {' '}
                    • {subPlanTitle}
                    {subPlanName && <span className="font-medium"> • {subPlanName}</span>}
                  </span>
                )}
              </h2>
              <h2 className={planTitleClassName} data-testid="plan_price">
                ${priceInfo(activePP)}
              </h2>
            </div>
            <div className={dividerClassName} />
            <div className="mb-4 flex flex-col gap-2">
              {currentPlan.additionalInfo
                .filter((v) => v.value)
                .map((advantage) => (
                  <AdvantageItem advantage={advantage} className="text-base" key={advantage.question} showCustomIcon />
                ))}
            </div>

            <div className="flex">
              <div className="flex flex-1 gap-4">
                <Common.Button color="blue" onClick={handleChangePlan} disabled={isInactive}>
                  Change plan
                </Common.Button>
                <Common.Button color="white-alt" onClick={handlePauseSubscription} disabled={isInactive}>
                  Pause Subscription
                </Common.Button>
                {showPlanRecommendation && (
                  <Common.Button color="white-alt" onClick={handlePlanRecommendation} disabled={isInactive}>
                    <Common.Icon name="scale-outline" className="mr-2" />
                    {planRecommendationTitle}
                  </Common.Button>
                )}
                <Common.Button
                  color={showSubPlanActions ? 'red-alt' : 'white-alt'}
                  onClick={handleCancelAccount}
                  disabled={isInactive}
                >
                  Cancel account or auto-renew
                </Common.Button>
              </div>
              <div>
                {(showDeactivateButton || showReactivateButton) && (
                  <Common.Button
                    dataTestId="deactivate_btn"
                    color="red-alt"
                    onClick={() => (showDeactivateButton ? handleDeactivate() : handleReactivate())}
                    {...(showDeactivateButton && {
                      onMouseEnter: handleMouseEnterDeactivate,
                    })}
                  >
                    {showDeactivateButton ? 'Blocklist patient' : 'Reactivate patient'}
                  </Common.Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlanDetails;
