import { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Markdown from 'react-markdown';

import Diamonds from 'assets/icons/chat/Diamonds.svg?react';

import { SummaryContent } from './patientSummary.styled';
import { Skeleton } from './Skeleton';
import { PatientSummaryProps } from './summary.types';
import ToggleSection from './ToggleSection';

const renderContent = ({
  isOpen,
  content,
  isLoading,
}: {
  isOpen: boolean;
  content: string | JSX.Element;
  isLoading: boolean;
}) => {
  return (
    <SummaryContent $isOpen={isOpen} className={isOpen ? '' : 'flex-auto'}>
      {isLoading ? <Skeleton isOpen /> : content}
    </SummaryContent>
  );
};

const PatientSummary = ({ patientSummary }: PatientSummaryProps) => {
  const { data, isLoading, isError } = patientSummary;
  const [isSummaryOpen, setIsSummaryOpen] = useState(true);
  const summaryResponse = data?.outputs?.['out-30']?.replace(/^```markdown\n/, '');
  const isEmptyResponse = data?.outputs?.['out-30'] === '';

  const summaryResponseElem = summaryResponse && (
    <div className="flex flex-col gap-4 text-base">
      {
        <Markdown
          components={{
            h1(props) {
              const { node, ...rest } = props;
              return <h1 className="font-semibold" {...rest} />;
            },
            h2(props) {
              const { node, ...rest } = props;
              return <h2 className="font-semibold" {...rest} />;
            },
            strong(props) {
              const { node, ...rest } = props;
              return <strong className="font-semibold" {...rest} />;
            },
            code(props) {
              const { node, ...rest } = props;
              return <code className="whitespace-pre-wrap" {...rest} />;
            },
            ol(props) {
              const { node, ...rest } = props;
              return <ol className="mb-4 list-outside list-disc ps-5" {...rest} />;
            },
            ul(props) {
              const { node, ...rest } = props;
              return <ul className="list-outside list-disc ps-5" {...rest} />;
            },
            li(props) {
              const { node, ...rest } = props;
              return <li {...rest} />;
            },
            p(props) {
              const { node, ...rest } = props;
              return <p className="text-base" {...rest} />;
            },
          }}
        >
          {summaryResponse}
        </Markdown>
      }
    </div>
  );

  const error = isError && (
    <div className="flex justify-center gap-1 text-base font-medium text-white/70">
      <Common.Icon name="error-outline" />
      Something went wrong. Please refresh the page.
    </div>
  );

  const emptyResponse = isEmptyResponse && (
    <div className="flex justify-center gap-1 text-base font-medium text-white/70">
      <Common.Icon name="error-outline" />
      Could not find patient data
    </div>
  );

  const content = error || emptyResponse || summaryResponseElem || '';

  const topMenuClasses = classNames('flex items-center justify-between', {
    'mb-4': isSummaryOpen,
    'gap-4': !isSummaryOpen,
  });

  return (
    <div className="mt-8 rounded-xl bg-[#0B4D4B] p-4">
      <div className={topMenuClasses}>
        <div className="flex h-7 w-fit flex-none items-center gap-2 self-start rounded-full bg-black/30 p-1 px-3">
          <Diamonds width={16} height={16} fill="#fff" />
          <span className="text-sm font-semibold text-white">Patient summary</span>
        </div>
        {!isSummaryOpen && renderContent({ isOpen: isSummaryOpen, content, isLoading })}

        {!isSummaryOpen && <ToggleSection isOpen={isSummaryOpen} setIsOpen={setIsSummaryOpen} />}
      </div>

      <AnimatePresence initial={isSummaryOpen}>
        {isSummaryOpen && (
          <motion.div
            key="content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="overflow-hidden"
          >
            {renderContent({ isOpen: isSummaryOpen, content, isLoading })}

            <div className="mt-4 flex items-center justify-end">
              <ToggleSection isOpen={isSummaryOpen} setIsOpen={setIsSummaryOpen} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PatientSummary;
