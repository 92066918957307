import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';

import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import type { RootState } from 'store';
import { AvailabilityProps, AvailabilityWorkHours, initialState } from 'store/availability/availability.types';

import { getAvailableDays } from './availability.settings';
import { apiSlice } from '../api/apiSlice';

export const availabilityApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAvailability: build.query<AvailabilityProps, { userId: string; localTz?: string }>({
      keepUnusedDataFor: 0,
      query: ({ userId, localTz }) => ({
        url: `/physician-availability/${userId}`,
        // fix timezone issue in firefox browser
        params: { localTz: localTz?.replace('Europe/Kyiv', 'Europe/Kiev') },
      }),
      transformResponse: (response: { data: AvailabilityProps }) => {
        for (const key in response.data.workHours) {
          const element = response.data.workHours[key];
          element.forEach((period) => {
            period.id = nanoid();
          });
        }
        return response.data;
      },
      providesTags: ['Availability'],
    }),
    updateAvailability: build.mutation<
      { data: AvailabilityWorkHours; message: string },
      { userId: string; localTz?: string; body: AvailabilityProps['overRide'] }
    >({
      query: ({ userId, localTz, body }) => ({
        url: `/physician-availability/${userId}`,
        method: 'PATCH',
        params: { localTz: localTz?.replace('Europe/Kyiv', 'Europe/Kiev') },
        body,
      }),
      invalidatesTags: ['Availability'],
    }),
    updateOverride: build.mutation<
      void,
      { userId: string; body: { dates: ({ startTime: string; endTime: string } | string)[] } }
    >({
      query: ({ userId, body }) => ({
        url: `/physician-availability/override/${userId}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['Availability', 'GlobalAvailability'],
    }),
    deleteOverride: build.mutation<
      void,
      { userId: string; localTz?: string; date: string | { startTime: string; endTime: string } }
    >({
      query: ({ userId, localTz, date }) => ({
        url: `/physician-availability/override/${userId}`,
        method: 'DELETE',
        params: { localTz: localTz?.replace('Europe/Kyiv', 'Europe/Kiev') },
        body: { date },
      }),
      invalidatesTags: ['Availability'],
    }),
  }),
});

export const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    addAvailabilityItem(state, action: PayloadAction<{ day: string }>) {
      const { day } = action.payload;
      const { availableDays, data } = state;

      if (!availableDays[day]) {
        data.workHours?.[day].push({ startTime: '', endTime: '', id: nanoid(), appointments: true, queue: false });
        availableDays[day] = true;
      } else {
        data.workHours?.[day].push({ startTime: '', endTime: '', id: nanoid(), appointments: true, queue: false });
      }
      state.isChanged = true;
    },
    toggleDay(state, action: PayloadAction<{ day: string }>) {
      const { day } = action.payload;
      const {
        data: { workHours },
        availableDays,
      } = state;

      if (workHours && !workHours[day].length) {
        workHours[day].push({ startTime: '', endTime: '', id: nanoid(), appointments: false, queue: false });
        availableDays[day] = true;
      } else {
        availableDays[day] = !availableDays[day];
      }
      state.isChanged = true;
    },
    removeItem(state, action: PayloadAction<{ id: string; day: string }>) {
      const { day, id } = action.payload;
      const {
        data: { workHours },
        availableDays,
      } = state;

      if (workHours && workHours[day].length) {
        const newItems = workHours[day].filter((item) => item.id !== id);
        workHours[day] = newItems;
        if (!workHours[day].length) {
          availableDays[day] = false;
        }
      }
      state.isChanged = true;
    },
    updateItem(
      state,
      action: PayloadAction<{ value: string; id: string; day: string; type: 'startTime' | 'endTime' }>,
    ) {
      const { day, id, type, value } = action.payload;
      const {
        data: { workHours },
      } = state;
      if (workHours && workHours[day].length) {
        const index = workHours[day].findIndex((item) => item.id === id);
        workHours[day][index][type] = value;
      }
      state.isChanged = true;
    },
    toggleAvailability(
      state,
      action: PayloadAction<{ id: string; day: string; value: boolean; type: 'appointments' | 'queue' }>,
    ) {
      const {
        data: { workHours },
      } = state;
      const { day, id, value, type } = action.payload;
      if (workHours && workHours[day].length) {
        const index = workHours[day].findIndex((item) => item.id === id);
        workHours[day][index][type] = value;
      }
      state.isChanged = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(availabilityApi.endpoints.getAvailability.matchFulfilled, (state, { payload }) => {
        Object.assign(state.data, payload);
        Object.assign(state.availableDays, getAvailableDays(payload.workHours));
      })
      .addMatcher(availabilityApi.endpoints.updateAvailability.matchPending, (state) => {
        state.isChanged = false;
      })
      .addMatcher(availabilityApi.endpoints.updateAvailability.matchRejected, (state) => {
        state.isChanged = true;
        notifyError('Something went wrong!');
      })
      .addMatcher(availabilityApi.endpoints.updateAvailability.matchFulfilled, (_, { payload }) => {
        notifySuccess(payload.message || 'Updated');
      });
  },
});

export const selectAvailability = (state: RootState) => state.availability;

export const { addAvailabilityItem, toggleDay, removeItem, updateItem, toggleAvailability } = availabilitySlice.actions;
export default availabilitySlice.reducer;

export const {
  useGetAvailabilityQuery,
  useLazyGetAvailabilityQuery,
  useUpdateAvailabilityMutation,
  useDeleteOverrideMutation,
  useUpdateOverrideMutation,
} = availabilityApi;
