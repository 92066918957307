import { Patient } from '@thecvlb/design-system';
import { FieldValues, useController } from 'react-hook-form';

import { ControlledInputProps } from './controlledInput.types';
const ControlledInput = <TFieldValues extends FieldValues>({
  control,
  name,
  rules,
  label,
  className,
  type,
}: ControlledInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <Patient.Input
      {...field}
      name={name}
      label={label}
      helper={error?.message}
      errors={error}
      className={className}
      type={type}
    />
  );
};

export default ControlledInput;
