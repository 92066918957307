import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { isEmpty, unionBy } from 'lodash';

import { getOptions } from 'components/appointments/ManageAvailability/WeeklyAvailability/WeeklyAvailabilityForm/TimeSlotTypeMultiselect/timeSlotTypeMultiSelect.settings';
import { notifyError } from 'components/common/Toast/Toast';
import CalendarTimeConflict from 'components/modals/CalendarTimeConflict';
import { DateFormat } from 'enums/dateFormats';
import { EventCategoryNames, EventMultiSelectValue, EventType, RepeatOptions } from 'models/event.types';
import { EventDetailsProps, ShiftTypesResponseProps } from 'store/calendar/calendar.types';
import { openModal } from 'store/modal/modalSlice';

import { NormalizeResponseProps } from './createEventForm.types';
import {
  CategoryFilter,
  transformShiftTypesToCategoryFilters,
} from './EventCategorySelect/eventCategorySelect.settings';
import { convertEventTimeToDate } from '../manageAvailability.settings';

export const getNextMonth = (dateFrom: Date) => {
  // Get the month and year of the current date
  const currentMonth = dateFrom.getMonth();
  const currentYear = dateFrom.getFullYear();

  // Calculate the month and year of the next month
  let nextMonth = currentMonth + 2;
  let nextYear = currentYear;

  if (nextMonth > 11) {
    // If the next month is greater than 11 (December), add 1 to the year and set the month to 0 (January)
    nextMonth = 0;
    nextYear += 1;
  }

  const nextMonthDate = new Date(nextYear, nextMonth, 1);

  return nextMonthDate;
};

/**
 * This function is used to get the shift types options for a given event.
 *
 * @param {EventDetailsProps} eventDetails - The details of the event.
 * @param {ShiftTypesResponseProps[]} [shiftTypes] - The available shift types.
 *
 * @returns {EventMultiSelectValue[]} - Returns an array of shift types options for the event.
 *
 */
const getShiftTypesOptions = (
  eventDetails: EventDetailsProps,
  shiftTypes?: ShiftTypesResponseProps[],
): EventMultiSelectValue[] => {
  const shifts = getOptions(eventDetails.type as EventType, shiftTypes);

  if (eventDetails.type === 'break' || eventDetails?.type === 'time-off') {
    const result = shifts.find((type) => type.value === eventDetails.type);

    return result && !isEmpty(result) ? [result] : [];
  } else if (eventDetails.type === 'shift') {
    const result = eventDetails.shiftTypes.map((item) => {
      const foundShift = shifts.find((type) => type.value === item._id);
      if (!foundShift) {
        console.error(`Shift type ${item} not found`);
        return;
      }
      return foundShift;
    }) as EventMultiSelectValue[];
    return result;
  } else {
    return [];
  }
};

// Helper function to find matching categories for a given filter
const findMatchingCategories = (categoryFilters: CategoryFilter[], filter: string) => {
  return categoryFilters.flatMap((categoryItem) => {
    // For each category, filter its options to find the ones that match the filter
    return categoryItem.options.filter((option) => option.value === filter);
  });
};

const getSelectedCategoryOptions = (
  shiftTypes: EventDetailsProps['shiftTypes'],
  fieldName: typeof EventCategoryNames.FILTERS | typeof EventCategoryNames.EXCLUSIONS,
  categoryFilters: CategoryFilter[],
) => {
  // Map over each shiftType
  const optionsList = shiftTypes.flatMap((shiftType) => {
    // For each shiftType, map over its filters or exclusions
    const categories = shiftType[fieldName]?.flatMap((filter) => {
      // For each filter, find the matching categories
      return findMatchingCategories(categoryFilters, filter);
    });

    // If no filters or exclusions are defined for the shiftType, return an empty array
    return categories || [];
  });

  return unionBy(optionsList, 'value');
};

/**
 * This function is used to get the selected shift category filters for a given event.
 *
 * @param {EventDetailsProps} eventDetails - The details of the event.
 * @param {ShiftTypesResponseProps[]} [shiftTypes] - The available shift types.
 *
 * @returns {EventMultiSelectValue[]} - Returns an array of selected shift category filters for the event.
 *
 */
const getSelectedShiftCategoryFilters = (
  eventDetails: EventDetailsProps,
  fieldName: typeof EventCategoryNames.FILTERS | typeof EventCategoryNames.EXCLUSIONS,
  shiftTypes?: ShiftTypesResponseProps[],
) => {
  if (!eventDetails.shiftTypes) return [];

  const hasCategoryFilters = eventDetails.shiftTypes.some((shiftType) => Boolean(shiftType?.[fieldName]?.length));

  if (!hasCategoryFilters && !shiftTypes) {
    return [];
  }

  if (!shiftTypes) {
    console.error(`Shift types are required when category ${fieldName} are present`);
    return [];
  }

  const selectedShiftTypes = getShiftTypesOptions(eventDetails, shiftTypes);
  const transformedShiftCategories = transformShiftTypesToCategoryFilters(shiftTypes, selectedShiftTypes);

  return getSelectedCategoryOptions(eventDetails.shiftTypes, fieldName, transformedShiftCategories);
};

/**
 * This function is used to get the recurring ends options for a given event.
 *
 * @param {EventDetailsProps} eventDetails - The details of the event.
 *
 * @returns {Object} - Returns an object with the ends option and ends date for the event.
 *
 */
const getRecurringEnds = (eventDetails: EventDetailsProps, calendarTimezone: string) => {
  if (!eventDetails.recurring) {
    return { endsOption: null, endsDate: null };
  }

  if (eventDetails.recurring.until) {
    const endsDate = convertEventTimeToDate(eventDetails.recurring.until, 'Etc/UTC', calendarTimezone);
    return { endsOption: 'on', endsDate };
  }

  if (eventDetails.recurring.count) {
    return { endsOption: 'after', endsDate: null, count: eventDetails.recurring.count };
  }

  return { endsOption: 'never', endsDate: null };
};

/**
 * This function is used to normalize the GetEvent query response,
 * in order to pass it as default values into useForm hook for CreateEventForm.
 *
 * @param {string} timezone - The calendar timezone.
 * @param {ShiftTypesResponseProps[]} [shiftTypes] - The shift types.
 * @param {EventDetailsProps} [response] - The response GetEvent query from .
 *
 * @returns {Object} - Returns an object that match CreateEventForm FormValues type.
 *
 */
export const normalizeResponse = ({
  timezone,
  shiftTypes,
  eventDetailsResponse,
  recurringEventDetailsResponse,
}: NormalizeResponseProps) => {
  if (!eventDetailsResponse) return;

  const isRecurringEvent = !isEmpty(recurringEventDetailsResponse) && recurringEventDetailsResponse;
  const eventDetails = isRecurringEvent ? recurringEventDetailsResponse : eventDetailsResponse;

  const shiftStartDateString = dayjs(eventDetails.start.dateTime).tz(timezone).format(DateFormat.YYYY_MM_DD);
  const shiftStartDate = dayjs(shiftStartDateString).toDate();

  const startShiftTime = dayjs(eventDetails.start.dateTime).tz(timezone).format(DateFormat.HH_mm);
  const endShiftTime = dayjs(eventDetails.end.dateTime).tz(timezone).format(DateFormat.HH_mm);

  const ends = getRecurringEnds(eventDetailsResponse, timezone);

  const shiftTypesOptions = eventDetails ? getShiftTypesOptions(eventDetails, shiftTypes) : [];
  const categoryFilters = eventDetails
    ? getSelectedShiftCategoryFilters(eventDetails, EventCategoryNames.FILTERS, shiftTypes)
    : [];
  const categoryExclusions = eventDetails
    ? getSelectedShiftCategoryFilters(eventDetails, EventCategoryNames.EXCLUSIONS, shiftTypes)
    : [];
  const eventDetailsRepeat =
    eventDetailsResponse?.recurring?.type === RepeatOptions.WEEKLY && eventDetailsResponse.recurring?.interval === 2
      ? RepeatOptions.BI_WEEKLY
      : eventDetailsResponse?.recurring?.type;

  return {
    shiftStart: {
      day: shiftStartDate,
      month: shiftStartDate,
    },
    shiftTime: [
      {
        start: startShiftTime,
        end: endShiftTime,
      },
    ],
    repeats: eventDetailsRepeat || RepeatOptions.DOES_NOT_REPEAT,
    ...(ends.endsOption && {
      ends: {
        option: ends.endsOption,
        ...(ends.endsDate && { date: ends.endsDate }),
        dayPickerMonth: shiftStartDate,
        ...(ends.count && { count: ends.count }),
      },
    }),
    eventTypes: shiftTypesOptions,
    categoryFilters,
    categoryExclusions,
  };
};

export const handleEventsConflict = (
  { data: error }: { data: { message?: string; data?: { conflicts: EventDetailsProps[] } } },
  dispatch: Dispatch,
  eventDetails: Partial<EventDetailsProps>,
  calendarTimezone: string,
) => {
  if (error.data) {
    if (error.data?.conflicts) {
      dispatch(
        openModal({
          modalContent: (
            <CalendarTimeConflict
              conflicts={error.data.conflicts}
              eventDetails={eventDetails}
              calendarTimezone={calendarTimezone}
            />
          ),
          size: 'base',
          hideClose: true,
        }),
      );
    } else if (error.message) {
      notifyError(error.message);
    }
  }
};
