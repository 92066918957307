import { nanoid } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { EventTypes } from 'models/event.types';
import type { ShiftTypesResponseProps } from 'store/calendar/calendar.types';

import type { GetTotalHoursProps, TotalHours } from './weekTotal.types';

const createShiftType = (title: string, name: string): ShiftTypesResponseProps => {
  return { title, name, _id: nanoid() };
};

/**
 * Retrieves the height of each row (week) in the calendar.
 *
 * @param {Element} node - The root node of the calendar.
 * @returns {Object} An object where the keys are the week row numbers and the values are the heights of the rows in pixels.
 * If the node is null or undefined, the function logs an error and returns undefined.
 * If the node does not contain a calendar body or week rows, the function returns an empty object.
 */
const getWeeksSize = (node: Element) => {
  if (node === null || node === undefined) {
    console.error('Invalid type of node');
    return;
  }

  const calendarBody = node?.querySelector('.fc-scrollgrid-sync-table');
  const weekRows = calendarBody?.querySelector('tbody')?.children;
  const result: { [key: number]: string } = {};

  if (weekRows) {
    Array.from(weekRows).forEach((row, index) => {
      result[index] = (row as HTMLElement).getBoundingClientRect().height.toFixed(2) + 'px';
    });
  }

  return result;
};

const normalizeWeekTotalDuration = (minutes: number) => {
  if (typeof minutes !== 'number') console.error('Invalid type of minutes');

  if (minutes < 0) return;

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours} hrs ${remainingMinutes} min`;
  } else if (hours > 0 && remainingMinutes <= 0) {
    return `${hours} hrs`;
  } else if (hours <= 0 && remainingMinutes > 0) {
    return `${remainingMinutes} min`;
  }
};

/**
 * Combines the durations of 'break' and 'time-off' events into a single total.
 *
 * @param {TotalHours[]} totalHours - An array of objects representing the total hours for each event type.
 * @returns {TotalHours[]} An array of objects representing the total hours for each event type, with 'break' and 'time-off' combined into a single total.
 * If totalHours is undefined or not an array, the function logs an error and returns undefined.
 * If totalHours does not contain any 'break' or 'time-off' items, the function returns totalHours as is.
 * If totalHours contains 'break' and/or 'time-off' items, the function returns a new array where these items are combined into a single item with a combined duration.
 */
const combineBreakAndTimeOff = (totalHours: TotalHours[]) => {
  if (totalHours === undefined || !Array.isArray(totalHours)) {
    console.error('Invalid type of totalHours');
    return;
  }

  // Filter out 'break' and 'time-off' items
  const timeOffItems = totalHours.filter(
    (item) => item?.shiftType?.name === 'break' || item?.shiftType?.name === 'time-off',
  );

  // If timeOffItems is not empty, calculate total duration and add a new item to combinedTotalHours
  if (timeOffItems.length > 0) {
    // Calculate total duration for 'break' and 'time-off' items
    const totalTimeOffDuration = timeOffItems.reduce((total, item) => total + (item.duration || 0), 0);

    // Filter out items that are not 'break' or 'time-off'
    const combinedTotalHours = totalHours.filter(
      (item) => item?.shiftType?.name !== 'break' && item?.shiftType?.name !== 'time-off',
    );

    // Check if there is an existing 'time-off' item
    const timeOffIndex = totalHours.findIndex((item) => item?.shiftType?.name === 'time-off');

    let shiftType;
    if (timeOffIndex !== -1) {
      // If there is an existing 'time-off' item, use its shiftType
      shiftType = totalHours[timeOffIndex].shiftType;
    } else {
      // Otherwise, create a new shiftType
      shiftType = { title: 'Day off', name: 'time-off', _id: nanoid() };
    }

    // Add the combined 'break' and 'time-off' item to the list
    combinedTotalHours.push({
      shiftType: shiftType,
      duration: totalTimeOffDuration,
      parsedDuration: normalizeWeekTotalDuration(totalTimeOffDuration),
    });

    return combinedTotalHours;
  }

  // If timeOffItems is empty, return totalHours as is
  return totalHours;
};

/**
 * Normalizes total duration by week.
 *
 * @param {GetTotalHoursProps} props - An object containing the necessary properties to calculate total hours.
 * @param {string} props.timezone - The timezone to use for date calculations.
 * @param {Object} props.totalDurationBreakdownByWeek - An object where the keys are ISO strings representing the start of each week and the values are array of objects representing the total duration of each event type for that week.
 * @param {Date[]} props.startWeekDates - An array of Date objects representing the start of each week.
 * @param {ShiftTypesResponseProps[]} props.shiftTypes - An array of objects representing the different shift types.
 * @returns {TotalHours[][]} An array of arrays, where each inner array represents a week and contains objects representing the total hours for each event type for that week.
 * If startWeekDates or shiftTypes is not an array, the function logs an error and returns undefined.
 * If totalDurationBreakdownByWeek does not contain an entry for a week, the function returns an empty array for that week.
 * If totalDurationBreakdownByWeek contains an entry for a week, the function returns an array of objects representing the total hours for each event type for that week, with 'break' and 'time-off' combined into a single total.
 */
const getTotalHours = ({ timezone, totalDurationBreakdownByWeek, startWeekDates, shiftTypes }: GetTotalHoursProps) => {
  if (!Array.isArray(startWeekDates) || !Array.isArray(shiftTypes)) {
    console.error('Invalid type of startWeekDates or shiftTypes');
    return;
  }

  return startWeekDates.map((date) => {
    // parse time in user timezone
    const parsedTime = dayjs(date.toISOString()).tz(timezone, true).toISOString();

    if (totalDurationBreakdownByWeek[parsedTime] && shiftTypes?.length) {
      const totalDuration: {
        shiftType: ShiftTypesResponseProps;
        parsedDuration?: string;
        duration: number;
      }[] = [];

      totalDurationBreakdownByWeek[parsedTime].forEach((item) => {
        let eventType;

        if (item.total === 0) {
          return null;
        } else {
          if (item.type !== EventTypes.BREAK && item.type !== EventTypes.TIME_OFF) {
            eventType = shiftTypes.find((type) => type._id === item.shiftType);
          } else if (item.type === EventTypes.BREAK) {
            eventType = createShiftType('Break', 'break');
          } else if (item.type === EventTypes.TIME_OFF) {
            eventType = createShiftType('Day off', 'time-off');
          }

          const parsedTotalHours = normalizeWeekTotalDuration(item.total);

          eventType &&
            totalDuration.push({
              shiftType: eventType,
              duration: item.total,
              parsedDuration: parsedTotalHours,
            });
        }
      });

      return combineBreakAndTimeOff(totalDuration);
    }
  });
};

export { getWeeksSize, getTotalHours };
