import { Common } from '@thecvlb/design-system';
import { FallbackProps } from 'react-error-boundary';

import { getErrorMessage } from 'utils/common/errors';

const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
  const handleClose = () => {
    if (resetErrorBoundary) resetErrorBoundary();
    window.location.replace('/dashboard/tasks?limit=50&pageNo=0');
  };

  const errorMessage = getErrorMessage(error);

  return (
    <Common.Modal isOpen zIndex={50} close={handleClose} size="base">
      <h2 className="text-xl text-primary">Oops, something went wrong.</h2>
      {!!errorMessage?.length && <p className="my-3 text-base">{errorMessage}</p>}
      <p className="my-3 text-base">Please click a close button to reload the page.</p>
    </Common.Modal>
  );
};

export default ErrorFallback;
