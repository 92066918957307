import dayjs from 'dayjs';

import { PlanCodesProps } from 'enums/appointmentStatus';
import { DateFormat } from 'enums/dateFormats';
import { AppointmentItemProps } from 'store/appointments/appointments.types';

export const isPastAppointment = (val: AppointmentItemProps) =>
  val.missedAppointment || val.pastAppointment || val.appointmentStatus === 'cancelled';

export const getUpcomingAppointments = (appointments: AppointmentItemProps[]) => {
  return appointments
    .filter((v) => !!v.appointmentTime?.startTime || v.category === 'queue')
    .filter((val) => !isPastAppointment(val))
    .filter((val) => val.appointmentStatus !== 'completed')
    .sort((a, b) => dayjs(b.appointmentTime?.startTime).diff(a.appointmentTime?.startTime))
    .sort((a, b) => +(b?.isStartsSoon || 0) - +(a?.isStartsSoon || 0));
};

export const checkCompletedOnboardingAppt = (appointmentsList: AppointmentItemProps[]) => {
  return appointmentsList.some((item) => {
    return (
      item.appointmentStatus === 'completed' && item.hasPatientJoined && item.hasStaffJoined && !item.missedAppointment
    );
  });
};

export const checkWmNotCompletedOnboarding = (activePlanCode: string, completedOnboarding: boolean) => {
  return activePlanCode === PlanCodesProps.WeightManagementMembership ? !completedOnboarding : false;
};

export const convertNumToWord = (v: number): number | string => {
  const values = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
  };
  return values[v as keyof typeof values] || v;
};

export const formatDateForScheduling = (date: string) => dayjs(date).format(DateFormat.MMM_DD);

export const getDaysList = (dates: number | string[] = 7, withCalendarOption = true, withASAPOption = false) => {
  const parsedDates =
    typeof dates === 'number'
      ? Array(dates)
          .fill(null)
          .map((_, i) => dayjs().add(i, 'day').format(DateFormat.YYYY_MM_DD))
      : dates;

  return parsedDates.flatMap((elem, i) => {
    const date = dayjs(elem);
    const item = date.format(DateFormat.YYYY_MM_DD);
    if (i === 0) {
      return withASAPOption ? ['asap', item] : [item];
    }
    if (i === parsedDates.length - 1) {
      return withCalendarOption ? [item, 'calendar'] : [item];
    }
    return [item];
  });
};

export const parseUpcomingAppointment = (item: AppointmentItemProps) => {
  const MINUTES_TO_MEETING = -15;
  const diff = dayjs().diff(item.appointmentTime?.startTime, 'm');
  if (diff >= MINUTES_TO_MEETING && item.appointmentStatus === 'pending') {
    return {
      ...item,
      isStartsSoon: true,
    };
  }
  return item;
};
