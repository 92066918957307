export const MEMBERSHIP_PROSPECT = [
  { label: 'Unlimited Membership', value: '60a64ff7a5142723dc9706f1' },
  { label: 'LifeMD Membership', value: '60a65031ba986f141c02b7dc' },
];

export const PROSPECT_STATUS_OPTIONS = [
  { label: 'New', value: 'New' },
  { label: 'Called', value: 'Called' },
  { label: 'Ordered', value: 'Ordered' },
  { label: 'Not interested', value: 'Not interested' },
];
