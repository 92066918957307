import { forwardRef } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';
import { MessageEvents } from 'enums/messages';

import { AppointmentSystemMessageProps } from './appointmentSystemMessage.types';
import { getProfileImageWrapperClassName } from '../Message/message.settings';

const AppointmentSystemMessage = forwardRef<HTMLDivElement, AppointmentSystemMessageProps>(
  ({ message, self = false }, ref) => {
    const isASAP = !message.appointmentDate;
    const messageWrapperClasses = getProfileImageWrapperClassName(self, false);
    const seenStatusClasses = classNames('w-full text-mXs text-gray-500', self ? 'text-right' : 'text-left');
    const nameClasses = classNames('flex text-gray items-center text-xs text-left -mt-2 mb-2', {
      'ml-auto flex-row-reverse': self,
      'mr-auto': !self,
    });
    const appointmentDate = isASAP ? 'ASAP' : dayjs(message.appointmentDate).format(DateFormat.MMM_D__h_mm_a_z);
    const messageClasses = classNames('w-fit rounded-2xl py-3 px-5 bg-secondary-100', {
      'rounded-br-none': self,
      'rounded-bl-none': !self,
    });
    const appointmentStatusClassName = classNames('mt-1 font-medium text-sm text-gray whitespace-normal', {
      'line-through': message.event !== MessageEvents.AppointmentCreated,
    });
    const wrapperMessageBlockClasses = classNames('flex flex-col gap-3', { 'items-end': self });
    const appointmentResultClasses = classNames(
      'flex items-center gap-3 rounded-xl rounded-bl-none bg-gradient-to-b from-[#002632] to-[#00384A] px-4 py-3',
      {
        'opacity-30': message.event === MessageEvents.AppointmentMissed,
      },
    );

    return (
      <div data-testid="message" className={messageWrapperClasses} ref={ref}>
        <div className="mb-[26px] mr-3 h-12 w-12 min-w-12 rounded-full bg-secondary-100 p-2">
          <Common.Logo name="cross" className="h-full w-full" />
        </div>

        <div className={wrapperMessageBlockClasses}>
          {message.appointmentType &&
          (message.event === MessageEvents.AppointmentCreated ||
            message.event === MessageEvents.AppointmentCancelled) ? (
            //  Appointment created or canceled
            <div data-testid="msg_container" className={messageClasses}>
              <p className="text-sm font-bold text-gray-700">
                Appointment {message.event === MessageEvents.AppointmentCreated ? 'scheduled' : 'canceled'}
              </p>
              <p className={appointmentStatusClassName}>{message.appointmentType}</p>
              <p className={appointmentStatusClassName}>{appointmentDate}</p>
              {message.message && !isASAP && (
                <p data-testid="msg_content" className="mt-3 text-base font-medium text-gray-700">
                  {message.message}
                </p>
              )}
            </div>
          ) : (
            //   Appointment complete or missed
            <div className={appointmentResultClasses}>
              <div className="h-7 w-7 rounded-lg bg-secondary p-1">
                <Common.Icon
                  name={message.event === MessageEvents.AppointmentComplete ? 'phone-filled' : 'phone-end-filled'}
                  className="h-full w-full text-white"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-bold text-white">
                  {message.event === MessageEvents.AppointmentComplete
                    ? 'Consultation complete'
                    : 'Consultation missed'}
                </p>
                {message.event === MessageEvents.AppointmentComplete ? (
                  <span className="text-xs font-semibold text-white/50">Call ended</span>
                ) : null}
              </div>
            </div>
          )}

          <span data-testid="message_datestamp" className={nameClasses}>
            {!self ? (
              `${dayjs(message.date).format(DateFormat.h_mm_a)}`
            ) : (
              <span className={seenStatusClasses}>{dayjs(message.date).format(DateFormat.h_mm_a)}</span>
            )}
          </span>
        </div>
      </div>
    );
  },
);

export default AppointmentSystemMessage;
