import isEmpty from 'lodash/isEmpty';

import { BodyImageManualStatuses } from 'models/bodyImage.types';
import { ManualVerifyStatus } from 'models/patientIdentity.types';
import { BodyImage, Identity } from 'models/tasks.types';

export const getIsIdentityVerified = (identity: Identity) => {
  const isVerifiedByVouched = identity.isVerifiedByVouched;
  const isCrosscheckVerified = identity.crosscheckVerified;

  if (!isEmpty(identity.manualVerifyStatus)) {
    return identity.manualVerifyStatus === ManualVerifyStatus.verified;
  }

  return isVerifiedByVouched || isCrosscheckVerified;
};

export const getIsNotSubmitted = (identity: Identity) => {
  return (
    !identity.isVerifiedByVouched &&
    !identity.crosscheckVerified &&
    !identity.governmentIssuedId &&
    !identity.selfPortrait
  );
};

export const getShowIdentityBadge = (identity: Identity) => {
  return (
    identity &&
    !identity.isVerifiedByVouched &&
    identity.manualVerifyStatus !== ManualVerifyStatus.verified &&
    !identity?.crosscheckVerified &&
    identity.governmentIssuedId &&
    identity.selfPortrait
  );
};

export const getShowBodyImageBadge = (bodyImage: BodyImage) => {
  return bodyImage && bodyImage.manualStatus !== BodyImageManualStatuses.ACCEPTED && bodyImage.documents?.length > 0;
};
