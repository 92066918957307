import React, { useCallback, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { useForm } from 'react-hook-form';

import { notifySuccess } from 'components/common/Toast/Toast';
import InputField from 'components/forms/controlled/InputField';
import { EmailStatus } from 'enums/emailStatus';
import { useUpdateEmailMutation } from 'store/patients/patientsSlice';
import { validation } from 'utils/helpers';
import { EMAIL_REGEXP } from 'utils/regExp';

import { EmailInfoProps } from './emailInfo.types';

type FormValues = {
  email: string;
};

const EmailInfo: React.FC<EmailInfoProps> = ({ email, emailStatus, patientId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updateEmail, { isLoading }] = useUpdateEmailMutation();

  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    mode: 'onChange',
  });

  const onSubmit = (data: FormValues) => {
    updateEmail({ id: patientId, email: data.email })
      .unwrap()
      .then((res) => {
        notifySuccess(res?.message);
        setIsEditing(false);
      });
  };

  const handleMail = useCallback(() => {
    if (email) window.open(`mailto:${email}`, '_self');
  }, [email]);

  const emailStatusClasses = classNames(
    'flex items-center rounded w-fit py-0.5 px-2 text-sm font-bold mt-1',
    emailStatus === EmailStatus.Verified ? 'bg-green-100 text-green-600' : 'bg-yellow-100 text-yellow-600',
  );

  const cancelEditing = () => {
    reset();
    setIsEditing(false);
  };

  const statusTitle =
    emailStatus === EmailStatus.Legacy &&
    'Email is unverified, but user account was created before "Verify Email" feature was added.';

  return (
    <div>
      {isEditing && (
        <form onSubmit={handleSubmit(onSubmit)} className="flex items-start gap-1">
          <InputField
            name="email"
            control={control}
            type="email"
            size="md"
            errors={formState.errors.email}
            rules={validation('Email', EMAIL_REGEXP)}
            defaultValue={email}
            disabled={isLoading}
            data-testid="editing-input"
          />
          <div className="flex items-start gap-1 py-[9px]">
            <Common.Button
              style="text-only"
              color="green"
              size="sm"
              preIcon="check-circle"
              type="submit"
              className="!p-0"
              disabled={isLoading}
              data-testid="saveEditing-button"
            />
            <Common.Button
              style="text-only"
              color="white"
              size="sm"
              preIcon="close"
              preIconClassName="text-gray"
              onClick={cancelEditing}
              className="!p-0"
              disabled={isLoading}
              data-testid="cancelEditing-button"
            />
          </div>
        </form>
      )}
      {!isEditing && (
        <div className="flex gap-1">
          <Common.Button
            dataTestId="email_val"
            form="text-only"
            className="break-all !p-0 !font-medium underline"
            onClick={handleMail}
          >
            {email}
          </Common.Button>
          <Common.Button
            data-testid="editEmail-button"
            style="text-only"
            color="white"
            size="sm"
            preIcon="pencil"
            preIconClassName="text-gray"
            onClick={() => setIsEditing(true)}
            className="!p-0"
          />
        </div>
      )}
      <div title={statusTitle || ''} className={emailStatusClasses}>
        {capitalize(emailStatus)}
      </div>
    </div>
  );
};

export default EmailInfo;
