import { useMemo } from 'react';

import * as RadioGroup from '@radix-ui/react-radio-group';
import { Common } from '@thecvlb/design-system';
import { useForm } from 'react-hook-form';

import { RoleShortName } from 'enums/role';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { EditEventOption, EditEventOptions } from 'models/event.types';
import { closeModal } from 'store/modal/modalSlice';
import { selectUser } from 'store/user/userSlice';

import { DeleteEventFormProps, FormValues } from './deleteEvent.types';

const indicatorClasses =
  'flex h-full w-full items-center justify-center after:h-1.5 after:w-1.5 after:rounded-full after:bg-white';
const labelClasses = 'flex flex-col text-base text-gray-700 font-medium';
const forGroupClasses = 'flex items-center gap-2 mb-2';

const DeleteShiftForm: React.FC<DeleteEventFormProps> = ({
  onSave,
  isLoading,
  isLoadingEventDetails,
  isRecurring,
  type,
}) => {
  const { userType } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { watch, setValue, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      deleteShiftOption: EditEventOptions.CURRENT,
    },
  });
  const isAdmin = userType.shortCode === RoleShortName.Admin;

  const getRadioItemClasses = (value: string) => {
    return `h-4 w-4 rounded-full border ${
      watch('deleteShiftOption') === value ? 'border-blue bg-blue' : 'border-gray-400 bg-white'
    }`;
  };

  const options = useMemo(
    () => ({
      [EditEventOptions.CURRENT]: type === 'time-off' ? 'This time off' : 'This shift',
      ...(isRecurring && {
        [EditEventOptions.CURRENT_AND_FOLLOWING]:
          type === 'time-off' ? 'This and following instances of time off' : 'This and following shifts',
      }),
      ...(isAdmin &&
        isRecurring && {
          [EditEventOptions.ALL]:
            type === 'time-off'
              ? 'This and all time-offs before and after it'
              : 'This and all shifts before and after it',
        }),
    }),
    [isAdmin, isRecurring, type],
  );

  const isDisabled = watch('deleteShiftOption') === undefined || isLoading;

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const onSubmit = (data: FormValues) => {
    onSave(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RadioGroup.Root
        value={watch('deleteShiftOption')}
        onValueChange={(value) => setValue('deleteShiftOption', value as EditEventOption, { shouldDirty: true })}
      >
        {!isLoadingEventDetails ? (
          Object.entries(options).map((entry) => {
            const [key, value] = entry;

            return (
              <div className={forGroupClasses} key={key}>
                <RadioGroup.Item value={key} className={getRadioItemClasses(key)} id={key}>
                  <RadioGroup.Indicator className={indicatorClasses} />
                </RadioGroup.Item>
                <label className={labelClasses} htmlFor={key}>
                  {value}

                  {key === EditEventOptions.ALL && (
                    <span className=" text-xs">
                      <strong>Caution:</strong> selecting this last option will delete historical data
                    </span>
                  )}
                </label>
              </div>
            );
          })
        ) : (
          <div className="flex animate-pulse flex-col gap-2" data-testid="delete-event-options-skeleton">
            <div className="h-4 w-2/5 rounded-lg bg-slate-200" />
            <div className="h-4 w-3/5 rounded-lg bg-slate-200" />
            <div className="h-4 w-4/5 rounded-lg bg-slate-200" />
          </div>
        )}
      </RadioGroup.Root>
      <div className="mt-6 flex items-center gap-2">
        <Common.Button
          color="white-alt"
          size="sm"
          className="w-full justify-center"
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </Common.Button>
        <Common.Button disabled={isDisabled} color="blue" size="sm" className="w-full justify-center" type="submit">
          Confirm delete
        </Common.Button>
      </div>
    </form>
  );
};

export default DeleteShiftForm;
