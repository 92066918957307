import classNames from 'classnames';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';
import { convertNumToWord } from 'utils/appointment';
import { dateTimeFormatter } from 'utils/datesAndTime';

import { getPaymentNumbers } from './providerAppointmentCard.settings';
import { ProviderAppointmentCardProps } from './providerAppointmentCard.types';

const ProviderAppointmentCard: React.FC<ProviderAppointmentCardProps> = ({
  category,
  providerName = 'doctor',
  time,
  pricePoint,
  discount,
  creditBalance,
  membershipData,
}) => {
  const freeAppointmentInfo = membershipData?.freeAppointmentInfo;
  const insuranceEligibility = membershipData?.insuranceEligibility;

  const insuranceFinalCost = insuranceEligibility?.finalCost;
  const insuranceFixedFinalCost = insuranceFinalCost
    ? `${(insuranceFinalCost / 100).toFixed((insuranceFinalCost / 100) % 1 !== 0 ? 2 : 0)}`
    : '';

  const { discountValue, creditUsed, finalPrice } = getPaymentNumbers(
    insuranceFixedFinalCost || pricePoint.subsequentAppointmentCost,
    freeAppointmentInfo?.isFree ? '100%' : discount,
    creditBalance,
  );

  const rowClassName = 'mb-1 flex justify-between font-bold md:mb-0.5';
  const rowWrapperClassName = 'mb-4 md:mb-3';
  const subheadingClassName = 'block text-mSm md:text-sm';

  const shouldShowBottomBanner = !insuranceEligibility && !insuranceFixedFinalCost;
  const notFreeAppointmentMessage = `Your plan includes access to single-issue visits for $${pricePoint.subsequentAppointmentCost}.`;
  const freeAppointmentMessage =
    freeAppointmentInfo &&
    `You have ${
      convertNumToWord(Number(freeAppointmentInfo.freeAppointmentsAmount)) || ''
    } free appointment available to be scheduled between ${dayjs(freeAppointmentInfo.periodStart).format(
      DateFormat.MMM_DD,
    )} and ${dayjs(freeAppointmentInfo.periodEnd).format(DateFormat.MMM_DD)}`;

  return (
    <div className="mb-4 rounded-2xl border border-secondary-200 bg-secondary-50 px-4 py-6 md:p-6">
      <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
        <div className={rowClassName}>
          <span className="block">
            {!!insuranceEligibility && insuranceFixedFinalCost ? 'Copay' : 'Provider appointment'}
          </span>
          <span className="">{`$${insuranceFixedFinalCost || pricePoint.subsequentAppointmentCost}`}</span>
        </div>
        {time && category && (
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>
            {`Your ${category} visit. With ${providerName} ${dateTimeFormatter(time)}`}
          </span>
        )}
      </div>
      {freeAppointmentInfo?.isFree && (
        <div className={classNames(rowWrapperClassName, 'text-orange')}>
          <div className={rowClassName}>
            <span className="block">Appointment credit</span>
            <span className="">{`-$${pricePoint.subsequentAppointmentCost}`}</span>
          </div>
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>{`${dayjs(
            freeAppointmentInfo.periodStart,
          ).format(DateFormat.MMM_DD)} - ${dayjs(freeAppointmentInfo.periodEnd).format(DateFormat.MMM_DD)}`}</span>
        </div>
      )}
      {!!discount && (
        <div className={classNames(rowWrapperClassName, 'text-orange')}>
          <div className={rowClassName}>
            <span className="block">{`Discount: ${discount} off`}</span>
            <span>-${discountValue}</span>
          </div>
        </div>
      )}
      {!!creditBalance && (
        <div className={classNames(rowWrapperClassName, 'text-primary')}>
          {+creditUsed != 0 && (
            <div className={rowClassName}>
              <span className="block">LifeMD Credit applied</span>
              <span>{`$${creditUsed}`}</span>
            </div>
          )}
          {+creditBalance != 0 && <span className={subheadingClassName}>{`Available credit: $${creditBalance}`}</span>}
        </div>
      )}
      <div className="my-4 h-px w-full bg-secondary" />

      <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
        <div className={rowClassName} data-testid="total_due">
          <span>Total due now</span>
          <span>{`$${finalPrice}`}</span>
        </div>
      </div>
      {shouldShowBottomBanner && (
        <p className="text-mSm md:text-sm">
          {freeAppointmentInfo?.isFree ? freeAppointmentMessage : notFreeAppointmentMessage}
        </p>
      )}
    </div>
  );
};

export default ProviderAppointmentCard;
