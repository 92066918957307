import { useCallback, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import { useAppDispatch } from 'hooks/redux';
import socket from 'socket/socketMessages';
import { resetBenefits, resetPatientInfo, resetPaymentProfiles } from 'store/patients/patientsSlice';
import { reset } from 'store/slidingInPane/slidingInPaneSlice';
import { clearStaffNotes } from 'store/staffNotes/staffNotesSlice';
import { resetTask } from 'store/tasks/tasksSlice';

export const useSlidingPane = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isPhoneCallActive, resetPhoneCallProps, hasGlobalInstance, hasSlidingPanelInstance, showCallCard } =
    usePhoneCall();

  const resetSlidingPaneState = useCallback(() => {
    dispatch(resetTask());
    dispatch(reset());
    dispatch(resetBenefits());
    dispatch(resetPaymentProfiles());
    dispatch(clearStaffNotes());
    !showCallCard && dispatch(resetPatientInfo());

    // NOTE: reset and close the Phone call pop-up if there is no online call
    if (!isPhoneCallActive && hasSlidingPanelInstance) {
      resetPhoneCallProps();
    }

    // NOTE: we need to close the socket connection when the sliding pane is closed
    socket.disconnect();
  }, [dispatch, isPhoneCallActive, hasGlobalInstance, hasSlidingPanelInstance]);

  const handleClose = useCallback(() => {
    if (searchParams.has('taskModalOpenID')) {
      searchParams.delete('messageType');
      searchParams.delete('taskModalOpenID');
      setSearchParams(searchParams);
    }

    resetSlidingPaneState();
  }, [resetSlidingPaneState, searchParams, setSearchParams, isPhoneCallActive]);

  return useMemo(() => ({ handleClose, resetSlidingPaneState }), [handleClose, resetSlidingPaneState]);
};
