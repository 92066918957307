import { Common } from '@thecvlb/design-system';

import { MEDICAL_HISTORY_FORM_FIELDS } from 'constants/user';
import { HealthConditionsItemProps } from 'store/patientChart/patientChart.types';

export interface HealthConditionsProps {
  dataTestId?: string;
  healthConditions: HealthConditionsItemProps[];
  loading?: boolean;
  onUpdate: (options: HealthConditionsItemProps[]) => void;
}

const HealthConditions: React.FC<HealthConditionsProps> = ({ onUpdate, healthConditions, loading = false }) => {
  const optionNamesList = healthConditions.map((v) => v.name);
  const NONE_ELEMENT = MEDICAL_HISTORY_FORM_FIELDS.find((e) => e.fieldID === '0');

  const handleChange = (name: string) => {
    let history: HealthConditionsItemProps[];
    if (optionNamesList.includes(name)) {
      history = healthConditions.filter((field) => field.name !== name);
    } else {
      if (name === NONE_ELEMENT?.fieldID) {
        history = [{ name, notes: '' }];
      } else {
        history = [...healthConditions, { name, notes: '' }].filter((e) => e.name !== NONE_ELEMENT?.fieldID);
      }
    }
    onUpdate(history);
  };

  const handleChangeNotes = (id: string, notes: string) => {
    onUpdate(healthConditions.map((v) => (v.name === id ? { ...v, notes } : v)));
  };

  return (
    <div className="flex flex-col gap-2" data-testid="health_condition">
      {MEDICAL_HISTORY_FORM_FIELDS.map((el) => (
        <Common.RichCheckbox
          checked={optionNamesList.includes(el.fieldID)}
          dataTestId="checkbox"
          disabled={loading}
          key={el.fieldID}
          label={el.label}
          value={el.fieldID}
          onChange={() => handleChange(el.fieldID)}
        >
          {optionNamesList.includes(el.fieldID) && el.fieldID !== NONE_ELEMENT?.fieldID && (
            <Common.TextArea
              dataTestId="text_area"
              disabled={loading}
              label={`Notes ${loading ? '- saving...' : ''}`}
              name="drug"
              placeholder={`Please add any relevant notes here...\n- Are you being treated for this? How? \n- When did it ${
                el.label === 'Recently suffered an injury' ? 'happen' : 'start'
              }?`}
              rows={5}
              value={healthConditions.find((value) => value.name === el.fieldID)?.notes}
              onChange={(e) => handleChangeNotes(el.fieldID, e.target.value)}
            />
          )}
        </Common.RichCheckbox>
      ))}
    </div>
  );
};

export default HealthConditions;
