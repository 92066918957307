enum RenewalBenefitsStatus {
  InProgress = 'InProgress',
  Complete = 'Complete',
  Error = 'Error',
  NoInsurance = 'NoInsurance',
}

enum BenefitsStatus {
  MissingInsurance = 'missing-insurance',
  IncorrectInsurance = 'incorrect-insurance',
  BenefitsIncomplete = 'benefits-incomplete',
  BenefitsComplete = 'benefits-complete',
}

enum OnboardingStatusLabels {
  'missing-insurance' = 'Missing insurance',
  'incorrect-insurance' = 'Bad address',
  'benefits-incomplete' = 'Benefits incomplete',
  'benefits-complete' = 'Insurance complete',
  'mif-normal' = 'MIF normal',
  'mif-warning' = 'MIF warnings',
}

enum OnboardingDetailsLabel {
  'only-problems' = 'Only show problems',
  'onboarded-by-staff' = 'Onboarded by staff',
  'not-onboarded-by-staff' = 'Not onboarded by staff',
  'address-verified' = 'Verified address',
  'address-not-verified' = 'Not verified address',
  'insurance-benefits-covered' = 'Complete - Covered',
  'insurance-benefits-not-covered' = 'Complete - Not covered',
  'insurance-benefits-pa-required' = 'PA required',
  'insurance-benefits-pa-requested' = 'PA requested',
  'insurance-benefits-plan-inactive' = 'Plan inactive',
  'insurance-benefits-incomplete-or-missing-insurance' = 'Incomplete insurance',
  'insurance-not-provided' = 'Insurance not provided',
  'insurance-waiting-benefits' = 'Waiting for benefits',
  'mif-normal' = 'MIF normal',
  'mif-warning' = 'MIF warnings',
  'mif-incomplete' = 'MIF incomplete',
  'identity-verified' = 'Verified Identity',
  'identity-not-verified' = 'Not verified identity',
  'symptoms-checker-complete' = 'Symptoms complete',
  'symptoms-checker-skipped' = 'Symptoms skipped',
  'symptoms-checker-incomplete' = 'Symptoms incomplete',
  'symptoms-checker-urgent' = 'Symptoms urgent',
}

enum MIFStatus {
  Normal = 'mif-normal',
  Warnings = 'mif-warnings',
  Incomplete = 'mif-incomplete',
}

enum InsuranceStatuses {
  INSURANCE_COVERED = 'insurance-benefits-covered',
  INSURANCE_NOT_COVERED = 'insurance-benefits-not-covered',
  INSURANCE_PA_REQUIRED = 'insurance-benefits-pa-required',
  INSURANCE_PA_REQUESTED = 'insurance-benefits-pa-requested',
  INSURANCE_PLAN_INACTIVE = 'insurance-benefits-plan-inactive',
  INSURANCE_INCOMPLETE_OR_MISSING_INSURANCE = 'insurance-benefits-incomplete-or-missing-insurance',
  INSURANCE_NOT_PROVIDED = 'insurance-not-provided',
  INSURANCE_WAITING_BENFITS = 'insurance-waiting-benefits',
}

enum PARequestStatus {
  CREATED = 'pa-request-created',
  SENT = 'pa-requested-sent',
  APPROVED = 'pa-request-approved',
  DENIED = 'pa-request-denied',
  ERROR = 'pa-request-error',
}

enum OnboardingStatus {
  TASK_ONLY_PROBLEMS = 'only-problems',
  // On boarded by staff
  ON_BOARDED_BY_STAFF = 'onboarded-by-staff',
  NOT_ON_BOARDED_BY_STAFF = 'not-onboarded-by-staff',
  // Address
  ADDRESS_VERIFIED = 'address-verified',
  ADDRESS_NOT_VERIFIED = 'address-not-verified',
  // Insurance
  INSURANCE_COVERED = 'insurance-benefits-covered', // Completed - Covered
  INSURANCE_NOT_COVERED = 'insurance-benefits-not-covered', // Completed - Not Covered
  INSURANCE_PA_REQUIRED = 'insurance-benefits-pa-required', // PA Required
  INSURANCE_PA_REQUESTED = 'insurance-benefits-pa-requested', // PA Requested
  INSURANCE_PLAN_INACTIVE = 'insurance-benefits-plan-inactive', // Plan inactive
  INSURANCE_INCOMPLETE_OR_MISSING_INSURANCE = 'insurance-benefits-incomplete-or-missing-insurance', // Incomplete - If patient doesn't complete filling out this information, OR if benefits check comes back incomplete.
  INSURANCE_NOT_PROVIDED = 'insurance-not-provided', // Insurance not provided
  INSURANCE_WAITING_BENEFITS = 'insurance-waiting-benefits', // Waiting for benefits check
  // Medical intake form
  MIF_NORMAL = 'mif-normal',
  MIF_WARNING = 'mif-warning',
  MIF_INCOMPLETE = 'mif-incomplete',
  // Identity
  IDENTITY_VERIFIED = 'identity-verified',
  IDENTITY_NOT_VERIFIED = 'identity-not-verified',
  // Symptoms
  SC_INCOMPLETE = 'symptoms-checker-incomplete',
  SC_SKIPPED = 'symptoms-checker-skipped',
  SC_COMPLETE = 'symptoms-checker-complete',
  SC_URGENT = 'symptoms-checker-urgent',
}

const DosagePreferences = {
  NO_INCREASE: 'no-increase',
  DECREASE: 'to-decrease',
} as const;

const RenewalStatus = {
  WAITING_FOR_PATIENT_TO_COMPLETE_QUESTIONNAIRE: 'waiting-for-patient-to-complete-questionnaire',
  APPOINTMENT_REQUIRED_BY_PROVIDER_WAITING_FOR_PATIENT_TO_SCHEDULE:
    'appointment-required-by-provider-waiting-for-patient-to-schedule',
  APPOINTMENT_REQUIRED_BY_PROVIDER_SCHEDULED: 'appointment-required-by-provider-scheduled',
  APPOINTMENT_REQUIRED_BASED_ON_RESPONSE_WAITING_FOR_PATIENT_TO_SCHEDULE:
    'appointment-required-based-on-response-waiting-for-patient-to-schedule',
  APPOINTMENT_REQUIRED_BASED_ON_RESPONSE_SCHEDULED: 'appointment-required-based-on-response-scheduled',
  PATIENT_COMPLETED_APPOINTMENT_WAITING_FOR_PRESCRIPRION: 'patient-completed-appointment-waiting-for-prescription',
} as const;

const RenewalStatusMessages = {
  [RenewalStatus.WAITING_FOR_PATIENT_TO_COMPLETE_QUESTIONNAIRE]: 'Waiting for patient to complete questionnaire.',
  [RenewalStatus.APPOINTMENT_REQUIRED_BY_PROVIDER_WAITING_FOR_PATIENT_TO_SCHEDULE]:
    'Appointment required by provider, waiting for patient to schedule.',
  [RenewalStatus.APPOINTMENT_REQUIRED_BY_PROVIDER_SCHEDULED]: (date: string = '') =>
    `Appointment required by provider, patient is scheduled for ${date}.`,
  [RenewalStatus.APPOINTMENT_REQUIRED_BASED_ON_RESPONSE_WAITING_FOR_PATIENT_TO_SCHEDULE]:
    'Appointment required based on response, waiting for patient to schedule.',
  [RenewalStatus.APPOINTMENT_REQUIRED_BASED_ON_RESPONSE_SCHEDULED]: (date: string = '') =>
    `Appointment required based on response, patient is scheduled for ${date}.`,
  [RenewalStatus.PATIENT_COMPLETED_APPOINTMENT_WAITING_FOR_PRESCRIPRION]: (date: string = '') =>
    `Patient completed appointment on ${date}, waiting for prescription.`,
} as const;

export {
  RenewalBenefitsStatus,
  OnboardingStatus,
  BenefitsStatus,
  OnboardingStatusLabels,
  OnboardingDetailsLabel,
  PARequestStatus,
  MIFStatus,
  InsuranceStatuses,
  DosagePreferences,
  RenewalStatus,
  RenewalStatusMessages,
};
