import { useCallback, useEffect } from 'react';

import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from 'react-router-dom';

import useLocationParams from 'hooks/common/location/useLocationParams';
import { useAppDispatch } from 'hooks/redux';
import { resetBulkEdits } from 'store/bulkEdit/bulkEditSlice';

import { OrderProps } from '../../../store/orders/orders.types';
import { BasicTableProps } from '../BasicTable/basicTable.types';
import Pagination from '../Pagination';

const OrdersTable = <TData extends object>({
  table,
  tableName,
  isFetching,
  totalCount,
  onClickRow,
}: BasicTableProps<TData>) => {
  const dispatch = useAppDispatch();
  const { [tableName ? `${tableName}` : 'pageNo']: [pageNo = '0'] = [], sortField } = useLocationParams();
  const [searchParams] = useSearchParams();
  const defaultColumnWidth = 150;

  const count = 50;
  const showingFrom = +pageNo + 1 === 1 ? +pageNo + 1 : +pageNo * count + 1;
  const showingTo = (+pageNo + 1) * count > totalCount ? totalCount : (+pageNo + 1) * count;
  const showPagination = isNumber(totalCount) && totalCount > 0;

  const getThClasses = (columnId: string) =>
    classNames(
      `p-3.5 text-left`,
      (sortField?.[0] === 'uniqueId' && columnId === '_id') ||
        sortField?.[0] === columnId ||
        sortField?.[0].split('.')[0] === columnId.split('.')[0]?.split('[')[0]
        ? 'bg-gray-200'
        : 'bg-gray-100',
    );

  const getRowStyles = (isRowActive?: boolean) => {
    const rowHeight = 'h-14';
    const activeRowStyle = 'bg-blue-50 border-y border-blue-200';
    const inactiveRowStyle = 'border-t border-gray-100';
    const textSize = 'text-sm';

    return `${rowHeight} ${isRowActive ? activeRowStyle : inactiveRowStyle} ${textSize}`;
  };

  const getTableRowClasses = (taskId: string) =>
    classNames('cursor-pointer hover:bg-gray-50', getRowStyles(searchParams.get('orderModalOpenID') === taskId));

  const resetBulkEditState = useCallback(() => {
    table.resetRowSelection();
    dispatch(resetBulkEdits());
  }, [dispatch, table]);

  useEffect(() => {
    return () => {
      dispatch(resetBulkEdits());
    };
  }, [dispatch]);

  return (
    <>
      <div data-testid="table_container" className="relative h-4/5 min-h-fit w-full max-w-full overflow-x-scroll">
        {table.getRowModel().rows.length ? (
          <table data-testid="data_table" className="w-full rounded-lg">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="sticky top-0 z-10">
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className={getThClasses(header.column.id)}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="w-fit bg-white">
              {table.getRowModel().rows.map((row) => {
                const orderData = row.original as OrderProps;

                return (
                  <tr
                    data-testid="table_row"
                    key={row.id}
                    className={getTableRowClasses(orderData._id)}
                    onClick={() => onClickRow && onClickRow(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const celWidth =
                        cell.column.columnDef.size === defaultColumnWidth ? 'auto' : cell.column.columnDef.size;
                      return (
                        <td
                          key={cell.id}
                          className="truncate p-3 text-sm font-medium"
                          style={{
                            width: celWidth,
                            maxWidth: celWidth,
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="my-2 p-4 text-center">{isFetching ? 'Loading...' : 'No data available'}</p>
        )}
      </div>

      <div
        className={classNames('flex max-w-full items-center justify-between', {
          'p-4': (showPagination && !isFetching) || isFetching,
        })}
      >
        {showPagination && (
          <>
            <Pagination
              loading={isFetching}
              showingFrom={showingFrom}
              showingTo={showingTo}
              totalCount={totalCount}
              pageNoParamName={tableName}
              resetBulkEditState={resetBulkEditState}
            />
          </>
        )}
        {showPagination && isFetching && (
          <>
            <Skeleton width={160} height={30} />
            <Skeleton width={160} height={30} />
          </>
        )}
      </div>
    </>
  );
};

export default OrdersTable;
