import { useEffect, useMemo } from 'react';

import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import Loader from 'components/common/Loader';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import ReleaseOrderFromHold from 'components/modals/ReleaseOrderFromHold';
import { formatHeaderText, getColorMapValue } from 'components/tables/OrdersTable/ordersTable.settings';
import { OrderLabels } from 'enums/orderLabels';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { useUpdateOrderMutation } from 'store/orders/ordersSlice';

import EditButtons from './EditButtons';
import {
  getItems,
  getShowReleaseFromHold,
  getSubmitOrderData,
  orderOptions,
  pharmacyOptions,
  shippingOptions,
  sortOptions,
} from './orderInfo.settings';
import { OrderFormValues, OrderInfoProps } from './orderInfo.types';

const OrderInfo: React.FC<OrderInfoProps> = ({
  order,
  headingClassNames,
  wrapperClassNames,
  labelClassNames,
  isEditable,
}) => {
  const isReleaseFromHoldDisabled = useFlag('disable-release-order-from-hold');
  const [isEditing, setIsEditing] = useToggle(false);
  const dispatch = useAppDispatch();
  const [updateOrder, { isLoading: isLoadingEdit }] = useUpdateOrderMutation();

  const { handleSubmit, control, reset } = useForm<OrderFormValues>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const headingClasses = classNames(headingClassNames || 'mb-2 text-base font-bold text-gray-700');
  const wrapperClasses = classNames(wrapperClassNames || 'grid grid-cols-2 gap-x-6 gap-y-2');
  const labelClasses = classNames('text-base mr-5 min-w-fit flex-none text-gray', labelClassNames || 'w-28');
  const showReleaseFromHoldButton = !isReleaseFromHoldDisabled && getShowReleaseFromHold(order);

  const createLegacyStatus = (status: string) => {
    return { label: capitalize(status), value: capitalize(status) };
  };

  const orderStatusOptions = useMemo(() => {
    const includesOrderStatus = order.orderStatus
      ? orderOptions.map((item) => item.value.toLocaleLowerCase()).includes(order.orderStatus.toLocaleLowerCase())
      : false;
    order.orderStatus && !includesOrderStatus && orderOptions.push(createLegacyStatus(order.orderStatus));
    return orderOptions.sort(sortOptions);
  }, [order]);

  const pharmacyStatusOptions = useMemo(() => {
    const includesPharmacyStatus = order.pharmacyStatus
      ? pharmacyOptions.map((item) => item.value.toLocaleLowerCase()).includes(order.pharmacyStatus.toLocaleLowerCase())
      : false;
    order.pharmacyStatus && !includesPharmacyStatus && pharmacyOptions.push(createLegacyStatus(order.pharmacyStatus));
    return pharmacyOptions.sort(sortOptions);
  }, [order]);

  const shippingStatusOptions = useMemo(() => {
    const includesShippingStatus = order.shippingStatus
      ? shippingOptions.map((item) => item.value.toLocaleLowerCase()).includes(order.shippingStatus.toLocaleLowerCase())
      : false;
    order.shippingStatus && !includesShippingStatus && shippingOptions.push(createLegacyStatus(order.shippingStatus));
    return shippingOptions.sort(sortOptions);
  }, [order]);

  useEffect(() => {
    reset({
      ...order,
      shippingStatus: shippingStatusOptions.find(
        (c) => c.value?.toLocaleLowerCase() === order.shippingStatus?.toLocaleLowerCase(),
      ),
      orderStatus: orderStatusOptions.find(
        (c) => c.value?.toLocaleLowerCase() === order.orderStatus?.toLocaleLowerCase(),
      ),
      pharmacyStatus: pharmacyStatusOptions.find(
        (c) => c.value?.toLocaleLowerCase() === order.pharmacyStatus?.toLocaleLowerCase(),
      ),
    });
  }, [reset, order, orderStatusOptions, pharmacyStatusOptions, shippingStatusOptions]);

  const onSubmit = async (formData: OrderFormValues) => {
    const submitOrder = getSubmitOrderData(formData);

    updateOrder({ orderId: order.id, order: submitOrder })
      .unwrap()
      .then((data) => {
        notifySuccess(data.message || 'Successfully updated order');
        setIsEditing(false);
      })
      .catch((error) => {
        notifyError(error.data?.message);
      });
  };

  const handleReleaseFromHold = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: <ReleaseOrderFromHold orderId={order?._id} />,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loader isVisible={isLoadingEdit} />
      <div className="flex justify-between">
        <h4 className={headingClasses}>Order info</h4>
        <div className="flex items-start gap-4">
          {showReleaseFromHoldButton && (
            <Common.Button
              color="blue-alt"
              size="sm"
              onClick={handleReleaseFromHold}
              preIcon="prescription-filled"
              type="button"
            >
              Release from hold
            </Common.Button>
          )}
          {isEditable && (
            <EditButtons isEditing={isEditing} setIsEditing={setIsEditing} isLoadingEdit={isLoadingEdit} />
          )}
        </div>
      </div>
      <div className={wrapperClasses}>
        {getItems(order).map((el) => {
          if (!isEditing) {
            if (el.label === OrderLabels.ShippingStatus) {
              return (
                <div key={el.label} className="flex">
                  <p className={labelClasses}>{el.label}</p>
                  {order.shippingStatus && (
                    <Common.ColorTag
                      color={getColorMapValue(order.shippingStatus)}
                      text={formatHeaderText(order.shippingStatus) || ''}
                    />
                  )}
                </div>
              );
            } else if (el.label === OrderLabels.OrderStatus) {
              return (
                <div key={el.label} className="flex">
                  <p className={labelClasses}>{el.label}</p>
                  {order.orderStatus && (
                    <Common.ColorTag
                      color={getColorMapValue(order.orderStatus)}
                      text={formatHeaderText(order.orderStatus) || ''}
                    />
                  )}
                </div>
              );
            } else if (el.label === OrderLabels.PharmacyStatus) {
              return (
                <div key={el.label} className="flex">
                  <p className={labelClasses}>{el.label}</p>
                  {order.pharmacyStatus && (
                    <Common.ColorTag
                      color={getColorMapValue(order.pharmacyStatus)}
                      text={formatHeaderText(order.pharmacyStatus) || ''}
                    />
                  )}
                </div>
              );
            } else {
              return (
                <div key={el.label} className="flex">
                  <p className={labelClasses}>{el.label}</p>
                  <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>{el.value}</p>
                </div>
              );
            }
          }

          if (el.label === OrderLabels.ShippingStatus) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <ControlledSelect
                  control={control}
                  options={shippingStatusOptions}
                  name="shippingStatus"
                  placeholder="Select..."
                  className="w-64"
                />
              </div>
            );
          } else if (el.label === OrderLabels.TrackingNumber) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name="trackingNumber" className="w-64" />
              </div>
            );
          } else if (el.label === OrderLabels.OrderStatus) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <ControlledSelect
                  control={control}
                  options={orderStatusOptions}
                  name="orderStatus"
                  placeholder="Select..."
                  className="w-64"
                />
              </div>
            );
          } else if (el.label === OrderLabels.PharmacyStatus) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <ControlledSelect
                  control={control}
                  options={pharmacyStatusOptions}
                  name="pharmacyStatus"
                  placeholder="Select..."
                  className="w-64"
                />
              </div>
            );
          } else {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>{el.value}</p>
              </div>
            );
          }
        })}
      </div>
    </form>
  );
};

export default OrderInfo;
