import { Common } from '@thecvlb/design-system/lib/src';

import Accordion from 'components/common/Accordion';

import { AccordionProps } from './accordion.types';

const ChartAccordion: React.FC<AccordionProps> = ({ title, subtitle, handleClick, children, isEditAllowed }) => {
  const heading = (
    <div className="flex flex-col gap-2 text-start">
      <span className="font-semibold text-gray-500">{title}</span>
      <span className="text-xl font-bold">{subtitle}</span>
    </div>
  );

  const showAdddButton = handleClick && isEditAllowed;
  return (
    <Accordion
      title={heading}
      isOpen={false}
      wrapperClassNames="bg-white rounded-2xl border border-gray-200 mb-0"
      buttonClassNames="bg-white rounded-2xl"
      panelClassNames="rounded-2xl"
      showInfoButton={false}
      children={
        <div>
          {children}
          {showAdddButton && (
            <Common.Button className="ml-auto" color="blue" preIcon="plus" size="md" onClick={handleClick}>
              Add measurement
            </Common.Button>
          )}
        </div>
      }
    />
  );
};

export default ChartAccordion;
