import { ResponseProps } from 'store/api/api.types';
import { StreamSuggestionResponse, SuggestionResponse } from 'store/stackAI/stackAI.types';

import { RequestStatusType, SuggestedMessageRequest } from './aiAssistant.types';
import { apiSlice } from '../api/apiSlice';

export const aiAssistantApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSuggestedResponse: build.mutation<
      ResponseProps<SuggestionResponse, { status: RequestStatusType }>,
      SuggestedMessageRequest
    >({
      query: ({ body, signal, force }) => ({
        url: `/ai/suggestions`,
        method: 'POST',
        ...(force && {
          headers: {
            'Cache-Control': 'no-cache',
          },
        }),
        body,
        signal,
      }),
    }),
    getStreamSuggestedResponse: build.mutation<StreamSuggestionResponse, SuggestedMessageRequest>({
      query: ({ body, signal, force }) => ({
        url: `/ai/stream`,
        method: 'POST',
        ...(force && {
          headers: {
            'Cache-Control': 'no-cache',
          },
        }),
        body,
        signal,
      }),
    }),
  }),
});

export const { useGetSuggestedResponseMutation, useGetStreamSuggestedResponseMutation } = aiAssistantApi;
