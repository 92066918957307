import { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import queryString from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useLocation, useNavigate } from 'react-router-dom';

import Accordion from 'components/common/Accordion';
import { formatPhoneNumber } from 'components/common/form/PhoneInput/phoneInput.settings';
import QuickLinks from 'components/common/QuickLinks';
import ConfirmAndPay from 'components/crossSell/confirmAndPay';
import CreateProfile from 'components/crossSell/CreateProfile';
import Header from 'components/crossSell/Header';
import PlanType from 'components/crossSell/PlanType';
import CrossSellAlert from 'components/modals/CrossSell';
import Modal from 'components/modals/modal';
import { COUPON_OPTONS } from 'constants/crossSell';
import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'constants/dateFormat';
import { DEFAULT_SEARCH_PARAMS } from 'constants/tables';
import { SEX_AT_BIRTH } from 'constants/user';
import { CrossSellLinks, PaymentMethod } from 'enums/crossSell';
import { PathName } from 'enums/pathName';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SignupCouponCodes } from 'store/crossSell/crossSell.types';
import { useGetStatesQuery } from 'store/crossSell/crossSellSlice';
import { closeModal, openModal, selectModal } from 'store/modal/modalSlice';
import {
  useCreateAccountMutation,
  useLazyGetProspectPatientQuery,
  useLazyGetProspectsQuery,
} from 'store/patients/patientsSlice';
import { getDefaultPricePoint } from 'utils/helpers';

import { handleActiveLink, tabLinks } from './crossSell.settings';
import { FormDataProps } from './crossSell.types';

const CrossSell = () => {
  const methods = useForm<FormDataProps>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      sexAtBirth: '',
      phone: '',
      address: '',
      city: '',
      zipCode: '',
      state: '',
      planType: {},
      creditCardAttributes: {
        couponCode: {},
        fullName: '',
      },
    },
  });
  const { handleSubmit, reset, getValues, setValue, watch } = methods;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isModal, setIsModal] = useState(0);
  const { isOpen: isOpenModal, size, modalContent, hideClose } = useAppSelector(selectModal);
  const [getProspects] = useLazyGetProspectsQuery();
  const locationQuery = queryString.parse(location.search);
  const patientId = locationQuery?.userId as string;
  const urlPromoCode = (locationQuery.promo || '').toString().toLowerCase() as SignupCouponCodes;
  const defaultPromo = { label: 'XSELL100OFF - 100% off', value: 'xsell100off' };
  const patientName = `${watch('firstName') || ''} ${watch('lastName') || ''}`.trim();
  let couponValue = defaultPromo;

  const [getPatientDetails, { data: patientDetails }] = useLazyGetProspectPatientQuery();
  const [createAccount] = useCreateAccountMutation();
  const { data: states } = useGetStatesQuery();

  const createProfileRef = useRef<HTMLHeadingElement>(null);
  const planTypeRef = useRef<HTMLHeadingElement>(null);
  const confirmAndPayRef = useRef<HTMLHeadingElement>(null);

  const refs = {
    [CrossSellLinks.CreateProfile]: createProfileRef,
    [CrossSellLinks.PlanType]: planTypeRef,
    [CrossSellLinks.ConfirmAndPay]: confirmAndPayRef,
  };

  const splitFullName = (fullName: string) => {
    const lastIndex = fullName.lastIndexOf(' ');
    const firstName = fullName.slice(0, lastIndex);
    const lastName = fullName.slice(lastIndex + 1);

    return [firstName, lastName];
  };

  useEffect(() => {
    if (!isOpenModal && isModal) {
      if (location.search.length) {
        navigate({ pathname: PathName.Prospect, search: DEFAULT_SEARCH_PARAMS });
        getProspects({ params: queryString.parse(location.search) });
      } else {
        navigate(0);
      }
      setIsModal(0);
    } else {
      setIsModal(1);
    }
  }, [isOpenModal]);

  const onSubmit = (formData: FormDataProps) => {
    const expirationYear = formData.creditCardAttributes.expDate.split('/')[1];
    const expirationMonth = formData.creditCardAttributes.expDate.split('/')[0];
    const [firstName, lastName] = splitFullName(formData.creditCardAttributes.fullName);
    const pricePoint = getDefaultPricePoint(formData.planType?.pricePoints);

    const reqData = {
      flow: 'cross-sell',
      firstName: formData.firstName,
      lastName: formData.lastName,
      dob: dayjs.utc(formData.dob).format(BACKEND_DATE_FORMAT),
      sexAtBirth: typeof formData.sexAtBirth === 'string' ? formData.sexAtBirth : formData.sexAtBirth?.value,
      phone: {
        countryCode: parsePhoneNumber(formData.phone)?.country ?? '',
        phoneNumber: parsePhoneNumber(formData.phone)?.nationalNumber ?? '',
      },
      phoneNumber: formData.phone,
      address: formData.address,
      city: formData.city,
      zipCode: formData.zipCode,
      email: formData.email,
      state: typeof formData.state === 'string' ? formData.state : formData.state?.label,
      timezone: dayjs.tz.guess(),
      doctorId: formData.doctorId,
      planId: formData?.planType?._id,
      planPricePointId: pricePoint?.planPricePointId,

      creditCardAttributes: {
        firstName,
        lastName,
        fullNumber: formData.creditCardAttributes.fullNumber,
        expirationYear,
        expirationMonth,
        billingZip: formData.creditCardAttributes?.billingZip,
        cvv: formData.creditCardAttributes.cvv,
        couponCode: formData.creditCardAttributes?.couponCode.value,
        paymentMethod: PaymentMethod.CreditCard,
      },
    };

    if (reqData)
      createAccount(reqData)
        .unwrap()
        .then((data) => {
          if (data) {
            dispatch(
              openModal({ size: 'sm', modalContent: <CrossSellAlert handleClick={() => dispatch(closeModal())} /> }),
            );
          }
        });
  };

  const getCouponValue = () => {
    if (urlPromoCode) {
      const couponOption = COUPON_OPTONS.filter((coupon) => coupon.value === urlPromoCode);
      couponValue = couponOption.length > 0 ? couponOption[0] : defaultPromo;
    } else {
      couponValue = defaultPromo;
    }
    return couponValue;
  };

  useEffect(() => {
    setValue('creditCardAttributes.couponCode', getCouponValue());
  }, []);

  useEffect(() => {
    if (patientId) {
      getPatientDetails({ id: patientId });
    }
  }, [getPatientDetails, patientId]);

  useEffect(() => {
    if (patientDetails) {
      const planType = getValues('planType');
      reset({
        firstName: patientDetails.firstName ?? '',
        lastName: patientDetails.lastName ?? '',
        email: patientDetails.email ?? '',
        dob: patientDetails.dob ? dayjs.utc(patientDetails.dob).format(FRONTEND_DATE_FORMAT) : '',
        sexAtBirth: patientDetails?.gender
          ? SEX_AT_BIRTH.find((item) => item.value === patientDetails?.gender)?.value
          : '',
        phone: patientDetails.phone?.phoneNumber ? formatPhoneNumber(patientDetails.phone?.phoneNumber) : '',
        address: patientDetails.address ?? '',
        city: patientDetails.city ?? '',
        zipCode: patientDetails.zipCode ?? '',
        state: patientDetails.state ? states?.find((state) => state.label === patientDetails.state) : '',
        planType,
        creditCardAttributes: {
          couponCode: getCouponValue(),
        },
      });
    }
  }, [getValues, patientDetails, reset, states]);

  return (
    <div className="sticky h-screen w-screen overflow-scroll">
      <Header userName={patientName} />
      <div className="mt-6 flex gap-x-16 px-16">
        <div data-testid="cross_sell_sidebar" className="w-72 min-w-[210px]">
          <QuickLinks tabLinks={tabLinks} onChange={(link) => handleActiveLink(link, refs)} type="custom" />
        </div>

        <div className="w-full">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion
                title="Create profile"
                ref={createProfileRef}
                children={<CreateProfile states={states ?? []} />}
              />
              <Accordion title="Plan type" ref={planTypeRef} children={<PlanType />} />
              <Accordion title="Confirm and pay" ref={confirmAndPayRef} children={<ConfirmAndPay />} />
            </form>
          </FormProvider>
        </div>
      </div>
      {isOpenModal && (
        <Modal isOpen={isOpenModal} size={size} padding={false} hideClose={hideClose}>
          {modalContent}
        </Modal>
      )}
    </div>
  );
};

export default CrossSell;
