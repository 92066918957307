import { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';

import { notifySuccess } from 'components/common/Toast/Toast';
import { MembershipPlanProps, PricePointProps } from 'components/crossSell/PlanType/planType.types';
import CancelPlan from 'components/modals/CancelPlan';
import { useAppDispatch } from 'hooks/redux';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { getDisplayName } from 'store/patients/patients.settings';
import { useChangeSubscriptionMutation, useLazyGetTagsQuery } from 'store/patients/patientsSlice';

import { renderContent } from './changePlan.settings';
import { ChangePlanFormData, ChangePlanProps, ChangePlanSteps } from './changePlan.types';
import { FlexCare } from './ChoosePlan/choosePlan.settings';
import { ChoosePlanTab } from './ChoosePlan/choosePlan.types';

const ChangePlan: React.FC<ChangePlanProps> = ({ patientInfo, currentPlan, activePP }) => {
  const dispatch = useAppDispatch();
  const [changeSubscription, { isLoading: isChangingPlan }] = useChangeSubscriptionMutation();
  const [getTags] = useLazyGetTagsQuery();
  const [step, setStep] = useState<ChangePlanSteps>(ChangePlanSteps.ChoosePlan);
  const [newPlan, setNewPlan] = useState<MembershipPlanProps>();
  const [newPricePoint, setNewPricePoint] = useState<PricePointProps>();

  const methods = useForm<ChangePlanFormData>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      confirmation: false,
      activePeriodsTab: ChoosePlanTab.EveryMonth,
    },
  });
  const formData = methods.watch();

  const patientName = getDisplayName(patientInfo);
  const isCurrentPricePointSelected = !!newPricePoint && newPricePoint?.planPricePointId === activePP?.planPricePointId;
  const disableConfirmPayment =
    (step === ChangePlanSteps.ChoosePlan && (!newPlan || isCurrentPricePointSelected)) ||
    (step === ChangePlanSteps.ConfirmPayment && !formData.confirmation) ||
    isChangingPlan;

  const handleBack = () => {
    if (step === ChangePlanSteps.ChoosePlan) {
      dispatch(closeModal());
    } else {
      setStep(ChangePlanSteps.ChoosePlan);
    }
  };

  const handleConfirmPayment = () => {
    if (step === ChangePlanSteps.ConfirmPayment) {
      if (patientInfo?._id && newPlan?._id && newPricePoint) {
        changeSubscription({
          patientId: patientInfo?._id,
          planId: newPlan._id,
          planPricePointId: newPricePoint.planPricePointId,
          couponCode: formData.couponCode?.value,
        })
          .unwrap()
          .then((res) => {
            notifySuccess(res.message);
            dispatch(closeModal());

            // NOTE: Update tags after changing subscription, but after a delay
            setTimeout(() => {
              getTags({ patientId: patientInfo?._id });
            }, 2000);
          });
      }
    } else {
      if (newPlan?.planName === FlexCare) {
        dispatch(
          openModal({
            size: 'base',
            hideClose: true,
            modalContent: <CancelPlan patientInfo={patientInfo} isAutoRenew />,
          }),
        );
      } else {
        setStep(ChangePlanSteps.ConfirmPayment);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form className="p-6" onSubmit={methods.handleSubmit(handleConfirmPayment)}>
        <h2 className="mb-2 text-xl font-bold text-gray-700">Change plan</h2>
        <p className="mb-6 text-base font-medium text-gray-700">
          {patientName}, {dayjs().diff(patientInfo?.dob, 'year')}
        </p>
        {patientInfo &&
          renderContent(step, setNewPlan, setNewPricePoint, patientInfo, newPlan, currentPlan, activePP, newPricePoint)}
        <div className="mt-6 flex justify-end gap-2">
          <Common.Button color="white-alt" onClick={handleBack} size="sm" type="button">
            {step === ChangePlanSteps.ChoosePlan ? 'Cancel' : 'Back'}
          </Common.Button>
          <Common.Button color="blue" size="sm" disabled={disableConfirmPayment} isLoading={isChangingPlan}>
            {step === ChangePlanSteps.ConfirmPayment ? 'Confirm payment' : 'Next'}
          </Common.Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ChangePlan;
