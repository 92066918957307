import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import { VideoCallProps } from './videoCall.types';

const initialState: VideoCallProps = {
  isOpen: false,
  taskId: '',
  isPiP: false, // Picture in Picture
  isLandscape: true,
  showControls: true,
  appointmentId: '',
};

const videoCallSlice = createSlice({
  name: 'videoCall',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    setTaskId: (state, action) => {
      state.taskId = action.payload;
    },
    setAppointmentId: (state, action) => {
      state.appointmentId = action.payload;
    },
    setIsPiP: (state, action) => {
      state.isPiP = action.payload;
    },
    setIsLandscape: (state, action) => {
      state.isLandscape = action.payload;
    },
    setShowControls: (state, action) => {
      state.showControls = action.payload;
    },
    resetVideoCall: () => initialState,
  },
});

export const selectVideoCall = (state: RootState) => state.videoCall;

export const { open, setTaskId, setAppointmentId, resetVideoCall, setIsPiP, setIsLandscape, setShowControls } =
  videoCallSlice.actions;

export default videoCallSlice.reducer;
