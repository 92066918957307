import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { HealthMetricTypes } from 'enums/chart';
import { DateFormat } from 'enums/dateFormats';
import { HealthMetricType } from 'store/patients/patients.types';

import EditCell from './EditCell';
import { HealthMetricTableColumnsProps } from './healthMetric.types';
import Header from '../Header';

const columnHelper = createColumnHelper<HealthMetricTableColumnsProps>();

export const columns = (metricType: HealthMetricType, isEditAllowed?: boolean, isWMDevices?: boolean) => {
  const showEditButton = metricType !== HealthMetricTypes.BMI && isEditAllowed;

  return [
    columnHelper.accessor('collectedDate', {
      header: () => <Header text="Date" sortField="collectedDate" customSortingTableName={metricType} />,
      cell: (info) => info.getValue() && dayjs(info.getValue()).format(DateFormat.MMM_D__YYYY),
    }),
    ...(metricType === HealthMetricTypes.Height
      ? [
          columnHelper.accessor('metrics.height', {
            header: () => <Header text="Height" sortField="metrics.height" customSortingTableName={metricType} />,
            cell: (info) => {
              const height = info.getValue();
              return <span>{height ? `${Math.floor(height / 12)}’ ${height % 12}”` : ''}</span>;
            },
          }),
        ]
      : []),
    ...(metricType === HealthMetricTypes.Weight
      ? isWMDevices
        ? [
            columnHelper.accessor('metrics.weight', {
              header: () => <Header text="Weight" sortField="metrics.weight" customSortingTableName={metricType} />,
              cell: (info) => {
                const weight = info.getValue();
                return <span>{weight ? `${weight} lbs` : ''}</span>;
              },
            }),
            columnHelper.accessor('metrics.visceralFat', {
              header: () => <Header text="Visceral fat" sortField="metrics.visceralFat" noSort />,
              cell: (info) => {
                const visceralFat = info.getValue();
                return <span>{visceralFat ? `${visceralFat} lbs` : ''}</span>;
              },
            }),
            columnHelper.accessor('metrics.boneMass', {
              header: () => <Header text="Bone mass" sortField="metrics.boneMass" noSort />,
              cell: (info) => {
                const boneMass = info.getValue();
                return <span>{boneMass ? `${boneMass} lbs` : ''}</span>;
              },
            }),
            columnHelper.accessor('metrics.muscleMass', {
              header: () => <Header text="Muscle mass" sortField="metrics.muscleMass" noSort />,
              cell: (info) => {
                const muscleMass = info.getValue();
                return <span>{muscleMass ? `${muscleMass} lbs` : ''}</span>;
              },
            }),
            columnHelper.accessor('metrics.bodyFat', {
              header: () => <Header text="Body fat %" sortField="metrics.bodyFat" noSort />,
              cell: (info) => {
                const bodyFat = info.getValue();
                return <span>{bodyFat ? `${bodyFat} %` : ''}</span>;
              },
            }),
            columnHelper.accessor('metrics.totalWater', {
              header: () => <Header text="Total water %" sortField="metrics.totalWater" noSort />,
              cell: (info) => {
                const totalWater = info.getValue();
                return <span>{totalWater ? `${totalWater} %` : ''}</span>;
              },
            }),
          ]
        : [
            columnHelper.accessor('metrics.weight', {
              header: () => <Header text="Weight" sortField="metrics.weight" customSortingTableName={metricType} />,
              cell: (info) => {
                const weight = info.getValue();
                return <span>{weight ? `${weight} lbs` : ''}</span>;
              },
            }),
          ]
      : []),
    ...(metricType === HealthMetricTypes.WaistCircumference
      ? [
          columnHelper.accessor('metrics.waistCircumference', {
            header: () => <Header text="Measurement" sortField="metrics.waistCircumference" noSort />,
            cell: (info) => {
              const waist = info.getValue();
              return <span>{waist ? `${waist} in` : ''}</span>;
            },
          }),
        ]
      : []),
    ...(metricType === HealthMetricTypes.BMI
      ? [
          columnHelper.accessor('metrics.bmi', {
            header: () => <Header text="BMI" sortField="metrics.bmi" noSort />,
            cell: (info) => {
              const bmi = info.getValue();
              return <span>{bmi ? bmi : ''}</span>;
            },
          }),
        ]
      : []),
    ...(metricType === HealthMetricTypes.BloodPressure
      ? [
          columnHelper.accessor('metrics.bloodPressure.SYS', {
            header: () => (
              <Header text="SYS" sortField="metrics.bloodPressure.SYS" customSortingTableName={metricType} />
            ),
            cell: (info) => {
              const SYS = info.getValue();
              return <span>{SYS ? SYS : ''}</span>;
            },
          }),
          columnHelper.accessor('metrics.bloodPressure.DIA', {
            header: () => (
              <Header text="DIA" sortField="metrics.bloodPressure.DIA" customSortingTableName={metricType} />
            ),
            cell: (info) => {
              const DIA = info.getValue();
              return <span>{DIA ? DIA : ''}</span>;
            },
          }),
          columnHelper.accessor('metrics.bloodPressure.pulse', {
            header: () => (
              <Header text="Pulse" sortField="metrics.bloodPressure.pulse" customSortingTableName={metricType} />
            ),
            cell: (info) => {
              const pulse = info.getValue();
              return <span>{pulse ? `${pulse} bpm` : ''}</span>;
            },
          }),
        ]
      : []),
    ...(metricType === HealthMetricTypes.Cholesterol
      ? [
          columnHelper.accessor('metrics.cholesterol.HDL', {
            header: () => <Header text="HDL" sortField="metrics.cholesterol.HDL" customSortingTableName={metricType} />,
            cell: (info) => {
              const HDL = info.getValue();
              return <span>{HDL ? HDL : ''}</span>;
            },
          }),
          columnHelper.accessor('metrics.cholesterol.nonHDL', {
            header: () => (
              <Header text="Non-HDL" sortField="metrics.cholesterol.nonHDL" customSortingTableName={metricType} />
            ),
            cell: (info) => {
              const nonHDL = info.getValue();
              return <span>{nonHDL ? nonHDL : ''}</span>;
            },
          }),
          columnHelper.accessor('metrics.cholesterol.LDL', {
            header: () => <Header text="LDL" sortField="metrics.cholesterol.LDL" customSortingTableName={metricType} />,
            cell: (info) => {
              const LDL = info.getValue();
              return <span>{LDL ? LDL : ''}</span>;
            },
          }),
          columnHelper.accessor('metrics.cholesterol.total', {
            header: () => (
              <Header text="Total" sortField="metrics.cholesterol.total" customSortingTableName={metricType} />
            ),
            cell: (info) => {
              const total = info.getValue();
              return <span>{total ? total : ''}</span>;
            },
          }),
        ]
      : []),
    ...(showEditButton && !isWMDevices
      ? [
          columnHelper.accessor('actions', {
            header: () => <span className="text-sm font-bold">Actions</span>,
            cell: (info) => <EditCell data={info.row.original} metricType={metricType} />,
            enableSorting: false,
            size: 100,
          }),
        ]
      : []),
    ...((metricType === HealthMetricTypes.Weight || metricType === HealthMetricTypes.BloodPressure) && isWMDevices
      ? [
          columnHelper.accessor('method', {
            header: () => <Header text="Method" sortField="method" noSort />,
            cell: (info) => {
              const method = info.row.original?.source?.name;
              const device =
                method === 'Withings' && metricType === HealthMetricTypes.Weight
                  ? 'Scale'
                  : method === 'Withings' && metricType === HealthMetricTypes.BloodPressure
                    ? 'Device'
                    : 'Manual';
              return <span>{device || 'Manual'}</span>;
            },
          }),
        ]
      : []),
  ];
};
