import { useEffect } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';

import Loader from 'components/common/Loader';
import { StaffNotesProvider } from 'contexts/StaffNotesContext/StaffNotesContext';
import { TemplatesProvider } from 'contexts/TemplatesContext/TemplatesContext';
import { MessageTemplates, Status } from 'enums/messages';
import { useStaffNotes } from 'hooks/common/useStaffNotes';
import { useAppSelector } from 'hooks/redux';
import { selectStaffNotes } from 'store/staffNotes/staffNotesSlice';
import { selectTask } from 'store/tasks/tasksSlice';

import StaffNotesContainer from './StaffNotesContainer';
import StaffNotesForm from './StaffNotesForm/StaffNotesForm';

const selectTaskDetails = createSelector([selectTask, selectStaffNotes], (task, staffNotes) => ({
  taskDetailsPatientId: task.taskDetails.personalInfo._id,
  isLoading: staffNotes.staffNotesLoadingStatus === Status.Pending,
  isRejected: staffNotes.staffNotesLoadingStatus === Status.Rejected,
}));

const StaffNotes = () => {
  const { patientId = '' } = useParams<{ patientId: string }>();
  const { taskDetailsPatientId, isLoading, isRejected } = useAppSelector(selectTaskDetails);
  const id = taskDetailsPatientId || patientId;
  const { hasPermissionToSendMessage, resetStaffNotes } = useStaffNotes({
    patientId: id,
  });

  useEffect(() => {
    return () => {
      resetStaffNotes();
    };
  }, [resetStaffNotes]);

  return (
    <div data-testid="staff_notes_block" className="flex h-[566px] w-full flex-col">
      <div className="flex max-h-14 flex-col items-center justify-center gap-1 border-b border-gray-200 py-1">
        <p className="text-lg font-bold text-gray-700">Staff notes</p>
        <p className="text-sm font-medium text-gray-500">Internal</p>
      </div>
      <Loader isVisible={isLoading} />

      <StaffNotesProvider>
        <StaffNotesContainer />
      </StaffNotesProvider>

      {!isRejected && hasPermissionToSendMessage && (
        <div className="border-t border-gray-200 p-4">
          <TemplatesProvider templatesType={MessageTemplates.Internal}>
            <StaffNotesForm />
          </TemplatesProvider>
        </div>
      )}
    </div>
  );
};

export default StaffNotes;
