import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

import type { EventSummaryProps } from './eventSummary.types';

const EventSummary = ({ isLoading, isTimeOff, isEditing, repeats, selectedTime, selectedDay }: EventSummaryProps) => {
  return (
    <div className="border-b border-gray-200 py-4">
      {isLoading ? (
        <>
          <div className="mb-1 h-5 w-5/12 animate-pulse rounded bg-slate-200" />
          <div className="h-4 w-7/12 animate-pulse rounded bg-slate-200" />{' '}
        </>
      ) : !isTimeOff ? (
        <>
          <strong className="text-base">
            {(isEditing ? 'Shift on ' : 'Hours starting ') + dayjs(selectedDay).format(DateFormat.dddd_MMMM_D)}
          </strong>
          <p className="text-base">
            {selectedTime}, {repeats}
          </p>
        </>
      ) : (
        <strong className="text-base">
          {(isEditing ? 'Time off on ' : 'Time off starting ') + dayjs(selectedDay).format(DateFormat.dddd_MMMM_D)}
        </strong>
      )}
    </div>
  );
};

export default EventSummary;
