import { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/StaffCostsTable/columns';
import { StatusFilterData } from 'constants/filters';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useLazyGetStaffCostsQuery } from 'store/staffCosts/staffCostsSlice';

const StaffCosts = () => {
  const [getStaffCosts, { data, isFetching, isUninitialized }] = useLazyGetStaffCostsQuery();

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.staffCosts,
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getStaffCosts({ params: queryString.parse(location.search) }, true);
  }, [getStaffCosts, navigate, location.search]);

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={StatusFilterData} queryParam="status" />
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable table={table} totalCount={data?.totalCount || 0} isFetching={isFetching} />
      </Card>
    </>
  );
};

export default StaffCosts;
