export enum PatientPrescriptionsType {
  Order = 'order',
  Prescription = 'prescription',
  LMDPrescription = 'lmd_prescription',
}

export enum PaymentStatus {
  Paid = 'paid',
  Failed = 'failed',
}

export enum NotPrescribingReason {
  TooEarlyToPrescribe = 'too-early-to-prescribe',
  WaitingForLabs = 'waiting-for-labs',
  NotClinicallyAppropriate = 'not-clinically-appropriate',
  AlreadyFilled = 'already-filled',
  RecommendingMaintenancePlan = 'recommending-maintenance-plan',
}
