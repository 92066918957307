import { useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FieldValues, useController, useFormContext, useWatch } from 'react-hook-form';
import { useToggle } from 'react-use';

import DatePickerInput from 'components/common/DatePickerInput';
import { DateFormat } from 'enums/dateFormats';
import { MM_DD_YYYY_REGEXP } from 'utils/regExp';

const MeasurementDateInput = <TFormValues extends FieldValues>({
  showTodayCheckbox = false,
  isTodayChecked = showTodayCheckbox,
  name = 'date',
}: TFormValues) => {
  const [selected, setSelected] = useState<Date>();

  const { setValue, control } = useFormContext<{ date: string }>();

  const formData = useWatch<{ date: string }>({ control });

  useController({
    control,
    defaultValue: dayjs().format(DateFormat.MM_DD_YYYY),
    name,
    rules: {
      pattern: {
        message: 'Date must be in the format MM/DD/YYYY',
        value: MM_DD_YYYY_REGEXP,
      },
      required: {
        message: `Date is required`,
        value: true,
      },
    },
  });
  const [isToday, toggleToday] = useToggle(isTodayChecked);
  return (
    <div
      className={classNames('my-4 flex flex-col gap-4', {
        'md:self-end md:pr-4': isToday,
      })}
    >
      {showTodayCheckbox && isToday && (
        <Common.Checkbox checked={isToday} color="blue" onChange={toggleToday}>
          <span className={classNames('text-mBase md:text-base', { 'font-bold': isToday })}>
            Use today’s date for this measurement
          </span>
        </Common.Checkbox>
      )}
      {(!showTodayCheckbox || !isToday) && (
        <>
          <DatePickerInput
            label="Date"
            labelDirection="row"
            inputValue={formData.date ?? ''}
            setInputValue={(value) => setValue('date', value)}
            selectedDate={selected}
            setSelectedDate={setSelected}
            startAllowedDate={dayjs().subtract(100, 'year').toDate()}
            lastAllowedDate={new Date()}
            captionLayout={'dropdown'}
            wrapperClasses="w-full relative mr-14"
            size="sm"
            isRtL
          />
        </>
      )}
    </div>
  );
};

export default MeasurementDateInput;
