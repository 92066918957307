import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { useForm } from 'react-hook-form';

import ControlledCombobox from 'components/forms/controlled/ControlledCombobox';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useGetAvailableProvidersQuery } from 'store/staffs/staffsSlice';
import { selectTask } from 'store/tasks/tasksSlice';

import { normalizeAvailableProvidersData } from './form.settings';
import { AssignProvidersFormProps } from './form.types';
import { ProviderSearchProps } from '../assignProvider.types';

const Form = ({ onSubmit, isLoading, actionBtnLabel }: AssignProvidersFormProps) => {
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<{ id: string; value: unknown; label: string }[]>([]);
  const [query, setQuery] = useState('');

  const { taskDetails } = useAppSelector(selectTask);
  const { handleSubmit, control, setValue } = useForm<ProviderSearchProps>({
    mode: 'onChange',
  });
  const cancelAssignProvider = () => dispatch(closeModal());

  const {
    data: availableProvidersData,
    isLoading: isLoadingAvailProviders,
    isFetching: isFetchingAvailProviders,
  } = useGetAvailableProvidersQuery({
    appointmentTypeId: taskDetails.appointmentInfo.appointmentType._id ?? '',
    state: taskDetails.personalInfo.state ?? '',
    patientUserId: taskDetails.patientId,
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    if (!event.target.value.length) {
      setValue('doctorName', null);
    }
  };

  const searchData = useMemo(() => {
    return availableProvidersData?.data.length ? normalizeAvailableProvidersData(availableProvidersData.data) : [];
  }, [availableProvidersData]);

  useEffect(() => {
    if (searchData?.length) setOptions(searchData);
  }, [searchData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <ControlledCombobox
        size="sm"
        label="Provider"
        labelDirection="col"
        control={control}
        name="doctorName"
        defaultValue=""
        options={options}
        preIcon="search"
        inputValue={query}
        onChange={(val) => setValue('doctorName', val)}
        onInputChange={handleInputChange}
        hideComboboxButton
        placeholder="Search for provider"
        isLoading={isLoadingAvailProviders || isFetchingAvailProviders}
        rules={{
          required: {
            value: true,
            message: 'Please select a provider',
          },
        }}
      />
      <div className="mt-2 grid grid-cols-2 gap-x-2">
        <Common.Button
          type="button"
          color="white-alt"
          className="!w-full justify-center"
          onClick={cancelAssignProvider}
          size="sm"
        >
          Cancel
        </Common.Button>
        <Common.Button className="!w-full justify-center" type="submit" color="blue" size="sm" disabled={isLoading}>
          {actionBtnLabel}
        </Common.Button>
      </div>
    </form>
  );
};

export default Form;
