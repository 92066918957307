import classNames from 'classnames';

import { TagCategories } from 'enums/tagTypes';
import { Tag } from 'store/patients/patients.types';

const isNeedInfoOptaviaTag = (tag?: Tag) => tag?.category === TagCategories.PARTNER && tag?.name.includes('Needs info');

export const getBadgeClasses = ({
  tag,
  isRed,
  additionalClasses = '',
}: {
  tag?: Tag;
  isRed?: boolean;
  additionalClasses?: string;
}) => {
  const isImportantTag = tag?.category === TagCategories.IMPORTANT;

  return classNames(
    'whitespace-nowrap px-1 py-0.5 rounded font-semibold flex items-center bg-gray-800/5 text-gray-600',
    additionalClasses,
    {
      'bg-red-100 text-red-600': isRed || tag?.isImportant || isImportantTag || isNeedInfoOptaviaTag(tag),
      'bg-secondary-100 text-secondary-600':
        tag?.category === TagCategories.PLAN || tag?.category === TagCategories.MEDICAL_INCLUDED,
    },
  );
};

export const categoriesOrder = {
  [TagCategories.PLAN]: 1,
  [TagCategories.MEDICAL_INCLUDED]: 2,
  [TagCategories.SUB_PLAN]: 3,
  [TagCategories.IMPORTANT]: 4,
  [TagCategories.PARTNER]: 5,
  [TagCategories.ASYNC]: 6,
  [TagCategories.PREV_PLAN]: 7,
  [TagCategories.OTHER]: 8,
};

export const mainCategoriesType = [TagCategories.PLAN, TagCategories.SUB_PLAN, TagCategories.MEDICAL_INCLUDED];

export const otherCategoriesType = [
  TagCategories.IMPORTANT,
  TagCategories.PARTNER,
  TagCategories.ASYNC,
  TagCategories.PREV_PLAN,
  TagCategories.OTHER,
];

// This function compares two tags based on their category, date, and name
export const sortedTags = (tagFirst: Tag, tagSecond: Tag) => {
  // Here we make an exception for the "Optavia • Needs info" tag
  const isFirstTagHasNeedsInfoOptaviaTag = isNeedInfoOptaviaTag(tagFirst);
  const isSecondTagHasNeedsInfoOptaviaTag = isNeedInfoOptaviaTag(tagSecond);

  if (isFirstTagHasNeedsInfoOptaviaTag && !isSecondTagHasNeedsInfoOptaviaTag) {
    return -1;
  } else if (!isFirstTagHasNeedsInfoOptaviaTag && isSecondTagHasNeedsInfoOptaviaTag) {
    return 1;
  }

  const orderFirst = categoriesOrder[tagFirst.category as keyof typeof categoriesOrder];
  const orderSecond = categoriesOrder[tagSecond.category as keyof typeof categoriesOrder];

  if (orderFirst !== orderSecond) {
    return orderFirst - orderSecond; // Sorting by category
  } else {
    // If categories are the same, check the date field
    if (tagFirst.planStartDate && tagSecond.planStartDate) {
      return new Date(tagSecond.planStartDate).getTime() - new Date(tagFirst.planStartDate).getTime(); // Sorting by date (newer first)
    } else {
      return tagFirst.name.localeCompare(tagSecond.name); // Sorting by name
    }
  }
};
