import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DayModifiers, DayPicker } from 'react-day-picker';
import { useFormContext } from 'react-hook-form';
import { useClickAway } from 'react-use';

import { PlanCodesProps } from 'enums/appointmentStatus';
import { DateFormat } from 'enums/dateFormats';
import { Role } from 'enums/role';
import { useAppSelector } from 'hooks/redux';
import {
  AppointmentTimeProps,
  AvailableDatesDataProps,
  CombinedTimeSlots,
} from 'store/appointments/appointments.types';
import {
  useLazyGetAvailableDatesQuery,
  useLazyGetAvailableTimesQuery,
  useLazyGetCombinedAvailabilityQuery,
  useLazyGetGlobalAvailableDatesQuery,
} from 'store/appointments/appointmentsSlice';
import { selectPatient } from 'store/patients/patientsSlice';
import { Staff } from 'store/staffs/staffs.types';
import { useLazyGetStaffQuery } from 'store/staffs/staffsSlice';
import { selectUser } from 'store/user/userSlice';
import { checkCompletedOnboardingAppt, checkWmNotCompletedOnboarding, getDaysList } from 'utils/appointment';

import { buildTimeSlotList, onFilterByAvailableDates, onFilterByNotAvailableDates } from './dateAndTime.settings';
import { DateAndTimeProps, Provider, ProviderType, SlotData } from './dateAndTime.types';
import Picker from './Picker';
import SchedulingDaySlider from './SchedulingDaySlider';
import StaffPicker from './StaffPicker';

dayjs.extend(relativeTime);

const DateAndTime: React.FC<DateAndTimeProps> = ({
  upcomingAppointmentsList,
  pastAppointmentsList,
  initialDate,
  onSelect,
  handleBack,
  isAdHoc,
  toggleIsAdHoc,
  isLoading,
  isReschedule = false, //Need for reschedule in next step
  appointmentId, //Need for reschedule in next step
}) => {
  const ref = useRef(null);
  const { userType } = useAppSelector(selectUser);
  const { patientInfo } = useAppSelector(selectPatient);
  const { watch, setValue } = useFormContext();

  const appointmentTypeId = watch('appointmentTypeId');

  const timezone = dayjs.tz?.guess();
  const defaultDate = initialDate && dayjs(initialDate).isValid() ? initialDate : dayjs().format(DateFormat.YYYY_MM_DD);

  const activePlanCode = patientInfo?.planInfo?.planCode ?? '';
  const activePlanId = patientInfo?.planInfo?._id ?? '';
  const patientState = patientInfo?.state ?? '';

  const isUnlimitedPlan = activePlanCode === PlanCodesProps.UnlimitedMembership;
  const isPhysician = userType?.name === Role.PH;
  const isAdmin = userType?.name === Role.AD;

  const completedOnboarding = checkCompletedOnboardingAppt(pastAppointmentsList);
  const wmNotCompletedOnboarding = checkWmNotCompletedOnboarding(activePlanCode, completedOnboarding);

  const [getStaff, { isFetching: isStaffFetching }] = useLazyGetStaffQuery();
  const [getCombinedData, { isFetching: isCombinedFetching }] = useLazyGetCombinedAvailabilityQuery();
  const [getAvailableTimes, { isFetching: isTimesFetching }] = useLazyGetAvailableTimesQuery();
  const [getAvailableDates, { isFetching: isFetchingAvailableDates }] = useLazyGetAvailableDatesQuery();
  const [getGlobalAvailableDates, { isFetching: isFetchingGlobalAvailableDates }] =
    useLazyGetGlobalAvailableDatesQuery();

  const [staffDetails, setStaffDetails] = useState<Staff>();
  const [provider, setProvider] = useState<ProviderType>(Provider.any);

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isCheckMoreDate, setIsCheckMoreDate] = useState<boolean>(false);
  const [isShowCalendar, toggleShowCalendar] = useState(false);
  const [selectedDay, setSelectedDay] = useState(defaultDate);
  const [timeSlots, setTimeSlots] = useState<(AppointmentTimeProps | CombinedTimeSlots)[] | undefined>();
  const [slotsData, setSlotsData] = useState<SlotData[]>([]);

  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [notAvailableDates, setNotAvailableDates] = useState<string[]>([]);

  const currentAppointment = upcomingAppointmentsList.find((item) => {
    return item._id === appointmentId;
  });

  const getDates = () => {
    let arrOfDates = availableDates;

    if (wmNotCompletedOnboarding && currentAppointment && isReschedule) {
      arrOfDates = arrOfDates.filter((e) =>
        dayjs(e)
          .add(1, 'day')
          .isAfter(currentAppointment.appointmentTime?.startTime),
      );
    }

    return arrOfDates.slice(0, 5) ?? [];
  };

  const datesList = getDaysList(getDates());

  const mixedLoading =
    isStaffFetching ||
    isTimesFetching ||
    isCombinedFetching ||
    isFetchingAvailableDates ||
    isFetchingGlobalAvailableDates;

  const titleWrapperClassName = 'mb-4 flex flex-col items-center gap-2 md:mb-8 md:text-primary-700';

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled || isCombinedFetching || !day) {
      return;
    }
    const newDate = dayjs(day).format(DateFormat.YYYY_MM_DD);
    setSelectedDay(newDate);
    toggleShowCalendar(false);
  };

  useClickAway(ref, () => {
    if (isShowCalendar) {
      toggleShowCalendar(false);
    }
  });

  const handleGetAvailableTimes = useCallback(() => {
    const date = dayjs(selectedDay.toString()).format(DateFormat.YYYY_MM_DD);
    !!patientInfo?.doctorId &&
      getAvailableTimes({
        patientUserId: patientInfo?._id ?? '',
        appointmentTypeId,
        date,
        doctorId: patientInfo.doctorId,
        timezone,
        isAdHocAppointment: isAdHoc,
        ...(isReschedule && { isReschedule }),
      })
        .unwrap()
        .then((data) => setTimeSlots(data.slots));
  }, [
    appointmentTypeId,
    getAvailableTimes,
    isAdHoc,
    isReschedule,
    patientInfo?._id,
    patientInfo?.doctorId,
    selectedDay,
    timezone,
  ]);

  const handleGetCombinedData = useCallback(() => {
    const date = dayjs(selectedDay.toString()).format(DateFormat.YYYY_MM_DD);
    getCombinedData({
      patientUserId: patientInfo?._id ?? '',
      appointmentTypeId,
      date,
      timezone,
      ...(isReschedule && { isReschedule }),
    })
      .unwrap()
      .then((data) => setTimeSlots(data.slots));
  }, [appointmentTypeId, getCombinedData, isReschedule, patientInfo?._id, selectedDay, timezone]);

  const handleSwitchProviderType = (selectedProvider: Provider) => {
    if (selectedProvider !== provider) {
      if (selectedProvider === Provider.primary) {
        setValue('doctorId', staffDetails?.userId);
        setValue('randomProviderName', '');
      }

      toggleIsAdHoc(false);
      setProvider(selectedProvider);
      setTimeSlots([]);
      toggleShowCalendar(false);
    }
  };

  const handleUpdatedTimeSlots = () => {
    const slotData = Array.isArray(timeSlots)
      ? buildTimeSlotList(timeSlots).filter((e) => {
          if (wmNotCompletedOnboarding && currentAppointment) {
            return dayjs(e.value).isAfter(currentAppointment.appointmentTime?.startTime);
          }
          return true;
        })
      : [];

    setSlotsData(slotData);
  };

  const handleGetProvider = () => {
    if (selectedDay && appointmentTypeId) {
      provider === Provider.any ? handleGetCombinedData() : handleGetAvailableTimes();
    }
  };

  const getStaffInfo = () => {
    if (patientInfo?.doctorId) {
      getStaff({ staffId: patientInfo.doctorId })
        .unwrap()
        .then((res) => setStaffDetails(res));
    }
  };

  const onInit = () => {
    if (!wmNotCompletedOnboarding) return;
    setSelectedDay(dayjs(currentAppointment?.appointmentTime?.startTime).format(DateFormat.YYYY_MM_DD) || '');
  };

  const setAvailableTimeThen = useCallback(
    (data: AvailableDatesDataProps[], startDate: string, endDate: string, isCalendar?: boolean) => {
      if (!isCalendar) {
        const availableList = onFilterByAvailableDates(data) ?? [];
        setAvailableDates(availableList);

        if (!availableList.includes(selectedDay)) {
          setSelectedDay(initialDate && availableList.includes(initialDate) ? initialDate : availableList[0]);
        }
      }

      setNotAvailableDates(onFilterByNotAvailableDates(data ?? [], startDate, endDate));
    },
    [initialDate, selectedDay],
  );

  const getAvailableTime = useCallback(
    (startDate?: string, endDate?: string, isCalendar?: boolean) => {
      const currentStartDate = startDate ?? dayjs().format(DateFormat.YYYY_MM_DD);
      const currentEndDate = endDate ?? dayjs().add(30, 'days').format(DateFormat.YYYY_MM_DD);

      getAvailableDates({
        patientUserId: patientInfo?._id ?? '',
        appointmentTypeId,
        doctorId: patientInfo?.doctorId ?? '',
        endDate: currentEndDate,
        startDate: currentStartDate,
        timezone,
        isAdHocAppointment: isAdHoc,
      })
        .unwrap()
        .then((res) => setAvailableTimeThen(res, currentStartDate, currentEndDate, isCalendar));
    },
    [
      appointmentTypeId,
      getAvailableDates,
      setAvailableTimeThen,
      isAdHoc,
      patientInfo?._id,
      patientInfo?.doctorId,
      timezone,
    ],
  );

  const getGlobalAvailableTime = useCallback(
    (startDate?: string, endDate?: string, isCalendar?: boolean) => {
      const currentStartDate = startDate ?? dayjs().format(DateFormat.YYYY_MM_DD);
      const currentEndDate = endDate ?? dayjs().add(30, 'days').format(DateFormat.YYYY_MM_DD);

      getGlobalAvailableDates({
        patientUserId: patientInfo?._id ?? '',
        startDate: currentStartDate,
        endDate: currentEndDate,
        state: patientState,
        timezone,
        appointmentTypeId,
        planId: activePlanId,
        ...(isReschedule && { isReschedule }),
      })
        .unwrap()
        .then((res) => setAvailableTimeThen(res, currentStartDate, currentEndDate, isCalendar));
    },
    [
      activePlanId,
      appointmentTypeId,
      setAvailableTimeThen,
      getGlobalAvailableDates,
      isReschedule,
      patientInfo?._id,
      patientState,
      timezone,
    ],
  );

  const getDaysWithSlots = (date: Date) => {
    const formattedDate = dayjs(date).isBefore(dayjs()) ? dayjs() : dayjs(date);
    const startDate = formattedDate.format(DateFormat.YYYY_MM_DD);
    const endDate = dayjs(formattedDate).endOf('month').format(DateFormat.YYYY_MM_DD);

    provider === Provider.primary
      ? getAvailableTime(startDate, endDate, true)
      : getGlobalAvailableTime(startDate, endDate, true);
  };

  const handleSelect = (value: string) => {
    if (value === 'calendar') {
      toggleShowCalendar(!isShowCalendar);
      if (!isShowCalendar) {
        const startDate = dayjs(selectedDay).startOf('month').format(DateFormat.YYYY_MM_DD);
        if (startDate) getDaysWithSlots(new Date(startDate));
      }
    } else {
      toggleShowCalendar(false);
      setSelectedDay(value);
    }
  };

  const getDisabledDates = () => {
    return [
      {
        before: new Date(),
        ...(mixedLoading && { after: new Date() }),
      },
      notAvailableDates.map((d) => new Date(d)),
    ].flat();
  };

  useEffect(() => {
    if (availableDates.length === 0 && !isFirstLoading && !isCheckMoreDate) {
      setIsCheckMoreDate(true);
      const firstDayOfNextMonth = dayjs().add(1, 'month').startOf('month').format(DateFormat.YYYY_MM_DD);
      const lastDayOfNextMonth = dayjs().add(1, 'month').endOf('month').format(DateFormat.YYYY_MM_DD);

      provider === Provider.primary
        ? getAvailableTime(firstDayOfNextMonth, lastDayOfNextMonth)
        : getGlobalAvailableTime(firstDayOfNextMonth, lastDayOfNextMonth);
    }
    setIsFirstLoading(false);
    // If add all dependencies, function will be called infinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDates]);

  useEffect(() => {
    provider === Provider.primary ? getAvailableTime() : getGlobalAvailableTime();
    setIsCheckMoreDate(false);
    // If add all dependencies, function will be called infinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, isAdHoc]);

  useEffect(getStaffInfo, [getStaff, patientInfo?.doctorId]);

  useEffect(onInit, [currentAppointment?.appointmentTime?.startTime, wmNotCompletedOnboarding]);

  useEffect(handleGetProvider, [
    selectedDay,
    provider,
    appointmentTypeId,
    handleGetCombinedData,
    handleGetAvailableTimes,
  ]);
  useEffect(handleUpdatedTimeSlots, [currentAppointment, timeSlots, wmNotCompletedOnboarding]);

  return (
    <div className="mt-8">
      {isReschedule && (
        <div className={titleWrapperClassName}>
          <h1 className="text-2xl font-bold">Reschedule appointment</h1>
          <p>Select a date and time below to reschedule</p>
        </div>
      )}

      {staffDetails && (
        <StaffPicker
          primaryStaffInfo={staffDetails}
          isLoading={mixedLoading && !isShowCalendar}
          provider={provider}
          setProvider={handleSwitchProviderType}
        />
      )}

      {provider !== Provider.any && (isPhysician || isAdmin) && (
        <div className="my-8 flex justify-center">
          <Common.Checkbox checked={isAdHoc} onChange={() => toggleIsAdHoc(!isAdHoc)} color="blue">
            <p className="!font-medium text-gray-700">
              <b>Ad-hoc appointments</b> allow you to schedule an appointment even when your schedule is blocked.
            </p>
          </Common.Checkbox>
        </div>
      )}

      {isReschedule && (
        <div className={titleWrapperClassName}>
          <p>Select a date and time below to reschedule</p>
        </div>
      )}

      <div className="relative flex max-h-min flex-col justify-center gap-8 md:gap-6">
        <div>
          {!isReschedule && (
            <h3 className="mb-2 text-mBase font-semibold text-gray-700 md:hidden">Choose time & date</h3>
          )}
          <SchedulingDaySlider
            dates={datesList}
            isAsapAvailable={false}
            isCalendarSelected={isShowCalendar}
            loading={mixedLoading && !isShowCalendar}
            selected={selectedDay}
            onSelect={handleSelect}
          />
        </div>

        {isShowCalendar && (
          <div className="absolute right-[80px] top-0 z-10 hidden rounded-xl bg-white p-4 shadow-lg md:block" ref={ref}>
            <div className="relative mx-auto w-fit md:m-0">
              <DayPicker
                data-testid="day-picker"
                defaultMonth={new Date(selectedDay)}
                disabled={getDisabledDates()}
                fromMonth={new Date()}
                selected={new Date(selectedDay)}
                onDayClick={handleDayClick}
                formatters={{
                  formatWeekdayName: (weekday) => dayjs(weekday).format(DateFormat.ddd),
                }}
                onMonthChange={getDaysWithSlots}
                disableNavigation={mixedLoading}
              />
            </div>
          </div>
        )}

        <Picker
          data={slotsData}
          date={selectedDay}
          isLoading={mixedLoading && !isShowCalendar}
          isUnlimitedPlan={isUnlimitedPlan}
          isReschedule={isReschedule}
          provider={provider}
          setProvider={handleSwitchProviderType}
          onConfirm={onSelect}
          shouldDisabledButton={isLoading}
        />
      </div>

      <div className="mt-3 flex items-center justify-center">
        <Common.Button color="white-alt" preIcon="arrow-left" onClick={handleBack}>
          Back
        </Common.Button>
      </div>
    </div>
  );
};

export default DateAndTime;
