import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { NotPrescribingReason } from 'enums/markCompleteOption';
import { parseHTMLToText } from 'utils/common/parseHTML';

import { getPatientStatus } from './detailsLabel.settings';
import { DetailsLabelProps } from './detailsLabel.types';

const DetailsLabel: React.FC<DetailsLabelProps> = ({
  detailsNote,
  benefitsStatus,
  mifDetails,
  prescribeReminderDate,
  notPrescribingReason,
}) => {
  const patientStatusData = (benefitsStatus || mifDetails) && getPatientStatus(benefitsStatus, mifDetails);
  const snoozedDetails = prescribeReminderDate ? (
    <p className="m-0 flex gap-1 text-sm">
      <span className="m-0 inline-block max-w-[200px] truncate">
        {notPrescribingReason === NotPrescribingReason.WaitingForLabs ? 'Waiting for labs' : 'Too early to prescribe'}
      </span>
      <span className="whitespace-nowrap font-bold">
        Remind in {dayjs(prescribeReminderDate).diff(dayjs(), 'days')} days
      </span>
    </p>
  ) : null;

  const details = detailsNote
    ? (parseHTMLToText({
        text: detailsNote,
        className: 'text-sm  block max-w-[200px] truncate font-normal underline',
      }) as JSX.Element)
    : snoozedDetails;

  const className = 'flex font-medium items-center justify-center gap-2 text-sm text-gray-700 rounded-md w-fit';
  const iconsGroupClassNames = classNames(
    {
      'bg-red-100 text-red-500': patientStatusData?.type === 'error',
      'bg-yellow-100 text-yellow-600': patientStatusData?.type === 'warning',
      'bg-black bg-opacity-5 text-gray-700': !patientStatusData?.type,
    },
    'flex text-xs text-bold gap-1 px-1.5 py-0.5 rounded-3xl items-center',
  );

  return patientStatusData && !prescribeReminderDate ? (
    <div className={className}>
      <div className={iconsGroupClassNames}>
        <Common.Icon name={patientStatusData.icon} />
        {patientStatusData.statusNumber
          ? patientStatusData.statusNumber
          : patientStatusData.statusIcon && <Common.Icon name={patientStatusData.statusIcon} className="h-3 w-3" />}
      </div>
      <span>{patientStatusData.title}</span>
    </div>
  ) : (
    <>{details}</>
  );
};

export default DetailsLabel;
