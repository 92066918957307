import React from 'react';

import 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

import FlagProvider, { IConfig } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Loader from 'components/common/Loader';
import ErrorFallback from 'components/modals/ErrorFallback';
import { initDatadog, logError } from 'utils/datadog';

import App from './App';
import { persistor, store } from './store';

import './index.css';

import 'react-day-picker/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-horizontal-scrolling-menu/dist/styles.css';

dayjs.extend(advanced);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(duration);

// Init dataDog
initDatadog();

const config = {
  url: import.meta.env.VITE_UNLEASH_PROXY_URL,
  clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'LifeMD Physician Portal',
  environment: import.meta.env.VITE_UNLEASH_ENV,
};

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <FlagProvider config={config as IConfig}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<Loader isVisible />}>
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            <App />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </FlagProvider>
  </React.StrictMode>,
);
