import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';

import { dateTimeFormatter } from 'utils/datesAndTime';

import { getDiffInMinutes, isToday } from './dateCell.settings';
import { DateCellProps } from './dateCell.types';

/**
 *
 * @description
 * Tasks Table Date Cell formats example:
 * @link https://www.notion.so/lifemd-inc/Update-Date-formatting-on-Task-listings-for-tasks-with-due-dates-d68dec048a354a6ca68558fd1ca04bdd?pvs=4#803b322e6c234a7b8eb80f4b7f8b0e36
 */

const renderer = ({
  dueDate,
  minutes,
  completed,
}: {
  dueDate: string;
  minutes: CountdownRenderProps['minutes'];
  completed: CountdownRenderProps['completed'];
}) => {
  return (
    <span className="bg-none text-sm font-bold">
      {completed ? (
        `${dateTimeFormatter(dueDate)}`
      ) : (
        <>
          {`${dateTimeFormatter(dueDate)}`}{' '}
          {minutes > 0 && (
            <span data-testid="dateCell-countdown" className="font-medium">{`in ${zeroPad(minutes)} min`}</span>
          )}
        </>
      )}
    </span>
  );
};

const DateCell: React.FC<DateCellProps> = ({ createdAt, dueDate, isQueue, addedToQueueAt, assignedAt }) => {
  const diffInMinutes = getDiffInMinutes(dueDate) || 0;
  const isWithinAnHour = diffInMinutes > 0 && diffInMinutes < 60;
  const seenAfterTime = dayjs.duration(dayjs(assignedAt).diff(createdAt, 'minute'), 'minutes').humanize();

  return (
    <div className="flex flex-row flex-nowrap gap-1">
      {isQueue ? (
        addedToQueueAt ? (
          <span data-testid="addedToQueueAt-label" className="flex flex-row gap-1 text-sm font-bold">
            <Common.Icon name="checkout" className="h-4 w-4" />
            {dayjs(createdAt).fromNow(true)}
            <div className="h-4 w-px bg-black opacity-10" />
            <Common.Icon name="check" className="h-4 w-4" />
            {dayjs(addedToQueueAt).fromNow(true)}
          </span>
        ) : (
          <span className="flex flex-row gap-1 text-sm font-bold">
            <Common.Icon name="clock" className="h-4 w-4" />
            {assignedAt ? <>Seen after {seenAfterTime}</> : <>Waiting {dayjs(createdAt).fromNow(true)}</>}
          </span>
        )
      ) : (
        <>
          <span className="flex flex-row gap-1 text-sm font-bold">
            <Common.Icon name="calendar" className="h-4 w-4" />
            {isToday(new Date(dueDate)) ? (
              isWithinAnHour ? (
                <Countdown
                  renderer={({ minutes, completed }) => renderer({ dueDate, minutes, completed })}
                  date={dueDate}
                />
              ) : (
                <span>{dateTimeFormatter(dueDate)}</span>
              )
            ) : (
              <span>{dueDate ? dateTimeFormatter(dueDate) : dateTimeFormatter(createdAt)}</span>
            )}
          </span>
        </>
      )}
    </div>
  );
};

export default DateCell;
