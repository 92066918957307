import React from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { ProfileImageProps } from '@thecvlb/design-system/lib/src/common';
import { useNavigate } from 'react-router-dom';

import TimeSlot from './TimeSlot';
import { UserTimeInfoProps } from './userTimeInfo.types';
import HoursCell from '../../Components/HoursCell';

const UserTimeInfo: React.FC<UserTimeInfoProps> = ({ item }) => {
  const navigate = useNavigate();
  const { user, timeSlots } = item;

  return (
    <div data-testid="doc_availability_area" className="flex w-full">
      <button
        data-testid="doc_name"
        onClick={() => navigate(`/dashboard/appointments/availability/${user._id}`)}
        className="flex h-[35px] w-44 flex-none items-center py-1 pl-6 pr-3 "
      >
        <Common.ProfileImage
          role={user.userType.shortCode as ProfileImageProps['role']}
          src={user.profileImage}
          size="sm"
          className="mr-1"
        />
        <p className="truncate whitespace-nowrap text-left text-xs font-medium text-gray-700">{`${item.user.firstName} `}</p>
      </button>

      <div className="relative flex h-[35px] w-full">
        <HoursCell shouldShowLastRightBorder shouldShowTopBorder />
        {timeSlots.map((timeSlot) => (
          <TimeSlot key={nanoid()} timeSlot={timeSlot} user={user} />
        ))}
      </div>
    </div>
  );
};

export default UserTimeInfo;
