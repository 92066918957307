import { ColumnDef } from '@tanstack/react-table';

import { TaskProps } from 'models/tasks.types';
import { QueueTasksProps } from 'store/queue/queueSlice.types';

export enum TaskStatus {
  Urgent = 'urgent',
  Total = 'total',
}

type DoctorInfo = {
  name: string;
  _id: string;
};

export interface TasksTableColumnsProps extends TaskProps {
  category: string;
  details: { note: string };
  doctorInfo?: DoctorInfo;
  status: string;
  isAutomated: boolean;
  assignedAt?: string;
  createdAt: string;
  _id: string;
  isSent?: boolean;
}

export interface TasksTableProps {
  regularTasksData: TaskProps[];
  regularTasksColumns: ColumnDef<TasksTableColumnsProps>[];
  sortField: string[];
  showQueue: boolean;
  queueTableData: QueueTasksProps[];
  queueTableColumns: ColumnDef<QueueTasksProps>[];
  isFetching?: boolean;
  totalCount: number;
  showingFrom: number;
  showingTo: number;
  handleOpenTask: (task: TaskProps | QueueTasksProps) => void;
  showRefreshTasksButton: boolean;
  newTasksCount: number;
  handleRefreshTasks: () => void;
}
