import { useFormContext } from 'react-hook-form';

import ControlledInput from 'components/common/form/ControlledInput';
import ControlledPhoneInput from 'components/common/form/ControlledPhoneInput';
import ControlledSelect from 'components/common/form/ControlledSelect';
import ControlledMaskInput from 'components/common/form/maskInput/ControlledMaskInput';
import { SEX_AT_BIRTH } from 'constants/user';

import {
  addressValidationRules,
  dateOfBirthValidationRules,
  emailValidationRules,
  phoneNumberValidationRules,
  textFieldValidationRules,
} from './createProfile.settings';
import { zipCodeValidationRules } from '../confirmAndPay/PaymentForm/paymentForm.settings';

const CreateProfile: React.FC<{ states: { value: string; label: string }[] }> = ({ states }) => {
  const { control, formState } = useFormContext();

  return (
    <div data-testid="create_profile_section" className="my-6">
      <h4 className="mb-4 text-base font-bold text-primary">Please create your secure account</h4>
      <div className="flex w-full gap-4">
        <ControlledInput
          className="mb-6 w-full"
          control={control}
          name="firstName"
          label="First name"
          rules={textFieldValidationRules('First name')}
        />
        <ControlledInput
          control={control}
          name="lastName"
          className="mb-6 w-full"
          label="Last name"
          rules={textFieldValidationRules('Last name')}
        />
      </div>
      <div className="flex w-full gap-4">
        <ControlledInput
          control={control}
          className="mb-6 w-full"
          name="email"
          label="Email"
          rules={emailValidationRules}
        />
        <div className="mb-6 w-full">
          <ControlledPhoneInput control={control} rules={phoneNumberValidationRules} />
        </div>
      </div>
      <div className="flex w-full gap-4">
        <div className="w-full">
          <ControlledMaskInput
            className="h-3"
            dataTestId="dob_field"
            control={control}
            placeholder="Date of birth"
            name="dob"
            rules={dateOfBirthValidationRules}
            format="##/##/####"
            isError={!!formState.errors.dob}
          />
          {!formState.errors.dob && <span className="ml-1 mt-1.5 block text-xs text-gray">MM/DD/YYYY</span>}
        </div>

        <ControlledSelect
          control={control}
          options={SEX_AT_BIRTH}
          name="sexAtBirth"
          className="w-full"
          label="Sex at birth"
          rules={{
            required: {
              value: true,
              message: 'Sex at birth is required',
            },
          }}
        />
      </div>
      <div className="mt-4 border-t">
        <h4 className="mb-4 mt-6 text-base font-bold text-primary">Address</h4>
        <ControlledInput
          control={control}
          name="address"
          className="mb-6"
          label="Street address"
          rules={addressValidationRules}
        />
        <div className="flex w-full gap-4">
          <ControlledInput
            control={control}
            name="city"
            className="w-full"
            label="City"
            rules={textFieldValidationRules('City')}
          />
          <ControlledSelect
            control={control}
            options={states}
            name="state"
            label="State"
            className="w-full"
            rules={{
              required: {
                value: true,
                message: 'State is required',
              },
            }}
          />
          <ControlledMaskInput
            name="zipCode"
            control={control}
            rules={zipCodeValidationRules}
            placeholder="ZIP"
            format="#####"
            wrapperClasses="w-full"
            isError={!!formState.errors.zipCode}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
