import { ProfileImageProps } from '@thecvlb/design-system/lib/src/common';

import TransferPopup from 'components/common/TransferPopup';
import { TransferPopupItemProps } from 'components/common/TransferPopup/transferPopup.types';
import User from 'components/common/User';
import { useAppSelector } from 'hooks/redux';
import type { AssignedToInfo } from 'models/tasks.types';
import { useUpdateTaskAssignMutation } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';

import { generateAudienceIcon, generateAudienceTitle, getAudience } from './dateCell.settings';

const AssignedToCell: React.FC<Partial<AssignedToInfo>> = ({
  _id,
  profileImage,
  userRoleInfo,
  name,
  specificAudience = [],
  defaultAudience = [],
  category,
  taskId,
  tags,
}) => {
  const { _id: userId } = useAppSelector(selectUser);
  const [updateTaskAssign] = useUpdateTaskAssignMutation();
  const audience = getAudience(specificAudience, defaultAudience);

  const reassignTaskAudience = (item: TransferPopupItemProps) => {
    updateTaskAssign({ taskId: taskId ?? '', audience: [item.shortCode] });
  };

  return (
    <div className="flex items-center font-normal">
      <TransferPopup
        src={profileImage}
        name={name ?? ''}
        role={userRoleInfo?.shortName}
        onButtonClick={reassignTaskAudience}
        isTasksList
        assignedToId={_id}
        taskId={taskId}
        tags={tags}
        taskCategory={category}
        specificAudience={audience}
      >
        {_id && name ? (
          <User
            src={profileImage}
            role={userRoleInfo?.shortName as ProfileImageProps['role']}
            name={_id === userId ? 'Me' : name}
          />
        ) : (
          <>
            {audience.length > 0 ? (
              <div className="flex items-center">
                {generateAudienceIcon(audience)}
                <div data-testid="user_name" className="ml-1 text-sm font-medium text-gray-700">
                  {generateAudienceTitle(audience)}
                </div>
              </div>
            ) : (
              <span className="text-sm font-normal">Unassigned</span>
            )}
          </>
        )}
      </TransferPopup>
    </div>
  );
};

export default AssignedToCell;
