import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';

import useZoomCall from 'hooks/common/useZoomCall';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';
import { selectUser } from 'store/user/userSlice';
import { selectVideoCall, setIsPiP } from 'store/videoCall/videoCallSlice';

import { AppointmentProps } from './appointment.types';

const AppointmentState = createSelector([selectVideoCall, selectUser], (videoCall, user) => ({
  isVideoCallOpen: videoCall.isOpen,
  isPiP: videoCall.isPiP,
  callId: videoCall.taskId,
  userId: user._id,
}));

const Appointment: React.FC<AppointmentProps> = ({ appointment, queueTime }) => {
  const dispatch = useAppDispatch();
  const { callId, isPiP, isVideoCallOpen } = useAppSelector(AppointmentState);
  const { handleOpenTask } = useOpenTask();
  const { taskInfo, isPiP: isZoomPiP, isZoomCallOpen, toggleIsPiP } = useZoomCall();
  const startTime = (queueTime || appointment?.appointmentTime?.startTime) ?? '';
  const isQueueAppointment = !!queueTime;
  const text = `${appointment?.appointmentType.displayName} • ${appointment?.staffName || ''}`;
  const onClick = () => {
    const shouldExpandVideoCall =
      (isVideoCallOpen && isPiP && callId === appointment?.taskId) ||
      (isZoomCallOpen && isZoomPiP && taskInfo?._id === appointment?.taskId);
    if (shouldExpandVideoCall) {
      dispatch(setIsPiP(false));
      toggleIsPiP(false);
    }
    handleOpenTask({
      taskId: appointment.taskId,
      assignToId: appointment.doctorId,
      assignToName: appointment.staffName,
    });
  };

  return (
    <Common.Appointment onClick={onClick} startTime={startTime} isQueueAppointment={isQueueAppointment} text={text} />
  );
};

export default Appointment;
