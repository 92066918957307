import { SignupCouponCodes } from 'store/crossSell/crossSell.types';

export const COUPON_OPTONS = [
  { label: '4HEALTH25 - 25% off', value: '4health25' },
  { label: '4HEALTH50 - 50% off', value: '4health50' },
  { label: 'XSELL100OFF - 100% off', value: 'xsell100off' },
];

export const SIGNUP_COUPON_CODES: { [key in SignupCouponCodes]?: string } = {
  '4health25': '25%',
  '4health50': '50%',
  xsell100off: '100%',
};

export const CHANGE_PLAN_COUPON_OPTIONS = [
  { label: '200OFFBUNDLE - $200.00 off', value: '200OFFBUNDLE' },
  { label: 'None', value: '' },
];
