import { Common } from '@thecvlb/design-system';

import { BasicConfirmationProps } from './basicConfirmation.types';

const BasicConfirmation: React.FC<BasicConfirmationProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  confirmButtonText = 'Delete',
  headerText = 'Are you sure?',
  subHeaderText = 'This action cannot be undone.',
}) => {
  return (
    <Common.Modal isOpen={isOpen} padding={false} zIndex={120} persist={isLoading} size="sm">
      <div className="p-4">
        <h3 className="mb-2 text-xl font-bold">{headerText}</h3>
        <p className="mb-6 text-base font-medium">{subHeaderText}</p>
        <div className="flex flex-row gap-3">
          <Common.Button
            className="grow justify-center"
            color="white-alt"
            type="button"
            size="sm"
            disabled={isLoading}
            onClick={onClose}
          >
            Cancel
          </Common.Button>
          <Common.Button
            className="grow justify-center"
            color="red"
            size="sm"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onConfirm}
            type="button"
          >
            {confirmButtonText}
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default BasicConfirmation;
