import { PopupHeaderProps } from './popupHeader.types';

const PopupHeader: React.FC<PopupHeaderProps> = ({ title, id }) => {
  return (
    <div className="mb-8 flex justify-between">
      <h1 className="text-xl font-bold text-gray-700">{title}</h1>
      {id && (
        <p data-testid="id" className="text-sm">
          ID: {id}
        </p>
      )}
    </div>
  );
};

export default PopupHeader;
