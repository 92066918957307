import { Common } from '@thecvlb/design-system';

import { PARequestStatus } from 'enums/taskDetailsStatus';

// For task view
export const getPARequestStatus = (status: PARequestStatus) => {
  switch (status) {
    case PARequestStatus.CREATED:
      return <Common.ColorTag color="red" text="PA requested" />;
    case PARequestStatus.SENT:
      return <Common.ColorTag color="gray" text="Sent" />;
    case PARequestStatus.APPROVED:
      return <Common.ColorTag color="green" text="Approved" />;
    case PARequestStatus.DENIED:
      return <Common.ColorTag color="red" text="Denied" />;
    case PARequestStatus.ERROR:
      return <Common.ColorTag color="red" text="Error" />;
  }
};

// For insurance view
export const getPARequestStyle = (status: PARequestStatus) => {
  switch (status) {
    case PARequestStatus.CREATED:
      return <Common.ColorTag color="red" text="PA requested" />;
    case PARequestStatus.SENT:
      return <Common.ColorTag color="gray" text="PA sent" />;
    case PARequestStatus.APPROVED:
      return <Common.ColorTag color="green" text="PA approved" />;
    case PARequestStatus.DENIED:
      return <Common.ColorTag color="red" text="PA denied" />;
    case PARequestStatus.ERROR:
      return <Common.ColorTag color="red" text="Error" />;
  }
};
