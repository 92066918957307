import { createEntityAdapter, createSlice, EntityId } from '@reduxjs/toolkit';

import { DocumentsDataItemProps } from 'components/patient/Documents/documents.types';
import { TagTypes } from 'enums/tagTypes';
import { BodyImageManualStatus } from 'models/bodyImage.types';
import { PatientsQueryParams, SymptomsResponseProps } from 'models/patients.types';
import { ProspectUserProps } from 'models/prospect.types';
import type { RootState } from 'store';

import { transformPatientsResponse } from './patients.settings';
import {
  BillingHistoryResponseProps,
  CallTokenProps,
  CancelSubscriptionAutoRenewRequestProps,
  ChartHealthMetrics,
  CheckInsuranceResponseProps,
  CreatePatientProps,
  DeactivateWithRefundRequestProps,
  DefaultQueryParams,
  DeletePatientResponseProps,
  DowngradeSubscriptionRequestProps,
  ElationProps,
  GetChartHealthMetricsProps,
  GetPatientDocumentsQueryParamsProps,
  GetPatientDocumentsQueryResponseProps,
  LoginTokenProps,
  PatientInitialStateProps,
  PatientProps,
  PauseSubscriptionRequestProps,
  PaymentProfileProps,
  PaymentProfileRes,
  PaymentTransactionsResponseProps,
  PreviewChangeSubscriptionData,
  PreviewChangeSubscriptionRequestProps,
  ProspectPatientProps,
  ReactivatePatientResponseProps,
  RefundInvoiceResponseProps,
  ReportProps,
  SubscriptionDetailsResponse,
  Tag,
  UploadDocumentsParams,
  UploadDocumentsResponse,
} from './patients.types';
import { apiSlice } from '../api/apiSlice';
import { HistoryProps } from '../history/history.types';

const documentsAdapter = createEntityAdapter<DocumentsDataItemProps, EntityId>({
  selectId: (document) => document._id,
  sortComparer: (a, b) => b.createdAt?.localeCompare(a.createdAt),
});

const initialState: PatientInitialStateProps = {
  documents: {
    items: documentsAdapter.getInitialState(),
    totalCount: 0,
    patientId: '',
  },
  benefits: null,
  paymentProfiles: null,
  patientInfo: null,
  isLoadingTagList: false,
  isTripleTherapyPatient: false,
};

export const patientsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query<{ patients: PatientProps[]; totalCount: number }, { params: PatientsQueryParams }>({
      query: ({ params }) => ({
        url: '/patients',
        params,
      }),
      transformResponse: (response: { data: PatientProps[]; info: { totalCount: number } }) => ({
        patients: transformPatientsResponse(response.data),
        totalCount: response.info.totalCount,
      }),
      providesTags: (result) =>
        result
          ? ['Patient', ...result.patients.map(({ _id }) => ({ type: 'Patient' as const, id: _id }))]
          : ['Patient'],
    }),

    getProspects: builder.query<
      { prospects: ProspectPatientProps[]; totalCount: number },
      { params: PatientsQueryParams }
    >({
      query: ({ params }) => ({
        url: '/patients/prospect-list',
        params,
      }),
      transformResponse: (response: { data: ProspectPatientProps[]; info: { totalCount: number } }) => ({
        prospects: response.data,
        totalCount: response.info.totalCount,
      }),
      providesTags: (result) =>
        result
          ? ['Patient', ...result.prospects.map(({ _id }) => ({ type: 'Patient' as const, id: _id }))]
          : ['Patient'],
    }),
    getPatient: builder.query<PatientProps, { id: string }>({
      query: ({ id }) => `/patients/${id}`,
      transformResponse: (response: { data: PatientProps }) => response.data,
      providesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.id }],
    }),
    getPatientChart: builder.query<{ [key: string]: { [key: string]: string }[] }, { id: string }>({
      query: ({ id }) => `/patients/${id}/my-chart`,
      transformResponse: (response: { data: { [key: string]: { [key: string]: string }[] } }) => response.data,
    }),
    getProspectPatient: builder.query<ProspectUserProps, { id: string }>({
      query: ({ id }) => ({
        url: 'patients/prospect',
        method: 'GET',
        params: { patientId: id },
      }),
      transformResponse: (response: { data: ProspectUserProps }) => response.data,
    }),
    getReports: builder.query<ReportProps[], { id: string }>({
      query: ({ id }) => `/patients/${id}/reports`,
      transformResponse: (response: { data: ReportProps[] }) => response.data,
    }),
    getReport: builder.query<Blob, { patientId: string; reportId: number }>({
      query: ({ patientId, reportId }) => ({
        url: `/patients/${patientId}/reports/details?reportId=${reportId}`,
        method: 'GET',
        responseHandler: (response: Response) => {
          return response.blob().then((res) => res);
        },
      }),
      transformResponse: (response: Blob) => response,
    }),
    viewEMR: builder.query<ElationProps, { id: string }>({
      query: ({ id }) => `/patients/${id}/elationId`,
      transformResponse: (response: { data: ElationProps }) => {
        window.open(`${import.meta.env.VITE_LIFE_MD_VIEW_AS_EMR}/${response.data.elationId}`, '_blank');
        return response.data;
      },
    }),
    viewMaxio: builder.query<{ url: string }, { id: string }>({
      query: ({ id }) => `patients/${id}/view-in-maxio`,
      transformResponse: (response: { data: { url: string } }) => {
        window.open(response.data.url, '_blank');
        return response.data;
      },
    }),
    getViewMaxioUrl: builder.query<{ url: string }, { id: string }>({
      query: ({ id }) => `patients/${id}/view-in-maxio`,
      transformResponse: (response: { data: { url: string } }) => response.data,
    }),
    loginLikePatient: builder.query<LoginTokenProps, { id: string }>({
      query: ({ id }) => `/patients/${id}/login-token`,
      transformResponse: (response: { data: LoginTokenProps }) => {
        window.open(
          `${import.meta.env.VITE_LIFE_MD_LOGIN_AS_PATIENT}?login-token=${response.data.patientViewToken}`,
          '_blank',
        );
        return response.data;
      },
    }),
    loginToCreateAppointment: builder.query<LoginTokenProps, { id: string }>({
      query: ({ id }) => `/patients/${id}/login-token`,
      transformResponse: (response: { data: LoginTokenProps }) => {
        window.open(
          `${import.meta.env.VITE_LIFE_MD_LOGIN_AS_PATIENT}?login-token=${
            response.data.patientViewToken
          }&create-appointment=true`,
          '_blank',
        );
        return response.data;
      },
    }),
    getHistory: builder.query<HistoryProps, { id: string; params: string }>({
      query: ({ id, params }) => `/patients/${id}/action-logs${params}`,
    }),
    getCallToken: builder.query<CallTokenProps, { id: string }>({
      query: ({ id }) => `patients/voice-calls/${id}/token`,
      transformResponse: (response: { data: CallTokenProps }) => response.data,
    }),
    createAccount: builder.mutation<unknown, CreatePatientProps>({
      query: (body) => ({
        url: 'patients/create-account',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: unknown }) => response.data,
    }),
    updateProspect: builder.mutation({
      query: ({ id, body }) => ({
        url: `/patients/${id}/update-prospect`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: { data: unknown; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.id }],
    }),
    updateNotes: builder.mutation<unknown, { id: string; notes: string }>({
      query: ({ id, notes }) => ({ url: `/patients/${id}/notes`, method: 'PATCH', body: { notes } }),
      transformResponse: (response: { data: unknown }) => response.data,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.id }],
    }),
    getPatientDocuments: builder.query<GetPatientDocumentsQueryResponseProps, GetPatientDocumentsQueryParamsProps>({
      query: ({ patientId, source, limit = 20, pageNo = 0 }) => ({
        url: `/patients/${patientId}/documents`,
        params: {
          source,
          limit,
          pageNo,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ _id }) => ({ type: 'Documents' as const, id: _id })),
              { type: 'Documents', id: 'LIST' },
            ]
          : [{ type: 'Documents', id: 'LIST' }],
    }),
    deleteDocument: builder.mutation<unknown, { patientId: string; documentId: string; isBodyImage?: boolean }>({
      query: ({ patientId, documentId }) => ({
        url: `/patients/${patientId}/documents/${documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Documents', id: arg.documentId },
        { type: 'Documents', id: 'LIST' },
        ...(arg.isBodyImage ? [{ type: 'Patient', id: arg.patientId }] : []),
      ],
    }),
    uploadDocuments: builder.mutation<UploadDocumentsResponse, UploadDocumentsParams>({
      query: ({ body, patientId }) => ({
        url: `/patients/${patientId}/documents`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Documents', id: 'LIST' },
        ...(arg.isBodyImage ? [{ type: 'Patient', id: arg.patientId }] : []),
      ],
    }),
    downloadPatientDocument: builder.query<Blob, { documentId: string }>({
      query: ({ documentId }) => ({
        url: `/patient-documents/${documentId}/download`,
        responseHandler: (response: Response) => {
          return response.blob().then((res) => res);
        },
      }),
      transformResponse: (response: Blob) => response,
    }),

    getTags: builder.query<{ data: Tag[]; totalCount: number }, { patientId: string }>({
      query: ({ patientId }) => `/patients/${patientId}/tags`,
      providesTags: () => [{ type: 'Tags', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),
    getTagsList: builder.query<{ data: Tag[]; message: string }, { tagTypes: TagTypes[] }>({
      query: ({ tagTypes }) => {
        return {
          url: '/user-tags',
          params: {
            types: tagTypes.join(','),
          },
        };
      },
      keepUnusedDataFor: 300, // keep tags cache for 5 min
    }),
    editPatientTags: builder.mutation<{ message: string }, { patientId: string; body: string[] }>({
      query: ({ patientId, body }) => ({
        url: `/patients/${patientId}/tags`,
        method: 'PUT',
        body,
      }),
    }),
    getSymptoms: builder.query<SymptomsResponseProps, { patientId: string }>({
      query: ({ patientId }) => `/patients/${patientId}/symptom-checker-results`,
      transformResponse: (response: { data: SymptomsResponseProps }) => ({
        id: response.data.id,
        scResults: response.data.scResults ?? [],
        symptomCheckerStatus: response.data.symptomCheckerStatus ?? '',
      }),
      providesTags: (result) =>
        result?.scResults
          ? ['Symptom', ...result?.scResults?.map(({ id }) => ({ type: 'Symptom' as const, id }))]
          : ['Symptom'],
    }),
    updatePatientsProvider: builder.mutation<{ message: string }, { patientId: string; doctorId: string }>({
      query: ({ patientId, doctorId }) => ({
        url: `/patients/${patientId}/provider-update`,
        method: 'PATCH',
        body: { doctorId },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
    }),
    reactivatePatient: builder.mutation<ReactivatePatientResponseProps, { patientId: string }>({
      query: ({ patientId }) => ({
        url: `/patients/${patientId}/reactivate`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
    }),
    deactivatePatient: builder.mutation<
      DeletePatientResponseProps,
      { patientId: string; subscriptionCancelReasonCode: string; chargifyCancelMessage?: string }
    >({
      query: ({ patientId, subscriptionCancelReasonCode, chargifyCancelMessage }) => ({
        url: `/patients/${patientId}`,
        method: 'DELETE',
        body: { subscriptionCancelReasonCode, ...(chargifyCancelMessage && { chargifyCancelMessage }) },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
    }),
    getBenefits: builder.query<CheckInsuranceResponseProps, { patientId: string }>({
      query: ({ patientId }) => `/patients/${patientId}/check-benefits`,
      transformResponse: (response: { data: CheckInsuranceResponseProps }) => response.data,
      providesTags: ['Benefits'],
    }),
    submitInsurance: builder.mutation({
      query: ({ body, patientId }) => ({
        url: `/patients/${patientId}/submit-insurance`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Benefits'],
    }),
    getChartHealthMetrics: builder.query<
      { data: ChartHealthMetrics; message: string },
      { params: GetChartHealthMetricsProps; patientId: string }
    >({
      query: ({ params, patientId }) => ({ url: `/patients/${patientId}/chart-health-metrics`, params }),
      providesTags: ['ChartHealthMetrics'],
    }),
    requireAppointment: builder.mutation({
      query: ({ patientId }) => ({
        url: `/patients/${patientId}/wm/require-appointment`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Documents', id: 'LIST' }],
    }),
    updateGoalWeight: builder.mutation({
      query: ({ id, goalWeight }) => ({
        url: `/patients/${id}/wm/goal-weight`,
        method: 'PATCH',
        body: {
          goalWeight,
        },
      }),
    }),
    updateEmail: builder.mutation({
      query: ({ id, email }) => ({
        url: `/patients/${id}/email`,
        method: 'PATCH',
        body: {
          email,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.id }],
    }),
    getBillingHistory: builder.query<BillingHistoryResponseProps, { id: string; params: DefaultQueryParams }>({
      query: ({ id, params }) => {
        return {
          url: `/patients/${id}/billing-history`,
          params: { ...params, sortOrder: params.sortOrder ? params.sortOrder.toLowerCase() : undefined },
        };
      },
      providesTags: ['Billing'],
    }),
    getPaymentTransactions: builder.query<PaymentTransactionsResponseProps, { id: string; params: DefaultQueryParams }>(
      {
        query: ({ id, params }) => {
          return {
            url: `/patients/${id}/payment-transactions`,
            params,
          };
        },
        providesTags: ['PaymentTransactions'],
      },
    ),
    refundInvoice: builder.mutation<RefundInvoiceResponseProps, unknown>({
      query: ({ id, body }) => ({
        url: `/invoices/${id}/refunds`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Billing'],
    }),
    cancelSubscriptionAutoRenew: builder.mutation<{ message: string }, CancelSubscriptionAutoRenewRequestProps>({
      query: ({ patientId, cancelReasonCode, note }) => ({
        url: `/patients/${patientId}/cancel-subscription-auto-renew`,
        body: { cancelReasonCode, note },
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }, 'Subscription'],
    }),
    deactivateWithRefund: builder.mutation<{ message: string }, DeactivateWithRefundRequestProps>({
      query: ({ patientId, cancelReasonCode, note, refundAmount }) => ({
        url: `/patients/${patientId}/deactivate-with-refund`,
        body: { cancelReasonCode, note, refundAmount },
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
    }),
    downgradeSubscription: builder.mutation<{ message: string }, DowngradeSubscriptionRequestProps>({
      query: ({ patientId, cancelReasonCode, note, refundAmount }) => ({
        url: `/patients/${patientId}/downgrade-subscription`,
        body: { cancelReasonCode, note, refundAmount },
        method: 'PATCH',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(patientsApi.util.invalidateTags([{ type: 'Patient', id: props.patientId }]));
        }, 10000);
      },
    }),
    pauseSubscription: builder.mutation<{ message: string }, PauseSubscriptionRequestProps>({
      query: ({ patientId, pauseDuration }) => ({
        url: `/patients/${patientId}/pause-subscription`,
        body: { pauseDuration },
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }, 'Subscription'],
    }),
    getSubscriptionDetails: builder.query<SubscriptionDetailsResponse, { patientId: string }>({
      query: ({ patientId }) => `/patients/${patientId}/subscription-details`,
      transformResponse: (response: { data: SubscriptionDetailsResponse }) => response.data,
      providesTags: ['Subscription'],
    }),
    getAccountBalances: builder.query<{ creditBalance: string }, { patientId: string }>({
      query: ({ patientId }) => `/patients/${patientId}/account-balances`,
      transformResponse: (response: { data: { creditBalance: string } }) => response.data,
    }),
    getPreviewChangeSubscription: builder.query<PreviewChangeSubscriptionData, PreviewChangeSubscriptionRequestProps>({
      query: ({ patientId, planId, planPricePointId, couponCode }) => ({
        url: `/patients/${patientId}/preview-change-subscription`,
        body: { planId, planPricePointId, couponCode },
        method: 'PATCH',
      }),
      transformResponse: (response: { data: PreviewChangeSubscriptionData }) => response.data,
    }),
    updateDefaultPaymentProfile: builder.mutation<{ message: string }, { patientId: string; paymentProfileId: string }>(
      {
        query: ({ patientId, paymentProfileId }) => ({
          url: `/patients/${patientId}/payment-profile/${paymentProfileId}/set-default`,
          method: 'PUT',
        }),
        invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
      },
    ),
    deletePaymentProfile: builder.mutation<{ message: string }, { patientId: string; paymentProfileId: string }>({
      query: ({ patientId, paymentProfileId }) => ({
        url: `/patients/${patientId}/payment-profile/${paymentProfileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
    }),
    createPaymentProfile: builder.mutation<
      PaymentProfileRes,
      { patientId: string; body: PaymentProfileProps | string }
    >({
      query: ({ patientId, body }) => ({
        url: `/patients/${patientId}/payment-profile`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: PaymentProfileRes }) => response.data,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }, 'Billing'],
    }),
    changeSubscription: builder.mutation<
      { message: string },
      { patientId: string; planId: string; planPricePointId: string; couponCode?: string }
    >({
      query: ({ patientId, planId, planPricePointId, couponCode }) => ({
        url: `/patients/${patientId}/change-subscription`,
        method: 'PATCH',
        body: { planId, planPricePointId, couponCode },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }, 'Billing'],
    }),
    createPlanRecommend: builder.mutation<
      { message: string },
      { patientId: string; body: { planRecommendationId: string } }
    >({
      query: ({ patientId, body }) => ({
        url: `/patients/${patientId}/plan-recommendation`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
    }),
    disablePlanRecommendation: builder.mutation<{ message: string }, { patientId: string }>({
      query: ({ patientId }) => ({
        url: `/patients/${patientId}/disable-plan-recommendation`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
    }),
    updateVerifyBodyImage: builder.mutation<
      { message: string },
      { patientId: string; manualStatus: BodyImageManualStatus | null }
    >({
      query: ({ patientId, manualStatus }) => ({
        url: `/patients/${patientId}/body-image-status`,
        method: 'PATCH',
        body: { manualStatus },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Patient', id: arg.patientId },
        { type: 'Tags', id: 'LIST' },
      ],
    }),
  }),
});

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    resetDocuments: (state) => {
      documentsAdapter.removeAll(state.documents.items);
      state.documents.totalCount = 0;
      state.documents.patientId = '';
    },
    resetBenefits: (state) => {
      state.benefits = null;
    },
    resetPaymentProfiles: (state) => {
      state.paymentProfiles = null;
    },
    resetPatientInfo: (state) => {
      state.patientInfo = null;
    },
    setLoadingTagList: (state, action) => {
      state.isLoadingTagList = action.payload;
    },
    setIsTripleTherapyPatient: (state, action) => {
      state.isTripleTherapyPatient = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(patientsApi.endpoints.getPatientDocuments.matchFulfilled, (state, { payload, meta }) => {
      state.documents.totalCount = payload.info.totalCount;
      if (state.documents.patientId === meta.arg.originalArgs.patientId) {
        documentsAdapter.upsertMany(state.documents.items, payload.data);
      } else {
        state.documents.patientId = meta.arg.originalArgs.patientId;
        documentsAdapter.setAll(state.documents.items, payload.data);
      }
    });
    builder.addMatcher(patientsApi.endpoints.deleteDocument.matchFulfilled, (state, { meta }) => {
      documentsAdapter.removeOne(state.documents.items, meta.arg.originalArgs.documentId);
    });
    builder.addMatcher(patientsApi.endpoints.uploadDocuments.matchFulfilled, (state, { payload }) => {
      documentsAdapter.upsertOne(state.documents.items, payload.data);
    });
    builder.addMatcher(patientsApi.endpoints.getBenefits.matchFulfilled, (state, { payload }) => {
      state.benefits = payload;
    });
    builder.addMatcher(patientsApi.endpoints.getPatient.matchFulfilled, (state, { payload }) => {
      state.patientInfo = payload;
      state.paymentProfiles = payload.paymentProfiles || null;
    });
  },
});

export const patientDocumentsSelectors = documentsAdapter.getSelectors<RootState>(
  (state) => state.patient.documents.items,
);

export const selectPatient = (state: RootState) => state.patient;

export const {
  resetDocuments,
  resetBenefits,
  resetPaymentProfiles,
  resetPatientInfo,
  setLoadingTagList,
  setIsTripleTherapyPatient,
} = patientSlice.actions;

export const {
  useGetPatientDocumentsQuery,
  useLazyGetPatientDocumentsQuery,
  useDeleteDocumentMutation,
  useUploadDocumentsMutation,
  useLazyGetHistoryQuery,
  useLazyDownloadPatientDocumentQuery,
  useGetPatientQuery,
  useLazyGetPatientChartQuery,
  useLazyGetProspectsQuery,
  useUpdateNotesMutation,
  useGetProspectsQuery,
  useCreateAccountMutation,
  useLazyGetPatientQuery,
  useUpdateProspectMutation,
  useGetPatientsQuery,
  useLazyViewEMRQuery,
  useLazyViewMaxioQuery,
  useGetViewMaxioUrlQuery,
  useLazyLoginLikePatientQuery,
  useLazyLoginToCreateAppointmentQuery,
  useLazyGetCallTokenQuery,
  useLazyGetProspectPatientQuery,
  useGetTagsQuery,
  useLazyGetTagsQuery,
  useGetTagsListQuery,
  useEditPatientTagsMutation,
  useGetSymptomsQuery,
  useUpdatePatientsProviderMutation,
  useReactivatePatientMutation,
  useDeactivatePatientMutation,
  useGetBenefitsQuery,
  useLazyGetBenefitsQuery,
  useSubmitInsuranceMutation,
  useGetChartHealthMetricsQuery,
  useRequireAppointmentMutation,
  useUpdateGoalWeightMutation,
  useUpdateEmailMutation,
  useLazyGetBillingHistoryQuery,
  useLazyGetPaymentTransactionsQuery,
  useCancelSubscriptionAutoRenewMutation,
  useDeactivateWithRefundMutation,
  useDowngradeSubscriptionMutation,
  usePauseSubscriptionMutation,
  useRefundInvoiceMutation,
  useGetSubscriptionDetailsQuery,
  useGetAccountBalancesQuery,
  useLazyGetPreviewChangeSubscriptionQuery,
  useUpdateDefaultPaymentProfileMutation,
  useDeletePaymentProfileMutation,
  useCreatePaymentProfileMutation,
  useChangeSubscriptionMutation,
  useCreatePlanRecommendMutation,
  useDisablePlanRecommendationMutation,
  useUpdateVerifyBodyImageMutation,
} = patientsApi;

export default patientSlice.reducer;
