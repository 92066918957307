import { io } from 'socket.io-client';

import config from 'config/socket/chat';

const { webSocket } = config;

const socketMessages = io(webSocket.baseUrlWebSocketMessages, {
  secure: true,
  reconnectionAttempts: 5,
  transports: ['websocket', 'webtransport'],
});

export default socketMessages;
