import { useState } from 'react';

import { Common } from '@thecvlb/design-system';

import { Option } from 'models/form.types';
import { useGetUsersQuery } from 'store/lookup/lookupSlice';

const PhysicianMultiSelect: React.FC<{ setSelectedPhysiciansIds: (ids: string[]) => void }> = ({
  setSelectedPhysiciansIds,
}) => {
  const { data: doctors } = useGetUsersQuery(
    { userType: 'Physician' },
    {
      selectFromResult: (result) => {
        if (result.isSuccess && result?.data) {
          return {
            data: result.data.map((item) => ({
              label: item.name,
              value: item._id,
            })),
          };
        }
        return { data: [] };
      },
    },
  );

  const [selectedPhysicians, setSelectedPhysicians] = useState<Option[]>([]);

  const handleDoctorChange = (selected: unknown) => {
    setSelectedPhysicians(selected as Option[]);
    setSelectedPhysiciansIds((selected as Option[]).map((option) => option.value));
  };

  return (
    <div data-testid="filter_doctors_dropdown" className="relative z-10 mb-4 ml-6 w-64">
      <Common.MultiSelect
        name="doctors"
        placeholder="All"
        size="md"
        value={selectedPhysicians}
        options={doctors as Option[]}
        onChange={handleDoctorChange}
      />
    </div>
  );
};

export default PhysicianMultiSelect;
