import { useEffect, useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import SearchForm from 'components/filters/SearchForm';
import TaskCategoriesFilter from 'components/filters/Tasks/TaskCategoriesFilter';
import CreateTask from 'components/modals/CreateTaskForm/CreateTaskForm';
import ConnectedTasksTable from 'components/tables/TaskTable/ConnectedTasksTable';
import { TagTypes } from 'enums/tagTypes';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { TasksQueryParams } from 'models/tasks.types';
import { openModal } from 'store/modal/modalSlice';
import { useGetTagsListQuery } from 'store/patients/patientsSlice';
import { useLazyGetTaskFiltersDetailsQuery } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';

import { filtersByRoles } from './Tasks.settings';

const selectUserState = createSelector(selectUser, (user) => ({ userRole: user.userType.name }));

const TasksPage = () => {
  const [searchParams, setSearchParams] = useSearchParams({ limit: '50', pageNo: '0' });
  const dispatch = useAppDispatch();

  const isQueueDisabled = useFlag('disable-queue');

  const queryParams: TasksQueryParams = useMemo(() => {
    const parsedQueryParams = queryString.parse(searchParams.toString());
    // remove taskModalOpenId from request payload
    if (parsedQueryParams?.taskModalOpenID) delete parsedQueryParams?.taskModalOpenID;
    return parsedQueryParams;
  }, [searchParams]);

  const { userRole } = useAppSelector(selectUserState);

  const [getTaskFiltersDetails] = useLazyGetTaskFiltersDetailsQuery();

  const { data: tags } = useGetTagsListQuery({ tagTypes: [TagTypes.Both, TagTypes.Manual, TagTypes.System] });
  const tagsOptions = useMemo(() => tags?.data?.map((tag) => ({ label: tag.name, value: tag._id })), [tags?.data]);

  const filterData = useMemo(
    () => filtersByRoles(userRole, queryParams.isSentRequests, isQueueDisabled),
    [queryParams.isSentRequests, userRole, isQueueDisabled],
  );

  useEffect(() => {
    if (
      !searchParams.has('futureTasksRange') &&
      (searchParams.has('isMyPatients') || searchParams.has('isSentRequests'))
    ) {
      getTaskFiltersDetails()
        .unwrap()
        .then((data) => {
          if (data.futureTasksRange) {
            searchParams.set('futureTasksRange', data.futureTasksRange);
            setSearchParams(searchParams);
          }
        })
        .catch((error) => console.error(error));
    }
    // This useEffect should run only on isMyPatients and isSentRequests search params change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('isMyPatients'), searchParams.get('isSentRequests')]);

  const handleCreateNewTask = () => {
    dispatch(
      openModal({
        size: 'sm',
        modalContent: <CreateTask />,
        hideClose: true,
      }),
    );
  };

  return (
    <>
      <div className="relative mb-5 flex items-center justify-between">
        <TaskCategoriesFilter categories={filterData} />
        <div className="flex items-center gap-3">
          <Common.Button style="pill" color="green-alt" size="sm" onClick={handleCreateNewTask}>
            <Common.Icon name="plus" color="green-600" />
          </Common.Button>
          <SearchForm showSearchBar />
        </div>
      </div>
      <AppliedFiltersTags options={tagsOptions} />
      <ConnectedTasksTable />
    </>
  );
};

export default TasksPage;
