import { useCallback, useState } from 'react';

import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { FieldValues, useForm, UseFormSetValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import DatePickerInput from 'components/common/DatePickerInput';
import AutocompleteInput from 'components/common/form/AutocompleteInput';
import AutocompleteSelect from 'components/forms/controlled/AutocompleteSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { STATUS_OPTIONS } from 'constants/status';
import { SEARCH_USER_TYPE } from 'constants/user';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import { useLazyGetBillingsQuery } from 'store/billing/billingSlise';
import { useLazyGetUsersQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { BillingConfigurationFilterProps } from './billingConfigurationFilter.types';
import Footer from '../Footer';

const defaultValues = {
  patient: '',
  doctor: '',
  due: '',
  searchKey: '',
  category: { label: '', value: '' },
  assignedTo: { label: '', value: '' },
  status: { label: '', value: '' },
};

const BillingConfigurationFilter = () => {
  const [selected, setSelected] = useState<Date>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { control, reset, setValue, getValues, handleSubmit } = useForm<BillingConfigurationFilterProps>({
    reValidateMode: 'onChange',
  });
  const [formData, setFormData] = useTableFiltersData('billingConfiguration', defaultValues, reset);
  const [getStaffList, { data: staffList, isLoading: isLoadingGetStaffs, isFetching: isFetchingGetStaffs }] =
    useLazyGetUsersQuery();
  const [getBillings, { data: billings, isFetching: isFetchingGetBillings, isLoading: isLoadingGetBillings }] =
    useLazyGetBillingsQuery();

  const onSubmit = (data: BillingConfigurationFilterProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...(data.patient && { patient: data.patient }),
      ...(data.doctor && { doctor: data.doctor }),
      ...(data.due && { due: data.due }),
      ...(data.searchKey && { searchKey: data.searchKey }),
      ...(data.category && { category: data.category.value }),
      ...(data.assignedTo && { assignedTo: data.assignedTo.value }),
      ...(data.status && { status: data.status.value }),
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  const getAssignedOptionsList = (value?: string) => {
    value && getStaffList({ userType: 'Staff', limit: 20, pageNo: 0, searchKey: value.trim() ?? '' });
  };

  const getListAssignedToName = useCallback(() => {
    const unassignedItem = { label: 'Unassigned', value: 'Unassigned' };

    const uniqueAssignTo = new Set([
      JSON.stringify(unassignedItem),
      ...(staffList?.map((item) => JSON.stringify({ label: item?.name || '', value: item?.name || '' })) ?? []),
    ]);

    return Array.from(uniqueAssignTo, (item) => JSON.parse(item));
  }, [staffList]);

  const getBillingsOptionsList = (value?: string) => {
    value && getBillings({ params: { limit: 20, pageNo: 0, searchKey: value.trim() ?? '' } });
  };

  const getListOfBillings = useCallback(() => {
    const uniqueBillings = new Set([
      ...(billings?.data?.map((item) => JSON.stringify({ label: item?.category, value: item?.category })) ?? []),
    ]);

    return Array.from(uniqueBillings, (item) => JSON.parse(item));
  }, [billings?.data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2">
        <div className="flex flex-col gap-4 border-r border-gray-200 pr-6">
          <AutocompleteInput
            userType={SEARCH_USER_TYPE.patient}
            name="patient"
            placeholder="Enter patient"
            wrapperClasses="flex-col"
            label="Patient"
            setValue={setValue as unknown as UseFormSetValue<FieldValues>}
            value={formData?.patient}
          />
          <AutocompleteInput
            userType={SEARCH_USER_TYPE.patient}
            name="patient"
            placeholder="Enter physician"
            label="Doctor"
            wrapperClasses="flex-col"
            setValue={setValue as unknown as UseFormSetValue<FieldValues>}
            value={formData?.doctor}
          />
          <DatePickerInput
            placeholder="Select date"
            label="Due"
            labelDirection="col"
            size="sm"
            inputValue={getValues('due')}
            setInputValue={(value) => setValue('due', value)}
            selectedDate={selected}
            setSelectedDate={setSelected}
            startAllowedDate={dayjs().subtract(100, 'year').toDate()}
            lastAllowedDate={new Date()}
            captionLayout={'dropdown'}
            wrapperClasses="w-full relative"
          />
        </div>
        <div className="flex flex-col gap-4 pl-6">
          <AutocompleteSelect
            control={control}
            labelDirection="col"
            options={getListAssignedToName()}
            defaultValue={formData?.assignedTo}
            placeholder="Select staff..."
            label="Assigned to"
            name="assignedTo"
            isLoading={isLoadingGetStaffs || isFetchingGetStaffs}
            getOptionsFunc={getAssignedOptionsList}
          />
          <AutocompleteSelect
            control={control}
            labelDirection="col"
            placeholder="Select category..."
            label="Category"
            name="category"
            defaultValue={formData?.category}
            options={getListOfBillings()}
            isLoading={isLoadingGetBillings || isFetchingGetBillings}
            getOptionsFunc={getBillingsOptionsList}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={STATUS_OPTIONS}
            placeholder="Select status..."
            label="Status"
            name="status"
            defaultValue={formData?.status}
          />
        </div>
      </div>
      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default BillingConfigurationFilter;
