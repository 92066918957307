import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { ALERT_UNIT_OPTIONS } from 'constants/alerts';
import { INTERNAL_TRIGGER_OPTIONS } from 'constants/automation';
import { AUDIENCE } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';
import { validation } from 'utils/helpers';
import { NUMBER_REGEXP } from 'utils/regExp';

import { defaultValues } from './internalAutomationsFiltersForm.settings';
import { InternalAutomationsFiltersFormProps } from './internalAutomationsFiltersForm.types';

const InternalAutomationsFiltersForm = () => {
  const { control, handleSubmit, reset, watch } = useForm<InternalAutomationsFiltersFormProps>({
    reValidateMode: 'onChange',
  });
  const [formData, setFormData] = useTableFiltersData('internalAutomations', defaultValues, reset);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = (data: InternalAutomationsFiltersFormProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      name: data?.name,
      uniqueId: data?.uniqueId,
      ...(data?.timeInHours && { 'triggerTime.timeInHours': data.timeInHours }),
      audience: data?.audience?.value,
      triggerType: data?.triggerType?.value,
      status: data?.status?.value,
      ...(data?.triggerUnit && {
        triggerUnit: data?.triggerUnit?.value.split('.')[0],
        'triggerTime.occurenceOrder': data?.triggerUnit?.value.split('.')[1],
      }),
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2">
        <div className="flex flex-col gap-4 border-r border-gray-200 pr-7">
          <InputField
            name="name"
            label="Name"
            type="text"
            placeholder="Enter name"
            control={control}
            value={formData?.name}
          />
          <InputField
            name="uniqueId"
            label="Unique ID"
            type="text"
            placeholder="Enter unique ID"
            control={control}
            value={formData?.uniqueId}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={INTERNAL_TRIGGER_OPTIONS}
            placeholder="Select type..."
            label="Type"
            name="triggerType"
            defaultValue={formData?.triggerTime}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={AUDIENCE}
            placeholder="Select audience..."
            label="Audience"
            name="audience"
            defaultValue={formData?.audience}
          />
        </div>
        <div className="flex flex-col gap-4 pl-7">
          <InputField
            name="timeInHours"
            type="number"
            label="Timing (amount)"
            placeholder="Enter amount"
            control={control}
            rules={validation('Timing (Amount)', NUMBER_REGEXP, '', true)}
            value={formData?.timeInHours}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={ALERT_UNIT_OPTIONS}
            placeholder="Select unit"
            label="Units"
            name="triggerUnit"
            defaultValue={formData?.triggerUnit}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={STATUS_OPTIONS}
            placeholder="Select status..."
            label="Status"
            name="status"
            defaultValue={formData?.status}
          />
        </div>
      </div>

      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default InternalAutomationsFiltersForm;
