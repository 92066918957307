import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

interface CacheEntry<T> {
  data: T;
  timestamp: number;
}

const CACHE_KEY = 'stack-ai-api-cache';

const loadCache = (): Map<string, CacheEntry<unknown>> => {
  const cacheString = localStorage.getItem(CACHE_KEY);
  return cacheString ? new Map(JSON.parse(cacheString)) : new Map();
};

const saveCache = (cache: Map<string, CacheEntry<unknown>>): void => {
  localStorage.setItem(CACHE_KEY, JSON.stringify(Array.from(cache.entries())));
};

const cache = loadCache();

const customBaseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_STACK_AI_URL,
  prepareHeaders: (headers) => {
    headers.set('Authorization', 'Bearer ' + import.meta.env.VITE_STACK_AI_AUTH_TOKEN);
    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

interface CustomFetchArgs extends FetchArgs {
  cacheDuration?: number; // optional cache duration in milliseconds
  force?: boolean; // optional flag to bypass cache
}

const cachingBaseQuery: BaseQueryFn<CustomFetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const { body, method, cacheDuration = 0, force = false } = args; // default cache duration zero
  const cacheKey = JSON.stringify({ body, method });

  if (!force) {
    const cachedEntry = cache.get(cacheKey);

    if (method === 'POST' && cachedEntry) {
      const { data, timestamp } = cachedEntry;
      const now = Date.now();

      if (now - timestamp < cacheDuration) {
        return { data };
      }

      // Remove expired cache entry
      cache.delete(cacheKey);

      saveCache(cache);
    }
  }

  const result = await customBaseQuery(args, api, extraOptions);

  if (method === 'POST' && result.data) {
    cache.set(cacheKey, { data: result.data, timestamp: Date.now() });

    saveCache(cache);
  }

  return result;
};

export default cachingBaseQuery;
