import classNames from 'classnames';

import All from 'assets/icons/task/All.svg?react';
import MAs from 'assets/icons/task/MAs.svg?react';
import MSRs from 'assets/icons/task/MSRs.svg?react';
import Provider from 'assets/icons/task/Provider.svg?react';
import { RoleShortName } from 'enums/role';

const getAudienceIcon = (audience: RoleShortName) => {
  switch (audience) {
    case RoleShortName.MedicalAssistant:
      return <MAs />;
    case RoleShortName.CustomerService:
      return <MSRs />;
    case RoleShortName.Physician:
      return <Provider />;
    default:
      return <></>;
  }
};

export const generateAudienceIcon = (audience: RoleShortName[]) => {
  if (audience.length === 1) {
    getAudienceIcon(audience[0]);
  }

  if (audience.length >= 3) {
    return <All />;
  }

  return (
    <div className="relative flex">
      {audience.map((item, index) => {
        /**
         * If we want that icons to be positioned correctly, we need to change the marginLeft property.
         * The distance we specify depends on the size of the icons. By default, it is 6px
         */
        return (
          <div
            key={index}
            className={classNames({
              'ml-[-6px]': index !== 0,
            })}
          >
            {getAudienceIcon(item)}
          </div>
        );
      })}
    </div>
  );
};

export const getAudienceTitle = (audience: RoleShortName) => {
  switch (audience) {
    case RoleShortName.MedicalAssistant:
      return 'MAs';
    case RoleShortName.CustomerService:
      return 'MSRs';
    case RoleShortName.Physician:
      return 'Providers';
    default:
      return '';
  }
};

export const generateAudienceTitle = (audience: RoleShortName[]) => {
  if (audience.length === 1) {
    return getAudienceTitle(audience[0]);
  }

  if (audience.length >= 3) {
    return 'All';
  }

  return audience.map((item) => getAudienceTitle(item)).join(' & ');
};

export const getAudience = (specificAudience?: RoleShortName[], defaultAudience?: RoleShortName[]) => {
  const audienceList = specificAudience && specificAudience.length > 0 ? specificAudience : defaultAudience || [];

  // TODO: In the future, the "NP" role will remove from BE, but now I need to remove it on the FE side
  return [...audienceList].filter((item) => item !== 'NP').sort((a, b) => b.localeCompare(a));
};

export const getDiffInMinutes = (date: string) => {
  const receivedDate = new Date(date);
  if (receivedDate.toString() === 'Invalid Date') return;

  const now = new Date();

  const timeDiff = receivedDate.getTime() - now.getTime();

  const result = Math.floor(timeDiff / (1000 * 60));

  return result;
};

export const isToday = (receivedDate: Date) => {
  const today = new Date();

  return (
    receivedDate.getDate() === today.getDate() &&
    receivedDate.getMonth() === today.getMonth() &&
    receivedDate.getFullYear() === today.getFullYear()
  );
};
