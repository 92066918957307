import { Common } from '@thecvlb/design-system';

import PatientInfo from 'components/patient/Overview/PatientInfo';
import PharmacyInfo from 'components/patient/Overview/PharmacyInfo';
import Notes from 'components/tasks/slidingPane/Notes';
import { checkIsPatientWMorPrevWM } from 'store/patients/patients.settings';
import { useLazyLoginToCreateAppointmentQuery } from 'store/patients/patientsSlice';

import Appointment from './Appointment';
import { OverviewProps } from './overview.types';
import PatientSummary from './PatientSummary';

const Overview: React.FC<OverviewProps> = ({ patient, patientSummary }) => {
  const [loginToCreateAppointment] = useLazyLoginToCreateAppointmentQuery();

  const queueTime = patient?.upcomingAppointmentInfo[0]?.category && patient?.createdAt;

  const onClickNew = () => {
    loginToCreateAppointment({ id: patient._id });
  };

  const isWMPatient = checkIsPatientWMorPrevWM(patient?.tags);
  const showAIassistant = isWMPatient;

  return (
    <div className="px-6 pb-6">
      <div className="mt-4 flex flex-row gap-8">
        <div className="w-1/2">
          <Notes note={patient.notes} patientId={patient._id} />
        </div>
        <div data-testid="appo_block" className="w-1/2">
          <div className="mb-2 flex items-center">
            <h4 className="mr-4 text-base font-semibold text-gray-700">Next appointment:</h4>
          </div>
          {patient?.upcomingAppointmentInfo?.map((appointment) => (
            <Appointment key={appointment.appointmentId} appointment={appointment} {...(queueTime && { queueTime })} />
          ))}
          {!patient?.upcomingAppointmentInfo?.length && (
            <div className="flex items-center justify-center rounded-lg bg-gray-100 p-7 text-base text-gray">
              <span className="py-1.5">You have no upcoming appointments.</span>
              <Common.Button dataTestId="schedule_one_now_btn" onClick={onClickNew} style="text-only" size="sm">
                <span className="underline">Schedule one now</span>
              </Common.Button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-8 flex w-full flex-row rounded-lg bg-gray-100 px-6 py-4">
        <div className="w-full">
          <h4 className="mb-2 text-base font-bold text-gray-700">Patient info</h4>
          <PatientInfo patientId={patient._id} />
        </div>
        {patient.pharmacyInfo?.pharmacyId && (
          <div className="ml-8 w-1/2">
            <h4 className="mb-2 text-base font-bold text-gray-700">Pharmacy info</h4>
            <PharmacyInfo pharmacyInfo={patient.pharmacyInfo} />
          </div>
        )}
      </div>
      {showAIassistant && <PatientSummary patientSummary={patientSummary} />}
    </div>
  );
};

export default Overview;
