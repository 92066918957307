import { useCallback, useState } from 'react';

import TextareaAutosize from 'react-textarea-autosize';

import { useUpdateNotesMutation } from 'store/patients/patientsSlice';

import { NotesTypes } from './notes.types';

const textareaStyle =
  'w-full scrollbar-hide shadow-sm text-base outline-none ring-gray-400 ring-1 border-none rounded-md placeholder-gray-400 text-gray-700 disabled:bg-gray-200 resize-y px-3 focus:shadow-none focus:ring-2 focus:ring-blue';

const Notes: React.FC<NotesTypes> = ({ patientId, note, maxRows = 15 }) => {
  const [updateNotes] = useUpdateNotesMutation();
  const [text, setText] = useState(note || '');

  const handleBlur = useCallback(() => {
    updateNotes({ id: patientId, notes: text });
  }, [patientId, text, updateNotes]);

  return (
    <div className="flex flex-col gap-2">
      <span className="text-base font-semibold text-gray-700">Notes:</span>
      <TextareaAutosize
        data-testid="notes_textarea"
        className={textareaStyle}
        placeholder="Enter a note about this patient..."
        value={text}
        onChange={(event) => setText(event.target.value)}
        onBlur={handleBlur}
        maxRows={maxRows}
      />
    </div>
  );
};

export default Notes;
