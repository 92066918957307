import React from 'react';

import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { ArrowDown } from '@thecvlb/design-system/lib/generated/icons';
import { Common } from '@thecvlb/design-system/lib/src';
import { twMerge } from 'tailwind-merge';

import { AccordionProps } from './accordion.types';

const Accordion = React.forwardRef<HTMLHeadingElement, AccordionProps>(
  (
    { title, children, isOpen = true, wrapperClassNames, panelClassNames, buttonClassNames, showInfoButton = true },
    ref,
  ) => {
    const wrapperClasses = twMerge('mb-8', wrapperClassNames);
    const buttonClasses = twMerge('flex w-full items-center justify-between bg-gray-100 px-6 py-3', buttonClassNames);
    const panelClasses = twMerge('bg-white px-6 py-8', panelClassNames);

    return (
      <Disclosure defaultOpen={isOpen}>
        {({ open }) => (
          <div ref={ref} className={wrapperClasses}>
            <DisclosureButton className={buttonClasses}>
              <div className="flex items-center">
                <h3 className="mr-3 font-bold text-gray-700">{title}</h3>
                {showInfoButton && <Common.Icon name="info" className="mt-0.5 h-4 w-4 text-gray-400" />}
              </div>

              <ArrowDown className={`${open ? 'rotate-180' : ''} h-5 w-5 text-gray`} />
            </DisclosureButton>
            <Transition
              appear={false}
              enter="transition duration-150 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <DisclosurePanel className={panelClasses}>{children}</DisclosurePanel>
            </Transition>
          </div>
        )}
      </Disclosure>
    );
  },
);

export default Accordion;
