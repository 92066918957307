import { io } from 'socket.io-client';

import config from 'config/socket/chat';

const { webSocket } = config;

const socketStaff = io(webSocket.baseUrlWebSocketStaff, {
  secure: true,
  autoConnect: false,
  transports: ['websocket', 'webtransport'],
});

export default socketStaff;
