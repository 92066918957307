import { createApi } from '@reduxjs/toolkit/query/react';

import cachingBaseQuery from './stackAI.settings';
import { PatientSummaryRequest, PatientSummaryResponse, SuggestionRequest, SuggestionResponse } from './stackAI.types';

export const stackAIApi = createApi({
  reducerPath: 'stackAIApi',
  baseQuery: cachingBaseQuery,
  endpoints: (builder) => ({
    getSuggestedResponse: builder.mutation<SuggestionResponse, SuggestionRequest>({
      query: ({ body, signal, isPH, force }) => ({
        url: `/${import.meta.env.VITE_STACK_AI_ORG_ID}/${
          isPH ? import.meta.env.VITE_STACK_AI_FLOW_ID_FOR_PHYSICIANS : import.meta.env.VITE_STACK_AI_FLOW_ID
        }`,
        method: 'POST',
        body,
        signal,
        cacheDuration: 300000, // 5 minutes
        force,
      }),
    }),
    getPatientSummary: builder.mutation<PatientSummaryResponse, PatientSummaryRequest>({
      query: ({ body }) => ({
        url: `/${import.meta.env.VITE_STACK_AI_ORG_ID}/${import.meta.env.VITE_STACK_AI_FLOW_ID_PATIENT_SUMMARY}`,
        method: 'POST',
        body,
        cacheDuration: 900000, // 15 minutes
      }),
    }),
  }),
});

export const { useGetSuggestedResponseMutation, useGetPatientSummaryMutation } = stackAIApi;
