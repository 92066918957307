import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import { useFormContext } from 'react-hook-form';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import { validation } from 'utils/helpers';
import { ANY_CHARACTER_REGEXP } from 'utils/regExp';

import { EVENT_TYPES_OPTIONS, EVENT_TYPES_WITHOUT_TIMING, WEBHOOK_TYPE_OPTIONS } from './webhookContent.settings';
import { WebhookContentProps } from './webhookContent.types';

const WebhookContent: React.FC<WebhookContentProps> = ({ setIsShowTiming, setEventType }) => {
  const { control, formState } = useFormContext();
  const onChangeEventType = (option: OptionProps) => {
    const showTiming = !EVENT_TYPES_WITHOUT_TIMING.find((item) => item.value === option.value);
    setIsShowTiming(showTiming);
    setEventType(String(option.value));
  };

  return (
    <div className="flex flex-col gap-4">
      <ControlledSelect
        control={control}
        labelDirection="row"
        options={EVENT_TYPES_OPTIONS}
        placeholder="Select event type..."
        onChange={onChangeEventType}
        rules={validation('Event Type')}
        label="Event Type"
        name="eventType"
      />

      <ControlledSelect
        control={control}
        labelDirection="row"
        options={WEBHOOK_TYPE_OPTIONS}
        placeholder="Select webhook type..."
        onChange={onChangeEventType}
        rules={validation('Webhook type')}
        label="Webhook type"
        name="webhookType"
      />

      <InputField
        name="webhookUrl"
        placeholder="Webhook type Id"
        label="Webhook type Id"
        control={control}
        labelDirection="row"
        type="text"
        errors={formState.errors.webhookUrl}
        helper={formState.errors.webhookUrl?.message?.toString()}
        rules={validation('Webhook type id', ANY_CHARACTER_REGEXP)}
      />
    </div>
  );
};

export default WebhookContent;
