import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { useFormContext, useWatch } from 'react-hook-form';

import ControlledSelect from 'components/common/form/ControlledSelect';
import Loader from 'components/common/Loader';
import RadioButtonList from 'components/common/RadioButtonList';
import { CHANGE_PLAN_COUPON_OPTIONS } from 'constants/crossSell';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { useGetMembershipPlansQuery } from 'store/crossSell/crossSellSlice';
import { useGetMedicationsQuery } from 'store/weightManagement/weightManagementSlice';

import {
  choosePlanTabs,
  FlexCare,
  getPlanLabel,
  getPricePoints,
  preventPricePointInPatientsState,
} from './choosePlan.settings';
import { ChoosePlanProps } from './choosePlan.types';
import { ChangePlanFormData } from '../changePlan.types';

const ChoosePlan: React.FC<ChoosePlanProps> = ({
  setNewPlan,
  patientInfo,
  currentPlan,
  activePP,
  setNewPricePoint,
  newPricePoint,
}) => {
  const { setValue, control, resetField } = useFormContext();
  const { data: membershipPlans, isLoading, isFetching } = useGetMembershipPlansQuery();

  const { data: medications, isLoading: isFetchingMedications } = useGetMedicationsQuery(
    { state: patientInfo.state, userId: patientInfo._id },
    { refetchOnMountOrArgChange: true },
  );

  const formData = useWatch({ control }) as ChangePlanFormData;
  const showCouponField =
    !!newPricePoint &&
    [
      'bundle-semaglutide-one-month',
      'bundle-semaglutide-three-month',
      'bundle-semaglutide-six-month',
      'bundle-tirzepatide-one-month',
      'bundle-tirzepatide-three-month',
      'bundle-tirzepatide-six-month',
    ].includes(newPricePoint?.planPricePointId);

  const showLoader = isLoading || isFetching || isFetchingMedications;

  const handleClickTab = (button: Common.DataItemProps) => {
    setValue('activePeriodsTab', button.label);
    setNewPlan();
    setNewPricePoint();
  };

  useEffect(() => {
    if (showCouponField) {
      setValue('couponCode', CHANGE_PLAN_COUPON_OPTIONS[0]);
    } else {
      resetField('couponCode');
    }
  }, [newPricePoint, resetField, setValue, showCouponField]);

  return (
    <div className="flex flex-col gap-6">
      {showLoader && <Loader isVisible />}
      {currentPlan && (
        <div>
          <h3 className="mb-4 text-base font-bold text-gray-700">Current plan</h3>
          <p className="text-sm font-medium"> {currentPlan.planName}</p>
          <p className="text-sm font-medium">{activePP?.planPricePointId}</p>
        </div>
      )}

      <div>
        <h3 className="mb-4 text-base font-bold text-gray-700">Billing frequency</h3>
        <Common.Tabs
          onChange={handleClickTab}
          data={choosePlanTabs}
          defaultSelected={[formData?.activePeriodsTab]}
          type="bar"
          className="rounded-full border border-gray-100 p-1 shadow"
        />
      </div>
      <div className="flex flex-col gap-3">
        <h3 className="text-base font-bold text-gray-700">Plan</h3>
        <div className="flex flex-col">
          {membershipPlans?.map((plan, i, { length }) => {
            const isCurrentPlan = plan._id === patientInfo?.activePlanId;
            const pricePoints = getPricePoints(
              plan.pricePoints,
              formData?.activePeriodsTab,
              plan.planCode === PlanCodesProps.WeightManagementMembership,
            );
            const isFlexCare = plan.planName === FlexCare;

            return pricePoints?.map((pricePoint) => {
              const isCurrentPricePoint = activePP?.planPricePointId === pricePoint?.planPricePointId;
              const disablePricePoint = preventPricePointInPatientsState(pricePoint, medications, patientInfo);

              return (
                (!!pricePoint || isFlexCare) && (
                  <RadioButtonList
                    label={getPlanLabel(
                      plan.planName,
                      isFlexCare,
                      pricePoint,
                      isCurrentPlan,
                      isCurrentPricePoint,
                      formData?.activePeriodsTab,
                    )}
                    value={pricePoint.planPricePointId}
                    color="blue"
                    key={pricePoint.planPricePointId}
                    isSelected={!!newPricePoint && pricePoint.planPricePointId === newPricePoint.planPricePointId}
                    handleChange={() => {
                      setNewPlan(plan);
                      setNewPricePoint(pricePoint);
                    }}
                    {...(isFlexCare &&
                      pricePoint.planPricePointId === 'limited-access' && { className: 'order-first', first: true })}
                    last={length - 1 === i}
                    disabled={disablePricePoint}
                  />
                )
              );
            });
          })}
        </div>
        <div>
          {showCouponField && (
            <ControlledSelect
              control={control}
              options={CHANGE_PLAN_COUPON_OPTIONS}
              name="couponCode"
              label="Coupon code"
              className="w-full"
              rules={{
                required: {
                  value: false,
                  message: 'Coupon code is optional',
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
