import { useFormContext } from 'react-hook-form';

import { getDefaultPricePoint, getPaymentAmount } from 'utils/helpers';

const PriceCard = () => {
  const { getValues, watch } = useFormContext();
  const planType = getValues('planType');
  const pricePoint = getDefaultPricePoint(planType?.pricePoints);
  const promoCode = getValues('creditCardAttributes.couponCode');

  watch(['planType', 'creditCardAttributes.couponCode']);

  const { discountPercentage, discountValue, amountDue } = getPaymentAmount(promoCode?.value, planType);

  return (
    <div className="rounded-lg bg-secondary-50 p-6">
      {planType?.planCode !== 'unlimited-membership' && (
        <div className="mb-4 flex items-center justify-between font-bold text-primary-700">
          <span className="">Doctor’s visit</span>
          <span>${pricePoint?.initialAppointmentCost}</span>
        </div>
      )}
      <div className="mb-2 flex items-center justify-between font-bold text-secondary">
        <span>{planType?.planName}</span>
        <span>${pricePoint?.totalCost}/mo</span>
      </div>
      <p className="mb-4 text-sm text-secondary">(Begins after 30 days)</p>
      {discountPercentage !== '0' && (
        <div
          data-testid="discount_block"
          className="mb-3 flex justify-between text-sm font-bold text-orange-400 md:font-bold"
        >
          <h4>Discount: {discountPercentage} off</h4>
          <span className="text-base">- ${discountValue}</span>
        </div>
      )}
      <div
        data-testid="total_price_block"
        className="mb-4 flex items-center justify-between font-bold text-primary-700"
      >
        <span>Total due today</span>
        <span>${amountDue}</span>
      </div>
      <hr className="mb-4 border-secondary" />
      <p className="text-sm font-normal">
        30-day FREE trial. <br /> Recurring monthly billing. Cancel anytime.
      </p>
    </div>
  );
};

export default PriceCard;
