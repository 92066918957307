import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useUpdatePatientsProviderMutation } from 'store/patients/patientsSlice';
import { AvailableProvider } from 'store/staffs/staffs.types';
import { useLazyGetAvailableProvidersQuery } from 'store/staffs/staffsSlice';

import { ProviderSelectProps } from './providerselect.types';

const ProviderSelect: React.FC<ProviderSelectProps> = ({ doctorId, patientId, patientState }) => {
  const [providerId, setProviderId] = useState(doctorId);
  const [availableProviders, setAvailableProviders] = useState<Common.SelectAltProps<string>['options']>([]);

  const [getAvailableProviders, { data: providersData, isLoading, isFetching }] = useLazyGetAvailableProvidersQuery();
  const [updatePatientsProvider, { isLoading: loadingUpdate }] = useUpdatePatientsProviderMutation();

  const isVisibleLoader = isLoading || isFetching || loadingUpdate;

  const sortProviders = (a: AvailableProvider, b: AvailableProvider) => {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  };

  const handleChangePhysician = (newProviderId?: string) => {
    if (newProviderId && newProviderId !== providerId) {
      updatePatientsProvider({ patientId, doctorId: newProviderId })
        .unwrap()
        .then((data) => {
          notifySuccess(data.message);
          setProviderId(newProviderId);
        });
    }
  };

  useEffect(() => {
    if (patientState) {
      getAvailableProviders({ state: patientState, patientUserId: patientId });
    }
  }, [patientState, getAvailableProviders, patientId]);

  useEffect(() => {
    if (providersData) {
      const sortedProviders = [...providersData.data].sort(sortProviders);
      setAvailableProviders(
        sortedProviders.map((provider) => ({
          label: `${provider.firstName} ${provider.lastName}`,
          value: provider.userId,
          id: provider.userId,
        })),
      );
    }
  }, [providersData]);

  return (
    <div className="flex flex-col whitespace-normal">
      {isVisibleLoader && <Loader isVisible />}
      <Common.SelectAlt
        dataTestId="provider_select_dropdown"
        size="sm"
        labelPosition="top"
        value={providerId}
        hideSuccessState
        options={availableProviders}
        onChange={handleChangePhysician}
        disabled={!patientState}
      />
      <div className="whitespace-normal text-sm text-gray-500">
        You will need to re-assign any tasks that are assigned to the current provider.
      </div>
    </div>
  );
};

export default ProviderSelect;
