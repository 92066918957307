import { useMemo, useState } from 'react';

import * as Popover from '@radix-ui/react-popover';
import dayjs from 'dayjs';

import { DateFormat } from 'enums/dateFormats';

import { EventProps } from './event.types';
import EventDetails from './EventDetails/EventDetails';
import EventIcon from '../../components/EventIcon';

const Event: React.FC<EventProps> = ({
  start,
  end,
  title,
  eventTypes,
  recurringEventId,
  constraintsElement,
  eventInstanceId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const eventStartTime = dayjs(start).format(DateFormat.h_mm_a);
  const eventEndTime = dayjs(end).format(DateFormat.h_mm_a);
  const eventDate = dayjs(start).format(`${DateFormat.MMM_D} • `);

  const isTimeOff = !!eventTypes.find((type) => type.name === 'time-off');

  const eventDetailsTitle = isTimeOff ? eventDate + 'Day off' : eventDate + eventStartTime + ' - ' + eventEndTime;

  const collisionBoundary = useMemo(() => constraintsElement || [], [constraintsElement]);

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger>
        <div className="flex items-center gap-1" data-testid="event-button">
          <EventIcon title={title} shouldViewMargin />

          <div className="text-start lg:w-[80px] xl:w-[100px] 2xl:w-[140px]">
            <p className="overflow-hidden text-ellipsis text-xs font-semibold">
              {isTimeOff ? title : `${dayjs(start).format(DateFormat.h_mma)} ${title}`}
            </p>
          </div>
        </div>
      </Popover.Trigger>

      <EventDetails
        eventTypes={eventTypes}
        isOpen={isOpen}
        title={eventDetailsTitle}
        shiftType={title}
        eventInstanceId={eventInstanceId}
        collisionBoundary={collisionBoundary}
        recurringEventId={recurringEventId}
        eventStartTime={start}
      />
    </Popover.Root>
  );
};

export default Event;
