import classNames from 'classnames';
import dayjs from 'dayjs';
import { Rating } from 'react-simple-star-rating';

import { DateFormat } from 'enums/dateFormats';
import { ReviewProps } from 'store/reviews/reviews.types';

const ReviewDetails: React.FC<{
  review?: ReviewProps;
  isPrevReview?: boolean;
}> = ({ review, isPrevReview }) => {
  const wrapperClassNames = classNames('flex flex-col gap-3 font-semibold', {
    'border-b border-gray-200 mb-4 pb-4 text-gray-500': isPrevReview,
  });
  return (
    <div className={wrapperClassNames}>
      <div data-testid="review_content" className="flex text-sm">
        <p className="w-[120px] text-sm">Review</p>{' '}
        <div className="max-w-[420px] truncate whitespace-normal text-sm">{review?.reviewComment}</div>
      </div>
      <div data-testid="provider_name" className="flex text-sm">
        <p className="w-[120px] text-sm">Provider</p> {review?.providerInfo?.name}
      </div>
      <div className="flex text-sm">
        <p className="w-[120px] text-sm">Stars</p>{' '}
        <Rating
          initialValue={Number(review?.rating) || 0}
          readonly={true}
          SVGclassName="inline-block mb-1"
          size={16}
          SVGstorkeWidth="1"
          fillColor="#FFA700"
          SVGstrokeColor="#FFA700"
          emptyColor="transparent"
        />
      </div>
      <div data-testid="date_added" className="flex text-sm">
        <p className="w-[120px] text-sm">Date added</p> {dayjs(review?.createdAt).format(DateFormat.MMM_D_YYYY)}
      </div>
    </div>
  );
};

export default ReviewDetails;
