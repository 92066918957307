import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { useToggle } from 'react-use';

import PaypalLogo from 'assets/icons/paymentForm/paypal.svg?react';
import PaymentIcon from 'components/crossSell/confirmAndPay/PaymentIcon/PaymentIcon';
import BasicConfirmation from 'components/modals/BasicConfirmation';
import { PaymentMethod } from 'enums/crossSell';

import { getCardTypeName } from './paymentMethodCard.settings';
import { PaymentMethodCardProps } from './paymentMethodCard.types';

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({ data, onDelete, onPickAsFavorite, isLoading }) => {
  const [isOpen, toggle] = useToggle(false);
  const isCardType = data.paymentMethod === PaymentMethod.CreditCard;
  const isSelected = data.isDefault;
  const handlePaymentType = () => (data.paymentMethod === PaymentMethod.CreditCard ? 'card' : 'PayPal');

  return (
    <>
      {!!onDelete && (
        <BasicConfirmation
          isLoading={isLoading}
          isOpen={isOpen}
          onClose={toggle}
          onConfirm={() => onDelete(data.paymentProfileId, () => toggle(false))}
        />
      )}
      <div
        className={classNames('flex items-center gap-4 rounded-2xl border border-gray-200 p-4', {
          'bg-blue-50': isSelected,
        })}
      >
        <div
          className={classNames(
            'flex h-11 w-[60px] flex-none items-center rounded-lg px-4 py-2',
            isCardType ? 'bg-primary-100' : 'bg-[#FEC53B]',
          )}
        >
          {isCardType ? <PaymentIcon cardType={data.cardType} /> : <PaypalLogo className="h-full w-full" />}
        </div>
        <div className="flex grow items-center justify-between gap-2 text-sm">
          <div className="flex flex-col gap-1 text-sm">
            {isCardType ? (
              <>
                <span className="first-letter:uppercase">
                  {`${getCardTypeName(data)} ending in `}
                  <span className="font-bold">{data.cardNumber.substring(data.cardNumber.length - 4)}</span>
                </span>
                <span className="text-gray">
                  Expires{' '}
                  {`${data.expirationMonth}/${String(data.expirationYear).substring(
                    String(data.expirationYear).length - 2,
                  )}`}
                </span>
              </>
            ) : (
              <>
                <span>Paypal</span>
                <span className={classNames('truncate text-gray', isSelected ? 'max-w-[150px]' : 'max-w-[75px]')}>
                  {data.email}
                </span>
              </>
            )}
          </div>
          <div className="flex gap-2">
            <Common.Button
              className={classNames(isSelected ? 'cursor-default !bg-blue-100 !text-blue-500' : '')}
              color="white-alt"
              disabled={isLoading}
              size="sm"
              isLoading={isLoading && !isSelected}
              preIcon={isSelected ? 'check' : undefined}
              onClick={() => !isSelected && onPickAsFavorite(data.paymentProfileId)}
              type="button"
            >
              {isSelected ? 'Selected' : `Use ${handlePaymentType()}`}
            </Common.Button>
            {!isSelected && !!onDelete && (
              <Common.Button
                color="white"
                disabled={isLoading}
                preIcon="trash"
                size="sm"
                onClick={toggle}
                type="button"
              >
                Remove
              </Common.Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodCard;
