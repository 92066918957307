import { ChangeEvent, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledCombobox from 'components/forms/controlled/ControlledCombobox';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { reset } from 'store/slidingInPane/slidingInPaneSlice';
import { AvailableStaffResponse } from 'store/tasks/task.types';
import { resetTask, selectTask, useAssignStaffToTaskMutation } from 'store/tasks/tasksSlice';

import { useGetAvailableStaff } from './assignToUser.settings';
import type { AssignToUserProps } from './assignToUser.types';

const AssignToUser = ({ taskId, assignedToId }: AssignToUserProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { taskDetails } = useAppSelector(selectTask);
  const [query, setQuery] = useState('');
  const { searchData, isLoading } = useGetAvailableStaff(
    query,
    assignedToId ?? taskDetails?.assignedToInfo?._id,
    taskId,
  );
  const [assignStaffToTask, { isLoading: isLoadingAssignStaff }] = useAssignStaffToTaskMutation();
  const { handleSubmit, control, setValue, setError, clearErrors, formState } = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
  });
  const formData = useWatch({ control }) as { staffMember: Option };
  const errors = (formData?.staffMember?.value as AvailableStaffResponse)?.errors.length
    ? (formData?.staffMember?.value as AvailableStaffResponse)?.errors
    : undefined;
  const errorMessage = formState?.errors?.staffMember?.message || errors?.join(' ');

  const cancelAssignUser = () => dispatch(closeModal());

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    if (!event.target.value?.length) {
      setValue('staffMember', '');
    }
  };

  const handleChangeStaff = (option: Option) => {
    clearErrors('staffMember');
    setValue('staffMember', option);
    setError(`staffMember`, { type: 'manual', message: errors?.join(' ') });
  };

  const handleAssignUser = () => {
    if (!!errors?.length) {
      setError(`staffMember`, { type: 'manual', message: errors[0] });
    } else if (formData?.staffMember) {
      assignStaffToTask({ taskId: taskId ?? taskDetails._id, staffId: formData.staffMember.id })
        .unwrap()
        .then((data) => {
          notifySuccess(data.message);
          if (searchParams.has('taskModalOpenID')) {
            searchParams.delete('taskModalOpenID');
            setSearchParams(searchParams);
          }
          dispatch(reset());
          dispatch(resetTask());
        })
        .finally(() => dispatch(closeModal()));
    }
  };

  return (
    <div data-testid="assign_provider_popup" className="p-6">
      <h2 className="mb-2 text-xl font-bold text-gray-700">Assign task</h2>

      <form onSubmit={handleSubmit(handleAssignUser)} className="flex flex-col gap-4">
        <ControlledCombobox
          size="sm"
          label="Staff member"
          labelDirection="col"
          control={control}
          name="staffMember"
          defaultValue=""
          options={searchData}
          isLoading={isLoading}
          preIcon="search"
          inputValue={query}
          onChange={handleChangeStaff}
          onInputChange={handleInputChange}
          hideComboboxButton
          errors={errors}
          helperText={errors?.join(' ')}
          placeholder="Search staff"
          rules={{
            required: {
              value: true,
              message: 'Please select a staff member',
            },
          }}
        />
        <div className="mt-2 grid grid-cols-2 gap-x-2">
          <Common.Button
            type="button"
            color="white-alt"
            className="!w-full justify-center"
            onClick={cancelAssignUser}
            size="sm"
          >
            Cancel
          </Common.Button>
          <Common.Button
            className="!w-full justify-center"
            type="submit"
            color="blue"
            size="sm"
            disabled={!!errorMessage}
            isLoading={isLoadingAssignStaff}
          >
            Assign
          </Common.Button>
        </div>
      </form>
    </div>
  );
};

export default AssignToUser;
