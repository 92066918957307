import { Common } from '@thecvlb/design-system';

import PrescribeCompound from 'components/modals/PrescribeCompound';
import { getPARequestStyle } from 'components/modals/TaskPopover/RequestDetails/requestDetails.settings';
import { PARequestStatus } from 'enums/taskDetailsStatus';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { selectPatient } from 'store/patients/patientsSlice';
import { useRequestPAMutation } from 'store/tasks/tasksSlice';

const getCoverageColor = (coverage: string) => {
  switch (coverage && coverage.toLowerCase()) {
    case 'covered':
      return <Common.ColorTag text={coverage} color="green" />;
    case 'not covered':
      return <Common.ColorTag text={coverage} color="red" />;
    case 'pa required':
      return <Common.ColorTag text={coverage} color="yellow" />;
    case 'not contracted':
      return <Common.ColorTag text={coverage} color="red" />;
    default:
      return <Common.ColorTag text={coverage} color="gray" />;
  }
};

const ActionsCell: React.FC<{
  status: string;
  requestId: string;
  drugName: string;
  insuranceName: string;
  requestStatus?: PARequestStatus;
}> = ({ status, requestId, drugName, insuranceName, requestStatus }) => {
  const dispatch = useAppDispatch();
  const { benefits } = useAppSelector(selectPatient);

  const [requestPA, { isLoading }] = useRequestPAMutation();
  const isRequestAuth = status && status.toLowerCase() === 'pa required';

  const handlePrescribe = () => {
    if (benefits?.userId)
      dispatch(
        openModal({
          hideClose: true,
          modalContent: <PrescribeCompound patientId={benefits.userId} />,
          size: 'sm',
        }),
      );
  };

  const handleRequestAuth = () => {
    if (benefits?.userId) {
      requestPA({
        patientUserId: benefits.userId,
        audience: 'MA',
        note: drugName,
        isUrgent: false,
        tpsBenefit: {
          request_id: requestId,
          drug_name: drugName,
          insurance_name: insuranceName,
        },
      });
    }
  };

  return (
    <div className="flex gap-2">
      {requestStatus ? (
        <div className="flex w-[92px] items-center justify-center">{getPARequestStyle(requestStatus)}</div>
      ) : (
        <Common.Button
          onClick={handleRequestAuth}
          color="white-alt"
          className="whitespace-nowrap"
          size="sm"
          disabled={isLoading}
        >
          Request PA
        </Common.Button>
      )}
      <Common.Button
        onClick={handlePrescribe}
        color="white"
        className="whitespace-nowrap"
        size="sm"
        disabled={!!isRequestAuth}
      >
        Prescribe
      </Common.Button>
    </div>
  );
};

export { getCoverageColor, ActionsCell };
