import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import type { UpdateTaskCompleteParams } from 'components/modals/MarkCompleteAsync/markCompleteAsync.types';
import { NOT_ACTIVE_TASK_STATUSSES } from 'constants/tasks';
import { AppointmentStatus } from 'enums/appointmentStatus';
import { MarkCompleteReasons } from 'enums/markCompleteOption';
import { TaskCategories } from 'enums/taskCategories';
import type {
  CallQualityInfo,
  IntakeItemsInfo,
  ReminderProps,
  TaskDetailsProps,
  TaskProps,
  TasksQueryParams,
  TasksSummaryProps,
} from 'models/tasks.types';
import socketStaff from 'socket/socketStaff';
import type { RootState } from 'store';

import type {
  AsyncScheduleDetails,
  AvailableStaffProps,
  AvailableStaffResponse,
  ChangeDispositionRequestProps,
  CreateNewTaskParams,
  RequestPAProps,
  UpdatedTaskResponseProps,
} from './task.types';
import { initialStateTask, initialStateTasks } from './task.types';
import { apiSlice } from '../api/apiSlice';

export const taskSlice = createSlice({
  name: 'task',
  initialState: initialStateTask,
  reducers: {
    setTaskDetails: (state, action) => {
      state.taskDetails = action.payload;
    },
    setIntakeItems: (state, action) => {
      state.intakeItems = [...action.payload].sort((currItems, prevItems) => currItems.sortNum - prevItems.sortNum);
    },
    setSelectRequireAppointment: (state, action) => {
      state.selectRequireAppointment = action.payload;
    },
    resetTask: () => initialStateTask,
  },
});

export const { setTaskDetails, setIntakeItems, resetTask, setSelectRequireAppointment } = taskSlice.actions;

export const tasksApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTasks: build.query<
      { tasks: TaskProps[]; totalCount: number; summary?: TasksSummaryProps; asyncProviderEnabled?: boolean },
      { params: TasksQueryParams }
    >({
      query: ({ params }) => {
        let queryParams = params;

        if (params?.category?.includes('History')) {
          queryParams = {
            ...params,
            completed: true,
          };
        }

        if (params?.startDueDate && !params?.endDueDate) {
          queryParams = {
            ...params,
            dueDate: params.startDueDate,
          };
          delete queryParams.startDueDate;
        }

        if (params?.startCreateDate && !params?.endCreateDate) {
          queryParams = {
            ...params,
            createdAt: params.startCreateDate,
          };
          delete queryParams.startCreateDate;
        }

        return {
          url: '/tasks',
          params: queryParams,
        };
      },
      keepUnusedDataFor: 30,
      transformResponse: (response: {
        data: TaskProps[];
        info: { summary: TasksSummaryProps; totalCount: number; asyncProviderEnabled?: boolean };
      }) => ({
        tasks: response.data,
        summary: response.info.summary,
        totalCount: response.info.totalCount,
        asyncProviderEnabled: response.info.asyncProviderEnabled,
      }),
      async onCacheEntryAdded(params, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        try {
          await cacheDataLoaded;

          const updateTaskInList = (data: UpdatedTaskResponseProps) => {
            if (isEmpty(data.updatedData)) return;

            updateCachedData((draft) => {
              if (!draft?.tasks?.length) return;
              const taskIndex = draft?.tasks.findIndex((task: TaskProps) => task._id === data.taskId);
              if (Number.isInteger(taskIndex) && taskIndex !== -1) {
                const updatedTasks: TaskProps[] = cloneDeep(draft.tasks);
                const isCancelledOnboarding =
                  updatedTasks[taskIndex].category === TaskCategories.Onboarding &&
                  data.updatedData.status?.toLowerCase() === AppointmentStatus.Cancelled;

                // NOTE: We have a tasks list with snoozed tasks on Patient page.
                // We want to have all tasks there even completed ones.
                // So we do not remove completed tasks from this list.
                // But we want to remove completed tasks from other lists.
                const isListWithSnoozesTasks = params?.params?.enableSnoozedTasks;

                // Remove task from list or update it
                if (
                  (NOT_ACTIVE_TASK_STATUSSES.includes(data.updatedData.status) &&
                    !params?.params?.category?.includes('History') &&
                    !isListWithSnoozesTasks) ||
                  isCancelledOnboarding
                ) {
                  updatedTasks.splice(taskIndex, 1);
                  draft.totalCount--;
                } else {
                  updatedTasks[taskIndex] = {
                    ...updatedTasks[taskIndex],
                    ...data.updatedData,
                  };
                }

                draft.tasks = updatedTasks;
              }
            });
          };

          const updateTaskDetails = (event: { tasksIdList: string[]; details: { note: string } }) => {
            updateCachedData((draft) => {
              if (!draft?.tasks?.length) return;
              event.tasksIdList.forEach((id) => {
                draft.tasks.forEach((task) => {
                  if (task._id === id) {
                    task.details.note = event.details.note;
                  }
                });
              });
            });
          };

          socketStaff.on('taskDetailUpdate', updateTaskDetails);
          socketStaff.on('taskUpdatedData', updateTaskInList);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        await cacheEntryRemoved;
      },
      providesTags: (result) =>
        result
          ? [...result.tasks.map(({ _id }) => ({ type: 'Task' as const, id: _id })), { type: 'Task', id: 'LIST' }]
          : [{ type: 'Task', id: 'LIST' }],
    }),
    getTaskDetails: build.query({
      query: ({ taskId, params }: { taskId: string; params?: { autoAssign?: boolean } }) => ({
        url: `/tasks/${taskId}`,
        params: params,
        method: 'GET',
      }),
      transformResponse: (response: { data: TaskDetailsProps }) => response.data,
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }) {
        try {
          await cacheDataLoaded;

          const updateTask = (data: UpdatedTaskResponseProps) => {
            if (isEmpty(data.updatedData)) return;

            updateCachedData((draft) => {
              const taskIndex = draft?._id === data.taskId;
              if (taskIndex) {
                const updatedTask = {
                  ...cloneDeep(draft),
                  ...data.updatedData,
                };
                draft = updatedTask;
                dispatch(setTaskDetails(updatedTask));
                if (data.updatedData.intakeItemsInfo) {
                  dispatch(setIntakeItems(data.updatedData.intakeItemsInfo));
                }
              }
            });
          };

          socketStaff.on('taskUpdatedData', updateTask);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        await cacheEntryRemoved;
      },
      providesTags: (_result, _error, arg) => (!arg.params?.autoAssign ? [{ type: 'Task', id: arg.taskId }] : []),
    }),
    createReminder: build.mutation<unknown, ReminderProps>({
      query: (body) => ({
        url: `/tasks/reminder`,
        body,
        method: 'POST',
      }),
    }),
    updateTaskAssign: build.mutation({
      query: ({ taskId, audience, releaseTask }: { taskId: string; audience?: string[]; releaseTask?: boolean }) => ({
        url: `/tasks/${taskId}/transfer`,
        body: { ...(releaseTask ? { releaseTask } : { audience }) },
        method: 'PATCH',
      }),
    }),
    updateTaskRequestToTakeOver: build.mutation({
      query: (taskId: string) => ({
        url: `/tasks/${taskId}/takeover`,
        method: 'PATCH',
      }),
      transformResponse: (response: { message: string }) => response,
    }),
    updateTaskComplete: build.mutation<{ message: string }, UpdateTaskCompleteParams>({
      query: ({
        taskId,
        status,
        note,
        copay,
        disposition,
        notPrescribingReason,
        snoozeDuration,
        shouldCreateRequestTaskToCancelAccount,
      }) => ({
        url: `/tasks/${taskId}/mark-complete`,
        method: 'PATCH',
        body: {
          status,
          note,
          copay,
          disposition,
          notPrescribingReason,
          snoozeDuration,
          shouldCreateRequestTaskToCancelAccount,
        },
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: (_result, _error, arg) =>
        arg.disposition === MarkCompleteReasons.AdditionalInfoRequired ? ['Task'] : [],
    }),
    updateSendTaskToPhysician: build.mutation({
      query: ({ taskId, body }: { taskId: string; body?: { markUrgent: boolean } }) => ({
        url: `/tasks/${taskId}/send-to-physician`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['Task'],
    }),
    createCallQuality: build.mutation({
      query: ({ taskId, callQualityInfo }: { taskId: string; callQualityInfo?: CallQualityInfo }) => ({
        url: `/tasks/${taskId}/call-quality`,
        body: callQualityInfo,
        method: 'POST',
      }),
    }),
    updateIntakeItems: build.mutation({
      query: ({ taskId, intakeItems }: { taskId: string; intakeItems: IntakeItemsInfo[] }) => ({
        url: `/tasks/${taskId}/update-intake-items`,
        method: 'POST',
        body: { intakeItems },
      }),
      async onQueryStarted({ taskId, ...intakeItems }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          tasksApi.util.updateQueryData('getTaskDetails', { taskId }, (draft) => {
            Object.assign(draft, intakeItems);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getTaskFiltersDetails: build.query<{ futureTasksRange: string }, void>({
      query: () => ({
        url: `/task-filters`,
        method: 'GET',
      }),
      transformResponse: (response: { data: { futureTasksRange: string } }) => response.data,
    }),
    updateTaskFiltersDetails: build.mutation<{ message: string }, { body: { futureTasksRange?: string } }>({
      query: ({ body }) => ({
        url: `/task-filters`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: { message: string }) => response,
    }),
    createNewTask: build.mutation<{ data: { id: string } }, { body: CreateNewTaskParams }>({
      query: ({ body }) => ({
        url: '/tasks/request',
        method: 'POST',
        body,
      }),
    }),
    stopReceivingTasks: build.mutation({
      query: (patientId: string) => ({
        url: `/patients/${patientId}/stop-receiving-tasks`,
        method: 'POST',
      }),
      invalidatesTags: ['Task'],
    }),
    assignProvider: build.mutation({
      query: ({ providerId, appointmentId }: { appointmentId: string; providerId: string; patientId: string }) => ({
        url: `/tasks/${appointmentId}/assign-provider`,
        method: 'PATCH',
        body: { userId: providerId },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Patient', id: arg.patientId }],
    }),
    getAvailableStaff: build.query<{ data: AvailableStaffResponse[] }, { params: AvailableStaffProps; taskId: string }>(
      {
        query: ({ taskId, params }) => ({
          url: `/tasks/${taskId}/available-staff`,
          params,
        }),
      },
    ),
    assignStaffToTask: build.mutation<{ message: string }, { taskId: string; staffId: string }>({
      query: ({ taskId, staffId }) => ({
        url: `/tasks/${taskId}/assign-staff`,
        method: 'PATCH',
        body: { userId: staffId },
      }),
      invalidatesTags: ['Task'],
    }),
    markPARequestSent: build.mutation<{ message: string }, { taskId: string }>({
      query: ({ taskId }) => ({
        url: `/tasks/${taskId}/mark-pa-request-sent`,
        method: 'PATCH',
        body: { taskId },
      }),
      transformResponse: (response: { data: { message: string } }) => response.data,
    }),
    requestPA: build.mutation<
      {
        data: {
          id: string;
        };
        message: string;
      },
      RequestPAProps
    >({
      query: (body) => ({
        url: `/tasks/request-pa`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Benefits'],
    }),
    removeBillingBlock: build.mutation({
      query: (patientId: string) => ({
        url: `/patients/${patientId}/remove-payment-block`,
        method: 'POST',
      }),
      invalidatesTags: ['Patient'],
    }),
    changeDisposition: build.mutation<{ message: string }, ChangeDispositionRequestProps>({
      query: ({ taskId, body }) => ({
        url: `/tasks/${taskId}/change-disposition`,
        method: 'PATCH',
        body,
      }),
    }),
    getAsyncScheduleDetails: build.query<{ data: AsyncScheduleDetails }, void>({
      query: () => ({
        url: `/async/schedule-details`,
      }),
    }),
  }),
});

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: initialStateTasks,
  reducers: {
    setTasksSummary: (state, action) => {
      state.tasksSummary = action.payload.summary;
    },
    setAsyncProviderEnabled: (state, action) => {
      state.asyncProviderEnabled = action.payload;
    },
  },
});

export const { setTasksSummary, setAsyncProviderEnabled } = tasksSlice.actions;

export const selectTask = (state: RootState) => state.task;
export const selectTasks = (state: RootState) => state.tasks;

export const {
  useLazyGetTasksQuery,
  useGetTaskDetailsQuery,
  useLazyGetTaskFiltersDetailsQuery,
  useLazyGetTaskDetailsQuery,
  useUpdateTaskAssignMutation,
  useUpdateSendTaskToPhysicianMutation,
  useUpdateTaskCompleteMutation,
  useCreateReminderMutation,
  useUpdateTaskRequestToTakeOverMutation,
  useUpdateIntakeItemsMutation,
  useUpdateTaskFiltersDetailsMutation,
  useCreateNewTaskMutation,
  useStopReceivingTasksMutation,
  useRemoveBillingBlockMutation,
  useAssignProviderMutation,
  useCreateCallQualityMutation,
  useLazyGetAvailableStaffQuery,
  useAssignStaffToTaskMutation,
  useMarkPARequestSentMutation,
  useRequestPAMutation,
  useChangeDispositionMutation,
  useLazyGetAsyncScheduleDetailsQuery,
} = tasksApi;
