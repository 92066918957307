import React, { useCallback, useEffect } from 'react';

import { Common } from '@thecvlb/design-system';
import { OptionProps } from '@thecvlb/design-system/lib/src/common/Select';
import { Controller, useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';

import { DEFAULT_APPOINTMENT_TYPE, DEFAULT_WEIGHT_MANAGEMENT_TYPE } from 'constants/appointmentType';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { useAppDispatch } from 'hooks/redux';
import { useGetLookupAppointmentTypesQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';

import { CategoryAndDetailsProps } from './categoryAndDetails.types';

const checkboxLabels = ['Prescription Refills', 'Aches & Pains (Head, ear, back, Mouth etc.)'];

const CategoryAndDetails: React.FC<CategoryAndDetailsProps> = ({
  onClickNext,
  handleBack,
  activePlanCode,
  hideHandleBackButton,
}) => {
  const dispatch = useAppDispatch();
  const { data: appointments, isLoading: isLoadingAppointments } = useGetLookupAppointmentTypesQuery({ params: '' });

  const [agreeNextStep, toggleAgreeNextStep] = useToggle(false);

  const { control, setValue, watch } = useFormContext();
  const appointmentTypeId = watch('appointmentTypeId');

  const selectedAppointmentType = useCallback(
    () => appointments?.find((appointment) => appointment._id === appointmentTypeId),
    [appointmentTypeId, appointments],
  );
  const displayCheckbox = checkboxLabels.includes(selectedAppointmentType()?.displayName || '');

  const options =
    appointments?.map((el) => ({
      label: el.displayName,
      value: el._id,
    })) || [];

  const handleOnClick = () => {
    const type = selectedAppointmentType();
    setValue('displayName', type?.displayName);
    setValue('uploadRequired', type?.uploadRequired);
    onClickNext(!!type?.uploadRequired);
  };

  //TODO: (Vlad) This is required for future changes (or will be removed later)
  const isWMPatient = activePlanCode === PlanCodesProps.WeightManagementMembership;

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (!appointments?.length) return;
    const selectedItem = selectedAppointmentType();
    if (selectedItem) {
      return setValue('appointmentTypeId', selectedItem._id);
    }
    const defaultItem = appointments.find((appointment) => {
      const appointmentType = isWMPatient ? DEFAULT_WEIGHT_MANAGEMENT_TYPE : DEFAULT_APPOINTMENT_TYPE;
      return appointment.displayName.toLowerCase().trim() === appointmentType.trim().toLowerCase();
    });
    if (defaultItem) {
      return setValue('appointmentTypeId', defaultItem._id);
    }
  }, [appointments, isWMPatient, selectedAppointmentType, setValue]);

  return (
    <div className="flex h-full grow flex-col items-center gap-4 md:gap-6">
      <div className="w-full">
        <Controller
          name="appointmentTypeId"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Please select category',
            },
          }}
          render={({ field }) => (
            <Common.Select
              dataTestId="appointmentTypeId"
              label="Category"
              options={options}
              placeholder="Select..."
              value={options.find((e) => e.value === field.value) as OptionProps}
              onChange={(e: OptionProps) => field.onChange(e.value as string)}
              disabled={isLoadingAppointments}
            />
          )}
        />
      </div>

      <Controller
        name="appointmentDescription"
        control={control}
        render={({ field }) => (
          <Common.TextArea
            dataTestId="appointmentDescription"
            className="w-full"
            label="Details"
            placeholder={
              selectedAppointmentType()?.displayName === 'Prescription Refills'
                ? 'What prescription are you looking to refill?'
                : 'What brings you in today?'
            }
            rows={3}
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
          />
        )}
      />

      {displayCheckbox ? (
        <Common.Checkbox checked={agreeNextStep} onChange={toggleAgreeNextStep}>
          <span>
            I understand that LifeMD does not provide prescriptions or refills for controlled substances such as
            benzodiazepines, narcotics or amphetamines.
          </span>
        </Common.Checkbox>
      ) : (
        <Common.Alert type="info" colorableBackground>
          <div className="flex flex-col text-base">
            <b>If you are on the call with the patient, please let them know:</b>
            <span>
              LifeMD does not provide prescriptions or refills for controlled substances such as benzodiazepines,
              narcotics or amphetamines.
            </span>
          </div>
        </Common.Alert>
      )}

      <div className="flex items-center justify-center gap-3">
        {hideHandleBackButton ? (
          <Common.Button color="white-alt" onClick={handleCloseModal}>
            Cancel
          </Common.Button>
        ) : (
          <Common.Button color="white-alt" preIcon="arrow-left" onClick={handleBack} type="button">
            Back
          </Common.Button>
        )}

        <Common.Button
          color="blue"
          disabled={!appointmentTypeId || (displayCheckbox && !agreeNextStep)}
          postIcon="arrow-right"
          onClick={handleOnClick}
        >
          Next
        </Common.Button>
      </div>
    </div>
  );
};

export default CategoryAndDetails;
