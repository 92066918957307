import { skipToken } from '@reduxjs/toolkit/query/react';
import { useParams } from 'react-router-dom';

import Loader from 'components/common/Loader';
import MessagesSection from 'components/modals/smsModal/MessagesSection';
import { useGetPatientQuery } from 'store/patients/patientsSlice';

const SMS = () => {
  const { patientId = '' } = useParams<{ patientId: string }>();
  const { data, isLoading } = useGetPatientQuery(patientId ? { id: patientId } : skipToken);

  return (
    <div data-testid="sms_chat_area" className="flex h-full flex-col">
      <div className="flex max-h-14 flex-col items-center justify-center gap-1 border-b border-gray-200 py-1">
        <p data-testid="sms_chat_header" className="text-lg font-bold text-gray-700">
          SMS
        </p>
        <p data-testid="recipient" className="text-sm font-medium text-gray-500">
          Patient
        </p>
      </div>

      <Loader isVisible={isLoading} />

      <>{data?._id && <MessagesSection patientId={data._id} patientPhone={data?.phone} />}</>
    </div>
  );
};

export default SMS;
