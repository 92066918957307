import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/RequestMessageTemplatesTable/columns';
import { StatusFilterData } from 'constants/filters';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { RequestMessageTemplatesQueryParams } from 'store/requestMessageTemplates/requestMessageTemplates.types';
import { useGetRequestMessageTemplatesQuery } from 'store/requestMessageTemplates/requestMessageTemplatesSlice';

const RequestMessageTemplates: React.FC = () => {
  const [searchParams] = useSearchParams();
  const queryParams: RequestMessageTemplatesQueryParams = useMemo(
    () => queryString.parse(searchParams.toString()),
    [searchParams],
  );
  const { data, isFetching, isUninitialized } = useGetRequestMessageTemplatesQuery({
    params: queryParams,
  });
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.data,
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={StatusFilterData} queryParam="status" />
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable table={table} totalCount={data?.totalCount || 0} isFetching={isFetching} />
      </Card>
    </>
  );
};

export default RequestMessageTemplates;
