import classNames from 'classnames';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { ASAP_OPTION_DATE } from 'constants/appointmentType';

import { LeftArrow, RightArrow } from './Arrows/Arrows';
import DayCard from './DayCard';
import { SchedulingDaySliderProps } from './schedulingDaySlider.types';

const SchedulingDaySlider: React.FC<SchedulingDaySliderProps> = ({
  dates,
  onSelect,
  selected,
  isCalendarSelected,
  loading,
  isAsapAvailable,
  queueWaitingTime,
}) => {
  const handleClick = (day: string) => () => {
    onSelect(day);
  };

  if (dates.length === 0 && !loading) {
    return <></>;
  }

  return dates.length === 0 && loading ? (
    <div className="flex gap-2 overflow-hidden">
      {Array.from(Array(6).keys()).map((val) => {
        return <div key={val} className="h-20 w-24 min-w-20 animate-pulse rounded-lg bg-slate-200" />;
      })}
    </div>
  ) : (
    <ScrollMenu
      itemClassName="shadow rounded-lg"
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      separatorClassName="mx-1 md:mx-1.5"
      wrapperClassName={classNames('overflow-visible transition-all ', {
        'animate-pulse': loading,
      })}
    >
      {dates.map((date) => (
        <DayCard
          date={date}
          disabled={!isAsapAvailable && date === ASAP_OPTION_DATE}
          itemId={date}
          key={date}
          loading={loading}
          queueWaitingTime={queueWaitingTime}
          selected={selected === date || (date === 'calendar' && isCalendarSelected)}
          selectedDate={selected}
          onClick={handleClick(date)}
        />
      ))}
    </ScrollMenu>
  );
};

export default SchedulingDaySlider;
