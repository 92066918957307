import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/ProspectTable/columns';
import { ProspectsFilterData } from 'constants/filters';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { PatientsQueryParams } from 'models/patients.types';
import { useGetProspectsQuery } from 'store/patients/patientsSlice';

const Prospects = () => {
  const [searchParams] = useSearchParams();

  const queryParams: PatientsQueryParams = useMemo(() => {
    return queryString.parse(searchParams.toString());
  }, [searchParams]);

  const { data, isFetching, isUninitialized } = useGetProspectsQuery({ params: queryParams });
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.prospects,
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={ProspectsFilterData} queryParam="prospectStatus" />
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable table={table} totalCount={data?.totalCount || 0} isFetching={isFetching} />
      </Card>
    </>
  );
};

export default Prospects;
