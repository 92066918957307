import { MouseEventHandler, useEffect, useState } from 'react';

import { XCircleIcon } from '@heroicons/react/20/solid';
import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import InputField from 'components/forms/controlled/InputField';

import { EducationGroupInputsProps, EducationListItem } from './educationGroupInputs.types';

const MAX_EDUCATION_LIST_LENGTH = 10;

const EducationGroupInputs: React.FC<EducationGroupInputsProps> = ({
  value = [],
  labelClasses = 'w-52 text-xl',
  formState,
  control,
  onChange,
  dataTestId,
}) => {
  const [currentInputId, setCurrentInputId] = useState<string>('');
  const [educationList, setEducationList] = useState<EducationListItem[]>(value);
  const educationListLength = educationList.length;

  const onClickNew = () => {
    setEducationList((list) => [...list, { value: '', id: nanoid() }]);
  };

  const handleRemove: MouseEventHandler<SVGSVGElement> = (e) => {
    if (educationListLength > 1) {
      const currentId = e.currentTarget.id;
      const newEducationList = educationList.filter((item) => currentId !== item.id);
      setEducationList(newEducationList);
      onChange && onChange(newEducationList);
    }
  };

  const XIconClasses = classNames(
    'absolute top-2.5 w-3 h-3',
    formState.errors.payAmount ? '-right-4' : 'right-3',
    educationListLength > 1 ? 'text-primary-500' : 'text-gray-200',
  );

  useEffect(() => {
    if (value.length) {
      setEducationList(value);
    }
  }, [value]);

  const onChangeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { id, value: eventValue } = e.target;
    const newArray = educationList.map((educationItem) => {
      if (educationItem.id.toString() === id) {
        return { value: eventValue || '', id: id };
      }
      return educationItem;
    });
    onChange && onChange(newArray);
    setCurrentInputId(id);
  };

  return (
    <div className="flex justify-between">
      <label className={labelClasses}>Education</label>
      <div className="flex grow flex-col">
        {educationList.map((item, index) => (
          <div className="flex grow items-start justify-between" key={item.id}>
            <div data-testid={dataTestId} className="relative w-4/5">
              <InputField
                name={`education${item.id}`}
                control={control}
                placeholder={'Add education...'}
                labelDirection="row"
                className={index === educationList.length - 1 ? 'mb-0' : 'mb-3'}
                autoFocus={currentInputId === item.id.toString()}
                id={item.id.toString()}
                value={item.value ?? ''}
                onChange={onChangeInput}
                type="text"
                errors={formState.errors.education}
              />
              <XCircleIcon
                data-testid="clear_btn"
                id={item.id.toString()}
                className={XIconClasses}
                onClick={handleRemove}
              />
            </div>
            {index === educationListLength - 1 && (
              <Common.NewButton onClick={onClickNew} disabled={educationListLength >= MAX_EDUCATION_LIST_LENGTH} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EducationGroupInputs;
