import { useEffect, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Common } from '@thecvlb/design-system/lib/src';
import { useController, useFormContext } from 'react-hook-form';

import { useGetMembershipPlansQuery } from 'store/crossSell/crossSellSlice';
import { getDefaultPricePoint } from 'utils/helpers';

import { MembershipPlanProps } from './planType.types';
import RadioButtonCard from '../RadioButtonCard';

const priceClassList = [
  {
    label: 'Standard',
    value: 0,
  },
  {
    label: 'Member cross-sell',
    value: 1,
  },
];

const PlanType: React.FC = () => {
  const { control, formState, setValue } = useFormContext();
  const { data, isSuccess } = useGetMembershipPlansQuery();
  const [selectPriceClass, setSelectPriceClass] = useState(0);
  const [sortedData, setSortedData] = useState<MembershipPlanProps[]>([]);
  const { field } = useController({
    name: 'planType',
    control,
    defaultValue: { planName: '' },
    rules: {
      required: {
        value: true,
        message: 'Plan type is required',
      },
    },
  });
  const showMembership = (membership: MembershipPlanProps, selectPriceClassValue: number) =>
    !membership.isComponent &&
    (membership.priceClass === 'all' ||
      (!selectPriceClassValue ? membership.priceClass === 'standard' : membership.priceClass === 'member-cross-sell'));

  const handleChange = (membershipPlan: MembershipPlanProps) => {
    setValue('planType', membershipPlan);
  };

  const handleChangePriceClass = (value: string) => {
    setSelectPriceClass(+value);
    const selectedPlan = data?.find(
      (plan) => field.value?.planName?.includes(plan.planName) && showMembership(plan, +value),
    );
    selectedPlan && handleChange(selectedPlan);
  };

  const isSelectPriceClass = (value: number) => {
    return value === selectPriceClass;
  };

  useEffect(() => {
    if (sortedData) setValue('planType', sortedData[0]);
  }, [isSuccess, setValue, sortedData]);

  useEffect(() => {
    if (data) {
      const newSortedData = [...(data || [])]
        ?.sort((a, b) => {
          const pricePointA = getDefaultPricePoint(a.pricePoints);
          const pricePointB = getDefaultPricePoint(b.pricePoints);

          return Number(pricePointA?.totalCost) - Number(pricePointB?.totalCost);
        })
        .filter((membership) => showMembership(membership, selectPriceClass));

      setSortedData(newSortedData);
    }
  }, [data, selectPriceClass]);

  return (
    <>
      <div className="mb-4 flex flex-col gap-4">
        <h2 className="text-base font-semibold text-gray-700">Price class</h2>
        <div data-testid="prices_classes_block" className="flex gap-4">
          {priceClassList.map((priceClass) => (
            <Common.RadioButton
              dataTestId="price_class"
              key={priceClass.value}
              color="blue"
              size="md"
              value={priceClass.value}
              checked={isSelectPriceClass(priceClass.value)}
              onChange={(e) => {
                handleChangePriceClass(e.target.value);
              }}
            >
              {priceClass.label}
            </Common.RadioButton>
          ))}
        </div>
        <h2 className="text-base font-semibold text-gray-700">Plan</h2>
      </div>
      <div data-testid="price_section" className="flex flex-wrap gap-4">
        {sortedData.map((membership) => {
          const pricePoint = getDefaultPricePoint(membership.pricePoints);
          return (
            <RadioButtonCard
              key={membership._id}
              label={`${membership.planName} ($${pricePoint?.totalCost}/mo)`}
              isSelected={field.value?.planName?.includes(membership.planName) || false}
              value={membership.planName}
              handleChange={() => handleChange(membership)}
              className="max-w-[160px]"
            />
          );
        })}
      </div>
      <ErrorMessage
        errors={formState.errors}
        name="planType"
        render={({ message }) => <p className="text-xs text-red">{message}</p>}
      />
    </>
  );
};

export default PlanType;
