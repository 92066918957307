import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import DatePickerInput from 'components/common/DatePickerInput';
import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'constants/dateFormat';
import { DateFormat } from 'enums/dateFormats';
import { useUpdateOrderMutation } from 'store/orders/ordersSlice';

import { RenewsDateProps } from './orderDate.types';

const OrderDate: React.FC<RenewsDateProps> = ({ date, type, orderId, isRtL, startAllowedDate = new Date(), role }) => {
  const [updateOrder] = useUpdateOrderMutation();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [inputValue, setInputValue] = useState<string>('');
  const canEdit = role !== 'MA';

  const handleRenewsDateUpdate = (newDate: string) => {
    const formattedDate = dayjs(newDate, FRONTEND_DATE_FORMAT).format(BACKEND_DATE_FORMAT);
    setInputValue(newDate);
    if (orderId && formattedDate) {
      updateOrder({ orderId: orderId, order: { [type]: formattedDate } });
    }
  };

  useEffect(() => {
    if (date) {
      setSelectedDate(dayjs(date, DateFormat.MM_DD_YYYY).toDate());
      setInputValue(date);
    }
  }, [date]);

  return (
    <DatePickerInput
      placeholder="Select renews"
      size="md"
      inputValue={inputValue}
      setInputValue={handleRenewsDateUpdate}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      startAllowedDate={startAllowedDate}
      lastAllowedDate={dayjs().add(100, 'year').toDate()}
      captionLayout="dropdown"
      wrapperClasses="w-full relative"
      labelClassNames="text-gray-500"
      isRtL={isRtL}
      showDateLabel
      showPostIcon={canEdit}
    />
  );
};

export default OrderDate;
