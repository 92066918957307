import { useCallback, useMemo } from 'react';

import { AutocompleteInputSelectProps } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { useDebounce } from 'react-use';

import { useAppSelector } from 'hooks/redux';
import type { AvailableStaffResponse } from 'store/tasks/task.types';
import { selectTask, useLazyGetAvailableStaffQuery } from 'store/tasks/tasksSlice';

export const useGetAvailableStaff = (query: string, userId?: string, taskId?: string) => {
  const [getAvailableStaff, { data: availableStaff, isFetching, isLoading }] = useLazyGetAvailableStaffQuery();
  const { taskDetails } = useAppSelector(selectTask);

  useDebounce(
    () => {
      if (query?.trim()) {
        getAvailableStaff({ taskId: taskId ?? taskDetails._id, params: { searchKey: query } });
      }
    },
    1000,
    [query, taskDetails, taskId],
  );

  const normalizeAvailableStaffData = useCallback(
    (data: AvailableStaffResponse[]): AutocompleteInputSelectProps['options'] =>
      data.flatMap((item) =>
        item._id === userId
          ? []
          : [
              {
                label: item.name,
                value: item,
                id: item._id,
                avatar: {
                  size: 'sm',
                  role: item.userType.shortCode,
                  src: item.profileImage,
                },
                customClassName: !!item.errors.length ? 'opacity-30' : '',
              },
            ],
      ),
    [userId],
  );

  const searchData: AutocompleteInputSelectProps['options'] = useMemo(
    () => (availableStaff?.data?.length ? normalizeAvailableStaffData(availableStaff.data) : []),
    [availableStaff, normalizeAvailableStaffData],
  );

  return useMemo(
    () => ({
      searchData,
      isLoading: isLoading || isFetching,
    }),
    [searchData, isLoading, isFetching],
  );
};
