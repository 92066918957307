import { Common } from '@thecvlb/design-system';

import LockIcon from 'assets/icons/paymentForm/bytesize_lock.svg?react';

import PaymentForm from './PaymentForm';
import PriceCard from './PriceCard';

const ConfirmAndPay = () => {
  return (
    <>
      <div data-testid="confirm_pay_section" className="mb-8 flex justify-between gap-8">
        <PaymentForm />
        <PriceCard />
      </div>
      <div className="flex flex-col items-center justify-center">
        <Common.Button style="pill" color="blue" size="sm" postIcon="arrow-right" className="mt-4 text-base font-bold">
          Create account
        </Common.Button>
        <div className="mt-4 flex items-center">
          <LockIcon />
          <span className="ml-2.5 text-sm font-bold">SSL, Encrypted, Secure Payment</span>
        </div>
      </div>
    </>
  );
};

export default ConfirmAndPay;
