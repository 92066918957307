export interface CreateTaskFormProps {
  patient?: { id: string; fullName: string; state?: string } | null;
}

export interface CreateTaskFormState {
  patient: { id: string; label: string; value: string };
  assignTo: string;
  staff: { id: string; label: string; value: string };
  taskDetails: string;
  dueDate: { date: string; time: string };
  isUrgent: boolean;
  taskCategory: { label: string; value: string };
  medication?: { label: string; value: string };
}

export enum TaskCategory {
  Request = 'Request',
  RequestPA = 'Request PA',
}
